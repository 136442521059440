import React from 'react';
import { CheckboxChecked, CheckboxUnChecked } from 'shared/assets/icons';
import { useAppSelector, useMediaQuery } from 'shared/lib';
import { devices } from 'shared/constants';

interface Props {
  toggleCheckbox?: () => void;
  checked: boolean;
  disabled?: boolean;
}

export const Checkbox: React.FC<Props> = ({ checked, toggleCheckbox, disabled = false }) => {
  const isMobile = useMediaQuery(devices.mobile);
  const theme = useAppSelector((state) => state.theme.theme);
  const size = { width: isMobile ? 16 : 24, height: isMobile ? 16 : 24 };

  const isDarkTheme = theme === 'dark';
  return (
    <label style={{ cursor: disabled ? 'default' : 'pointer' }}>
      <input type="checkbox" defaultChecked={checked} onChange={toggleCheckbox} style={{ display: 'none' }} />
      <span>{checked ? <CheckboxChecked {...size} /> : <CheckboxUnChecked {...size} isDarkTheme={isDarkTheme} />}</span>
    </label>
  );
};
