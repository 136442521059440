export enum LoadingResultsT {
  IDLE = 'idle',
  PENDING = 'pending',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export type LoadingType =
  | LoadingResultsT.IDLE
  | LoadingResultsT.PENDING
  | LoadingResultsT.SUCCEEDED
  | LoadingResultsT.FAILED;
