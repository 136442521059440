import { CurrenciesType } from 'entities/priceList';
import { ImageValuesType } from 'shared/ui';

export const defaultValues: CreateProjectValues = {
  name: '',
  address: null,
  square: '',
  budget: '',
  currency: null,
  image: null,
  customer: {
    value: '',
    id: null,
  },
};

export type CreateProjectValues = {
  name: string;
  address: string | null;
  square: string;
  budget?: string;
  currency: {
    title: CurrenciesType;
    text: string;
  } | null;
  image: ImageValuesType | null;
  customer: CustomerInfoType;
};

export type CustomerInfoType = {
  value: string;
  id: number | null;
};
