import styled from '@emotion/styled';

export const DropDownContainer = styled('div')(({ className }) => ({
  position: 'absolute',
  left: 0,
  width: '280px',
  '& ul': {
    top: 2,
    width: '100%',
  },
  '& li': {
    width: '100%',
    justifyContent: 'flex-start',
  },
}));

export const ArrowContainer = styled('div')(({ className }) => ({
  position: 'absolute',
  right: className?.includes('import') ? '10px' : '16px',
  top: className?.includes('import') ? '24px' : '16px',
  cursor: 'pointer',
  '@media (min-width: 769px)': {
    right: '16px',
    top: className?.includes('import') ? '8px' : '16px',
  },
}));
