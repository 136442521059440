import React, { useEffect, useRef, useState } from 'react';
import { DraggableProvided } from '@hello-pangea/dnd';
import { useParams } from 'react-router-dom';
import { DeleteItemsModal, CreatePositionsModal, EditPositionsModal } from 'widgets';
import { IPriceList, duplicatePosition, editPosition } from 'entities/priceList';
import { addCheckedPositions, removeCheckedPositions } from 'entities/positionsFilter';

import {
  DragIcon,
  MaterialsIcon,
  WorkIcon,
  GradientRoundPlus,
  Dots,
  Trash,
  Edit,
  DoubleIcon,
  HorizontalDivider,
} from 'shared/assets/icons';
import { Checkbox } from 'shared/ui';
import { useHover, useMediaQuery, useAppSelector, useAppDispatch, getCategoryById } from 'shared/lib';
import { COLORS, devices, text } from 'shared/constants';

import {
  AddCategoryButtonWrapper,
  ButtonTableCellContainer,
  DropdownBody,
  DropdownElement,
  MainTableCellContainer,
  PriceTableCellContainer,
  RowButtonWrapper,
  SecondaryTableCellContainer,
  TableRowContainer,
} from '../styles';
import { PositionRowDropDownArticleContainer, PositionRowDropDownDotsContainer } from './styles';

interface Props {
  position: IPriceList;
  provided: DraggableProvided;
  isDragging: boolean;
  isModalPriceList: boolean;
}

const PriceListPositionTableRow: React.FC<Props> = ({ position, provided, isDragging, isModalPriceList }) => {
  const isMobile = useMediaQuery(devices.mobile);
  const activeCurrency = useAppSelector((state) => state.currencies.activeCurrency);
  const language = useAppSelector((state) => state.language.language);
  const company = useAppSelector((state) => state.company.company);
  const categories = useAppSelector((state) => state.categories.categories);
  const projects = useAppSelector((state) => state.projects.projects);
  const checkedPositionsIds = useAppSelector((state) => state.positionsFilter.checkedPositionsIds);
  const userCurrencyList = useAppSelector((state) => state.currencies.userCurrencyList);
  const theme = useAppSelector((state) => state.theme.theme);
  const dispatch = useAppDispatch();

  const positionRow = useRef<HTMLTableRowElement | null>(null);
  const positionRowHovered = useHover(positionRow.current);

  const [openDeletePositionModal, setOpenDeletePositionModal] = useState(false);
  const [openEditPositionModal, setOpenEditPositionModal] = useState(false);
  const [openAddPositionModal, setOpenAddPositionModal] = useState(false);
  const [isOpenedDotsDropDown, setIsOpenedDotsDropDown] = useState<boolean>(false);
  const [isOpenedArticleDropDown, setIsOpenedArticleDropDown] = useState<boolean>(false);
  const [checked, setChecked] = useState(false);
  const [currency, setCurrency] = useState(activeCurrency);

  const { projectId } = useParams();
  const currentProjectCurrency = projects?.find((proj) => proj.id.toString() === projectId)?.currency;

  const isPositionInCategory = categories && getCategoryById(position.category.id, categories)?.primary === false;

  function toggleCheckbox() {
    if (checked) {
      dispatch(removeCheckedPositions([position.id]));
    } else {
      dispatch(addCheckedPositions([position.id]));
    }
    setChecked(!checked);
  }

  useEffect(() => {
    if (currentProjectCurrency && isModalPriceList) {
      return setCurrency(currentProjectCurrency);
    } else if (activeCurrency) {
      setCurrency(activeCurrency);
    }
  }, [activeCurrency, isModalPriceList, currentProjectCurrency]);

  useEffect(() => {
    if (checkedPositionsIds.includes(position.id)) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [checkedPositionsIds, position.id]);

  useEffect(() => {
    if (!positionRowHovered) {
      setIsOpenedDotsDropDown(false);
      setIsOpenedArticleDropDown(false);
    }
  }, [positionRowHovered]);

  function OnDotsClick() {
    setIsOpenedDotsDropDown(!isOpenedDotsDropDown);
  }

  function OnAddPositionClick() {
    setOpenAddPositionModal(true);
  }

  function OnDoublePositionClick() {
    if (!company) return;
    dispatch(
      duplicatePosition({
        companyID: company[0].id,
        articleID: position.article,
        categoryID: position.category.id,
        name: position.name,
        unit: position.units,
        funds: position.funds,
        order: position.order + 1,
        nextTo: position.id,
      }),
    );
  }

  function OnEditPositionClick() {
    setOpenEditPositionModal(true);
  }

  function OnDeletePositionClick() {
    setOpenDeletePositionModal(true);
  }

  function OnChangeArticleClick(articleNum: number) {
    if (articleNum !== position.article && company) {
      dispatch(
        editPosition({
          companyID: company[0].id,
          articleID: articleNum,
          name: position.name,
          unit: position.units,
          funds: position.funds,
          itemID: position.id,
          isHidden: position.isHidden,
        }),
      );
      setIsOpenedArticleDropDown(false);
    }
  }

  const rowClassName = () => {
    let cn = '';

    if (isDarkTheme) cn += 'dark';
    if (isModalPriceList) cn += 'modalPriceList';
    if (isDragging) cn += 'dragging';

    return cn;
  };

  const isDarkTheme = theme === 'dark';
  //TODO: refactoring classnames
  return (
    <>
      <TableRowContainer ref={positionRow} className={rowClassName()}>
        {!isModalPriceList && (
          <>
            {!isMobile && (
              <AddCategoryButtonWrapper className="hoveredContainer" id="AddItem" onClick={OnAddPositionClick}>
                <GradientRoundPlus />
              </AddCategoryButtonWrapper>
            )}
            <ButtonTableCellContainer {...provided.dragHandleProps}>
              {isMobile && (
                <RowButtonWrapper className={isDarkTheme ? 'dark' : ''}>
                  <DragIcon size={16} />
                </RowButtonWrapper>
              )}
              {!isMobile && (
                <RowButtonWrapper className={isDarkTheme ? 'hoveredContainer dark' : 'hoveredContainer'}>
                  <DragIcon />
                </RowButtonWrapper>
              )}
            </ButtonTableCellContainer>
          </>
        )}
        <ButtonTableCellContainer className={isModalPriceList ? 'modalPriceList' : ''}>
          <Checkbox toggleCheckbox={toggleCheckbox} checked={checked} />
        </ButtonTableCellContainer>
        <ButtonTableCellContainer className={isModalPriceList ? 'modalPriceList' : ''}>
          <RowButtonWrapper
            className={isDarkTheme ? 'dark' : ''}
            onClick={() => setIsOpenedArticleDropDown(!isOpenedArticleDropDown)}
          >
            {position.article === 1 ? (
              <MaterialsIcon size={isModalPriceList && isMobile ? 14 : 20} id="CostItem" />
            ) : (
              <WorkIcon size={isModalPriceList && isMobile ? 14 : 20} id="CostItem" language={language} />
            )}
            {isOpenedArticleDropDown && !isModalPriceList && (
              <PositionRowDropDownArticleContainer>
                <DropdownBody className={isDarkTheme ? 'dark' : ''}>
                  <DropdownElement
                    className={isDarkTheme ? 'dark without-flashing-svg' : 'without-flashing-svg'}
                    onClick={() => OnChangeArticleClick(1)}
                  >
                    <MaterialsIcon />
                    <span>{text[language].Materials}</span>
                  </DropdownElement>
                  <DropdownElement
                    className={isDarkTheme ? 'dark without-flashing-svg' : 'without-flashing-svg'}
                    onClick={() => OnChangeArticleClick(2)}
                  >
                    <WorkIcon language={language} />
                    <span>{text[language].Work}</span>
                  </DropdownElement>
                </DropdownBody>
              </PositionRowDropDownArticleContainer>
            )}
          </RowButtonWrapper>
        </ButtonTableCellContainer>
        <MainTableCellContainer className={isModalPriceList ? 'modalPriceList' : ''}>
          {isPositionInCategory && <HorizontalDivider />}
          <span>{position.name}</span>
        </MainTableCellContainer>
        <SecondaryTableCellContainer className={isModalPriceList ? 'modalPriceList' : ''}>
          {position.units}
        </SecondaryTableCellContainer>
        <PriceTableCellContainer className={isModalPriceList ? 'modalPriceList' : ''}>
          {currency && position.funds[currency]?.total.toFixed(2)}
        </PriceTableCellContainer>
        <PriceTableCellContainer className={isModalPriceList ? 'modalPriceList' : ''}>
          {currency && (position.funds[currency]?.cost?.toFixed(2) || position.funds[currency]?.total.toFixed(2))}
        </PriceTableCellContainer>
        {!isMobile && !isModalPriceList && <ButtonTableCellContainer></ButtonTableCellContainer>}
        {!isModalPriceList && (
          <ButtonTableCellContainer>
            <RowButtonWrapper
              className={isDarkTheme ? 'hoveredContainer dark' : 'hoveredContainer'}
              onClick={OnDotsClick}
            >
              <Dots size={isMobile ? 16 : 24} id="More" isActive={isOpenedDotsDropDown} />
              {isOpenedDotsDropDown && (
                <PositionRowDropDownDotsContainer>
                  <DropdownBody className={isDarkTheme ? 'dark' : ''}>
                    <DropdownElement className={isDarkTheme ? 'dark' : ''} onClick={OnEditPositionClick}>
                      <Edit
                        size={isMobile ? 16 : 24}
                        stroke={isDarkTheme ? COLORS.TEXT_WHITE : COLORS.ACTION_BAR_BLACK_BG}
                      />
                      <span>{text[language].EditItem}</span>
                    </DropdownElement>
                    <DropdownElement className={isDarkTheme ? 'dark' : ''} onClick={OnDoublePositionClick}>
                      <DoubleIcon
                        size={isMobile ? 16 : 24}
                        stroke={isDarkTheme ? COLORS.TEXT_WHITE : COLORS.ACTION_BAR_BLACK_BG}
                      />
                      <span>{text[language].DoubleItem}</span>
                    </DropdownElement>
                    <DropdownElement className={isDarkTheme ? 'dark' : ''} onClick={OnDeletePositionClick}>
                      <Trash
                        stroke={isDarkTheme ? COLORS.TEXT_WHITE : COLORS.ACTION_BAR_BLACK_BG}
                        size={isMobile ? 16 : 24}
                      />
                      <span>{text[language].DeleteItem}</span>
                    </DropdownElement>
                  </DropdownBody>
                </PositionRowDropDownDotsContainer>
              )}
            </RowButtonWrapper>
          </ButtonTableCellContainer>
        )}
      </TableRowContainer>
      <CreatePositionsModal
        handleClose={() => setOpenAddPositionModal(false)}
        isOpen={openAddPositionModal}
        order={position.order + 1}
        category={position.category.id}
        nextTo={position.id}
      />
      <DeleteItemsModal
        handleClose={() => setOpenDeletePositionModal(false)}
        isOpen={openDeletePositionModal}
        type={{ type: 'position', count: 'single', currency: userCurrencyList.length > 1 ? 'multiple' : 'single' }}
        positionID={[position.id]}
      />
      <EditPositionsModal
        handleClose={() => setOpenEditPositionModal(false)}
        isOpen={openEditPositionModal}
        positionID={position.id}
      />
    </>
  );
};

export default PriceListPositionTableRow;
