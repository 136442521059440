import React, { useEffect, useState } from 'react';
import { PriceListTable } from 'widgets/PriceListTable';
import { AddCurrencyAlertModal } from 'widgets';
import { IPriceList, resetFilteredPriceList, resetSearchingPriceList, setFilteredPriceList } from 'entities/priceList';
import { PriceItemType, setEstimatesPriceList, setEstimatesPriceListCategories } from 'entities/estimates';
import { resetPositionsFilterList } from 'entities/positionsFilter';
import { resetFilteredCategories, setFilteredCategories } from 'entities/category';
import { COLORS, devices, text } from 'shared/constants';
import { GetSearchedCategories, useAppDispatch, useAppSelector, useMediaQuery, GetSearchedPositions } from 'shared/lib';
import { ActionButton, Input, ModalWrapper } from 'shared/ui';
import { CloseButton, SearchIcon } from 'shared/assets/icons';
import {
  ClearSearchButton,
  ModalButtonsWrapper,
  ScrollableContainer,
  SearchContainer,
  SearchIconContainer,
} from './styles';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  projectId: number | null;
}

export const ChoosePositionsFromPriceListModal: React.FC<Props> = ({ isOpen, handleClose, projectId }) => {
  const language = useAppSelector((state) => state.language.language);
  const priceList = useAppSelector((state) => state.priceList.priceList);
  const filteredByCurrencyPriceList = useAppSelector((state) => state.priceList.filteredByCurrencyPriceList);
  const categories = useAppSelector((state) => state.categories.categories);
  const projects = useAppSelector((state) => state.projects.projects);
  const estimatePriceList = useAppSelector((state) => state.estimates.estimatePriceList);
  const estimatePriceListCategories = useAppSelector((state) => state.estimates.estimatePriceListCategories);
  const checkedPositionsIds = useAppSelector((state) => state.positionsFilter.checkedPositionsIds);
  const checkedCategoryIds = useAppSelector((state) => state.positionsFilter.checkedCategoryIds);
  const userCurrencies = useAppSelector((state) => state.currencies.userCurrencyList);
  const theme = useAppSelector((state) => state.theme.theme);
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState('');
  const [isOpenCurrencyAlertModal, setIsOpenCurrencyAlertModal] = useState(false);

  const isMobile = useMediaQuery(devices.mobile);
  const isTablet = useMediaQuery(devices.tablet);
  const isTabletOrMobile = isMobile || isTablet;
  const currentCurrency = projects?.find((proj) => proj.id === projectId)?.currency;

  useEffect(() => {
    if (!filteredByCurrencyPriceList || !priceList || !isOpen || !currentCurrency) return;
    const currencyInCurrenciesList = userCurrencies.includes(currentCurrency);
    setIsOpenCurrencyAlertModal(filteredByCurrencyPriceList?.length !== priceList?.length && !currencyInCurrenciesList);
  }, [filteredByCurrencyPriceList, priceList, isOpen, currentCurrency, userCurrencies]);

  const parseData = (positions: IPriceList[]): PriceItemType[] => {
    return positions.map((position) => {
      const funds = position.funds[currentCurrency || 'UAH'];
      return {
        article: position.article,
        funds,
        id: position.id,
        name: position.name,
        quantity: 1,
        units: position.units,
        category: position.category,
        isTemporary: position.isTemporary,
      };
    });
  };

  const clearSearch = () => {
    setSearch('');
    dispatch(resetSearchingPriceList());
    dispatch(resetFilteredCategories());
  };

  //TODO: refactoring
  const onChoosePositionsClick = () => {
    const checkedPositions = priceList?.filter((position) => checkedPositionsIds.includes(position.id));
    const checkedCategories = categories?.filter((position) => checkedCategoryIds.includes(position.id));
    if (!checkedPositions) return;
    const preparedPositions = parseData(checkedPositions);

    const categoriesFromPositions = categories?.filter((position) =>
      preparedPositions.map((pos) => pos.category?.id).includes(position.id),
    );
    dispatch(setEstimatesPriceList([...preparedPositions, ...(estimatePriceList || [])]));
    const allCategoriesArray = [...(checkedCategories || []), ...(categoriesFromPositions || [])];
    if (allCategoriesArray) {
      dispatch(setEstimatesPriceListCategories([...allCategoriesArray, ...(estimatePriceListCategories || [])]));
    }
    dispatch(resetPositionsFilterList());
    clearSearch();
    handleClose();
  };

  const isDisabled = checkedPositionsIds.length > 0 || checkedCategoryIds.length > 0;
  const isDarkTheme = theme === 'dark';
  return (
    <ModalWrapper isOpen={isOpen} title={text[language].choosePosFromPriceList} titleClassName="smallTitleSize">
      <ScrollableContainer>
        <SearchContainer className={isDarkTheme ? 'dark' : ''}>
          <SearchIconContainer>
            <SearchIcon size={isTabletOrMobile ? 12 : 24} active={!!search} isDarkTheme={isDarkTheme} />
          </SearchIconContainer>
          <Input
            placeholder={text[language].SearchItemByName}
            value={search}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (!search && !event.target.value.trim()) {
                return false;
              }
              if (!filteredByCurrencyPriceList || !categories) return;
              dispatch(setFilteredPriceList(GetSearchedPositions(event.target.value, filteredByCurrencyPriceList)));
              dispatch(
                setFilteredCategories(
                  GetSearchedCategories(event.target.value, categories, filteredByCurrencyPriceList),
                ),
              );

              setSearch(event.target.value);
              if (!event.target.value.trim().length) {
                clearSearch();
              }
            }}
            withoutLabel={true}
          />
          {!!search && (
            <ClearSearchButton onClick={clearSearch}>
              <CloseButton
                size={isTabletOrMobile ? 12 : 24}
                stroke={isDarkTheme ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK}
              />
            </ClearSearchButton>
          )}
        </SearchContainer>
        <PriceListTable isModalPriceList={true} projectCurrency={currentCurrency} />
      </ScrollableContainer>
      <ModalButtonsWrapper>
        <ActionButton
          text={text[language].Cancel}
          onClick={() => {
            clearSearch();
            handleClose();
            dispatch(resetFilteredPriceList());
          }}
        />
        <ActionButton
          text={text[language].choosePositions}
          orangeBtn
          disabled={!isDisabled}
          onClick={() => {
            onChoosePositionsClick();
            dispatch(resetFilteredPriceList());
          }}
          isPriceListMainButton={true}
        />
      </ModalButtonsWrapper>
      <AddCurrencyAlertModal isOpen={isOpenCurrencyAlertModal} handleClose={() => setIsOpenCurrencyAlertModal(false)} />
    </ModalWrapper>
  );
};
