import styled from '@emotion/styled';
import { COLORS } from 'shared/constants';

export const ContentWrapper = styled('div')(({ className }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '14px',
  padding: '0px 14px',
  gap: '32px',
  width: '100%',
  maxWidth: '344px',
  '& p': {
    color: className?.includes('dark') ? COLORS.BODY_TEXT_BLACK_20 : COLORS.ACTION_BAR_BLACK_BG,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
  },
  '& button': {
    width: '100%',
    height: '40px',
    padding: '8px 16px',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
  },
  '@media (min-width: 360px)': {
    marginTop: '32px',
    padding: '0 32px',
  },
}));
