import { Roles } from 'entities/account';
import { text } from 'shared/constants';

export const getUserRole = (role: Roles, language: string) => {
  if (Roles[role] === 'owner') return text[language].owner;

  if (Roles[role] === 'partner') return text[language].partner;

  if (Roles[role] === 'customer') return text[language].customer;

  if (Roles[role] === 'contractor') return 'contractor'; //TODO: add translations

  if (Roles[role] === 'employee') return 'employee'; //TODO: add translations
};
