import React from 'react';
import { LocalStorage, useAppSelector, useAppDispatch } from 'shared/lib';
import { setLanguage } from 'entities/language';
import { text } from 'shared/constants';
import { LanguageItemContainer, SwitchLanguage } from './styles';

export const LanguageSwitchButton: React.FC = () => {
  const { language } = useAppSelector((state) => state.language);
  const theme = useAppSelector((state) => state.theme.theme);
  const dispatch = useAppDispatch();

  const switchLanguage = () => {
    const lang = language === 'EN' ? 'UA' : 'EN';
    dispatch(setLanguage(lang));

    LocalStorage.setActiveLanguage(lang);
  };

  const languageClassName = (lang: string) => {
    let cn = '';
    const isCurrentLanguage = language === lang;
    if (isCurrentLanguage) {
      cn += 'active';
    }
    if (isDarkTheme) {
      cn += 'Dark';
    }

    return cn;
  };

  const isDarkTheme = theme === 'dark';

  return (
    <SwitchLanguage
      onClick={() => {
        switchLanguage();
      }}
    >
      <LanguageItemContainer className={languageClassName('UA')}>
        {text[language].UA.toUpperCase()}
      </LanguageItemContainer>
      <LanguageItemContainer className={languageClassName('EN')}>
        {text[language].EN.toUpperCase()}
      </LanguageItemContainer>
    </SwitchLanguage>
  );
};
