import React from 'react';
import { useLocation } from 'react-router-dom';
import { PriceItemType } from 'entities/estimates';
import { CardDivider, CardDividerVertical, EstimateIcon, MaterialsIcon, WorkIcon } from 'shared/assets/icons';
import {
  formatNumberWithSpaces,
  useAppSelector,
  useMediaQuery,
  calculateTotalAndCostByArticle,
  calculateTotalCounts,
  markupCalculation,
} from 'shared/lib';
import { devices, routes, text } from 'shared/constants';
import {
  AmountGroup,
  AmountRow,
  AmountTitleWrapper,
  CardTitle,
  EstimateAmountContainer,
  EstimateContainer,
  EstimateSumHeader,
  TotalAmountRow,
  TotalAmountRowHeader,
} from './styles';

type Props = {
  priceItems?: PriceItemType[] | null;
};

export const EstimateAmount: React.FC<Props> = ({ priceItems }) => {
  const language = useAppSelector((state) => state.language.language);
  const markups = useAppSelector((state) => state.projects.tempProjectMarkup);
  const theme = useAppSelector((state) => state.theme.theme);
  const isMobile = useMediaQuery(devices.mobile);
  const isTablet = useMediaQuery(devices.tablet);

  const location = useLocation();
  const isNotEstimateDetailPage =
    location.pathname.split('/').includes(routes.estimateEditing) ||
    location.pathname.split('/').includes(routes.estimateCreation);

  //TODO: refactoring
  const funds = {
    materials: {
      cost: calculateTotalAndCostByArticle(1, priceItems).cost || 0,
      total: calculateTotalAndCostByArticle(1, priceItems).total,
      markup: markupCalculation(calculateTotalAndCostByArticle(1, priceItems).total, markups?.materialsMarkup),
      totalSum:
        markupCalculation(calculateTotalAndCostByArticle(1, priceItems).total, markups?.materialsMarkup) +
        +calculateTotalAndCostByArticle(1, priceItems).total,
    },
    work: {
      cost: calculateTotalAndCostByArticle(2, priceItems).cost,
      total: calculateTotalAndCostByArticle(2, priceItems).total,
      markup: markupCalculation(calculateTotalAndCostByArticle(2, priceItems).total, markups?.workMarkup),
      totalSum:
        markupCalculation(calculateTotalAndCostByArticle(2, priceItems).total, markups?.workMarkup) +
        +calculateTotalAndCostByArticle(2, priceItems).total,
    },
  };

  const incomes = funds.materials.total - funds.materials.cost + funds.work.total - funds.work.cost;

  const isTabletOrMob = isMobile || isTablet;
  const isDarkTheme = theme === 'dark';
  return (
    <EstimateAmountContainer className={isDarkTheme ? 'dark' : ''}>
      <EstimateContainer>
        <EstimateSumHeader>
          <EstimateIcon size={50} />
          <CardTitle className={isDarkTheme ? 'dark' : ''}>{text[language].estimateAmount}</CardTitle>
          <span>{formatNumberWithSpaces(calculateTotalCounts(priceItems, markups), true)}</span>
        </EstimateSumHeader>
        <AmountGroup>
          <AmountRow className={isDarkTheme ? 'dark' : ''}>
            <span>{text[language].totalMarkup}</span>
            <span>{formatNumberWithSpaces(funds.work.markup + funds.materials.markup, true)}</span>
          </AmountRow>
          <AmountRow className={isDarkTheme ? 'dark' : ''}>
            <span>{text[language].profitShare}</span>
            <span>{formatNumberWithSpaces(incomes, true)}</span>
          </AmountRow>
          <CardDivider />
          <TotalAmountRow className={isDarkTheme ? 'dark' : ''}>
            <span className="total">{text[language].profit}</span>
            <span className="colored">
              {formatNumberWithSpaces(funds.work.markup + funds.materials.markup + incomes, true)}
            </span>
          </TotalAmountRow>
        </AmountGroup>
      </EstimateContainer>
      {!isTabletOrMob && (
        <>
          <CardDividerVertical height={isNotEstimateDetailPage ? 316 : 254} />
          {isNotEstimateDetailPage ? (
            <EstimateContainer>
              <AmountGroup>
                <AmountTitleWrapper>
                  <WorkIcon language={language} />
                  <CardTitle className={isDarkTheme ? 'dark' : ''}>{text[language].Work}</CardTitle>
                </AmountTitleWrapper>
                <AmountRow className={isDarkTheme ? 'dark' : ''}>
                  <span>{text[language].Cost}</span>
                  <span>{formatNumberWithSpaces(funds.work.cost, true)}</span>
                </AmountRow>
                <AmountRow className={isDarkTheme ? 'dark' : ''}>
                  <span>{text[language].Total}</span>
                  <span>{formatNumberWithSpaces(funds.work.total, true)}</span>
                </AmountRow>
                <AmountRow className={isDarkTheme ? 'dark' : ''}>
                  <span>{text[language].markupOnArticleExpenses} </span>
                  <span>{formatNumberWithSpaces(funds.work.markup, true)}</span>
                </AmountRow>
                <CardDivider />
                <TotalAmountRow className={isDarkTheme ? 'dark unsetWidth' : 'unsetWidth'}>
                  <span>{text[language].totalSum}</span>
                  <span className="total">{formatNumberWithSpaces(funds.work.totalSum, true)}</span>
                </TotalAmountRow>
              </AmountGroup>
              <AmountGroup>
                <AmountTitleWrapper>
                  <MaterialsIcon />
                  <CardTitle className={isDarkTheme ? 'dark' : ''}>{text[language].Materials}</CardTitle>
                </AmountTitleWrapper>
                <AmountRow className={isDarkTheme ? 'dark' : ''}>
                  <span>{text[language].Cost}</span>
                  <span>{formatNumberWithSpaces(funds.materials.cost, true)}</span>
                </AmountRow>
                <AmountRow className={isDarkTheme ? 'dark' : ''}>
                  <span>{text[language].Total}</span>
                  <span>{formatNumberWithSpaces(funds.materials.total, true)}</span>
                </AmountRow>
                <AmountRow className={isDarkTheme ? 'dark' : ''}>
                  <span>{text[language].markupOnArticleExpenses} </span>
                  <span>{formatNumberWithSpaces(funds.materials.markup, true)}</span>
                </AmountRow>
                <CardDivider />
                <TotalAmountRow className={isDarkTheme ? 'dark unsetWidth' : 'unsetWidth'}>
                  <span>{text[language].totalSum}</span>
                  <span className="total">{formatNumberWithSpaces(funds.materials.totalSum, true)}</span>
                </TotalAmountRow>
              </AmountGroup>
            </EstimateContainer>
          ) : (
            <AmountGroup>
              <TotalAmountRowHeader className={isDarkTheme ? 'dark' : ''}>
                <span></span>
                <div>
                  <WorkIcon language={language} size={16} />
                  {text[language].Work}
                </div>
                <div>
                  <MaterialsIcon size={16} />
                  {text[language].Materials}
                </div>
              </TotalAmountRowHeader>
              <TotalAmountRow className={isDarkTheme ? 'dark' : ''}>
                <span>{text[language].Cost}</span>
                <span>{formatNumberWithSpaces(funds.work.cost, true)}</span>
                <span>{formatNumberWithSpaces(funds.materials.cost, true)}</span>
              </TotalAmountRow>
              <TotalAmountRow className={isDarkTheme ? 'dark' : ''}>
                <span>{text[language].Total}</span>
                <span>{formatNumberWithSpaces(funds.work.total, true)}</span>
                <span>{formatNumberWithSpaces(funds.materials.total, true)}</span>
              </TotalAmountRow>
              <TotalAmountRow className={isDarkTheme ? 'dark' : ''}>
                <span>{text[language].markupOnArticleExpenses}</span>
                <span>{formatNumberWithSpaces(funds.work.markup, true)}</span>
                <span>{formatNumberWithSpaces(funds.materials.markup, true)}</span>
              </TotalAmountRow>
              <CardDivider />
              <TotalAmountRow className={isDarkTheme ? 'dark' : ''}>
                <span className="total">{text[language].totalSum}</span>
                <span className="total">{formatNumberWithSpaces(funds.work.totalSum, true)}</span>
                <span className="total">{formatNumberWithSpaces(funds.materials.totalSum, true)}</span>
              </TotalAmountRow>
            </AmountGroup>
          )}
        </>
      )}
    </EstimateAmountContainer>
  );
};
