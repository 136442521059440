export const handleNumberValue = (value: string): string => {
  const validPattern = /^[+]?\d*\.?\d*$/;
  const isValid = validPattern.test(value);
  value = value.replace(',', '.');

  // Remove invalid characters
  if (!isValid) value = value.replace(/[^\d.]/g, '');

  value = value.replace(/[^\d.-]/g, ''); // Filter out unwanted characters

  const decimalSeparatorIndex = value.indexOf('.');
  if (decimalSeparatorIndex !== -1 && value.length - decimalSeparatorIndex > 3) {
    value = value.slice(0, decimalSeparatorIndex + 3); // Limit decimal places to 2
  }

  if (decimalSeparatorIndex !== -1) {
    const digitsBeforeDecimal = decimalSeparatorIndex; // Calculate the number of digits before the decimal separator

    if (digitsBeforeDecimal > 10) {
      value = value.slice(0, 10) + value.slice(decimalSeparatorIndex); // Truncate the string to keep only 10 characters before the decimal separator
    }
  } else {
    if (value.length > 10) {
      value = value.slice(0, 10); // Truncate the string to keep only the first 10 characters
    }
  }

  if (decimalSeparatorIndex !== -1 && value.indexOf('.', decimalSeparatorIndex + 1) !== -1) {
    value = value.replace('.', ''); // Remove additional decimal separators if isDecimal is true
  }

  if (value === '.') {
    value = '0.'; // Replace a standalone decimal separator with "0."
  }

  if (value.length > 1 && value.startsWith('0') && value[1] !== '.') {
    value = value.substring(1); // Remove leading zero, except if it's followed by a decimal separator
  }

  if (value.length > 15) value = value.slice(0, 15); // Limit total length to 15 characters

  if (!value.length) {
    return '';
  } else {
    return value.toLocaleString();
  }
};
