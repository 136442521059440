import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CreateProjectModal } from 'widgets';
import { ProjectType, editPinProject, setPinnedProject } from 'entities/project';
import { PinIcon } from 'shared/assets/icons';
import { useAppDispatch, useAppSelector } from 'shared/lib';
import { routes, text } from 'shared/constants';
import { API_CONFIG } from 'shared/api';
import { AddProjectRound } from './ui/AddProjectRound';
import { ExistingProject, NewProject, PinWrapper, ProjectInfo, ProjectTitle, ProjectWrapper } from './styles';

type Props = {
  project?: ProjectType;
  createNewProject?: boolean;
};

export const ProjectPreview: React.FC<Props> = ({ project, createNewProject }) => {
  const language = useAppSelector((state) => state.language.language);
  const theme = useAppSelector((state) => state.theme.theme);
  const [isPinned, setIsPinned] = useState(false);
  const [isOpenCreateProjectModal, setOpenCreateProjectModal] = useState(false);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (project?.isPinned) {
      setIsPinned(true);
    } else {
      setIsPinned(false);
    }
  }, [project]);

  const togglePinned = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setIsPinned(!isPinned);
    if (!project) return;

    dispatch(setPinnedProject({ ...project, isPinned: !project.isPinned }));
    dispatch(
      editPinProject({
        companyId: project.company,
        projectId: project.id,
        isPinned: !isPinned,
      }),
    );
  };

  const openCreateProjectModal = () => {
    setOpenCreateProjectModal(true);
  };

  const onOpenProjectClick = () => {
    if (!createNewProject && project) navigate(`/${routes.projects}/${project.id}`);
  };
  const imageUrl = project?.image
    ? API_CONFIG.BASE_URL + project?.image?.project_image_path
    : require('shared/assets/icons/default_project_photo.png');

  const isDarkTheme = theme === 'dark';
  return (
    <ProjectWrapper className={isDarkTheme ? 'dark' : ''} onClick={onOpenProjectClick}>
      {createNewProject ? (
        <NewProject className={isDarkTheme ? 'dark' : ''} onClick={openCreateProjectModal}>
          <AddProjectRound />
          <p>{text[language].projectPage.createNewProject}</p>
        </NewProject>
      ) : (
        <ExistingProject>
          <div style={{ backgroundImage: `url(${imageUrl})` }} className="image-wrapper"></div>
          <PinWrapper onClick={togglePinned} className={!isPinned ? 'hidden' : ''}>
            <PinIcon isPinned={isPinned} />
          </PinWrapper>
          <ProjectInfo>
            <ProjectTitle>{project?.name}</ProjectTitle>
            {/* TODO: animate */}
            {project?.address && <span className="hidden">{project?.address}</span>}
            <span className="hidden">{project?.square}м2</span>
          </ProjectInfo>
        </ExistingProject>
      )}
      <CreateProjectModal isOpen={isOpenCreateProjectModal} handleClose={() => setOpenCreateProjectModal(false)} />
    </ProjectWrapper>
  );
};
