import { IPriceList, CurrenciesType } from 'entities/priceList';

export function GetUserCurrencies(priceList: IPriceList[] | null, userCurrencies: string[]) {
  let letCurrencies: string[] = userCurrencies;
  const filteredPriceList = priceList?.filter((pos) => !pos.isHidden).filter((pos) => !pos.isTemporary);
  if (filteredPriceList && filteredPriceList.length > 0) {
    filteredPriceList.forEach((item: IPriceList) => {
      Object.keys(item.funds).forEach((key: string) => {
        if (item.funds[key as CurrenciesType].total !== null) {
          if (letCurrencies.find((currency: string) => currency === key)) {
          } else {
            letCurrencies = [...letCurrencies, key];
          }
        }
      });
    });
  }
  return [...new Set(letCurrencies)];
}
