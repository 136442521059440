import styled from '@emotion/styled';
import { COLORS } from 'shared/constants';

export const AddCurrencyModalContainer = styled('div')(({ className }) => ({
  margin: '32px auto',
  '& p': {
    marginBottom: '16px',
    textAlign: 'left',
  },
  padding: '0px 14px',
  '@media (min-width: 360px)': {
    padding: '0 32px',
  },
}));

export const AddCurrencyRadioGroup = styled('div')(({ className }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  '& label': {
    color: className?.includes('dark') ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK,
    height: '44px',
    alignItems: 'center',
    display: 'flex',
    gap: '16px',
    cursor: 'pointer',
  },
}));

export const AddCurrencyModalButtonsWrapper = styled('div')(({ className }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
  '& button': {
    fontSize: '16px',
    padding: className?.includes('mobile') ? '3px 8px' : '8px 16px',
    lineHeight: '24px',
    width: '136px',
    height: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: '8px',
  },
  padding: '0px 14px',
  '@media (min-width: 360px)': {
    padding: '0 32px',
  },
}));

export const AddCurrencyButton = styled('button')(({ className }) => ({
  textAlign: 'center',
  padding: '8px 16px',
  gap: '8px',
  cursor: 'pointer',
  border: `1px solid ${className?.includes('dark') ? 'transparent' : COLORS.BORDER_GREY}`,
  backgroundColor: className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.TEXT_WHITE,
  transition: 'background 0.2s linear',
  color: className?.includes('dark') ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK,
  '&:active': {
    background: className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.GREY,
  },
  '&:hover': {
    background: className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.GREY,
  },
  '&:disabled': {
    background: className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.GREY,
    color: className?.includes('dark') ? COLORS.BODY_TEXT_BLACK_70 : COLORS.DISABLED_BTN_TEXT,
    cursor: 'default',
  },
  '& p': {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 400,
  },
}));
