import React from 'react';
import { COLORS } from 'shared/constants';

type Props = {
  size?: number;
  stroke?: string;
  strokeWidth?: number;
};

export const Edit: React.FC<Props> = ({ size = 24, stroke = COLORS.ACTION_BAR_BLACK_BG, strokeWidth = 1 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        d="M13.3328 7.35496L4.5 16.1877V19.5H7.81229L16.6451 10.6672M13.3328 7.35496L15.8643 4.82339C16.2955 4.3922 16.9946 4.3922 17.4258 4.82339L19.1766 6.57424C19.6078 7.00542 19.6078 7.70449 19.1766 8.13567L16.6451 10.6672M13.3328 7.35496L16.6451 10.6672"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};
