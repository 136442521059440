import React, { useEffect, useState } from 'react';
import ModalLayout from '../ModalLayout/ModalLayout';
import {
  CurrenciesType,
  stringFundsType,
  editPosition as editPositionAction,
  resetPositionLoading,
  resetPriceListLoading,
} from 'entities/priceList';
import { LoadingResultsT } from 'entities/loadingType';
import { useAppSelector, useAppDispatch } from 'shared/lib';
import { text } from 'shared/constants';
import { ModalWrapper } from 'shared/ui';
import { ItemValues } from '../../model/defaultValues';
import { convertFundsToObjectWithNumbers } from '../../model/convertFundsToObjectWithNumbers';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  positionID: number;
}

export const EditPositionsModal: React.FC<Props> = ({ isOpen, handleClose, positionID }) => {
  const language = useAppSelector((state) => state.language.language);
  const company = useAppSelector((state) => state.company.company);
  const userCurrencyList = useAppSelector((state) => state.currencies.userCurrencyList);
  const priceList = useAppSelector((state) => state.priceList.priceList);
  const loadingPosition = useAppSelector((state) => state.priceList.loadingPosition);
  const dispatch = useAppDispatch();

  const [itemValues, setItemValues] = useState<ItemValues>({} as ItemValues);

  useEffect(() => {
    if (loadingPosition === LoadingResultsT.SUCCEEDED) {
      dispatch(resetPriceListLoading());
      dispatch(resetPositionLoading());
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingPosition, dispatch]);

  useEffect(() => {
    const item = priceList?.find((item) => item.id === positionID);
    if (!item) return;
    // Create an itemFunds object based on the data from the found position
    const itemFunds = userCurrencyList.reduce((acc, currency) => {
      acc[currency] = { total: item.funds[currency]?.total.toString(), cost: item.funds[currency]?.cost?.toString() };
      return acc;
    }, {} as Record<CurrenciesType, stringFundsType>);
    // Update the itemValues state with data from the found position
    setItemValues({
      itemName: item.name,
      itemArticle: item.article.toString(),
      itemUnit: item.units.toString(),
      itemFunds,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCurrencyList, priceList]);

  function editPosition() {
    if (!company || !positionID) return;
    const positionIsHidden = priceList?.find((item) => item.id === positionID)?.isHidden;
    const funds = convertFundsToObjectWithNumbers(itemValues.itemFunds);
    dispatch(
      editPositionAction({
        companyID: company[0].id,
        articleID: Number(itemValues.itemArticle),
        name: itemValues.itemName,
        unit: itemValues.itemUnit,
        funds: funds,
        itemID: positionID,
        isHidden: !!positionIsHidden,
      }),
    );
  }

  return (
    <ModalWrapper isOpen={isOpen} title={text[language].EditItem} subtitle={text[language].ItemInfo}>
      <ModalLayout
        onModalClose={handleClose}
        setItemValues={setItemValues}
        itemValues={itemValues}
        onSaveClick={editPosition}
      />
    </ModalWrapper>
  );
};
