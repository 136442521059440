import { createSlice } from '@reduxjs/toolkit';
import { LoadingResultsT } from '../../loadingType';
import { ICategory } from './types';
import {
  deleteCategories,
  fetchCategories,
  editCategory,
  createCategory,
  ungroupCategory,
  groupPositionsToCategory,
  orderCategories,
} from '../api/thunks';

interface IState {
  categories: ICategory[] | null;
  filteredCategories: ICategory[];
  loadingCategories: LoadingResultsT;
  loadingGroupPositionsToCategory: LoadingResultsT;
  error: string | null;
}

const initialState: IState = {
  categories: null,
  filteredCategories: [],
  loadingCategories: LoadingResultsT.IDLE,
  loadingGroupPositionsToCategory: LoadingResultsT.IDLE,
  error: null,
};

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    resetFilteredCategories(state) {
      state.filteredCategories = [];
    },
    setFilteredCategories(state, { payload }) {
      state.filteredCategories = payload;
    },
    removeCategories: (state, { payload }) => {
      const filteredCategories = state.categories?.filter((category) => !payload.includes(category.id));
      if (filteredCategories) {
        state.categories = [...filteredCategories];
      }
    },
    resetCategoriesLoading: (state) => {
      state.loadingCategories = LoadingResultsT.IDLE;
      state.loadingGroupPositionsToCategory = LoadingResultsT.IDLE;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.loadingCategories = LoadingResultsT.PENDING;
      })
      .addCase(fetchCategories.fulfilled, (state, { payload }) => {
        state.loadingCategories = LoadingResultsT.SUCCEEDED;

        state.categories = payload;
        state.error = null;
      })
      .addCase(fetchCategories.rejected, (state, { error }) => {
        state.loadingCategories = LoadingResultsT.FAILED;
        state.error = error.message as string;
      })

      .addCase(deleteCategories.pending, (state) => {
        state.loadingCategories = LoadingResultsT.PENDING;
      })
      .addCase(deleteCategories.fulfilled, (state, { payload }) => {
        state.loadingCategories = LoadingResultsT.SUCCEEDED;

        state.error = null;
      })
      .addCase(deleteCategories.rejected, (state, { error }) => {
        state.loadingCategories = LoadingResultsT.FAILED;
        state.error = error.message as string;
      })

      .addCase(editCategory.pending, (state) => {
        state.loadingCategories = LoadingResultsT.PENDING;
      })
      .addCase(editCategory.fulfilled, (state, { payload }) => {
        state.loadingCategories = LoadingResultsT.SUCCEEDED;

        const categoryIndex = state.categories?.findIndex((category) => category.id === payload.id); // Find the index of the category to update by its ID

        if (!state.categories || typeof categoryIndex === 'undefined' || categoryIndex === -1) return;
        const updatedCategories = [...state.categories]; // Create a copy of the categories state to update
        updatedCategories.splice(categoryIndex, 1, payload); // Use splice to replace the category at the found index with the new payload

        state.categories = updatedCategories;
        state.error = null;
      })
      .addCase(editCategory.rejected, (state, { error }) => {
        state.loadingCategories = LoadingResultsT.FAILED;
        state.error = error.message as string;
      })

      .addCase(createCategory.pending, (state) => {
        state.loadingCategories = LoadingResultsT.PENDING;
      })
      .addCase(createCategory.fulfilled, (state, { payload }) => {
        state.loadingCategories = LoadingResultsT.SUCCEEDED;
        const newCategory = payload;
        const order = payload.order;

        if (!state.categories) return;
        const indexToInsertAfter = state.categories.findIndex((cat) => cat.order === order);
        if (indexToInsertAfter !== -1 && order) {
          state.categories.splice(indexToInsertAfter + 1, 0, newCategory);
        } else {
          state.categories = [...state.categories, newCategory];
        }
        state.error = null;
      })
      .addCase(createCategory.rejected, (state, { error }) => {
        state.loadingCategories = LoadingResultsT.FAILED;
        state.error = error.message as string;
      })

      .addCase(ungroupCategory.pending, (state) => {
        state.loadingCategories = LoadingResultsT.PENDING;
      })
      .addCase(ungroupCategory.fulfilled, (state, { payload }) => {
        state.loadingCategories = LoadingResultsT.SUCCEEDED;
        state.error = null;
      })
      .addCase(ungroupCategory.rejected, (state, { error }) => {
        state.loadingCategories = LoadingResultsT.FAILED;
        state.error = error.message as string;
      })

      .addCase(groupPositionsToCategory.pending, (state) => {
        state.loadingGroupPositionsToCategory = LoadingResultsT.PENDING;
      })
      .addCase(groupPositionsToCategory.fulfilled, (state, { payload }) => {
        state.loadingGroupPositionsToCategory = LoadingResultsT.SUCCEEDED;

        if (!state.categories) return;
        state.categories = [...state.categories, payload];
        state.error = null;
      })
      .addCase(groupPositionsToCategory.rejected, (state, { error }) => {
        state.loadingGroupPositionsToCategory = LoadingResultsT.FAILED;
        state.error = error.message as string;
      })

      .addCase(orderCategories.pending, (state) => {
        state.loadingCategories = LoadingResultsT.PENDING;
      })
      .addCase(orderCategories.fulfilled, (state, { payload }) => {
        state.loadingCategories = LoadingResultsT.SUCCEEDED;

        state.categories = [...payload];
        state.error = null;
      })
      .addCase(orderCategories.rejected, (state, { error }) => {
        state.loadingCategories = LoadingResultsT.FAILED;
        state.error = error.message as string;
      }),
});

export const { removeCategories, resetCategoriesLoading, setFilteredCategories, resetFilteredCategories } =
  categoriesSlice.actions;
export const categoryReducer = categoriesSlice.reducer;
