import React from 'react';
import { CurrenciesType } from 'entities/priceList';
import { RadioButtonChecked, RadioButtonUnchecked } from 'shared/assets/icons';
import { ActionButton, ModalWrapper } from 'shared/ui';

import { useAppDispatch, useAppSelector } from 'shared/lib';
import { createCompanyCurrency, setActiveCurrency } from 'entities/currencies';
import { currencyNames, text } from 'shared/constants';

import {
  AddCurrencyButton,
  AddCurrencyModalButtonsWrapper,
  AddCurrencyModalContainer,
  AddCurrencyRadioGroup,
} from './styles';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  selectedCurrency: string | null;
  setSelectedCurrency: (currency: string | null) => void;
  setIsOpenCurrencyRateModal: (isOpen: boolean) => void;
  projectCurrency?: string;
}

export const AddCurrencyModal: React.FC<Props> = ({
  isOpen,
  handleClose,
  selectedCurrency,
  setSelectedCurrency,
  setIsOpenCurrencyRateModal,
  projectCurrency,
}) => {
  const language = useAppSelector((state) => state.language.language);
  const userCurrencies = useAppSelector((state) => state.currencies.userCurrencyList);
  const priceList = useAppSelector((state) => state.priceList.priceList);
  const company = useAppSelector((state) => state.company.company);
  const theme = useAppSelector((state) => state.theme.theme);
  const dispatch = useAppDispatch();

  const handleCurrencyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCurrency(event.target.value);
  };

  function onAddCurrencyClick() {
    if (!priceList?.length && company) {
      dispatch(createCompanyCurrency({ companyID: company[0].id, currency: selectedCurrency as CurrenciesType }));
      dispatch(setActiveCurrency(selectedCurrency));
    } else {
      setIsOpenCurrencyRateModal(true);
    }
    handleClose();
  }

  const isDarkTheme = theme === 'dark';
  return (
    <ModalWrapper
      isOpen={isOpen}
      onCloseButtonClick={() => handleClose()}
      title={text[language].AddCurrency}
      subtitle={text[language].ChooseOneCurrency}
    >
      <AddCurrencyModalContainer>
        <AddCurrencyRadioGroup className={isDarkTheme ? 'dark' : ''}>
          {(() => {
            if (projectCurrency) {
              return (
                <label>
                  {selectedCurrency === projectCurrency ? <RadioButtonChecked /> : <RadioButtonUnchecked />}
                  <input
                    type="radio"
                    checked={selectedCurrency === projectCurrency}
                    name="currency"
                    value={projectCurrency}
                    onChange={handleCurrencyChange}
                    style={{ display: 'none' }}
                  />
                  {text[language][projectCurrency]}
                </label>
              );
            } else {
              return (
                <>
                  {currencyNames
                    .filter((i: string) => !userCurrencies.find((element: string) => i === element))
                    .map((currency: string) => (
                      <label key={currency}>
                        {selectedCurrency === currency ? <RadioButtonChecked /> : <RadioButtonUnchecked />}
                        <input
                          type="radio"
                          checked={selectedCurrency === currency}
                          name="currency"
                          value={currency}
                          onChange={handleCurrencyChange}
                          style={{ display: 'none' }}
                        />
                        {text[language][currency]}
                      </label>
                    ))}
                </>
              );
            }
          })()}
        </AddCurrencyRadioGroup>
      </AddCurrencyModalContainer>
      <AddCurrencyModalButtonsWrapper>
        <AddCurrencyButton
          className={isDarkTheme ? 'dark' : ''}
          onClick={() => {
            handleClose();
            setSelectedCurrency(null);
          }}
        >
          <p>{text[language].Cancel}</p>
        </AddCurrencyButton>
        <ActionButton
          text={text[language].Add}
          orangeBtn
          disabled={!selectedCurrency}
          onClick={onAddCurrencyClick}
          isPriceListMainButton={true}
        />
      </AddCurrencyModalButtonsWrapper>
    </ModalWrapper>
  );
};
