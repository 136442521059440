import React from 'react';
import { COLORS } from 'shared/constants';

type Props = {
  size?: number;
  stroke?: string;
};

export const RadioButtonUnchecked: React.FC<Props> = ({ size = 24, stroke = COLORS.BODY_TEXT_BLACK_20 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="8.5" stroke={stroke} />
    </svg>
  );
};
