import React, { useEffect, useState } from 'react';
import { text } from 'shared/constants';
import { useAppDispatch, useAppSelector } from 'shared/lib';
import { ActionButton, Input, ModalWrapper } from 'shared/ui';
import { CommonModalContainer } from 'widgets/modals/styles';
import { ButtonsWrapper, InputWrapper } from './styles';
import { editCompanyInfo } from 'entities/company';
import { LoadingResultsT } from 'entities/loadingType';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
}

const defaultValues = {
  name: '',
  address: '',
  phone: '',
  email: '',
};

export const EditCompanyModal: React.FC<Props> = ({ isOpen, handleClose }) => {
  const selectedCompany = useAppSelector((state) => state.company.selectedCompany);
  const loadingCompanyEditing = useAppSelector((state) => state.company.loadingCompanyEditing);
  const language = useAppSelector((state) => state.language.language);
  const theme = useAppSelector((state) => state.theme.theme);
  const [companyInfo, setCompanyInfo] = useState({ ...defaultValues });
  const dispatch = useAppDispatch();

  const companyId = selectedCompany?.id;

  useEffect(() => {
    if (selectedCompany) {
      setCompanyInfo({
        ...companyInfo,
        name: selectedCompany.name,
        address: selectedCompany.address || '',
        phone: selectedCompany.phone || '',
        email: selectedCompany.email || '',
      });
    }
  }, [selectedCompany, isOpen]);

  function handleModalClose() {
    handleClose();
  }

  function onEditCompanyClick() {
    if (!companyId || !companyInfo.name) return;
    dispatch(editCompanyInfo({ companyId, body: companyInfo }));
  }

  const isDarkTheme = theme === 'dark';

  return (
    <ModalWrapper isOpen={isOpen} title={text[language].CompanyInfoEditing}>
      <CommonModalContainer>
        <InputWrapper className={isDarkTheme ? 'dark' : ''}>
          <p>{text[language].CompanyInfo}</p>
          <Input
            autoFocus={true}
            placeholder={text[language].CompanyName}
            value={companyInfo.name}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setCompanyInfo({ ...companyInfo, name: event.target.value });
            }}
          />
          <Input
            placeholder={`${text[language].address} ${text[language].optional}`}
            value={companyInfo.address}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setCompanyInfo({ ...companyInfo, address: event.target.value });
            }}
          />
          <Input
            placeholder={`${text[language].Phone} ${text[language].optional}`}
            value={companyInfo.phone}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setCompanyInfo({ ...companyInfo, phone: event.target.value });
            }}
          />
          <Input
            placeholder={`${text[language].Email} ${text[language].optional}`}
            value={companyInfo.email}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setCompanyInfo({ ...companyInfo, email: event.target.value });
            }}
          />
        </InputWrapper>
      </CommonModalContainer>
      <ButtonsWrapper>
        <ActionButton text={text[language].Cancel} onClick={handleModalClose} />
        <ActionButton
          isPending={loadingCompanyEditing === LoadingResultsT.PENDING}
          text={text[language].Save}
          orangeBtn
          disabled={!companyInfo.name}
          onClick={onEditCompanyClick}
          isPriceListMainButton={true}
        />
      </ButtonsWrapper>
    </ModalWrapper>
  );
};
