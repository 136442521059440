import React from 'react';
import { COLORS } from 'shared/constants';
import { useAppSelector } from 'shared/lib';

export const CardDivider: React.FC = () => {
  const theme = useAppSelector((state) => state.theme.theme);

  const isDarkTheme = theme === 'dark';

  const stroke = () => {
    let color = COLORS.BORDER_GREY;
    if (isDarkTheme) color = COLORS.LIGHT_GREY_BORDER;
    return color;
  };
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="2" viewBox="0 0 304 2" fill="none">
      <path d="M1 1H303" stroke={stroke()} strokeLinecap="round" />
    </svg>
  );
};
