import React from 'react';
import { COLORS } from 'shared/constants';

type Props = {
  size?: number;
  stroke?: string;
};

export const CompanyIcon: React.FC<Props> = ({ size = 48, stroke = COLORS.TEXT_WHITE }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 48 48" fill="none">
      <circle cx="24" cy="24" r="24" fill="#262626" />
      <path
        d="M20.7733 20.773V30.4537M20.7733 20.773H15.9329M20.7733 20.773H27.2271M20.7733 30.4537H15.1155C14.2721 30.4537 13.8504 30.4537 13.5283 30.6178C13.2449 30.7622 13.0146 30.9926 12.8702 31.2759C12.7061 31.5981 12.7061 32.0233 12.7061 32.8739V32.8739C12.7061 33.7244 12.7061 34.1497 12.8702 34.4718C13.0146 34.7552 13.2449 34.9855 13.5283 35.1299C13.8504 35.294 14.2721 35.294 15.1155 35.294H32.8849C33.7283 35.294 34.1499 35.294 34.4721 35.1299C34.7554 34.9855 34.9858 34.7552 35.1302 34.4718C35.2943 34.1497 35.2943 33.7244 35.2943 32.8739V32.8739C35.2943 32.0233 35.2943 31.5981 35.1302 31.2759C34.9858 30.9926 34.7554 30.7622 34.4721 30.6178C34.1499 30.4537 33.7283 30.4537 32.8849 30.4537H27.2271M20.7733 30.4537H27.2271M15.9329 20.773H15.1155C14.2721 20.773 13.8504 20.773 13.5283 20.6089C13.2449 20.4645 13.0146 20.2342 12.8702 19.9508C12.7061 19.6287 12.7061 19.207 12.7061 18.3636V17.7501C12.7061 17.0987 12.7061 16.7729 12.8178 16.4992C12.9165 16.2576 13.0763 16.0458 13.2815 15.8846C13.5139 15.7019 13.8271 15.6124 14.4536 15.4334L21.3525 13.4623C22.3375 13.1808 22.8301 13.0401 23.331 12.9841C23.7757 12.9344 24.2246 12.9344 24.6693 12.9841C25.1703 13.0401 25.6628 13.1808 26.6478 13.4623L33.5468 15.4334C34.1732 15.6124 34.4864 15.7019 34.7189 15.8846C34.9241 16.0458 35.0838 16.2576 35.1825 16.4992C35.2943 16.7729 35.2943 17.0987 35.2943 17.7501V18.3636C35.2943 19.207 35.2943 19.6287 35.1302 19.9508C34.9858 20.2342 34.7554 20.4645 34.4721 20.6089C34.1499 20.773 33.7283 20.773 32.8849 20.773H32.0674M15.9329 20.773V30.4537M32.0674 20.773V30.4537M32.0674 20.773H27.2271M27.2271 20.773V30.4537"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
