import { ImageValuesType } from "shared/ui";

export const defaultValues: EditProjectValues = {
  name: '',
  address: null,
  square: '',
  budget: '',
  currency: '',
  image: null,
  customer: null,
};

export type EditProjectValues = {
  name: string;
  address: string | null;
  square: string;
  budget: string;
  currency: string;
  image: ImageValuesType | null;
  customer: string | null;
};
