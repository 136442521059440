import { getArrayOfIds } from 'shared/lib';

export const massPositionsReorder = (
  list: any[],
  startIndex: number,
  endIndex: number,
  checkedPositions: any[],
): any[] => {
  const copy = Array.from(list);
  const [removed] = copy.splice(startIndex, 1);
  const checkedIds = getArrayOfIds(checkedPositions);
  const filteredResult = copy.filter((item) => !checkedIds.includes(item.id));
  const positionsWithoutRemoved = checkedPositions.filter((item) => item.id !== removed.id);
  filteredResult.splice(endIndex, 0, ...[removed, ...positionsWithoutRemoved]);
  return filteredResult;
};

export const reorder = (list: any[], startIndex: number, endIndex: number): any[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};
