//TODO: rewrite using thunks

import axios from 'axios';
import Cookies from 'js-cookie';
import { API_CONFIG, HEADERS, HEADERS_FOR_EMAIL_REDIRECT, USER_STATUS } from './config';
import { routes } from 'shared/constants';
var md5 = require('md5');

const url = API_CONFIG.BASE_URL;
const MONOBANK_API_KEY = process.env.REACT_APP_MONO_API_KEY;

export async function SocialLogin(user: any) {
  Cookies.remove('sessionID');

  const response = await fetch(`${url}/v1/access/social`, {
    method: 'POST',
    headers: {
      ...HEADERS,
    },
    body: JSON.stringify({
      userSocialID: user.sub,
      email: user.email,
    }),
    credentials: 'include',
  });
  const data = await response.json();
  return data;
}

export async function StandardLogin(email: string, password: string) {
  Cookies.remove('sessionID');
  const response = await fetch(`${url}/v1/access/signin`, {
    method: 'POST',
    headers: {
      ...HEADERS,
    },
    body: JSON.stringify({
      email: email.toLowerCase(),
      password: md5(password),
    }),
    credentials: 'include',
  });
  const data = await response.json();
  return data;
}

export async function LogOutAccount() {
  const response = await fetch(`${url}/v1/access/logout`, {
    method: 'POST',
    headers: {
      ...HEADERS,
    },
    credentials: 'include',
  });
  const data = await response.json();

  return data;
}

export async function AccountInfo() {
  const response = await fetch(`${url}/v1/access`, {
    method: 'GET',
    headers: {
      ...HEADERS,
    },
    credentials: 'include',
  });

  const data = await response.json();

  return data;
}

export async function SignUp(email: string) {
  const response = await fetch(`${url}/v1/access/signup`, {
    method: 'POST',
    headers: {
      ...HEADERS,
      ...HEADERS_FOR_EMAIL_REDIRECT,
    },
    body: JSON.stringify({
      email: email.toLowerCase(),
    }),
    credentials: 'include',
  });

  const data = await response.json();

  return data;
}

export async function FirstLogin(email: string, password: string) {
  const response = await fetch(`${url}/v1/access/firstLogin/${email}`, {
    method: 'POST',
    headers: {
      ...HEADERS,
      ...HEADERS_FOR_EMAIL_REDIRECT,
    },
    body: JSON.stringify({
      password: md5(password),
    }),
    credentials: 'include',
  });

  const data = await response.json();

  return data;
}

export async function ResendConfirmation(email: string) {
  const response = await fetch(`${url}/v1/access/resendConfirmation/${email}`, {
    method: 'POST',
    headers: {
      ...HEADERS,
      ...HEADERS_FOR_EMAIL_REDIRECT,
    },
    credentials: 'include',
  });

  const data = await response.json();

  return data;
}

export async function SendRestorePasswordListOnEmail(email: string) {
  const response = await fetch(`${url}/v1/access/forgetPassword/${email}`, {
    method: 'GET',
    headers: {
      ...HEADERS,
      ...HEADERS_FOR_EMAIL_REDIRECT,
    },
    credentials: 'include',
  });

  const data = await response.json();

  return data;
}

export async function RestorePasswordCreateNewPassword(email: string, token: string, password: string) {
  const response = await fetch(`${url}/v1/access/forgetPassword/${email}/${token}`, {
    method: 'PATCH',
    headers: {
      ...HEADERS,
    },
    body: JSON.stringify({
      password: md5(password),
    }),
    credentials: 'include',
  });

  const data = await response.json();

  return data;
}

export async function GetPDF(companyID: number, pdflink: string, language: string) {
  try {
    const response = await axios.get(`${url}/v1/company/${companyID}/pricelist/export?${pdflink}`, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/pdf',
        userStatus: USER_STATUS,
        languageCode: language.toLowerCase(),
      },
      responseType: 'arraybuffer',
      responseEncoding: 'binary',
    });
    const data = await response.data;
    return data;
  } catch (error) {}
}

export async function BuySubscriptionMono(amount: number, userId: number, rate: string, type: string) {
  try {
    const response = await axios.post(
      'https://api.monobank.ua/api/merchant/invoice/create',
      // 'https://api.monobank.ua/api/merchant/wallet/payment',
      {
        // cardToken: '67XZtXdR4NpKU3',
        // initiationKind: 'merchant',
        amount: 1,
        ccy: 840, //USD code
        webhookUrl: `${url}/api/webhook/?user_id=${userId}&period=${rate}&subscription=${type}`,
      },
      {
        headers: {
          'X-Token': MONOBANK_API_KEY,
        },
      },
    );

    const data = await response.data;
    return data;
  } catch (error) {}
}

export function unauthorizedLogout() {
  const location = window.location.origin;
  if (
    window.location.pathname === routes.auth ||
    window.location.pathname.includes(routes.restoreAccess) ||
    window.location.pathname.includes(routes.registration)
  )
    return;
  Cookies.remove('sessionID');
  LogOutAccount();
  window.location.replace(location);
}
