import { CurrenciesType, fundsType, stringFundsType } from 'entities/priceList';

export function convertFundsToObjectWithNumbers(funds: Record<CurrenciesType, stringFundsType>) {
  const updatedFunds: any = {};

  for (const currency in funds) {
    if (funds.hasOwnProperty(currency)) {
      const total = funds[currency as CurrenciesType].total;
      const cost = funds[currency as CurrenciesType].cost || total;
      const updatedTotal = parseFloat(total);
      const updatedCost = parseFloat(cost);

      updatedFunds[currency as CurrenciesType] = {
        total: updatedTotal,
        cost: updatedCost,
      };
    }
  }

  return updatedFunds as Record<CurrenciesType, fundsType>;
}
