import { IPriceList, CreatePositionRequest } from 'entities/priceList';

export const getPositionsByIds = (
  ids: number[],
  positions: IPriceList[],
  currentCategoryId: number,
): CreatePositionRequest[] => {
  const result: CreatePositionRequest[] = [];
  ids.forEach((id) => {
    const position = positions.filter((position) => position.id === id)[0];
    if (position) {
      result.push({ ...position, category: currentCategoryId });
    }
  });
  return result;
};
