import { createSlice } from '@reduxjs/toolkit';

interface IState {
  checkedPositionsIds: number[];
  checkedCategoryIds: number[];
  error: string | null;
}

const initialState: IState = {
  checkedPositionsIds: [],
  checkedCategoryIds: [],
  error: null,
};

const estimatesFilterSlice = createSlice({
  name: 'estimatesFilter',
  initialState,
  reducers: {
    resetEstimatesFilterList(state) {
      state.checkedPositionsIds = [];
      state.checkedCategoryIds = [];
    },
    addCheckedCategories(state, { payload }) {
      state.checkedCategoryIds = [...state.checkedCategoryIds, ...payload];
    },
    removeCheckedCategories(state, { payload }) {
      state.checkedCategoryIds = state.checkedCategoryIds.filter((checkedCat) => !payload.includes(checkedCat));
    },
    addCheckedPositions(state, { payload }) {
      state.checkedPositionsIds = [...state.checkedPositionsIds, ...payload];
    },
    removeCheckedPositions(state, { payload }) {
      state.checkedPositionsIds = state.checkedPositionsIds.filter((checkedPos) => !payload.includes(checkedPos));
    },
  },
});

export const {
  resetEstimatesFilterList,
  addCheckedPositions,
  removeCheckedPositions,
  addCheckedCategories,
  removeCheckedCategories,
} = estimatesFilterSlice.actions;
export const estimatesFilterReducer = estimatesFilterSlice.reducer;
