import styled from '@emotion/styled';
import { COLORS } from 'shared/constants';

export const ParsedTableWrapper = styled('div')(({ className }) => ({
  maxHeight: '600px',
  overflowY: 'auto',
  '& div.table-wrapper': {
    minHeight: '200px',
  },
  '& div.table-row': {
    fontSize: '6.5px',
    lineHeight: '9px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    borderBottom: `0.46px solid ${className?.includes('dark') ? COLORS.LIGHT_GREY_BORDER : COLORS.BORDER_GREY}`,
    height: '22px',
    fontWeight: 500,
  },
  '& div.table-cell': {
    position: 'relative',
    padding: '4px 0px',
    flexGrow: 1,
    '& ul': {
      maxHeight: '95px',
    },
  },
  '& div.table-cell:nth-of-type(2)': {
    width: '15%',
  },
  '& div.table-cell:nth-of-type(3), & div.table-cell:last-child': {
    width: '10%',
  },
  '& div.table-cell:first-of-type': {
    width: '35%',
  },
  '& input': {
    color: className?.includes('dark') ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK,
    padding: '0 2px',
    border: '1px solid transparent',
    outline: 'none',
    width: '100%',
    height: '18px',
    fontSize: '6.5px',
    lineHeight: '9px',
    borderRadius: '8px',
    background: className?.includes('dark') ? COLORS.BACKGROUND_BLACK : 'transparent',
    minHeight: 'unset',
  },
  '& input:active': {
    background: className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.TABLE_BG,
    border: '1px solid transparent!important',
  },
  '& input:hover': {
    background: className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.TABLE_BG,
    border: '1px solid transparent!important',
  },
  '& div.table-cell ul': {
    width: '140px',
  },
  '& div.open-upwards ul': {
    transform: 'translateY(-130%)',
  },
  '@media (min-width: 769px)': {
    '& div.table-row': {
      fontSize: '14px',
      borderBottom: `1px solid ${className?.includes('dark') ? COLORS.LIGHT_GREY_BORDER : COLORS.BORDER_GREY}`,
      height: '48px',
      lineHeight: '20px',
    },
    '& input': {
      height: '40px',
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
}));

export const ModalTableWrapper = styled('div')(({ className }) => ({
  margin: '15px auto 0',
  width: '100%',
  minWidth: '298px',
  padding: '0px 14px!important',
  '@media (min-width: 769px)': {
    minWidth: '704px',
    padding: '0 32px',
    margin: '32px auto 0',
  },
}));

export const ModalTable = styled('div')(({ className }) => ({
  fontFamily: 'Golos',
  backgroundColor: className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.TEXT_WHITE,
  border: `1px solid ${className?.includes('dark') ? COLORS.LIGHT_GREY_BORDER : COLORS.BORDER_GREY}`,
  borderTopLeftRadius: '8px',
  borderTopRightRadius: '8px',
}));

export const ModalTableHeaderRow = styled('div')(({ className }) => ({
  fontSize: '6.5px',
  fontWeight: 400,
  lineHeight: '14px',
  color: className?.includes('dark') ? COLORS.DISABLED_BTN_TEXT : COLORS.TEXT_GREY,
  borderBottom: `1px solid ${className?.includes('dark') ? COLORS.LIGHT_GREY_BORDER : COLORS.BORDER_GREY}`,
  padding: '6px 4px',
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  whiteSpace: 'pre-wrap',
  '& div': {
    flexGrow: 1,
    padding: '4px 4px',
  },
  '& div:nth-of-type(2)': {
    width: '15%',
  },
  '& div:nth-of-type(3), & div:last-child': {
    width: '10%',
  },
  '& div:first-of-type': {
    width: '35%',
  },
  '@media (min-width: 769px)': {
    fontSize: '12px',
    lineHeight: '20px',
    padding: '10px 8px',
    whiteSpace: 'nowrap',
  },
}));

export const InputWrapper = styled('div')(({ className }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
}));

export const ModalTableBody = styled('div')(({ className }) => ({
  padding: '0 8px',
  border: className?.includes('error')
    ? `1px solid ${COLORS.MAIN_RED}`
    : className?.includes('warning')
    ? `1px solid ${COLORS.ORANGE_BTN}`
    : '1px solid transparent',
}));

export const ModalTableInput = styled('textarea')(({ className }) => ({
  fontFamily: 'inherit',
  fontSize: '14px',
  width: '100%',
  color: COLORS.TEXT_BLACK,
  outline: 'none',
  border: 'none',
  lineHeight: '20px',
  fontWeight: 500,
  backgroundColor: 'transparent',
  textAlign: 'center',
  wordWrap: 'normal',
  whiteSpace: 'pre',
  overflow: 'hidden',
  resize: 'none',
  padding: '18px 0',
  caretColor: className?.includes('dark') ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK,
  '&:focus::placeholder': {
    color: 'transparent',
  },
}));

export const InputHelperText = styled('div')(({ className }) => ({
  textAlign: 'right',
  fontSize: '6.5px',
  fontWeight: 400,
  minHeight: '20px',
  lineHeight: '11px',
  color: className?.includes('warning') ? COLORS.ORANGE_BTN : COLORS.MAIN_RED,
  marginTop: '8px',
  marginBottom: '15px',
  padding: '0px 14px',
  '@media (min-width: 769px)': {
    marginBottom: '32px',
    fontSize: '14px',
    padding: '0 32px',
    lineHeight: '20px',
  },
}));

export const InputPlaceHolder = styled('div')(({ className }) => ({
  fontFamily: 'inherit',
  position: 'absolute',
  top: '50%',
  maxWidth: '458px',
  transform: 'translateY(-50%)',
  pointerEvents: 'none',
  opacity: className?.includes('show') ? 1 : 0,
  transition: 'opacity 0.2s ease',
  fontSize: '5.5px',
  fontWeight: 400,
  lineHeight: '12px',
  textAlign: 'center',
  color: className?.includes('dark') ? COLORS.DISABLED_BTN_TEXT : COLORS.TEXT_GREY,
  '@media (min-width: 769px)': {
    fontSize: '12px',
  },
}));

export const ButtonsWrapper = styled('div')(({ className }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  gap: className?.includes('mobile') ? '3px' : '8px',
  marginTop: className?.includes('mobile') ? '14px' : '32px',
  fontWeight: 400,
  '& button': {
    fontSize: '7.5px',
    padding: '3px 8px',
    lineHeight: '11px',
    width: '63px',
    borderRadius: '3.2px',
  },
  padding: '0px 14px',
  '@media (min-width: 769px)': {
    padding: '0 32px',
    '& button': {
      fontSize: '16px',
      padding: '8px 16px',
      lineHeight: '24px',
      width: '136px',
      borderRadius: '8px',
    },
  },
}));
