import styled from '@emotion/styled';

export const PDFModalButtonsWrapper = styled('div')(({ className }) => ({
  marginTop: '32px',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  '& button': {
    width: '280px',
    padding: '8px 16px 8px 12px',
    height: '48px',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 400,
    gap: '8px',
  },
  padding: '0px 14px',
  '@media (min-width: 360px)': {
    padding: '0 32px',
  },
}));
