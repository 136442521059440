import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { currenciesReducer } from 'entities/currencies';
import { loginProgressReducer } from 'entities/loginProgress';
import { languageReducer } from 'entities/language';
import { priceListReducer } from 'entities/priceList';
import { companyReducer } from 'entities/company';
import { categoryReducer } from 'entities/category';
import { positionsFilterReducer } from 'entities/positionsFilter';
import { estimatesFilterReducer } from 'entities/estimatesFilter';
import { projectsReducer } from 'entities/project';
import { estimatesReducer } from 'entities/estimates';
import { accessCompanyReducer } from 'entities/accessCompany';
import { themeReducer } from 'entities/theme';
import { accountInfoReducer } from 'entities/account';
import { subscriptionsReducer } from 'entities/subscriptions';

const rootReducer = combineReducers({
  language: languageReducer,
  loginProgress: loginProgressReducer,
  priceList: priceListReducer,
  currencies: currenciesReducer,
  company: companyReducer,
  categories: categoryReducer,
  positionsFilter: positionsFilterReducer,
  estimatesFilter: estimatesFilterReducer,
  projects: projectsReducer,
  estimates: estimatesReducer,
  accessCompany: accessCompanyReducer,
  account: accountInfoReducer,
  theme: themeReducer,
  subscriptions: subscriptionsReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignoring non-serializable values in the following paths
        // These fields may contain instances of non-serializable data types such as Date objects.
        ignoredPaths: ['subscriptions.customerInfo'],
        // Ignoring non-serializable actions for these specific action types
        // This is necessary because they may carry non-serializable payloads.
        ignoredActions: ['subscriptions/setCustomerInfo'],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
