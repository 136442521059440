import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { ArticleType, editMarkups, editMarkupsHiddenCopy } from 'entities/project';
import { routes, text } from 'shared/constants';
import { LoadingResultsT } from 'entities/loadingType';
import { currentEstimateMarkups, getDefaultProjectMarkups, useAppDispatch, useAppSelector } from 'shared/lib';
import { ActionButton, ModalWrapper } from 'shared/ui';
import { MaterialsIcon, WorkIcon } from 'shared/assets/icons';
import { defaultValues } from '../model/defaultValues';
import { ArticleWrapper, ButtonsWrapper, ContentWrapper, InputWrapper, MarkupWrapper } from './styles';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  selectedProjectId: number | null;
  currentEstimateId?: number;
}

export const EditMarkupModal: React.FC<Props> = ({ isOpen, handleClose, selectedProjectId, currentEstimateId }) => {
  const language = useAppSelector((state) => state.language.language);
  const selectedCompany = useAppSelector((state) => state.company.selectedCompany);
  const loadingProjectsMarkups = useAppSelector((state) => state.projects.loadingProjectsMarkups);
  const projectMarkups = useAppSelector((state) => state.projects.projectMarkups);
  const estimates = useAppSelector((state) => state.estimates.estimates);
  const theme = useAppSelector((state) => state.theme.theme);
  const dispatch = useAppDispatch();
  const [markup, setMarkup] = useState(defaultValues);
  const [isFormChanged, setIsFormChanged] = useState(false);

  const { projectId: idFromParams } = useParams();
  const location = useLocation();

  const isEstimateEditingPage = location.pathname.split('/').includes(routes.estimateEditing);
  const currentEstimate = estimates?.find((estimate) => estimate.id === currentEstimateId);

  const onModalClose = () => {
    handleClose();
    setIsFormChanged(false);
  };

  useEffect(() => {
    if (loadingProjectsMarkups === LoadingResultsT.SUCCEEDED) {
      onModalClose();
      setMarkup({ ...defaultValues });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingProjectsMarkups]);

  useEffect(() => {
    if (!projectMarkups) return;
    const markups = getDefaultProjectMarkups(projectMarkups);

    if (!isEstimateEditingPage && markups) {
      setMarkup(markups);
    } else if (isEstimateEditingPage && currentEstimate) {
      const currentMarkups = currentEstimateMarkups(currentEstimate, projectMarkups);
      setMarkup(currentMarkups);
    }
  }, [projectMarkups, language, isOpen, currentEstimate, isEstimateEditingPage]);

  //TODO: propose to add article id to markups and remove languages
  const prepareMarkupToRequest = () => {
    let workMarkupId;
    let materialsMarkupId;
    if (currentEstimateId && currentEstimate) {
      workMarkupId = currentEstimate?.workArticle;
      materialsMarkupId = currentEstimate.materialsArticle;
    } else {
      workMarkupId = projectMarkups?.find(
        (markup) => markup.name === text['UA'].Work || markup.name === text['EN'].Work,
      )?.id;
      materialsMarkupId = projectMarkups?.find(
        (markup) => markup.name === text['UA'].Materials || markup.name === text['EN'].Materials,
      )?.id;
    }
    if (!workMarkupId || !materialsMarkupId) return;
    return {
      work: {
        markup: markup.workMarkup,
        id: workMarkupId,
        name: ArticleType.workArticle,
      },
      materials: {
        markup: markup.materialsMarkup,
        id: materialsMarkupId,
        name: ArticleType.materialsArticle,
      },
    };
  };

  const onSaveButtonClick = () => {
    const companyId = selectedCompany?.id;
    const projectId = selectedProjectId || Number(idFromParams);

    if (!companyId || !projectId) return;
    const preparedMarkup = prepareMarkupToRequest();
    if (!preparedMarkup) return;
    //TODO: propose to edit request body for removing double request
    if (isEstimateEditingPage) {
      dispatch(
        editMarkupsHiddenCopy({
          companyId,
          projectId,
          items: [preparedMarkup.materials, preparedMarkup.work],
          estimateId: currentEstimateId,
        }),
      );
    } else {
      dispatch(editMarkups({ companyId, projectId, ...preparedMarkup.work }));
      dispatch(editMarkups({ companyId, projectId, ...preparedMarkup.materials }));
    }
  };

  function handleMarkupInput(value: string) {
    if (!value.length) return 0;
    const trimmedValue = value.replace(/[^0-9]/g, '');
    let numberValue = parseInt(trimmedValue, 10);

    if (numberValue > 99) {
      numberValue = parseInt(trimmedValue.slice(0, 2), 10);
    }

    numberValue = Math.min(99, numberValue);
    return numberValue;
  }

  const isSaveButtonDisabled = !isFormChanged;
  const isDarkTheme = theme === 'dark';
  return (
    <ModalWrapper isOpen={isOpen} title={text[language].editingMarkups}>
      <ContentWrapper className={isDarkTheme ? 'dark' : ''}>
        <p>{text[language].enterMarkup}</p>
        <MarkupWrapper className={isDarkTheme ? 'dark' : ''}>
          <ArticleWrapper>
            <WorkIcon language={language} size={20} />
            <p>{text[language].Work}</p>
          </ArticleWrapper>
          <InputWrapper className={isDarkTheme ? 'dark' : ''}>
            <input
              type="text"
              inputMode="numeric"
              value={markup.workMarkup}
              onChange={(e) => {
                const workMarkup = handleMarkupInput(e.target.value);
                setMarkup({ ...markup, workMarkup });
                setIsFormChanged(true);
              }}
            />
            <div>%</div>
          </InputWrapper>
        </MarkupWrapper>
        <MarkupWrapper className={isDarkTheme ? 'dark' : ''}>
          <ArticleWrapper>
            <MaterialsIcon size={20} />
            <p>{text[language].Materials}</p>
          </ArticleWrapper>
          <InputWrapper className={isDarkTheme ? 'dark' : ''}>
            <input
              type="text"
              inputMode="numeric"
              value={markup.materialsMarkup}
              onChange={(e) => {
                const materialsMarkup = handleMarkupInput(e.target.value);
                setMarkup({ ...markup, materialsMarkup });
                setIsFormChanged(true);
              }}
            />
            <div>%</div>
          </InputWrapper>
        </MarkupWrapper>
      </ContentWrapper>
      <ButtonsWrapper>
        <ActionButton text={text[language].Cancel} onClick={onModalClose} />
        <ActionButton
          isPending={loadingProjectsMarkups === LoadingResultsT.PENDING}
          text={text[language].Save}
          orangeBtn
          disabled={isSaveButtonDisabled}
          onClick={onSaveButtonClick}
          isPriceListMainButton={true}
        />
      </ButtonsWrapper>
    </ModalWrapper>
  );
};
