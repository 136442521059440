import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { COLORS } from 'shared/constants';

const loading = keyframes`
0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
`;

export const SkeletonTextContainer = styled('div')(({ className }) => ({
  width: '100%',
  padding: '8px',
}));

export const SkeletonLine = styled('div')(({ className }) => ({
  width: '100%',
  height: '20px',
  background: className?.includes('sideBarLoader') ? COLORS.SIDEBAR_SKELETON_BG : COLORS.SKELETON_BG,
  backgroundSize: '200% 100%',
  animation: `${loading} 1.5s infinite`,
  borderRadius: '5px',
}));
