import { CurrenciesType } from 'entities/priceList';

export const calculateCrossRate = (
  baseCurrency: string | null,
  targetCurrency: string,
  currencyRateList: Record<CurrenciesType, string>,
): number | undefined => {
  if (!baseCurrency || !Object.keys(currencyRateList).length) return;

  const baseCurrencyRate = parseFloat(currencyRateList[baseCurrency as CurrenciesType]);

  const targetCurrencyRate = parseFloat(currencyRateList[targetCurrency as CurrenciesType]);

  if (baseCurrency === 'UAH') {
    return baseCurrencyRate;
  }

  if (targetCurrency === 'UAH') {
    return 1 / baseCurrencyRate;
  }
  const crossRate = targetCurrencyRate / baseCurrencyRate;

  return crossRate;
};
