import React from 'react';
import { COLORS } from 'shared/constants';
import { useAppSelector } from 'shared/lib';
type Props = {
  isActive: boolean;
  onClick: () => void;
};
export const CalendarIcon: React.FC<Props> = ({ isActive, onClick }) => {
  const theme = useAppSelector((state) => state.theme.theme);

  const isDarkTheme = theme === 'dark';
  return (
    <>
      {isActive ? (
        <svg
          onClick={onClick}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M8.57143 5.44429H7.65714C6.37702 5.44429 5.73696 5.44429 5.24802 5.68836C4.81794 5.90306 4.46827 6.24564 4.24913 6.66701C4 7.14604 4 7.77312 4 9.02729V11.0427M8.57143 5.44429H15.4286M8.57143 5.44429V4.00024M8.57143 5.44429V7.12429M15.4286 5.44429H16.3429C17.623 5.44429 18.263 5.44429 18.752 5.68836C19.1821 5.90306 19.5317 6.24564 19.7509 6.66701C20 7.14604 20 7.77312 20 9.02729V11.0427M15.4286 5.44429V4.00024M15.4286 5.44429V7.12429M20 11.0427V16.4172C20 17.6714 20 18.2985 19.7509 18.7775C19.5317 19.1989 19.1821 19.5415 18.752 19.7562C18.263 20.0002 17.623 20.0002 16.3429 20.0002H7.65714C6.37702 20.0002 5.73696 20.0002 5.24802 19.7562C4.81794 19.5415 4.46827 19.1989 4.24913 18.7775C4 18.2985 4 17.6714 4 16.4172V11.0427M20 11.0427H4"
            stroke="url(#paint0_linear_2611_4739)"
            strokeLinecap="round"
          />
          <defs>
            <linearGradient
              id="paint0_linear_2611_4739"
              x1="4"
              y1="20.0002"
              x2="20"
              y2="4.00024"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FF8C21" />
              <stop offset="0.880208" stopColor="#FFD200" />
            </linearGradient>
          </defs>
        </svg>
      ) : (
        <svg
          onClick={onClick}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M8.57143 5.44416H7.65714C6.37702 5.44416 5.73696 5.44416 5.24802 5.68824C4.81794 5.90294 4.46827 6.24552 4.24913 6.66689C4 7.14592 4 7.773 4 9.02717V11.0426M8.57143 5.44416H15.4286M8.57143 5.44416V4.00012M8.57143 5.44416V7.12416M15.4286 5.44416H16.3429C17.623 5.44416 18.263 5.44416 18.752 5.68824C19.1821 5.90294 19.5317 6.24552 19.7509 6.66689C20 7.14592 20 7.773 20 9.02717V11.0426M15.4286 5.44416V4.00012M15.4286 5.44416V7.12416M20 11.0426V16.4171C20 17.6713 20 18.2984 19.7509 18.7774C19.5317 19.1988 19.1821 19.5413 18.752 19.756C18.263 20.0001 17.623 20.0001 16.3429 20.0001H7.65714C6.37702 20.0001 5.73696 20.0001 5.24802 19.756C4.81794 19.5413 4.46827 19.1988 4.24913 18.7774C4 18.2984 4 17.6713 4 16.4171V11.0426M20 11.0426H4"
            stroke={isDarkTheme ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK}
            strokeLinecap="round"
          />
        </svg>
      )}
    </>
  );
};
