import React from 'react';
import { COLORS } from 'shared/constants';
import { useAppSelector } from 'shared/lib';

export const EstimateWrapper: React.FC = () => {
  const theme = useAppSelector((state) => state.theme.theme);
  const isDarkTheme = theme === 'dark';
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 200 200" fill="none">
      <path
        d="M168 48C159.163 48 152 40.8366 152 32M199.5 47.5H168C159.44 47.5 152.5 40.5604 152.5 32V0.5H168.373C172.483 0.5 176.426 2.13303 179.333 5.03984L194.96 20.6673C197.867 23.5741 199.5 27.5166 199.5 31.6274V47.5ZM199.5 48.5V100V184C199.5 192.56 192.56 199.5 184 199.5H16C7.43958 199.5 0.5 192.56 0.5 184V16C0.5 7.43958 7.43959 0.5 16 0.5H100H151.5V32C151.5 41.1127 158.887 48.5 168 48.5H199.5Z"
        fill={isDarkTheme ? COLORS.MODAL_BG_DARK : COLORS.TEXT_WHITE}
        stroke={isDarkTheme ? COLORS.LIGHT_GREY_BORDER : COLORS.BORDER_GREY}
      />
    </svg>
  );
};
