import React from 'react';
import { useAppSelector } from 'shared/lib';

type Props = {
  size?: number;
  isActive?: boolean;
  id?: string;
};

export const Dots: React.FC<Props> = ({ size = 24, isActive = false, id }) => {
  const theme = useAppSelector((state) => state.theme.theme);
  const isDarkTheme = theme === 'dark';
  return (
    <>
      {isActive ? (
        <svg id={id} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
          <path
            d="M17 12C17 12.5523 17.4477 13 18 13C18.5523 13 19 12.5523 19 12C19 11.4477 18.5523 11 18 11C17.4477 11 17 11.4477 17 12Z"
            fill="url(#paint0_linear_1142_10996)"
          />
          <path
            d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z"
            fill="url(#paint1_linear_1142_10996)"
          />
          <path
            d="M5 12C5 12.5523 5.44771 13 6 13C6.55229 13 7 12.5523 7 12C7 11.4477 6.55229 11 6 11C5.44771 11 5 11.4477 5 12Z"
            fill="url(#paint2_linear_1142_10996)"
          />
          <path
            d="M17 12C17 12.5523 17.4477 13 18 13C18.5523 13 19 12.5523 19 12C19 11.4477 18.5523 11 18 11C17.4477 11 17 11.4477 17 12Z"
            stroke="url(#paint3_linear_1142_10996)"
          />
          <path
            d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z"
            stroke="url(#paint4_linear_1142_10996)"
          />
          <path
            d="M5 12C5 12.5523 5.44771 13 6 13C6.55229 13 7 12.5523 7 12C7 11.4477 6.55229 11 6 11C5.44771 11 5 11.4477 5 12Z"
            stroke="url(#paint5_linear_1142_10996)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_1142_10996"
              x1="19"
              y1="13"
              x2="18.44"
              y2="9.08"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FF8C21" />
              <stop offset="0.880208" stopColor="#FFD200" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_1142_10996"
              x1="19"
              y1="13"
              x2="18.44"
              y2="9.08"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FF8C21" />
              <stop offset="0.880208" stopColor="#FFD200" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_1142_10996"
              x1="19"
              y1="13"
              x2="18.44"
              y2="9.08"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FF8C21" />
              <stop offset="0.880208" stopColor="#FFD200" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_1142_10996"
              x1="19"
              y1="13"
              x2="18.44"
              y2="9.08"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FF8C21" />
              <stop offset="0.880208" stopColor="#FFD200" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_1142_10996"
              x1="19"
              y1="13"
              x2="18.44"
              y2="9.08"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FF8C21" />
              <stop offset="0.880208" stopColor="#FFD200" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_1142_10996"
              x1="19"
              y1="13"
              x2="18.44"
              y2="9.08"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FF8C21" />
              <stop offset="0.880208" stopColor="#FFD200" />
            </linearGradient>
          </defs>
        </svg>
      ) : (
        <svg id={id} width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17 12C17 12.5523 17.4477 13 18 13C18.5523 13 19 12.5523 19 12C19 11.4477 18.5523 11 18 11C17.4477 11 17 11.4477 17 12Z"
            fill={isDarkTheme ? 'white ' : 'black'}
          />
          <path
            d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z"
            fill={isDarkTheme ? 'white ' : 'black'}
          />
          <path
            d="M5 12C5 12.5523 5.44772 13 6 13C6.55228 13 7 12.5523 7 12C7 11.4477 6.55228 11 6 11C5.44772 11 5 11.4477 5 12Z"
            fill={isDarkTheme ? 'white ' : 'black'}
          />
          <path
            d="M17 12C17 12.5523 17.4477 13 18 13C18.5523 13 19 12.5523 19 12C19 11.4477 18.5523 11 18 11C17.4477 11 17 11.4477 17 12Z"
            stroke={isDarkTheme ? 'white ' : 'black'}
            strokeWidth="1"
          />
          <path
            d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z"
            stroke={isDarkTheme ? 'white ' : 'black'}
            strokeWidth="1"
          />
          <path
            d="M5 12C5 12.5523 5.44772 13 6 13C6.55228 13 7 12.5523 7 12C7 11.4477 6.55228 11 6 11C5.44772 11 5 11.4477 5 12Z"
            stroke={isDarkTheme ? 'white ' : 'black'}
            strokeWidth="1"
          />
        </svg>
      )}
    </>
  );
};
