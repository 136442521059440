import React, { useEffect, useRef, useState } from 'react';

import { EditableTableCell } from '../../../styles';
import { useAppSelector } from 'shared/lib';

type Props = {
  width: string;
  onChangeInputField: (value: string, field: 'quantity' | 'cost' | 'total') => void;
  onBlurInputField?: (value: string, field: 'quantity' | 'cost' | 'total') => void;
  value: string | number;
  className?: string;
  type: 'quantity' | 'cost' | 'total';
  isPossibleToEdit: boolean;
};

const EditableCell: React.FC<Props> = ({
  width,
  onChangeInputField,
  onBlurInputField,
  value,
  className = '',
  type,
  isPossibleToEdit,
}) => {
  const theme = useAppSelector((state) => state.theme.theme);
  const cellRef = useRef<HTMLDivElement | null>(null);
  const [isTouched, setIsTouched] = useState(false);

  const useContentEditableFocus = (contentEditableRef: React.RefObject<HTMLDivElement>, value: string | number) => {
    useEffect(() => {
      const contentEditableElement = contentEditableRef.current;
      if (contentEditableElement) {
        const selection = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents(contentEditableElement);
        range.collapse(false);
        selection?.removeAllRanges();
        selection?.addRange(range);
      }
    }, [value, contentEditableRef]);
  };

  useContentEditableFocus(cellRef, value);

  //TODO: add throttling to changing values
  const isDarkTheme = theme === 'dark';
  return (
    <EditableTableCell
      disabled={!isPossibleToEdit}
      type="text"
      inputMode="decimal"
      style={{ width }}
      className={isDarkTheme ? `${className} dark` : className}
      onChange={(e) => onChangeInputField(e.target.value, type)}
      value={value}
      onClick={(e) => {
        const value = (e.target as any).value;
        setIsTouched(true);
        if (value === '1' && !isTouched && type === 'quantity') {
          onChangeInputField('0', type);
        }
      }}
      onBlur={(e) => {
        let value;
        if (e.target.value === '0') {
          value = '1';
          onChangeInputField(value, type);
        } else {
          value = e.target.value;
        }
        onBlurInputField && onBlurInputField(value, type);
      }}
    />
  );
};

export default EditableCell;
