import React from 'react';
import { COLORS } from 'shared/constants';

type Props = {
  size?: number;
  isDarkTheme: boolean;
};

export const Logout: React.FC<Props> = ({ size = 24, isDarkTheme }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.1429 7.42857V4H4M4 4V15.4286L9.71429 20V8.57143L4 4ZM13.1429 14.2857H20M20 14.2857L16.5714 10.8571M20 14.2857L16.5714 17.7143"
        stroke={isDarkTheme ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
