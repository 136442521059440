import { ICategory } from 'entities/category';
import { IPriceList } from 'entities/priceList';

export const areAllPositionWithoutCategory = (
  categories: ICategory[] | null,
  positions: IPriceList[] | null,
  checkedPositionsIds: number[],
) => {
  const checkedPositions = positions?.filter((pos) => checkedPositionsIds.includes(pos.id));

  const hasPrimaryCategory = checkedPositions?.every((pos) => {
    const category = categories?.find((cat) => cat.id === pos.category.id);
    return category?.primary === true;
  });

  return !hasPrimaryCategory;
};

export const areAllCheckedPositionsFromCheckedCategories = (
  checkedCategoriesIds: number[],
  checkedPositionsIds: number[],
  categories: ICategory[] | null,
  positions: IPriceList[] | null,
) => {
  const checkedCategories = categories?.filter((cat) => checkedCategoriesIds.includes(cat.id));
  const checkedPositions = positions?.filter((pos) => checkedPositionsIds.includes(pos.id));
  const checkedPositionsCategoriesIds = checkedPositions?.map((pos) => pos.category?.id);
  const checkedPositionsCategories = categories?.filter((cat) => checkedPositionsCategoriesIds?.includes(cat.id));

  const checkedCategoriesWithoutEmpty = removeEmptyCategories(checkedCategories, checkedPositions);

  if (checkedCategoriesWithoutEmpty?.length === checkedPositionsCategories?.length) {
    return true;
  } else {
    return false;
  }
};

const removeEmptyCategories = (categories?: ICategory[], positions?: IPriceList[]) => {
  const usedCategoryIds = new Set(positions?.map((pos) => pos.category?.id));

  const nonEmptyCategories = categories?.filter((cat) => usedCategoryIds.has(cat.id));
  return nonEmptyCategories;
};
