import React from 'react';

export const SaleIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M9.40234 9.40234L9.28187 9.2818M5 8.50357L5 10.9758C5 11.5266 5.00001 11.8019 5.0637 12.0602C5.12016 12.2892 5.21324 12.5075 5.33933 12.7068C5.48158 12.9316 5.68025 13.1223 6.0776 13.5036L11.2826 18.4989C12.1511 19.3324 12.5853 19.7492 13.0808 19.9023C13.5167 20.0371 13.9838 20.0323 14.4169 19.8886C14.9091 19.7253 15.3347 19.2997 16.1859 18.4485L18.4485 16.1859C19.2997 15.3347 19.7253 14.9091 19.8886 14.4169C20.0323 13.9838 20.0371 13.5167 19.9023 13.0808C19.7492 12.5853 19.3324 12.1511 18.4989 11.2825L13.5036 6.0776C13.1223 5.68025 12.9316 5.48157 12.7068 5.33933C12.5076 5.21323 12.2892 5.12015 12.0603 5.06369C11.802 5 11.5266 5 10.9759 5L8.50355 5C7.27719 5 6.66401 5 6.1956 5.23866C5.78358 5.4486 5.44859 5.78359 5.23866 6.19561C4.99999 6.66402 4.99999 7.2772 5 8.50357Z"
        stroke="white"
        strokeLinecap="round"
      />
    </svg>
  );
};
