import { IPriceList } from 'entities/priceList';
import { AppDispatch } from 'app/store';
import { ICategory } from 'entities/category';
import { checkPositionsTypes } from './types';
import { getArrayOfIds } from 'shared/lib';
import { addCheckedCategories, addCheckedPositions } from 'entities/positionsFilter';

export function headCheckboxHandler(
  type: checkPositionsTypes,
  priceList: IPriceList[] | null,
  categories: ICategory[] | null,
  dispatch: AppDispatch,
) {
  switch (type) {
    case 'all':
      priceList?.forEach((position) => {
        dispatch(addCheckedPositions([position.id]));
      });
      categories?.forEach((category) => {
        if (category.primary === true) return;
        dispatch(addCheckedCategories([category.id]));
      });
      break;

    case 'materials':
      priceList
        ?.filter((position) => position.article === 1)
        .forEach((position) => {
          dispatch(addCheckedPositions([position.id]));
        });
      break;

    case 'work':
      priceList
        ?.filter((position) => position.article === 2)
        .forEach((position) => {
          dispatch(addCheckedPositions([position.id]));
        });
      break;

    case 'allCategories':
      categories?.forEach((category) => {
        const checkedPositions = priceList?.filter((pos) => pos.category.id === category?.id);

        if (category.primary === true) return;
        dispatch(addCheckedCategories([category.id]));
        dispatch(addCheckedPositions(getArrayOfIds(checkedPositions)));
      });
      break;

    case 'withoutCategories':
      const unCategorizedCategory = categories?.find((category) => category.primary === true);
      priceList
        ?.filter((position) => position.category.id === unCategorizedCategory?.id)
        .forEach((position) => {
          dispatch(addCheckedPositions([position.id]));
        });
      break;

    default:
      break;
  }
}
