import React, { useEffect, useRef, useState } from 'react';
import { addCheckedCategories, addCheckedPositions, resetEstimatesFilterList } from 'entities/estimatesFilter';
import { Checkbox } from 'shared/ui';
import { useHover, useAppSelector, useAppDispatch, getArrayOfIds } from 'shared/lib';
import { text } from 'shared/constants';

import { ButtonTableCellContainer, DropdownBody, DropdownElement, MainTableCellContainer, TableCell } from '../styles';
import { TableHeadDropDownContainer, TableHeadRowContainer } from './styles';

type checkPositionsTypes = 'all' | 'materials' | 'work' | 'allCategories' | 'withoutCategories';

type Props = {
  isPossibleToEdit: boolean;
};

const EstimatePosTableHead: React.FC<Props> = ({ isPossibleToEdit }) => {
  const language = useAppSelector((state) => state.language.language);
  const priceList = useAppSelector((state) => state.estimates.estimatePriceList);
  const checkedPositionsIds = useAppSelector((state) => state.estimatesFilter.checkedPositionsIds);
  const checkedCategoryIds = useAppSelector((state) => state.estimatesFilter.checkedCategoryIds);
  const categories = useAppSelector((state) => state.categories.categories);
  const theme = useAppSelector((state) => state.theme.theme);
  const dispatch = useAppDispatch();
  const checkBoxWrapperRef = useRef<HTMLDivElement | null>(null);
  const checkBoxHovered = useHover(checkBoxWrapperRef.current);
  const [checked, setChecked] = useState(false);
  const [checkedPosition, setCheckedPosition] = useState<Record<checkPositionsTypes, boolean>>({
    all: false,
    materials: false,
    work: false,
    allCategories: false,
    withoutCategories: false,
  });

  const resetChecked = () => {
    setCheckedPosition({
      all: false,
      materials: false,
      work: false,
      allCategories: false,
      withoutCategories: false,
    });
    dispatch(resetEstimatesFilterList());
  };

  useEffect(() => {
    if (checkedCategoryIds.length > 0 || checkedPositionsIds.length > 0) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [checkedCategoryIds.length, checkedPositionsIds.length]);

  // Set all fields in an object to false
  useEffect(() => {
    if (!checked) {
      const updatedObj = { ...checkedPosition };
      // Iterate through the keys in the object
      for (const key in updatedObj) {
        if (updatedObj.hasOwnProperty(key)) {
          // Set each field to false
          updatedObj[key as checkPositionsTypes] = false;
        }
      }
      // Use the setCheckedPosition function to update the state
      setCheckedPosition(updatedObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  useEffect(() => {
    const isAnyTrue = Object.values(checkedPosition).some((value) => value === true);
    setChecked(isAnyTrue);
  }, [checkedPosition]);

  function HeadCheckboxHandler(type: checkPositionsTypes) {
    switch (type) {
      case 'all':
        priceList?.forEach((position) => {
          dispatch(addCheckedPositions([position.id]));
        });
        categories?.forEach((category) => {
          if (category.primary === true) return;
          dispatch(addCheckedCategories([category.id]));
        });
        break;

      case 'materials':
        priceList
          ?.filter((position) => position.article === 1)
          .forEach((position) => {
            dispatch(addCheckedPositions([position.id]));
          });
        break;

      case 'work':
        priceList
          ?.filter((position) => position.article === 2)
          .forEach((position) => {
            dispatch(addCheckedPositions([position.id]));
          });
        break;

      case 'allCategories':
        categories?.forEach((category) => {
          const checkedPositions = priceList?.filter((pos) => pos.category?.id === category?.id);

          if (category.primary === true) return;
          dispatch(addCheckedCategories([category.id]));
          dispatch(addCheckedPositions(getArrayOfIds(checkedPositions)));
        });
        break;

      case 'withoutCategories':
        priceList
          ?.filter((position) => !position.category?.id)
          .forEach((position) => {
            dispatch(addCheckedPositions([position.id]));
          });
        break;

      default:
        break;
    }
  }

  function OnDropDownClick(type: checkPositionsTypes) {
    // Reset the filter in the store to start with a clean slate
    dispatch(resetEstimatesFilterList());

    // If the value was 'true', set to 'false'
    if (checkedPosition[type] === true) {
      return setCheckedPosition({ ...checkedPosition, [type]: false });
    }

    // Call the HeadCheckboxHandler to update store data based on the selected type
    HeadCheckboxHandler(type);

    // Update the checkedPosition state to toggle the selected type to 'true' and reset others to 'false'
    const updatedPosition = { ...checkedPosition };

    // Toggle the value for the specified type
    updatedPosition[type] = !updatedPosition[type];

    // If the value is toggled to 'true', set all others to 'false'
    if (updatedPosition[type]) {
      Object.keys(updatedPosition).forEach((key) => {
        if (key !== type) {
          updatedPosition[key as checkPositionsTypes] = false;
        }
      });
    }
    setCheckedPosition(updatedPosition);
  }

  function IsActiveDropDownElement(type: checkPositionsTypes): string {
    return checkedPosition[type] ? 'active' : '';
  }

  const toggleCheckbox = () => {
    if (!checked) {
      OnDropDownClick('all');
    } else {
      resetChecked();
    }
  };

  const isDarkTheme = theme === 'dark';
  return (
    <TableHeadRowContainer className={isDarkTheme ? 'dark' : ''}>
      {isPossibleToEdit && (
        <ButtonTableCellContainer>
          <div style={{ position: 'relative', paddingTop: '3px' }} ref={checkBoxWrapperRef}>
            <Checkbox toggleCheckbox={toggleCheckbox} checked={checked} />
            {checkBoxHovered && (
              <TableHeadDropDownContainer>
                <DropdownBody className={isDarkTheme ? 'dark' : ''}>
                  <DropdownElement
                    className={isDarkTheme ? `dark ${IsActiveDropDownElement('all')}` : IsActiveDropDownElement('all')}
                    onClick={() => OnDropDownClick('all')}
                  >
                    {text[language].ChooseAll}
                  </DropdownElement>
                  <DropdownElement
                    className={
                      isDarkTheme
                        ? `dark ${IsActiveDropDownElement('materials')}`
                        : IsActiveDropDownElement('materials')
                    }
                    onClick={() => OnDropDownClick('materials')}
                  >
                    {text[language].ChooseMaterials}
                  </DropdownElement>
                  <DropdownElement
                    className={
                      isDarkTheme ? `dark ${IsActiveDropDownElement('work')}` : IsActiveDropDownElement('work')
                    }
                    onClick={() => OnDropDownClick('work')}
                  >
                    {text[language].ChooseWork}
                  </DropdownElement>
                  <DropdownElement
                    className={
                      isDarkTheme
                        ? `dark ${IsActiveDropDownElement('allCategories')}`
                        : IsActiveDropDownElement('allCategories')
                    }
                    onClick={() => OnDropDownClick('allCategories')}
                  >
                    {text[language].ChooseAllCategories}
                  </DropdownElement>
                  <DropdownElement
                    className={
                      isDarkTheme
                        ? `dark ${IsActiveDropDownElement('withoutCategories')}`
                        : IsActiveDropDownElement('withoutCategories')
                    }
                    onClick={() => OnDropDownClick('withoutCategories')}
                  >
                    {text[language].ChoosePositionsWithoutCategory}
                  </DropdownElement>
                </DropdownBody>
              </TableHeadDropDownContainer>
            )}
          </div>
        </ButtonTableCellContainer>
      )}
      <ButtonTableCellContainer></ButtonTableCellContainer>
      <MainTableCellContainer>{text[language].PositionName}</MainTableCellContainer>
      <TableCell style={{ width: '80px' }}>{text[language].number}</TableCell>
      <TableCell style={{ width: '130px' }}>{text[language].units}</TableCell>
      <TableCell style={{ width: '100px' }}>{text[language].Total}</TableCell>
      <TableCell style={{ width: '100px' }}>{text[language].Cost}</TableCell>
      <TableCell style={{ width: '100px' }}>{text[language].theTotalCost}</TableCell>
      <TableCell style={{ width: '100px' }}>{text[language].totalCost}</TableCell>
      <TableCell style={{ width: '70px' }}>{text[language].markup} %</TableCell>
      <TableCell style={{ width: '120px' }}>{text[language].totalSumWithMarkup}</TableCell>
    </TableHeadRowContainer>
  );
};

export default EstimatePosTableHead;
