import { ICategory } from 'entities/category';
import { IPriceList } from 'entities/priceList';

export const getCategoryById = (categoryId: number, categoriesList: ICategory[]): ICategory | undefined =>
  categoriesList.find((category) => category.id === categoryId);

export const getArrayOfIds = (arr?: any[]): number[] => {
  const res: number[] = [];
  if (arr) {
    arr.forEach((i) => {
      if (i?.id) {
        res.push(i.id);
      }
    });
  }
  return res;
};

export function GetUniqueUnitsFromPriceList(priceList: IPriceList[] | null) {
  if (!priceList) return [];
  const units = priceList.map((item) => item.units);
  return [...new Set(units)];
}
