import { createSlice } from '@reduxjs/toolkit';
import { setDarkScrollBar, setLightScrollBar } from './helpers';

export type ThemeType = 'light' | 'dark';

interface IState {
  theme: ThemeType;
}

const initialState: IState = {
  theme: 'light',
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setTheme(state, { payload }) {
      if (payload === 'dark') {
        setDarkScrollBar();
      } else if (payload === 'light') {
        setLightScrollBar();
      }
      state.theme = payload;
    },
  },
});

export const { setTheme } = themeSlice.actions;
export const themeReducer = themeSlice.reducer;
