import React from 'react';
import { useAppSelector } from 'shared/lib';
import { text } from 'shared/constants';
import EstimatePositionsTableHead from './EstimatePosTableHead/EstimatePosTableHead';
import EstimatePositionsEmptyTableRow from './EstimatePosEmptyTableRow/EstimatePosEmptyTableRow';
import EstimatePosTableBody from './EstimatePosTableBody/EstimatePosTableBody';
import { getTotalCounts } from '../model';
import { AllTableRowContainer, ButtonTableCellContainer, MainTableCell, TableCell, TableWrapper } from './styles';

type Props = {
  isPossibleToEdit?: boolean;
};

export const EstimatePositionsTable: React.FC<Props> = ({ isPossibleToEdit = true }) => {
  const language = useAppSelector((state) => state.language.language);
  const tempMarkups = useAppSelector((state) => state.projects.tempProjectMarkup);
  const estimatePriceList = useAppSelector((state) => state.estimates.estimatePriceList);
  const theme = useAppSelector((state) => state.theme.theme);

  const calculatePriceItems = () => {
    if (!estimatePriceList || !tempMarkups) return;
    return getTotalCounts(estimatePriceList, tempMarkups);
  };

  const isDarkTheme = theme === 'dark';
  return (
    <TableWrapper className={isDarkTheme ? 'dark' : ''}>
      <EstimatePositionsTableHead isPossibleToEdit={isPossibleToEdit} />
      {!estimatePriceList || estimatePriceList?.length === 0 ? (
        <EstimatePositionsEmptyTableRow />
      ) : (
        <>
          <EstimatePosTableBody isPossibleToEdit={isPossibleToEdit} />
          <AllTableRowContainer className={isDarkTheme ? 'dark' : ''}>
            {isPossibleToEdit && <ButtonTableCellContainer></ButtonTableCellContainer>}
            <ButtonTableCellContainer></ButtonTableCellContainer>
            <MainTableCell>{text[language].inTotal}</MainTableCell>
            <TableCell style={{ width: '80px' }}></TableCell>
            <TableCell style={{ width: '130px' }}></TableCell>
            <TableCell style={{ width: '100px' }}>{calculatePriceItems()?.total.toFixed(2)}</TableCell>
            <TableCell style={{ width: '100px' }}>{calculatePriceItems()?.cost.toFixed(2)}</TableCell>
            <TableCell style={{ width: '100px' }}>{calculatePriceItems()?.generalTotal.toFixed(2)}</TableCell>
            <TableCell style={{ width: '100px' }}>{calculatePriceItems()?.generalCost.toFixed(2)}</TableCell>
            <TableCell style={{ width: '70px' }}></TableCell>
            <TableCell style={{ width: '120px' }}>{(calculatePriceItems()?.totalWithMarkup || 0).toFixed(2)}</TableCell>
          </AllTableRowContainer>
          <AllTableRowContainer className={isDarkTheme ? 'dark sum' : 'sum'}>
            {isPossibleToEdit && <ButtonTableCellContainer></ButtonTableCellContainer>}
            <ButtonTableCellContainer></ButtonTableCellContainer>
            <MainTableCell>{text[language].estimateAmount}</MainTableCell>
            <TableCell style={{ width: '80px' }}></TableCell>
            <TableCell style={{ width: '130px' }}></TableCell>
            <TableCell style={{ width: '100px' }}></TableCell>
            <TableCell style={{ width: '100px' }}></TableCell>
            <TableCell style={{ width: '100px' }}></TableCell>
            <TableCell style={{ width: '100px' }}></TableCell>
            <TableCell style={{ width: '70px' }}></TableCell>
            <TableCell style={{ width: '120px' }}>{(calculatePriceItems()?.totalWithMarkup || 0).toFixed(2)}</TableCell>
          </AllTableRowContainer>
        </>
      )}
    </TableWrapper>
  );
};
