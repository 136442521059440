import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AddCategoryModal, DeleteItemsModal, DeleteItemsModalType } from 'widgets';
import { removeCheckedCategories, resetPositionsFilterList } from 'entities/positionsFilter';
import { ungroupCategory } from 'entities/category';
import { changeArticles, removePositionsById, setPositions } from 'entities/priceList';
import { groupEditEstimatePriceListPositionArticles } from 'entities/estimates';

import { TransparentButton } from 'shared/ui';
import { CloseButton, Trash, TriangleArrow, MaterialsIcon, WorkIcon } from 'shared/assets/icons';
import { useMediaQuery, useAppSelector, useAppDispatch, getArrayOfIds } from 'shared/lib';
import { COLORS, text, devices, routes } from 'shared/constants';
import { areAllCheckedPositionsFromCheckedCategories, areAllPositionWithoutCategory } from '../model/helpers';
import {
  ActionBarContainer,
  ActionBarWrapper,
  ActionButtonsWrapper,
  CloseButtonWrapper,
  PositionsWrapper,
  DropdownBody,
  SingleButtonContainer,
  DropdownElement,
} from './styles';

export const BulkActionBar: React.FC = () => {
  const isMobile = useMediaQuery(devices.mobile);
  const language = useAppSelector((state) => state.language.language);
  const company = useAppSelector((state) => state.company.company);
  const userCurrencyList = useAppSelector((state) => state.currencies.userCurrencyList);
  const categories = useAppSelector((state) => state.categories.categories);
  const priceList = useAppSelector((state) => state.priceList.priceList);
  const checkedPositionsIds = useAppSelector((state) => state.positionsFilter.checkedPositionsIds);
  const checkedCategoryIds = useAppSelector((state) => state.positionsFilter.checkedCategoryIds);

  const checkedEstimatePositionsIds = useAppSelector((state) => state.estimatesFilter.checkedPositionsIds);
  const checkedEstimateCategoryIds = useAppSelector((state) => state.estimatesFilter.checkedCategoryIds);
  const location = useLocation();

  const locationsArray = location.pathname.split('/');
  const isPriceList = locationsArray[locationsArray.length - 1] === routes.priceList;

  const [openDeleteItemsModal, setOpenDeleteItemsModal] = useState(false);
  const dispatch = useAppDispatch();

  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [isPossibleToUnGroup, setIsPossibleToUnGroup] = useState(false);
  const [isPossibleToGroupPositions, setIsPossibleToGroupPositions] = useState(false);
  const [isBarShowed, setIsBarShowed] = useState(false);
  const [openAddCategoryModal, setOpenCategoryModal] = useState(false);

  useEffect(() => {
    if (checkedEstimatePositionsIds.length > 0 || checkedEstimateCategoryIds.length > 0) {
      setIsBarShowed(true);
    } else {
      setIsBarShowed(false);
    }
  }, [checkedEstimateCategoryIds, checkedEstimatePositionsIds]);

  useEffect(() => {
    if ((checkedCategoryIds.length > 0 || checkedPositionsIds.length > 0) && isPriceList) {
      setIsBarShowed(true);
    } else {
      setIsBarShowed(false);
    }

    if (checkedCategoryIds.length === 0 && checkedPositionsIds.length > 0) {
      const categoriesWithoutCategoryIds = categories
        ?.filter((category) => category.primary === true)
        .map((cat) => cat.id);
      const isIncludes = categoriesWithoutCategoryIds?.some((id) => checkedPositionsIds.includes(id));
      setIsPossibleToGroupPositions(!isIncludes);
    }

    if (areAllCheckedPositionsFromCheckedCategories(checkedCategoryIds, checkedPositionsIds, categories, priceList)) {
      setIsPossibleToUnGroup(true);
    } else {
      setIsPossibleToUnGroup(false);
    }

    if (!areAllPositionWithoutCategory(categories, priceList, checkedPositionsIds)) {
      setIsPossibleToGroupPositions(true);
    } else {
      setIsPossibleToGroupPositions(false);
    }
    return () => setIsDropDownOpen(false);
  }, [checkedCategoryIds, checkedPositionsIds, categories, priceList, isPriceList]);

  function OnCostItemClick() {
    setIsDropDownOpen(!isDropDownOpen);
  }

  function OnCombineIntoCategoryClick() {
    setOpenCategoryModal(true);
  }

  //Function for creating estimate from Price list page, action removed
  // function OnCreateEstimateClick() {
  //   const checkedPositions = priceList?.filter((position) => checkedPositionsIds.includes(position.id));
  //   const checkedCategories = categories?.filter((position) => checkedCategoryIds.includes(position.id));
  //   if (!checkedPositions) return;
  //   const preparedPositions = parseData(checkedPositions);

  //   dispatch(setEstimatesPriceList([...preparedPositions]));
  //   if (checkedCategories) {
  //     dispatch(setEstimatesPriceListCategories([...checkedCategories]));
  //   }
  //   dispatch(resetPositionsFilterList());
  //   navigate(`/${routes.priceList}/${routes.estimateCreation}`);
  // }

  function OnDeleteClick() {
    setOpenDeleteItemsModal(true);
  }

  function OnCloseButtonClick() {
    dispatch(resetPositionsFilterList());
  }

  function OnUnGroupClick() {
    if (!company || !checkedCategoryIds.length) return;

    dispatch(ungroupCategory({ companyID: company[0].id, categoryIDs: checkedCategoryIds }));

    // local removing categories and adding positions from removed categories
    const positionsFromRemovedCategories = priceList?.filter((position) =>
      checkedCategoryIds.includes(position.category.id),
    );
    const positionsIds = getArrayOfIds(positionsFromRemovedCategories);

    const categoryIdWithoutCategory = categories?.find((cat) => cat.primary === true)?.id;
    if (!categoryIdWithoutCategory) return;
    const positionsWithChangedCategory = positionsFromRemovedCategories?.map((position) => {
      return {
        ...position,
        category: {
          ...position.category,
          id: categoryIdWithoutCategory,
        },
      };
    });

    dispatch(removeCheckedCategories(checkedCategoryIds));
    dispatch(removePositionsById(positionsIds));

    if (positionsWithChangedCategory) {
      dispatch(setPositions(positionsWithChangedCategory));
    }
  }

  const onDropDownElementClick = (article: number) => {
    if (!company) return;
    if (isPriceList) {
      dispatch(changeArticles({ items: checkedPositionsIds, article, company: company[0].id }));
    } else {
      dispatch(changeArticles({ items: checkedEstimatePositionsIds, article, company: company[0].id }));
      dispatch(groupEditEstimatePriceListPositionArticles({ ids: checkedEstimatePositionsIds, article }));
    }
  };

  // TODO: refactoring
  const deleteItemsModalType: DeleteItemsModalType = {
    type: checkedCategoryIds.length > 0 ? 'multipleDeleting' : 'position',
    count: checkedPositionsIds.length === 1 || checkedCategoryIds.length === 1 ? 'single' : 'multiple',
    countEstimate:
      checkedEstimatePositionsIds.length === 1 || checkedEstimateCategoryIds.length === 1 ? 'single' : 'multiple',
    currency: userCurrencyList.length > 1 ? 'multiple' : 'single',
  };

  return (
    <>
      {isBarShowed && (
        <ActionBarContainer className={isMobile ? 'mobile' : ''}>
          <ActionBarWrapper className={isMobile ? 'mobile' : ''}>
            <ActionButtonsWrapper className={isMobile ? 'mobile' : ''}>
              <TransparentButton onClick={OnCostItemClick}>
                <SingleButtonContainer className={isDropDownOpen ? 'open' : ''}>
                  <TriangleArrow />
                  <span>{text[language].CostItem}</span>
                  {isDropDownOpen && (
                    <DropdownBody>
                      <DropdownElement onClick={() => onDropDownElementClick(1)}>
                        <MaterialsIcon />
                        <span>{text[language].Materials}</span>
                      </DropdownElement>
                      <DropdownElement onClick={() => onDropDownElementClick(2)}>
                        <WorkIcon language={language} />
                        <span>{text[language].Work}</span>
                      </DropdownElement>
                    </DropdownBody>
                  )}
                </SingleButtonContainer>
              </TransparentButton>
              {(() => {
                if (isPriceList) {
                  return (
                    <>
                      {isPossibleToUnGroup || !!checkedCategoryIds.length ? (
                        <TransparentButton disabled={!isPossibleToUnGroup} onClick={OnUnGroupClick}>
                          <span>{text[language].UnGroup}</span>
                        </TransparentButton>
                      ) : (
                        <TransparentButton disabled={!isPossibleToGroupPositions} onClick={OnCombineIntoCategoryClick}>
                          <span>{text[language].GroupToCategory}</span>
                        </TransparentButton>
                      )}
                    </>
                  );
                }
              })()}
              <TransparentButton onClick={OnDeleteClick}>
                <SingleButtonContainer>
                  <Trash stroke={COLORS.TEXT_WHITE} />
                  <span>{isPriceList ? text[language].Delete : text[language].deleteFromEstimate}</span>
                </SingleButtonContainer>
              </TransparentButton>
            </ActionButtonsWrapper>
            <CloseButtonWrapper>
              {!isMobile && (
                <PositionsWrapper>
                  <span>{text[language].SelectedPositions}</span>
                  <div>{`${isPriceList ? checkedPositionsIds.length : checkedEstimatePositionsIds.length} ${
                    text[language].Positions
                  }`}</div>
                </PositionsWrapper>
              )}
              <TransparentButton onClick={OnCloseButtonClick}>
                <span>
                  <CloseButton />
                </span>
              </TransparentButton>
            </CloseButtonWrapper>
          </ActionBarWrapper>
        </ActionBarContainer>
      )}
      <AddCategoryModal
        isGrouping={true}
        handleClose={() => setOpenCategoryModal(false)}
        isOpen={openAddCategoryModal}
      />
      <DeleteItemsModal
        handleClose={() => setOpenDeleteItemsModal(false)}
        isOpen={openDeleteItemsModal}
        type={deleteItemsModalType}
        categoryID={isPriceList ? checkedCategoryIds : checkedEstimateCategoryIds}
        positionID={isPriceList ? checkedPositionsIds : checkedEstimatePositionsIds}
      />
    </>
  );
};
