import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { COLORS } from 'shared/constants';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const ModalBackground = styled('div')(({ className }) => ({
  zIndex: 2,
  position: 'fixed',
  top: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100vw',
  height: '100vh',
  backgroundColor: className?.includes('dark') ? 'rgba(0, 0, 0, 0.75)' : COLORS.MODAL_OVERLAY,
  animation: `${fadeIn} 0.3s ease`,
  overflowX: 'auto',
}));

export const StyledModalWrapper = styled('div')(({ className }) => ({
  fontFamily: '"Golos", sans-serif',
  position: 'relative',
  width: '90%',
  maxWidth: '768px',
  padding: className?.includes('smallTitleSize') ? '16px 0' : '24px 0',
  borderRadius: '16px',
  backgroundColor: className?.includes('dark') ? COLORS.MODAL_BG_DARK : COLORS.MODAL_BG,
  '& > div': {
    padding: className?.includes('smallTitleSize') ? '0 16px' : '0 24px',
  },
  '@media (min-width: 767px)': {
    padding: '32px 0',
    width: 'max-content',
    '& > div': {
      padding: '0 32px',
    },
  },
}));

export const ModalSubTitle = styled('p')(({ className }) => ({
  margin: '32px 0 16px',
  padding: '0 14px',
  '& p': {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'left',
    color: className?.includes('dark') ? COLORS.BODY_TEXT_BLACK_20 : COLORS.ACTION_BAR_BLACK_BG,
  },
  '@media (min-width: 480px)': {
    padding: '0 32px',
  },
  '@media (min-width: 768px)': {
    '& p': {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
}));

export const ModalTitle = styled('p')(({ className }) => ({
  color: className?.includes('dark') ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK,
  padding: '0 14px',
  '& p': {
    textAlign: className?.includes('titleToLeft') ? 'left' : 'center',
    fontWeight: 600,
    fontSize: className?.includes('smallTitleSize') ? '10px' : '18px',
    lineHeight: className?.includes('smallTitleSize') ? '14px' : '26px',
  },
  '@media (min-width: 480px)': {
    padding: '0 32px',
  },
  '@media (min-width: 768px)': {
    '& p': {
      fontSize: '18px',
      lineHeight: '26px',
    },
  },
}));

export const ModalCloseBtn = styled('button')(({ className }) => ({
  position: 'absolute',
  top: '12px',
  right: '12px',
  cursor: 'pointer',
  borderWidth: 0,
  backgroundColor: 'transparent',
  '@media (min-width: 480px)': {
    top: '24px',
    right: '24px',
  },
}));
