import React from 'react';
import { CircularProgress } from '../';

import { StyledButton } from './styles';
import { useAppSelector } from 'shared/lib';

interface ActionButtonT {
  icon?: React.ReactNode;
  text: string;
  onClick?: () => void;
  disabled?: boolean;
  orangeBtn?: boolean;
  whiteBtn?: boolean;
  isPending?: boolean;
  id?: string;
  isPriceListMainButton?: boolean;
}

// Implemented white and orange types of buttons
export const ActionButton: React.FC<ActionButtonT> = ({
  id,
  icon,
  text,
  onClick,
  disabled,
  orangeBtn,
  whiteBtn,
  isPending,
  isPriceListMainButton,
}) => {
  const theme = useAppSelector((state) => state.theme.theme);
  const isDarkTheme = theme === 'dark';

  const cn = () => {
    let className = '';
    if (orangeBtn) className += 'orangeBtn';
    if (isDarkTheme && !isPriceListMainButton) {
      className += 'dark';
    } else if (isDarkTheme && isPriceListMainButton) {
      className += 'orangeDark';
    }
    return className;
  };

  return (
    <StyledButton id={id} onClick={onClick} className={cn()} disabled={disabled}>
      {isPending ? (
        <CircularProgress isOrange={!orangeBtn} />
      ) : (
        <>
          {icon}
          <p>{text}</p>
        </>
      )}
    </StyledButton>
  );
};
