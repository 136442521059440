import React from 'react';

type Props = {
  size?: number;
  stroke?: string;
};

export const CloseButton: React.FC<Props> = ({ size = 24, stroke = 'white' }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 6L18 18M18 6L6 18" stroke={stroke} strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
