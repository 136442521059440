import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { COLORS } from 'shared/constants';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const TableWrapper = styled('div')(({ className }) => ({
  borderRadius: '8px 8px 0px 0px',
  border: `1px solid transparent`,
  width: '100%',
  color: className?.includes('dark') ? COLORS.DISABLED_BTN_TEXT : COLORS.TEXT_GREY,
  fontFamily: "'Golos', sans-serif",
  fontStyle: 'normal',
  fontWeight: 400,
  backgroundColor: className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.TEXT_WHITE,
}));

export const TableRowContainer = styled('div')(({ className }) => ({
  fontFamily: "'Golos', sans-serif",
  position: 'relative',
  color: className?.includes('dark') ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK,
  background: className?.includes('darkdragging')
    ? 'rgba(26, 26, 26, 0.90)'
    : className?.includes('dragging')
    ? COLORS.TABLE_DRAGGING
    : className?.includes('dark')
    ? COLORS.BACKGROUND_BLACK
    : COLORS.TEXT_WHITE,
  borderBottom: `1px solid ${className?.includes('dark') ? COLORS.LIGHT_GREY_BORDER : COLORS.BORDER_GREY}`,
  display: 'flex',
  gap: className?.includes('modalPriceList') ? '2px' : '16px',
  justifyContent: 'space-between',
  alignItems: 'center',
  minHeight: '48px',
  verticalAlign: 'middle',
  textAlign: 'left',
  padding: '10px 8px 10px 12px',
  '& > div:not(:nth-of-type(3))': {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  '&:hover': {
    background: className?.includes('dark')
      ? COLORS.BACKGROUND_LIGHT_DARK
      : className?.includes('dragging')
      ? COLORS.TABLE_DRAGGING
      : COLORS.MODAL_BG,
  },
  '& .hoveredContainer': {
    opacity: 0,
  },
  '&:hover .hoveredContainer': {
    opacity: 1,
  },
  '@media (max-width: 767px)': {
    '& .hoveredContainer': {
      opacity: 1,
    },
  },
}));

export const DropdownBody = styled('ul')(({ className }) => ({
  listStyle: 'none',
  whiteSpace: 'nowrap',
  outline: 'none',
  background: className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.TEXT_WHITE,
  border: `1px solid ${className?.includes('dark') ? COLORS.LIGHT_GREY_BORDER : COLORS.BORDER_GREY}`,
  borderRadius: '8px',
  padding: '16px',
  animation: `${fadeIn} 0.2s ease`,
  boxShadow: className?.includes('dark') ? 'unset' : COLORS.TABLE_DROPDOWN_BOX_SHADOW,
}));

export const DropdownElement = styled('li')(({ className }) => ({
  color: 'white',
  fontSize: '10px',
  lineHeight: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  padding: '8px 0',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  cursor: 'pointer',
  background: className?.includes('active')
    ? COLORS.ORANGE_TEXT_HOVERED
    : className?.includes('dark')
    ? COLORS.TEXT_WHITE
    : COLORS.ACTION_BAR_BLACK_BG,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  '&:hover': {
    background: COLORS.ORANGE_TEXT_HOVERED,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    '& svg path': {
      stroke: className?.includes('without-flashing-svg') ? 'unset' : COLORS.ORANGE_BTN,
    },
  },
  '&:first-of-type': {
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
  },
  '&:last-of-type': {
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
  },
  '@media (min-width: 1100px)': {
    fontSize: '12px',
    lineHeight: '24px',
  },
}));

export const AddCategoryButtonWrapper = styled('div')(({ className }) => ({
  cursor: 'pointer',
  height: '24px',
  position: 'absolute',
  zIndex: 1,
  bottom: -13,
  left: 0,
}));

export const RowButtonWrapper = styled('div')(({ className }) => ({
  position: className?.includes('relative') ? 'relative' : 'unset',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '24px',
  minWidth: '24px',
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.TEXT_WHITE,
  },
  '@media (min-width: 1100px)': {
    height: '32px',
    minWidth: '32px',
  },
}));

export const ButtonTableCellContainer = styled('div')(({ className }) => ({
  width: '24px',
  flexShrink: 0,
  '&:first-of-type svg': {
    marginTop: className?.includes('modalPriceList') ? '4px' : 'unset',
  },
  '@media (min-width: 1024px)': {
    width: '40px',
  },
}));

export const MainTableCellContainer = styled('div')(({ className }) => ({
  paddingRight: '8px',
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '8px',
  width: className?.includes('modalPriceList') ? '100%' : '264px',
  maxWidth: className?.includes('modalPriceList') ? '185px' : '264px',
  '& span:first-of-type': {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '& svg': {
    marginRight: '24px',
  },
  '@media (min-width: 1240px)': {
    width: className?.includes('modalPriceList') ? '230px' : '320px',
    maxWidth: className?.includes('modalPriceList') ? '230px' : 'unset',
  },
  '@media (min-width: 1600px)': {
    width: '536px',
  },
}));

export const SecondaryTableCellContainer = styled('div')(({ className }) => ({
  width: className?.includes('modalPriceList') ? '60px' : '130px',
  paddingRight: '8px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  '@media (min-width: 767px)': {
    width: '130px',
  },
}));

export const PriceTableCellContainer = styled('div')(({ className }) => ({
  width: className?.includes('modalPriceList') ? '40px' : '100px',
  '@media (min-width: 767px)': {
    width: '100px',
  },
}));

export const DropPlaceholder = styled('div')(({ className }) => ({
  width: '100%',
  zIndex: 0,
  position: 'absolute',
  background: className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.TABLE_BG,
  verticalAlign: 'middle',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  paddingLeft: '50px',
}));

export const TableScrollWrapper = styled('div')(({ className }) => ({
  overflowY: 'hidden',
  overflowX: className?.includes('modalPriceList') ? 'hidden' : 'auto',
  marginTop: className?.includes('modalPriceList') ? '0px' : '24px',
  // marginBottom: className?.includes('modalPriceList') ? '0px' : '50px',
  minHeight: '240px', // check table height and drop down
  background: className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.TEXT_WHITE,
  lineHeight: className?.includes('modalPriceList') ? '10px' : '20px',
  fontSize: className?.includes('modalPriceList') ? '6px' : '14px',
  '& > div': {
    width: className?.includes('modalPriceList') ? 'unset' : '742px',
  },
  '@media (min-width: 767px)': {
    lineHeight: '20px',
    fontSize: '14px',
    '& > div': {
      width: '100%',
    },
  },
  '@media (min-width: 1280px)': {
    overflow: 'visible',
    minHeight: 'unset',
    background: 'unset',
  },
}));
