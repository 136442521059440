import { CurrenciesType } from 'entities/priceList';

export function checkIsEmptyCurrencyRateFields(
  selectedCurrencies: (string | null)[],
  currencyRate: {
    [x: string]: string;
  },
) {
  return selectedCurrencies.every(
    (currency) => !!currencyRate[currency as CurrenciesType] && Number(currencyRate[currency as CurrenciesType]) > 0,
  );
}
