import { createSlice } from '@reduxjs/toolkit';
import { LoadingResultsT } from '../../loadingType';
import {
  createCustomer,
  createNewProject,
  fetchCounterAgents,
  fetchProjects,
  fetchMarkups,
  editMarkups,
  fetchTeam,
  fetchProjectStats,
  editProject,
  editMarkupsHiddenCopy,
} from '../api/thunks';
import {
  CounterAgentType,
  EstimateMarkupsType,
  MarkupType,
  ProjectStatsResponse,
  ProjectType,
  TeamMemberType,
} from './types';

interface IState {
  projects: ProjectType[] | null;
  projectMarkups: MarkupType[] | null;
  tempProjectMarkup: EstimateMarkupsType | null;
  projectStats: ProjectStatsResponse | null;
  counterAgents: CounterAgentType[] | null;
  newCustomer: CounterAgentType | null;
  team: TeamMemberType[] | null;
  loadingTeam: LoadingResultsT;
  loadingProjects: LoadingResultsT;
  loadingProjectStats: LoadingResultsT;
  loadingEditProject: LoadingResultsT;
  loadingProjectsMarkups: LoadingResultsT;
  loadingCreatingProject: LoadingResultsT;
  loadingCreatingCustomer: LoadingResultsT;
  loadingCounterAgents: LoadingResultsT;
  creatingCustomerError: string | null;
  creatingProjectError: string | null;
  error: string | null;
  projectsLength: number | null;
}

const initialState: IState = {
  projects: null,
  projectMarkups: null,
  tempProjectMarkup: null,
  projectStats: null,
  counterAgents: null,
  newCustomer: null,
  team: null,
  loadingTeam: LoadingResultsT.IDLE,
  loadingProjects: LoadingResultsT.IDLE,
  loadingProjectStats: LoadingResultsT.IDLE,
  loadingEditProject: LoadingResultsT.IDLE,
  loadingProjectsMarkups: LoadingResultsT.IDLE,
  loadingCreatingProject: LoadingResultsT.IDLE,
  loadingCreatingCustomer: LoadingResultsT.IDLE,
  loadingCounterAgents: LoadingResultsT.IDLE,
  creatingCustomerError: null,
  creatingProjectError: null,
  error: null,
  projectsLength: null,
};

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    resetProjectsLoading: (state) => {
      state.loadingProjects = LoadingResultsT.IDLE;
      state.loadingCreatingProject = LoadingResultsT.IDLE;
      state.loadingCreatingCustomer = LoadingResultsT.IDLE;
      state.loadingEditProject = LoadingResultsT.IDLE;
      state.loadingProjectsMarkups = LoadingResultsT.IDLE;
      state.loadingTeam = LoadingResultsT.IDLE;
      state.loadingCounterAgents = LoadingResultsT.IDLE;
    },
    resetCustomerError: (state) => {
      state.creatingCustomerError = null;
    },
    resetTeam: (state) => {
      state.team = null;
    },
    resetCreatingProjectError: (state) => {
      state.creatingProjectError = null;
    },
    resetNewCustomer: (state) => {
      state.newCustomer = null;
    },
    setPinnedProject: (state, { payload }) => {
      if (!state.projects) return;
      const projectIndex = state.projects.findIndex((project) => project.id === payload.id);
      const project = payload;
      if (projectIndex !== -1) {
        const unpinnedProjects = state.projects
          .filter((project) => project.id !== payload.id)
          .map((proj) => {
            return { ...proj, isPinned: false };
          });
        state.projects = [project, ...unpinnedProjects];
      }
    },
    setTempProjectMarkups: (state, { payload }) => {
      state.tempProjectMarkup = payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchProjects.pending, (state) => {
        state.loadingProjects = LoadingResultsT.PENDING;
      })
      .addCase(fetchProjects.fulfilled, (state, { payload }) => {
        state.loadingProjects = LoadingResultsT.SUCCEEDED;
        state.projectsLength = payload.length as number;
        const filteredProjects = payload.filter((proj) => proj.isArchive !== true);

        state.projects = filteredProjects;
        state.error = null;
      })
      .addCase(fetchProjects.rejected, (state, { error }) => {
        state.loadingProjects = LoadingResultsT.FAILED;
        state.error = error.message as string;
      })

      .addCase(editProject.pending, (state) => {
        state.loadingEditProject = LoadingResultsT.PENDING;
      })
      .addCase(editProject.fulfilled, (state, { payload }) => {
        state.loadingEditProject = LoadingResultsT.SUCCEEDED;

        const projectIndex = state.projects?.findIndex((project) => project.id === payload.id); // Find the index of the project to update by its ID

        if (!state.projects || typeof projectIndex === 'undefined' || projectIndex === -1) return;
        const updatedProjects = [...state.projects]; // Create a copy of the projects state to update
        updatedProjects.splice(projectIndex, 1, payload);

        state.projects = updatedProjects;
        state.error = null;
      })
      .addCase(editProject.rejected, (state, { error }) => {
        state.loadingEditProject = LoadingResultsT.FAILED;
        state.error = error.message as string;
      })

      .addCase(fetchProjectStats.pending, (state) => {
        state.loadingProjectStats = LoadingResultsT.PENDING;
      })
      .addCase(fetchProjectStats.fulfilled, (state, { payload }) => {
        state.loadingProjectStats = LoadingResultsT.SUCCEEDED;

        state.projectStats = payload;
        state.error = null;
      })
      .addCase(fetchProjectStats.rejected, (state, { error }) => {
        state.loadingProjectStats = LoadingResultsT.FAILED;
        state.error = error.message as string;
      })

      .addCase(createNewProject.pending, (state) => {
        state.loadingCreatingProject = LoadingResultsT.PENDING;
      })
      .addCase(createNewProject.fulfilled, (state, { payload }) => {
        state.loadingCreatingProject = LoadingResultsT.SUCCEEDED;

        state.projects = [payload, ...(state.projects || [])];
        state.creatingProjectError = null;
      })
      .addCase(createNewProject.rejected, (state, { error }) => {
        state.loadingCreatingProject = LoadingResultsT.FAILED;
        state.creatingProjectError = error.message as string;
      })

      .addCase(createCustomer.pending, (state) => {
        state.loadingCreatingCustomer = LoadingResultsT.PENDING;
      })
      .addCase(createCustomer.fulfilled, (state, { payload }) => {
        state.loadingCreatingCustomer = LoadingResultsT.SUCCEEDED;

        if (!state.counterAgents || !payload) return;
        state.newCustomer = payload;
        state.counterAgents = [...state.counterAgents, payload];
        state.creatingCustomerError = null;
      })
      .addCase(createCustomer.rejected, (state, { error }) => {
        state.loadingCreatingCustomer = LoadingResultsT.FAILED;
        state.creatingCustomerError = error.message as string;
      })

      .addCase(fetchCounterAgents.pending, (state) => {
        state.loadingCounterAgents = LoadingResultsT.PENDING;
      })
      .addCase(fetchCounterAgents.fulfilled, (state, { payload }) => {
        state.loadingCounterAgents = LoadingResultsT.SUCCEEDED;

        state.counterAgents = payload;
        state.error = null;
      })
      .addCase(fetchCounterAgents.rejected, (state, { error }) => {
        state.loadingCounterAgents = LoadingResultsT.FAILED;
        state.error = error.message as string;
      })

      .addCase(fetchMarkups.pending, (state) => {
        state.loadingProjectsMarkups = LoadingResultsT.PENDING;
      })
      .addCase(fetchMarkups.fulfilled, (state, { payload }) => {
        state.loadingProjectsMarkups = LoadingResultsT.SUCCEEDED;

        state.projectMarkups = payload;
        state.error = null;
      })
      .addCase(fetchMarkups.rejected, (state, { error }) => {
        state.loadingProjectsMarkups = LoadingResultsT.FAILED;
        state.error = error.message as string;
      })

      .addCase(editMarkups.pending, (state) => {
        state.loadingProjectsMarkups = LoadingResultsT.PENDING;
      })
      .addCase(editMarkups.fulfilled, (state, { payload }) => {
        state.loadingProjectsMarkups = LoadingResultsT.SUCCEEDED;

        if (!state.projectMarkups) return;
        const filteredMarkups = state.projectMarkups.filter((markup) => !markup.isHidden);
        const existingMarkupIndex = filteredMarkups.findIndex((markup) => markup.id === payload.id);

        if (existingMarkupIndex !== -1) {
          filteredMarkups[existingMarkupIndex] = payload;
        } else {
          filteredMarkups.push(payload);
        }

        state.projectMarkups = filteredMarkups;
        state.error = null;
      })
      .addCase(editMarkups.rejected, (state, { error }) => {
        state.loadingProjectsMarkups = LoadingResultsT.FAILED;
        state.error = error.message as string;
      })

      .addCase(editMarkupsHiddenCopy.pending, (state) => {
        state.loadingProjectsMarkups = LoadingResultsT.PENDING;
      })
      .addCase(editMarkupsHiddenCopy.fulfilled, (state, { payload }) => {
        state.loadingProjectsMarkups = LoadingResultsT.SUCCEEDED;
        if (!state.projectMarkups) return;

        const copyMarkups = [...state.projectMarkups];
        state.projectMarkups = [...copyMarkups, ...payload];
        state.error = null;
      })
      .addCase(editMarkupsHiddenCopy.rejected, (state, { error }) => {
        state.loadingProjectsMarkups = LoadingResultsT.FAILED;
        state.error = error.message as string;
      })

      .addCase(fetchTeam.pending, (state) => {
        state.loadingTeam = LoadingResultsT.PENDING;
      })
      .addCase(fetchTeam.fulfilled, (state, { payload }) => {
        state.loadingTeam = LoadingResultsT.SUCCEEDED;

        state.team = payload;
        state.error = null;
      })
      .addCase(fetchTeam.rejected, (state, { error }) => {
        state.loadingTeam = LoadingResultsT.FAILED;
        state.error = error.message as string;
      }),
});

export const {
  resetProjectsLoading,
  resetCustomerError,
  setPinnedProject,
  setTempProjectMarkups,
  resetNewCustomer,
  resetCreatingProjectError,
  resetTeam,
} = projectsSlice.actions;
export const projectsReducer = projectsSlice.reducer;
