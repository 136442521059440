import React from 'react';
import { devices } from 'shared/constants';
import { useMediaQuery } from 'shared/lib';

export const GradientOutline: React.FC = () => {
  const isDesktop = useMediaQuery(devices.desktop);

  return (
    <>
      {isDesktop ? (
        <svg xmlns="http://www.w3.org/2000/svg" width="302" height="44" viewBox="0 0 302 44" fill="none">
          <path
            d="M110.909 12.1811C90.6978 10.0456 20.7739 1.04481 2.69267 18.7795C-28.2843 49.1627 292.835 49.4007 300.378 26.8226C310.588 -3.74204 139.789 -1.30444 70.6623 4.65401"
            stroke="url(#paint0_linear_3860_96665)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_3860_96665"
              x1="1.50069"
              y1="48.9738"
              x2="10.6309"
              y2="-35.6651"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FF8C21" />
              <stop offset="0.880208" stopColor="#FFD200" />
            </linearGradient>
          </defs>
        </svg>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="214" height="44" viewBox="0 0 214 44" fill="none">
          <path
            d="M78.4214 13.6788C64.126 11.7993 14.6609 3.94684 1.96144 18.7093C-19.7955 44.0006 207.181 45.6618 212.409 26.7972C219.487 1.25956 98.773 2.52229 49.9398 7.19507"
            stroke="url(#paint0_linear_3875_121603)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_3875_121603"
              x1="1.25639"
              y1="43.9781"
              x2="10.6906"
              y2="-26.376"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FF8C21" />
              <stop offset="0.880208" stopColor="#FFD200" />
            </linearGradient>
          </defs>
        </svg>
      )}
    </>
  );
};
