import React from 'react';
import { COLORS } from 'shared/constants';
import { useAppSelector } from 'shared/lib';

export const AddProjectRound: React.FC = () => {
  const theme = useAppSelector((state) => state.theme.theme);

  const isDarkTheme = theme === 'dark';
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
      <circle cx="22" cy="22" r="22" fill="url(#paint0_linear_2419_18955)" />
      <path d="M22.0001 15V28.9998M15 21.9998H29" stroke="white" strokeWidth="2" strokeLinecap="round" />
      <defs>
        <linearGradient
          id="paint0_linear_2419_18955"
          x1="1.37403e-06"
          y1="44"
          x2="44"
          y2="1.37403e-06"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={isDarkTheme ? COLORS.MODAL_BG_DARK : COLORS.BODY_TEXT_BLACK_20} />
          <stop offset="0.880208" stopColor={isDarkTheme ? COLORS.MODAL_BG_DARK : COLORS.BODY_TEXT_BLACK_20} />
        </linearGradient>
      </defs>
    </svg>
  );
};
