import { createSlice } from '@reduxjs/toolkit';

interface IState {
  progress: number;
}

const initialState: IState = {
  progress: 0,
};

const loginProgressSlice = createSlice({
  name: 'loginProgress',
  initialState,
  reducers: {
    setLoginProgress(state, { payload }) {
      state.progress = payload;
    },
  },
});

export const { setLoginProgress } = loginProgressSlice.actions;
export const loginProgressReducer = loginProgressSlice.reducer;
