import React from 'react';
import { COLORS } from 'shared/constants';

type Props = {
  size?: number;
  stroke?: string;
};

export const InfoIcon: React.FC<Props> = ({ size = 16, stroke = COLORS.ORANGE_BTN }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_2387_33552)">
        <path
          d="M8 11V7.3M8 5C8 4.99085 8 4.97656 8 4.97656M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2387_33552">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
