import React, { useState } from 'react';

import { ReactComponent as PasswordShow } from 'shared/assets/icons/svg/LoginScreen/show_password_icon.svg';
import { ReactComponent as PasswordHide } from 'shared/assets/icons/svg/LoginScreen/hide_password_icon.svg';
import { useAppSelector } from 'shared/lib';
import { text } from 'shared/constants';

import { AuthInput, AuthInputContainer, PasswordToggle, StyledLabel } from './styles';

type InputType = 'email' | 'password';

interface LoginInputProps {
  value: string;
  error: string | null;
  type: InputType;
  placeholder: string;
  onInputChange: (e: React.FormEvent<HTMLInputElement>) => void;
  isCreatingPasswordInput?: boolean;
}

export const LoginInput: React.FC<LoginInputProps> = ({
  value,
  error,
  type,
  placeholder,
  onInputChange,
  isCreatingPasswordInput,
}) => {
  const language = useAppSelector((state) => state.language.language);
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState(false);

  // Determine the input type based on the 'type' prop and password visibility
  const inputType = type === 'email' ? 'email' : type === 'password' && !passwordVisible ? 'password' : 'text';

  const showLabel = placeholder && (isFocused || !!value.length);

  const cn = () => {
    let res = '';

    if (showLabel) {
      res += ' showLabel';
    }
    if (isCreatingPasswordInput) {
      res += ' creatingPassword';
    }

    return res;
  };

  const inputPlaceholder = () => {
    if (!showLabel && !isCreatingPasswordInput) {
      return placeholder;
    }
    if (isCreatingPasswordInput) {
      return text[language].AtLeast8;
    }
  };

  return (
    <AuthInputContainer className={error ? 'error' : ''}>
      <AuthInput
        className={cn()}
        type={inputType}
        placeholder={inputPlaceholder()}
        value={value}
        onChange={onInputChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />

      {type === 'password' && (
        <PasswordToggle onClick={() => setPasswordVisible(!passwordVisible)}>
          {passwordVisible ? <PasswordHide /> : <PasswordShow />}
        </PasswordToggle>
      )}
      <StyledLabel className={cn()}>{placeholder}</StyledLabel>
    </AuthInputContainer>
  );
};
