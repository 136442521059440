import React, { useEffect, useState } from 'react';
import ModalLayout from '../ModalLayout/ModalLayout';
import {
  CurrenciesType,
  stringFundsType,
  resetPositionLoading,
  resetPriceListLoading,
  createPosition as createPositionRequest,
} from 'entities/priceList';
import { LoadingResultsT } from 'entities/loadingType';
import { text } from 'shared/constants';
import { useAppSelector, useAppDispatch } from 'shared/lib';
import { ModalWrapper } from 'shared/ui';
import { ItemValues, defaultValues } from '../../model/defaultValues';
import { convertFundsToObjectWithNumbers } from '../../model/convertFundsToObjectWithNumbers';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  order?: number;
  category?: number;
  nextTo?: number;
  projectCurrency?: string;
}

export const CreatePositionsModal: React.FC<Props> = ({
  isOpen,
  handleClose,
  order,
  category,
  nextTo,
  projectCurrency,
}) => {
  const language = useAppSelector((state) => state.language.language);
  const company = useAppSelector((state) => state.company.company);
  const userCurrencyList = useAppSelector((state) => state.currencies.userCurrencyList);
  const priceList = useAppSelector((state) => state.priceList.priceList);
  const loadingCreatingPosition = useAppSelector((state) => state.priceList.loadingCreatingPosition);
  const categories = useAppSelector((state) => state.categories.categories);
  const dispatch = useAppDispatch();

  const [itemValues, setItemValues] = useState<ItemValues>({} as ItemValues);

  const addBaseValuesItemFunds = (userCurrencyList: CurrenciesType[]) => {
    return userCurrencyList.reduce((acc, currency) => {
      acc[currency] = { total: '', cost: '' };
      return acc;
    }, {} as Record<CurrenciesType, stringFundsType>);
  };

  const onModalClose = () => {
    const itemFunds = addBaseValuesItemFunds(userCurrencyList);
    setItemValues({ ...defaultValues, itemFunds });

    handleClose();
  };

  useEffect(() => {
    if (loadingCreatingPosition === LoadingResultsT.SUCCEEDED) {
      dispatch(resetPriceListLoading());
      dispatch(resetPositionLoading());
      onModalClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingCreatingPosition, dispatch]);

  useEffect(() => {
    const itemFunds = addBaseValuesItemFunds(userCurrencyList);
    setItemValues({ ...defaultValues, itemFunds });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCurrencyList, priceList]);

  function createPosition() {
    let itemCategoryID = category;
    // If the item does not have a category, find the first category 'without category' and use its ID
    if (!itemCategoryID && categories) {
      const categoryWithoutCategory = categories.find((cat) => cat.primary === true);
      itemCategoryID = categoryWithoutCategory?.id;
    }
    if (!company) return;
    const funds = convertFundsToObjectWithNumbers(itemValues.itemFunds);

    dispatch(
      createPositionRequest({
        data: [
          {
            article: Number(itemValues.itemArticle),
            order: order,
            category: Number(itemCategoryID),
            isTemporary: false,
            name: itemValues.itemName,
            units: itemValues.itemUnit,
            funds: funds,
          },
        ],
        company: company[0].id,
        nextTo: nextTo,
        projectCurrency,
      }),
    );
  }

  return (
    <ModalWrapper isOpen={isOpen} title={text[language].NewItem} subtitle={text[language].ItemInfo}>
      <ModalLayout
        onModalClose={onModalClose}
        setItemValues={setItemValues}
        itemValues={itemValues}
        onSaveClick={createPosition}
      />
    </ModalWrapper>
  );
};
