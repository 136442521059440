import styled from '@emotion/styled';
import { COLORS } from 'shared/constants';

export const PriseListHeaderContainer = styled('div')(({ className }) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: className?.includes('portraitPLHeader') ? '24px' : 'unset',
  flexDirection: className?.includes('portraitPLHeader') ? 'column' : 'row',
  justifyContent: 'center',
  gap: '16px',
  width: className?.includes('portraitPLHeader') ? '90%!important' : '100%',
  marginBottom: className?.includes('portraitPLHeader') ? '32px' : '40px',
}));

export const ClearSearchButton = styled('button')(({ className }) => ({
  marginRight: 0,
  borderWidth: 0,
  backgroundColor: 'transparent',
}));

export const SearchIconContainer = styled('div')(({ className }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '48px',
  marginRight: '10px',
}));

export const SearchContainer = styled('div')(({ className }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  height: '48px',
  padding: '0 10px',
  border: `1px solid ${className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.BORDER_GREY}`,
  borderRadius: '8px',
  background: className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.TEXT_WHITE,
  '&:hover': {
    borderColor: className?.includes('dark') ? COLORS.DIVIDER_DARK : COLORS.BODY_TEXT_BLACK_20,
  },
  '&:focus-within': {
    borderColor: className?.includes('dark') ? COLORS.DIVIDER_DARK : COLORS.TEXT_GREY,
  },
  '& input': {
    borderColor: 'transparent!important',
    padding: '0px',
    minHeight: '30px',
  },
}));

export const Title = styled('p')(({ className }) => ({
  fontSize: '32px',
  fontWeight: 500,
  textAlign: 'left',
  width: '100%',
  color: className?.includes('dark') ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK,
}));
