import React, { useEffect, useState } from 'react';
import uk from 'date-fns/locale/uk';
import en from 'date-fns/locale/en-US';
import DatePicker from 'react-datepicker';

import { useAppSelector } from 'shared/lib';
import { CalendarIcon } from 'shared/assets/icons';
import { DatePickerWrapper, StyledLabel } from './styles';

type Props = {
  placeholder: string;
  onChange: (date: Date) => void;
  defaultDate: Date | null;
};

export const StyledDatePicker: React.FC<Props> = ({ placeholder, onChange, defaultDate }) => {
  const language = useAppSelector((state) => state.language.language);
  const theme = useAppSelector((state) => state.theme.theme);
  const [startDate, setStartDate] = useState<Date | null>(defaultDate);
  const [isCalendarOpened, setIsCalendarOpened] = useState(false);
  const datePickerRef = React.useRef<DatePicker | null>(null);

  useEffect(() => {
    if (defaultDate) {
      setStartDate(defaultDate);
    } else {
      setStartDate(null);
    }
  }, [defaultDate]);

  const locale = (language: string) => {
    if (language === 'UA') {
      return uk;
    } else {
      return en;
    }
  };

  const handleDateChange = (date: Date | null) => {
    if (!date) return;
    setStartDate(date);
    onChange(date);
  };

  const onIconClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(!isCalendarOpened);
      setIsCalendarOpened(!isCalendarOpened);
    }
  };

  const isDarkTheme = theme === 'dark';

  return (
    <DatePickerWrapper className={isDarkTheme ? 'dark' : '  '}>
      <StyledLabel className={isDarkTheme ? 'dark' : '  '}>{placeholder}</StyledLabel>
      <DatePicker
        ref={datePickerRef}
        selected={startDate}
        onChange={handleDateChange}
        placeholderText={placeholder}
        onCalendarClose={() => setIsCalendarOpened(false)}
        onCalendarOpen={() => setIsCalendarOpened(true)}
        dateFormat="dd.MM.yyyy"
        maxDate={new Date()}
        locale={locale(language)}
        showIcon
        popperPlacement="bottom-end"
        icon={<CalendarIcon onClick={onIconClick} isActive={isCalendarOpened} />}
        onKeyDown={(e) => {
          e.preventDefault();
        }}
      />
    </DatePickerWrapper>
  );
};
