import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { ThemeControls } from 'widgets/ThemeControls';
import { CompanySelect } from 'widgets/CompanySelect/ui/CompanySelect';
import { fetchCompanyInfo } from 'entities/company';
import { fetchPriceList } from 'entities/priceList';
import { fetchCounterAgents, fetchProjects } from 'entities/project';
import { TextSkeleton, LanguageSwitchButton } from 'shared/ui';
import { LogoGubadoo, Logout, CloseButton, PriceListIcon, ProjectsIcon, ProfileIcon } from 'shared/assets/icons';
import { text, devices, routes, COLORS } from 'shared/constants';
import { useMediaQuery, useAppSelector, useLocationPath, useAppDispatch } from 'shared/lib';
import { LogOutAccount } from 'shared/api';

import {
  ExitMenuButton,
  LogoContainer,
  LogoutButton,
  SideBarContainer,
  SideBarMainContentContainer,
  SideBarHeader,
  SideBarMenuElement,
  SideBarContentWrapper,
  SkeletonContainer,
  ElementsCounter,
  TabTitle,
  SideBarDownButtonsWrapper,
  BetaContainer,
} from './styles';

interface Props {
  setOpenSidebar: (v: boolean) => void;
  isOpenedSidebar: boolean;
}

export const SideBar: React.FC<Props> = ({ setOpenSidebar, isOpenedSidebar }) => {
  const isMobile = useMediaQuery(devices.mobile);
  const isTablet = useMediaQuery(devices.tablet);
  const navigate = useNavigate();
  const locationPage = useLocationPath();
  const language = useAppSelector((state) => state.language.language);
  const priceListLength = useAppSelector((state) => state.priceList.priceListLength);
  const projectsLength = useAppSelector((state) => state.projects.projectsLength);
  const selectedCompany = useAppSelector((state) => state.company.selectedCompany);
  const theme = useAppSelector((state) => state.theme.theme);
  const dispatch = useAppDispatch();

  const companyID = selectedCompany?.id;

  useEffect(() => {
    dispatch(fetchCompanyInfo());
  }, [dispatch]);

  useEffect(() => {
    if (companyID) {
      dispatch(fetchPriceList({ id: companyID }));
      dispatch(fetchProjects(companyID));
      dispatch(fetchCounterAgents(companyID));
    }
  }, [companyID, dispatch]);

  const tabClassName = (route: string) => {
    let cn = '';
    if (locationPage.includes(route)) {
      cn += 'active';
    }
    if (isDarkTheme) {
      cn += 'Dark';
    }
    return cn;
  };

  const mobOrTab = isMobile || isTablet;
  const isDarkTheme = theme === 'dark';
  // TODO: refactor sidebar
  return (
    <>
      {mobOrTab && !isOpenedSidebar ? (
        <></>
      ) : (
        <SideBarContainer className={isDarkTheme ? 'dark' : ''}>
          {mobOrTab && (
            <SideBarHeader>
              <ExitMenuButton onClick={() => setOpenSidebar(false)}>
                <CloseButton stroke={isDarkTheme ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK} />
              </ExitMenuButton>
              <LanguageSwitchButton />
            </SideBarHeader>
          )}
          <SideBarContentWrapper>
            <SideBarMainContentContainer>
              <LogoContainer>
                <LogoGubadoo
                  height={mobOrTab ? 24 : 28}
                  width={mobOrTab ? 118 : 137}
                  fill={isDarkTheme ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK}
                />
                <BetaContainer className={isDarkTheme ? 'dark' : ''}>
                  <span>BETA</span>
                </BetaContainer>
              </LogoContainer>
              <CompanySelect />
              <div>
                <SideBarMenuElement
                  onClick={() => navigate(`/${routes.projects}`)}
                  className={tabClassName(routes.projects)}
                >
                  <div>
                    <ProjectsIcon isDarkTheme={isDarkTheme} />
                    <TabTitle className={isDarkTheme ? 'dark' : ''}>{text[language].projectPage.project}</TabTitle>
                  </div>
                  <ElementsCounter>
                    {projectsLength !== null ? (
                      <p>{projectsLength}</p>
                    ) : (
                      <SkeletonContainer>
                        <TextSkeleton isSideBar={true} />
                      </SkeletonContainer>
                    )}
                  </ElementsCounter>
                </SideBarMenuElement>
                <SideBarMenuElement
                  onClick={() => navigate(`/${routes.priceList}`)}
                  className={tabClassName(routes.priceList)}
                >
                  <div>
                    <PriceListIcon isDarkTheme={isDarkTheme} />
                    <TabTitle className={isDarkTheme ? 'dark' : ''}>{text[language].PriceList}</TabTitle>
                  </div>
                  <ElementsCounter>
                    {priceListLength !== null ? (
                      <p>{priceListLength}</p>
                    ) : (
                      <SkeletonContainer>
                        <TextSkeleton isSideBar={true} />
                      </SkeletonContainer>
                    )}
                  </ElementsCounter>
                </SideBarMenuElement>
                {/* <SideBarMenuElement
                  onClick={() => navigate(`/${routes.profile}`)}
                  className={tabClassName(routes.profile)}
                >
                  <div>
                    <ProfileIcon isDarkTheme={isDarkTheme} />
                    <TabTitle className={isDarkTheme ? 'dark' : ''}>{text[language].profile}</TabTitle>
                  </div>
                </SideBarMenuElement> */}
              </div>
            </SideBarMainContentContainer>
            <SideBarDownButtonsWrapper>
              <ThemeControls />
              <LogoutButton
                className={isDarkTheme ? 'dark' : ''}
                onClick={() => {
                  Cookies.remove('sessionID');
                  LogOutAccount();
                  navigate(routes.auth);
                }}
              >
                <Logout isDarkTheme={isDarkTheme} />
                <p>{text[language].LogOut}</p>
              </LogoutButton>
            </SideBarDownButtonsWrapper>
          </SideBarContentWrapper>
        </SideBarContainer>
      )}
    </>
  );
};
