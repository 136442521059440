import React, { useEffect, useState } from 'react';

import {
  getCurrencyRate,
  createCurrency,
  resetCurrenciesLoading,
  setActiveCurrency,
  createCompanyCurrency,
} from 'entities/currencies';
import { createPosition, resetPriceListLoading, CurrenciesType, CreatePositionRequest } from 'entities/priceList';
import { LoadingResultsT } from 'entities/loadingType';

import { text } from 'shared/constants';
import { ActionButton, Input, ModalWrapper } from 'shared/ui';
import { useAppSelector, useAppDispatch, handleNumberValue } from 'shared/lib';

import { calculateCrossRate, checkIsEmptyCurrencyRateFields, addCurrencyRatesToData } from '../model';
import { CurrencyName, CurrencyRateModalButtonsWrapper, CurrencyRateModalContainer, InputsContainer } from './styles';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  handleCloseImportModal?: () => void;
  selectedCurrencies: (string | null)[];
  type: 'creatingNewCurrency' | 'addingRateToRequest';
  requestData?: CreatePositionRequest[] | null;
  isImportPositions?: boolean;
}

export const CurrencyRateModal: React.FC<Props> = ({
  isOpen,
  handleClose,
  handleCloseImportModal,
  selectedCurrencies,
  type,
  requestData,
  isImportPositions,
}) => {
  const language = useAppSelector((state) => state.language.language);
  const selectedCompany = useAppSelector((state) => state.company.selectedCompany);
  const userCurrencies = useAppSelector((state) => state.currencies.userCurrencyList);
  const activeCurrency = useAppSelector((state) => state.currencies.activeCurrency);
  const currencyError = useAppSelector((state) => state.currencies.error);
  const loadingCreatingPosition = useAppSelector((state) => state.priceList.loadingCreatingPosition);
  const loadingCurrency = useAppSelector((state) => state.currencies.loadingCurrency);
  const currencyRateList = useAppSelector((state) => state.currencies.currencyRateList);
  const theme = useAppSelector((state) => state.theme.theme);
  const dispatch = useAppDispatch();

  const defaultCompanyCurrency = selectedCompany?.currency;
  const currentCurrency = (isImportPositions ? activeCurrency : defaultCompanyCurrency) as CurrenciesType;

  const [defaultCurrencyRate, setDefaultCurrencyRate] = useState({ USD: '', UAH: '', GBP: '', EUR: '' });
  const [currencyRate, setCurrencyRate] = useState({ [selectedCurrencies[0] as CurrenciesType]: '' });
  const [importRequestDataWithRates, setImportRequestDataWithRates] = useState([] as CreatePositionRequest[]);

  function createCurrencyRate() {
    if (!selectedCompany || !selectedCurrencies[0]) return;
    dispatch(
      createCurrency({
        companyID: selectedCompany.id,
        baseCompanyCurrency: selectedCompany.currency,
        newCurrency: selectedCurrencies[0],
        currencyRate: currencyRate[selectedCurrencies[0] as CurrenciesType],
      }),
    );
    if (!currencyError) {
      dispatch(createCompanyCurrency({ companyID: selectedCompany.id, currency: selectedCurrencies[0] as CurrenciesType }));
      dispatch(setActiveCurrency(selectedCurrencies[0]));
    }
  }

  function importPosition() {
    if (!selectedCompany) return;
    dispatch(createPosition({ data: importRequestDataWithRates, company: selectedCompany.id }));
  }

  function handleClick() {
    if (type === 'creatingNewCurrency') {
      createCurrencyRate();
    } else if (type === 'addingRateToRequest') {
      importPosition();
    }
  }

  function CloseCurrencyRateModal() {
    if (handleCloseImportModal) handleCloseImportModal();
    handleClose();
    setCurrencyRate({ [selectedCurrencies[0] as CurrenciesType]: '' });
  }

  useEffect(() => {
    if (!selectedCurrencies) return;
    selectedCurrencies.forEach((currency) => {
      if (!currency) return;
      dispatch(getCurrencyRate(currency as CurrenciesType));
    });
  }, [selectedCurrencies, dispatch]);

  useEffect(() => {
    if (!userCurrencies) return;
    userCurrencies.forEach((currency) => {
      if (!currency) return;
      dispatch(getCurrencyRate(currency as CurrenciesType));
    });
  }, [userCurrencies, dispatch]);

  useEffect(() => {
    if (!selectedCurrencies) return;
    let rates = {} as Record<CurrenciesType, string>;
    selectedCurrencies.forEach((currency) => {
      if (!currency) return;
      if (currentCurrency === 'UAH') {
        setDefaultCurrencyRate(currencyRateList);
      } else {
        const crossRate = calculateCrossRate(currentCurrency, currency, currencyRateList);
        if (!crossRate) return;
        rates = { ...rates, [currency as CurrenciesType]: handleNumberValue(crossRate.toString()) };
        setDefaultCurrencyRate(rates);
      }
    });
  }, [currencyRateList, selectedCurrencies, currentCurrency]);

  useEffect(() => {
    setCurrencyRate({ ...defaultCurrencyRate });
  }, [defaultCurrencyRate]);

  useEffect(() => {
    if (loadingCreatingPosition === LoadingResultsT.SUCCEEDED || loadingCurrency === LoadingResultsT.SUCCEEDED) {
      CloseCurrencyRateModal();
      dispatch(resetPriceListLoading());
      dispatch(resetCurrenciesLoading());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, loadingCreatingPosition, loadingCurrency]);

  useEffect(() => {
    const data = addCurrencyRatesToData(
      requestData as CreatePositionRequest[],
      currentCurrency,
      selectedCurrencies,
      currencyRate,
    );
    if (data) {
      setImportRequestDataWithRates(data);
    }
  }, [selectedCurrencies, requestData, currencyRate, currentCurrency]);

  const isDarkTheme = theme === 'dark';
  return (
    <ModalWrapper isOpen={isOpen} onCloseButtonClick={() => handleClose()} title={text[language].CurrencyRate}>
      <CurrencyRateModalContainer className={isDarkTheme ? 'dark' : ''}>
        <p>{text[language].EnterCurrencyRate}</p>
        <InputsContainer>
          <div>
            <CurrencyName className={isDarkTheme ? 'dark' : ''}>{currentCurrency}</CurrencyName>
            <Input
              type="text"
              inputMode="numeric"
              value={'1'}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {}}
              withoutLabel={true}
            />
          </div>
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path
                d="M2 6H14M2 10H14"
                stroke={isDarkTheme ? 'white' : 'black'}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          {selectedCurrencies[0] &&
            selectedCurrencies.map((currency) => (
              <div key={currency}>
                <CurrencyName className={isDarkTheme ? 'dark' : ''}>{currency}</CurrencyName>
                <Input
                  type="text"
                  inputMode="decimal"
                  placeholder={defaultCurrencyRate[currency as CurrenciesType]}
                  value={currencyRate[currency as CurrenciesType]}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (!event.target.value) setCurrencyRate({ ...currencyRate, [currency as CurrenciesType]: '' });
                    const value = handleNumberValue(event.target.value);

                    setCurrencyRate({ ...currencyRate, [currency as CurrenciesType]: value });
                  }}
                  withoutLabel={true}
                />
              </div>
            ))}
        </InputsContainer>
      </CurrencyRateModalContainer>
      <CurrencyRateModalButtonsWrapper>
        <ActionButton
          isPending={loadingCreatingPosition === LoadingResultsT.PENDING || loadingCurrency === LoadingResultsT.PENDING}
          text={text[language].SaveCurrencyRate}
          orangeBtn
          disabled={!checkIsEmptyCurrencyRateFields(selectedCurrencies, currencyRate)}
          onClick={handleClick}
          isPriceListMainButton={true}
        />
      </CurrencyRateModalButtonsWrapper>
    </ModalWrapper>
  );
};
