import React from 'react';
import { GoBackArrow, LogoGubadoo } from 'shared/assets/icons';
import { Breadcrumbs, LanguageSwitchButton } from 'shared/ui';
import { ArrowBackContainer, AuthHeaderContainer, NavigationContainer } from './styles';
import { useAppSelector, useMediaQuery } from 'shared/lib';
import { COLORS, devices, routes } from 'shared/constants';
import { useLocation, useNavigate } from 'react-router-dom';

type Props = {
  withBreadCrumbs?: boolean;
};

export const AuthHeader: React.FC<Props> = ({ withBreadCrumbs }) => {
  const theme = useAppSelector((state) => state.theme.theme);
  const isDesktop = useMediaQuery(devices.desktop);
  const navigate = useNavigate();

  const location = useLocation();
  const locationsArray = location.pathname.split('/');
  const isNoSubscriptionPage = locationsArray.includes(routes.nosubscription);

  const isDarkTheme = theme === 'dark';

  const logoFill = () => {
    if (isNoSubscriptionPage && !withBreadCrumbs) {
      return isDarkTheme ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK;
    } else if (!isNoSubscriptionPage) {
      return isDesktop ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK;
    }
  };

  return (
    <AuthHeaderContainer>
      {withBreadCrumbs ? (
        <NavigationContainer>
          <ArrowBackContainer onClick={() => navigate(-1)}>
            <GoBackArrow stroke={isDarkTheme ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK} />
          </ArrowBackContainer>
          <Breadcrumbs />
        </NavigationContainer>
      ) : (
        <LogoGubadoo width={118} height={24} fill={logoFill()} />
      )}
      <LanguageSwitchButton />
    </AuthHeaderContainer>
  );
};
