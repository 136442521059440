import React, { useEffect, useRef, useState } from 'react';
import { devices, text } from 'shared/constants';
import { useAppSelector, useMediaQuery } from 'shared/lib';
import { DeleteUploadedImageBtn, DropPhotoContainer } from './styles';
import { API_CONFIG } from 'shared/api';

type Props = {
  setProjectImage: (image: ImageValuesType) => void;
  defaultImage?: string | null;
  error?: boolean;
  setError?: (v: boolean) => void;
};

export type ImageValuesType = {
  file: File;
  base64: string;
};

export const ImageUploader: React.FC<Props> = ({ setProjectImage, defaultImage, error, setError }) => {
  const language = useAppSelector((state) => state.language.language);
  const theme = useAppSelector((state) => state.theme.theme);
  const [image, setImage] = useState<string | null | any>(null);
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const isMobile = useMediaQuery(devices.mobile);
  const isTablet = useMediaQuery(devices.tablet);

  const isTabletOrMobile = isMobile || isTablet;

  const imagePreviewSize = isTabletOrMobile ? '32px' : '64px';
  const imagePreviewDeleteSize = isTabletOrMobile ? 12 : 24;

  const allowedExtensions = ['jpg', 'jpeg'];

  useEffect(() => {
    if (defaultImage) {
      const imageUrl = API_CONFIG.BASE_URL + defaultImage;
      setImage(imageUrl);
    } else {
      setImage(null);
    }
  }, [defaultImage]);

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDraggingOver(false);
    const file = e.dataTransfer.files[0];

    if (file) {
      handleFile(file);
    }
  };

  const handleFileInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement & {
      files: FileList;
    };
    const selectedFile = target.files[0];
    handleFile(selectedFile);
  };

  const handleFile = (file: File | undefined) => {
    if (file) {
      const fileExtension = file.name.split('.').pop()?.toLowerCase();
      if (fileExtension && allowedExtensions.includes(fileExtension)) {
        const reader = new FileReader();
        reader.onload = () => {
          const base64String = reader.result as string;

          setImage(base64String);
          setProjectImage({ file: file, base64: base64String });
        };
        reader.readAsDataURL(file);
      } else {
        setError && setError(true);
      }
    }
  };
  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDraggingOver(true);
  };

  const containerClassName = () => {
    let cn = '';
    if (error) cn += 'error';
    if (isDraggingOver) cn += 'draggingOver';
    if (isDarkTheme) cn += 'dark';
    return cn;
  };

  const isDarkTheme = theme === 'dark';
  return (
    <div>
      {!image && (
        <DropPhotoContainer
          onDrop={handleDrop}
          onDragEnter={handleDragEnter}
          onDragOver={(e) => e.preventDefault()}
          className={containerClassName()}
        >
          <p>{text[language].projectPage.dropPhotoHere}</p>
          <p>{text[language].Or}</p>
          <label htmlFor="fileInput" style={{ cursor: 'pointer' }}>
            <p>{text[language].ChooseFile}</p>
          </label>
          <input
            id="fileInput"
            type="file"
            accept="image/jpeg"
            ref={inputRef}
            style={{ display: 'none' }}
            onChange={handleFileInputChange}
          />
        </DropPhotoContainer>
      )}
      {image && (
        <div style={{ width: imagePreviewSize, height: imagePreviewSize, position: 'relative' }}>
          <img src={image} alt="Uploaded" style={{ width: '100%', height: '100%', borderRadius: '8px' }} />
          <DeleteUploadedImageBtn onClick={() => setImage(null)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={imagePreviewDeleteSize}
              height={imagePreviewDeleteSize}
              viewBox="0 0 24 24"
              fill="none"
            >
              <g filter="url(#filter0_b_2477_32842)">
                <rect x="4" y="4" width="16" height="16" rx="8" fill="url(#paint0_linear_2477_32842)" />
                <path d="M8 8L16 16M16 8L8 16" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
              </g>
              <defs>
                <filter
                  id="filter0_b_2477_32842"
                  x="-6"
                  y="-6"
                  width="36"
                  height="36"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feGaussianBlur in="BackgroundImageFix" stdDeviation="5" />
                  <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2477_32842" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_2477_32842" result="shape" />
                </filter>
                <linearGradient
                  id="paint0_linear_2477_32842"
                  x1="12"
                  y1="20"
                  x2="12"
                  y2="4"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#262626" />
                  <stop offset="1" stopOpacity="0.02" />
                </linearGradient>
              </defs>
            </svg>
          </DeleteUploadedImageBtn>
        </div>
      )}
    </div>
  );
};
