import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { TriangleArrow } from 'shared/assets/icons';
import { COLORS, routes, text } from 'shared/constants';
import { useAppSelector } from 'shared/lib';
import { BreadcrumbsWrapper, SinglePathElement } from './styles';

interface BreadcrumbItem {
  path: string;
  label: string;
}

export const Breadcrumbs: React.FC = () => {
  const language = useAppSelector((state) => state.language.language);
  const projects = useAppSelector((state) => state.projects.projects);
  const estimates = useAppSelector((state) => state.estimates.estimates);
  const theme = useAppSelector((state) => state.theme.theme);
  const location = useLocation();
  const { projectId, estimateId } = useParams();

  const projectName = projects?.find((proj) => proj.id.toString() === projectId)?.name;
  const estimateName = estimates?.find((estimate) => estimate.id.toString() === estimateId)?.name;
  const locationArray = location.pathname.split('/');

  const getLabelBreadcrumbs = (pathPart: string, index: number) => {
    let label = '';
    if (locationArray[1] === routes.projects) {
      if (pathPart === routes.estimateCreation) {
        label = text[language].estimateCreation;
      }
      if (pathPart === routes.estimateEditing) {
        label = text[language].estimateEditing;
      }
      if (index === 1) {
        label = text[language].projectPage.project;
      }
      if (index === 2 && projectName) {
        label = projectName;
      }
      if (index === 3 && estimateName) {
        label = estimateName;
      }
    } else if (locationArray[1] === routes.priceList) {
      if (pathPart === routes.estimateCreation) {
        label = text[language].estimateCreation;
      }
      if (index === 1) {
        label = text[language].PriceList;
      }
    } else if (locationArray[1] === routes.profile) {
      if (index === 1) {
        label = text[language].profile;
      }
      if (index === 2) {
        label = text[language].Subscription;
      }
    }
    return label;
  };

  const getPatchBreadcrumbs = (index: number) => {
    let path = '';
    path = locationArray.slice(0, index + 1).join('/');
    return path;
  };

  const breadcrumbs: BreadcrumbItem[] = locationArray.reduce((acc, pathPart, index) => {
    if (!pathPart) return acc;
    const label = getLabelBreadcrumbs(pathPart, index);
    const path = getPatchBreadcrumbs(index);

    acc.push({ path, label });
    return acc;
  }, [] as BreadcrumbItem[]);

  const isDarkTheme = theme === 'dark';
  return (
    <BreadcrumbsWrapper className={breadcrumbs.length ? breadcrumbs.length.toString() : ''}>
      {breadcrumbs.map((breadcrumb, index) => (
        <SinglePathElement className={isDarkTheme ? 'dark' : ''} key={breadcrumb.path}>
          {index > 0 && breadcrumb.label && (
            <TriangleArrow size={16} stroke={isDarkTheme ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK} />
          )}
          {breadcrumb.path === location.pathname ? (
            <span>{breadcrumb.label}</span>
          ) : (
            <Link to={breadcrumb.path}>{breadcrumb.label}</Link>
          )}
        </SinglePathElement>
      ))}
    </BreadcrumbsWrapper>
  );
};
