import React from 'react';

export const GradientGift: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M12 19H14.8C15.9201 19 16.4802 19 16.908 18.7811C17.2843 18.5887 17.5903 18.2815 17.782 17.9037C18 17.4742 18 16.9119 18 15.7873V10.9683H6V15.7873C6 16.9119 6 17.4742 6.21799 17.9037C6.40973 18.2815 6.71569 18.5887 7.09202 18.7811C7.51984 19 8.07989 19 9.2 19H12ZM12 19V7.95645M12 7.95645H17.5C17.9659 7.95645 18.1989 7.95645 18.3827 8.03287C18.6277 8.13477 18.8224 8.33022 18.9239 8.57622C19 8.76071 19 8.99461 19 9.46239C19 9.93018 19 10.1641 18.9239 10.3486C18.8224 10.5946 18.6277 10.79 18.3827 10.8919C18.1989 10.9683 17.9659 10.9683 17.5 10.9683H6.5C6.03406 10.9683 5.80109 10.9683 5.61732 10.8919C5.37229 10.79 5.17761 10.5946 5.07612 10.3486C5 10.1641 5 9.93018 5 9.46239C5 8.99461 5 8.76071 5.07612 8.57622C5.17761 8.33022 5.37229 8.13477 5.61732 8.03287C5.80109 7.95645 6.03406 7.95645 6.5 7.95645H8M12 7.95645C12 5.45031 10.0965 4.78063 9.00785 5.05861C7.50796 5.44159 7.62733 7.3068 8 7.95645M12 7.95645H8M12 7.95645C12 5.45031 13.9035 4.78063 14.9921 5.05861C16.492 5.44159 16.3727 7.3068 16 7.95645H12Z"
        stroke="url(#paint0_linear_3860_98139)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient id="paint0_linear_3860_98139" x1="5" y1="19" x2="19" y2="5" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF8C21" />
          <stop offset="0.880208" stopColor="#FFD200" />
        </linearGradient>
      </defs>
    </svg>
  );
};
