import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AddCurrencyModal, CurrencyRateModal } from 'widgets';
import { setActiveCurrency } from 'entities/currencies';
import { CurrenciesType } from 'entities/priceList';
import { Plus } from 'shared/assets/icons';
import { CurrenciesTabs, TextSkeleton } from 'shared/ui';
import { useMediaQuery, useAppSelector, useAppDispatch } from 'shared/lib';
import { text, COLORS, devices, currencyNames, routes } from 'shared/constants';

import { AddCurrencyButton, CurrenciesBarWrapper, SkeletonContainer } from './styles';

export const CurrenciesBar: React.FC = () => {
  const isMobile = useMediaQuery(devices.mobile);
  const userCurrencyList = useAppSelector((state) => state.currencies.userCurrencyList);
  const activeCurrency = useAppSelector((state) => state.currencies.activeCurrency);
  const language = useAppSelector((state) => state.language.language);
  const company = useAppSelector((state) => state.company.company);
  const theme = useAppSelector((state) => state.theme.theme);
  const dispatch = useAppDispatch();

  const location = useLocation();
  const locationsArray = location.pathname.split('/');
  const isProfilePage = locationsArray.includes(routes.profile);

  const [isOpenAddCurrencyModal, setIsOpenAddCurrencyModal] = useState(false);
  const [isOpenCurrencyRateModal, setIsOpenCurrencyRateModal] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState<string | null>(null);
  const [activeIndex, setActive] = useState(0);

  useEffect(() => {
    if (userCurrencyList?.length > 1) {
      const index = userCurrencyList.findIndex((item: CurrenciesType) => item === activeCurrency);
      setActive(index);
    } else {
      setActive(0);
    }
  }, [userCurrencyList, activeCurrency]);

  const wrapperClassName = () => {
    let cn = '';

    if (isDarkTheme) {
      cn += 'dark';
    }
    if (userCurrencyList?.length === 1) {
      cn += 'single';
    }

    return cn;
  };

  const setCompanyCurrencyToFirstPosition = (userCurrencyList: CurrenciesType[], companyCurrency: CurrenciesType) => {
    const res = userCurrencyList.filter((currency) => currency !== companyCurrency);
    res.unshift(companyCurrency);
    return res;
  };

  const isDarkTheme = theme === 'dark';
  return (
    <>
      <CurrenciesBarWrapper className={wrapperClassName()}>
        {activeCurrency && company ? (
          setCompanyCurrencyToFirstPosition(userCurrencyList, company[0].currency as CurrenciesType).map(
            (item: CurrenciesType, index: number) => (
              <CurrenciesTabs
                key={item}
                item={item}
                isSelected={activeIndex === index}
                setSelectedCurrency={() => {
                  dispatch(setActiveCurrency(item));
                  setActive(index);
                }}
              />
            ),
          )
        ) : (
          <SkeletonContainer>
            <TextSkeleton />
          </SkeletonContainer>
        )}
        {userCurrencyList?.length < currencyNames.length && !isProfilePage && (
          <AddCurrencyButton className={isDarkTheme ? 'dark' : ''} onClick={() => setIsOpenAddCurrencyModal(true)}>
            <Plus
              size={isMobile ? 16 : 24}
              stroke={isDarkTheme ? COLORS.BODY_TEXT_BLACK_20 : COLORS.ACTION_BAR_BLACK_BG}
            />
            <p>{text[language].AddCurrency}</p>
          </AddCurrencyButton>
        )}
      </CurrenciesBarWrapper>
      <AddCurrencyModal
        isOpen={isOpenAddCurrencyModal}
        handleClose={() => setIsOpenAddCurrencyModal(false)}
        selectedCurrency={selectedCurrency}
        setIsOpenCurrencyRateModal={setIsOpenCurrencyRateModal}
        setSelectedCurrency={setSelectedCurrency}
      />
      <CurrencyRateModal
        isOpen={isOpenCurrencyRateModal}
        handleClose={() => setIsOpenCurrencyRateModal(false)}
        selectedCurrencies={[selectedCurrency]}
        type="creatingNewCurrency"
      />
    </>
  );
};
