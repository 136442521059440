import styled from '@emotion/styled';
import { COLORS } from 'shared/constants';
import backgroundImage from '../../../shared/assets/icons/auth_page_bg.png';

export const GreetingsText = styled('h3')(({ className }) => ({
  fontSize: '32px',
  lineHeight: '48px',
  '@media (min-width: 1920px)': {
    fontSize: '48px',
    lineHeight: '58px',
    fontWeight: 500,
  },
}));

export const Subtitle = styled('h4')(({ className }) => ({
  fontSize: '18px',
  lineHeight: '26px',
  marginBottom: '24px',
  '@media (min-width: 1920px)': {
    fontSize: '32px',
    lineHeight: '48px',
    fontWeight: 500,
  },
}));

export const ListHeader = styled('span')(({ className }) => ({
  fontSize: '16px',
  lineHeight: '24px',
  '@media (min-width: 1920px)': {
    fontSize: '24px',
    lineHeight: '34px',
    fontWeight: 500,
  },
}));

export const List = styled('ul')(({ className }) => ({
  display: 'flex',
  gap: '8px',
  alignItems: 'flex-start',
  justifyContent: 'center',
  flexDirection: 'column',
  marginTop: '16px',
  '& li': {
    fontSize: '14px',
    lineHeight: '20px',
    listStyle: 'inside',
  },
  '@media (min-width: 1920px)': {
    gap: '16px',
    maxWidth: '540px',
    marginTop: '32px',
    '& li': {
      fontSize: '18px',
      lineHeight: '26px',
      fontWeight: 500,
    },
  },
}));

export const BackgroundContainer = styled('div')(({ className }) => ({
  background: COLORS.ORANGE_TEXT_HOVERED,
}));

export const AuthInfoContainer = styled('div')(({ className }) => ({
  padding: '84px 80px 228px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  gap: '32px',
  color: COLORS.TEXT_WHITE,
  fontWeight: 500,
  backgroundImage: `url(${backgroundImage})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'bottom',
  backgroundSize: '100%',
  height: '100%',
  '@media (min-width: 1920px)': {
    gap: '40px',
    padding: '132px 160px 228px',
  },
}));

export const AuthInfoButtonsWrapper = styled('div')(({ className }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  gap: '8px',
  '& button': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    border: '1px solid rgba(255, 255, 255, 0.395)',
    borderRadius: '8px',
    background: 'rgba(255, 255, 255, 0.2)',

    backdropFilter: 'blur(6px)',
    width: '160px',
    height: '44px',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 500,
    color: COLORS.TEXT_WHITE,
    fontFamily: '"Golos", sans-serif',
    transition: 'background 0.2s linear',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.3)',
    },
  },
  '@media (min-width: 1920px)': {
    gap: '16px',
  },
}));
