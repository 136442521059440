import styled from '@emotion/styled';
import { COLORS } from 'shared/constants';

export const EstimateInfoContainer = styled('div')(({ className }) => ({
  flex: 1,
  '& > p:first-of-type': {
    color: className?.includes('dark') ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK,
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '30px',
    marginBottom: '32px',
  },
  '& > p:last-of-type': {
    color: className?.includes('dark') ? COLORS.BODY_TEXT_BLACK_20 : COLORS.ACTION_BAR_BLACK_BG,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    marginBottom: '24px',
  },
  '& input': {
    height: '56px',
  },
  '@media (min-width: 1024px)': {
    '& > p:first-of-type': {
      fontSize: '32px',
      fontWeight: 500,
      lineHeight: '48px',
    },
  },
}));

export const EstimateDescriptionWrapper = styled('div')(({ className }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  position: 'relative',
  '& p': {
    color: className?.includes('dark') ? COLORS.TEXT_GREY : COLORS.DISABLED_BTN_TEXT,
    textAlign: 'right',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
  },
  '& > span': {
    fontFamily: '"Golos", sans-serif',
    position: 'absolute',
    color: className?.includes('dark') ? COLORS.BODY_TEXT_BLACK_20 : COLORS.ACTION_BAR_BLACK_BG,
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    paddingTop: '8px',
    top: '1px',
    left: '16px',
    background: className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.TEXT_WHITE,
    width: 'calc(100% - 20%)',
  },
}));

export const EstimateInputsGroup = styled('div')(({ className }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  '& input': {
    minWidth: '260px',
    width: '100%',
  },
  '@media (min-width: 1024px)': {
    flexDirection: 'row',
    gap: '8px',
  },
}));

export const EstimateInputsContainer = styled('div')(({ className }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
}));

export const DropDownContainer = styled('div')(({ className }) => ({
  position: 'relative',
  left: 0,
  '& p': {
    maxWidth: '250px',
  },
  '& ul': {
    top: 2,
    width: '100%',
  },
  '& li': {
    width: '100%',
    justifyContent: 'flex-start',
  },
  '& > p': {
    marginTop: '8px',
    color: COLORS.TEXT_GREY,
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
  },
  '& > div > div': {
    height: '56px',
  },
  '@media (min-width: 768px)': {
    '& p': {
      maxWidth: 'unset',
    },
  },
}));

export const StyledTextarea = styled('textarea')(({ className }) => ({
  fontFamily: '"Golos", sans-serif',
  fontSize: '16px',
  resize: 'none',
  fontWeight: 400,
  width: '100%',
  height: '100%',
  padding: className?.includes('showLabel') ? '28px 16px 16px 16px' : '16px',
  color: className?.includes('dark') ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK,
  border: className?.includes('error')
    ? `1px solid ${COLORS.MAIN_RED}`
    : className?.includes('warning')
    ? `1px solid ${COLORS.ORANGE_BTN}`
    : '1px solid transparent',
  borderRadius: '8px',
  outline: 'none',
  backgroundColor: className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.TEXT_WHITE,
  overflow: 'auto',
  '&:hover': {
    border: className?.includes('error')
      ? `1px solid ${COLORS.MAIN_RED}`
      : className?.includes('warning')
      ? `1px solid ${COLORS.ORANGE_BTN}`
      : `1px solid ${COLORS.BODY_TEXT_BLACK_20}`,
  },
  '&:focus-within': {
    border: className?.includes('error')
      ? `1px solid ${COLORS.MAIN_RED}`
      : className?.includes('warning')
      ? `1px solid ${COLORS.ORANGE_BTN}`
      : `1px solid ${COLORS.TEXT_GREY}`,
  },
  '&:placeholder': {
    color: className?.includes('dark') ? COLORS.TEXT_GREY : COLORS.BODY_TEXT_30,
  },
}));
