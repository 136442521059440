import { ICategory } from 'entities/category';
import { IPriceList } from 'entities/priceList';

export function GetSearchedCategories(word: string, categories: ICategory[], priceList: IPriceList[]) {
  const lowercasedWord = word.toLowerCase();

  const result: ICategory[] = [];

  categories.forEach((cat: ICategory) => {
    const filteredItems = priceList?.filter((i: IPriceList) => i.category.id === cat.id);
    const matchingPositions = GetSearchedPositions(word, filteredItems);

    if (cat.name.toLowerCase().includes(lowercasedWord) || matchingPositions?.length > 0) {
      result.push(cat);
    }
  });

  return result;
}

export function GetSearchedPositions(word: string, priceList: IPriceList[]) {
  return priceList?.filter((i: IPriceList) => i.name.toLowerCase().includes(word.toLowerCase()));
}
