import React from 'react';
import { COLORS } from 'shared/constants';

type Props = {
  isActive: boolean;
};

export const DarkThemeIcon: React.FC<Props> = ({ isActive }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M16 12.3066C15.212 12.6629 14.3372 12.8613 13.4161 12.8613C9.94923 12.8613 7.13875 10.0508 7.13875 6.58393C7.13875 5.66282 7.33714 4.78804 7.69353 4C5.51549 4.98496 4 7.17682 4 9.72263C4 13.1895 6.81048 16 10.2774 16C12.8232 16 15.015 14.4846 16 12.3066Z"
        stroke={isActive ? COLORS.ORANGE_BTN : COLORS.DISABLED_BTN_TEXT}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
};
