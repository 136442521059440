export const estimateInfoDefaultValues: EstimateInfoType = {
  project: {
    value: '',
    id: null,
    img: null,
  },
  name: '',
  paymentTime: new Date(),
  description: '',
  materialsArticle: 0,
  workArticle: 0,
};

export type EstimateInfoType = {
  project: {
    value: string;
    id: number | null;
    img?: string | null;
  };
  name: string;
  paymentTime: Date;
  description: string;
  materialsArticle: number;
  workArticle: number;
};
