import { LoadingResultsT } from 'entities/loadingType';
import React, { useEffect, useRef, useState } from 'react';
import { text } from 'shared/constants';
import { API_CONFIG } from 'shared/api';
import { formatPhoneNumber, useAppDispatch, useAppSelector } from 'shared/lib';
import { ActionButton, ImageValuesType, Input, ModalWrapper } from 'shared/ui';
import { CommonModalContainer } from 'widgets/modals/styles';
import { AddPhotoIcon, NoAvatar } from 'shared/assets/icons';
import { editAccountInfo } from 'entities/account';
import { addAccountImage, editAccountImage } from 'entities/account/api/thunks';
import { AddPhotoWrapper, AvatarContainer, ButtonsWrapper, InputWrapper } from './styles';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
}

const defaultValues = {
  firstName: '',
  lastName: '',
  phone: '',
};

export const EditAccountModal: React.FC<Props> = ({ isOpen, handleClose }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const language = useAppSelector((state) => state.language.language);
  const loadingEditing = useAppSelector((state) => state.account.loadingEditing);
  const account = useAppSelector((state) => state.account.accountInfo);
  const dispatch = useAppDispatch();
  const [accountInfo, setAccountInfo] = useState({ ...defaultValues });
  const [image, setImage] = useState<ImageValuesType | null>(null);
  const requestData = new FormData();

  useEffect(() => {
    if (account) {
      setAccountInfo({
        ...accountInfo,
        firstName: account.firstName,
        lastName: account.lastName,
        phone: account.phone || '',
      });
    }
    if (account?.image) {
      setImage({ ...image, base64: API_CONFIG.BASE_URL + account.image?.account_image_path } as ImageValuesType);
    }
  }, [account, isOpen]);

  function handleModalClose() {
    handleClose();
  }

  function onEditAccountClick() {
    image && requestData.append('image', image?.file);

    dispatch(editAccountInfo({ ...accountInfo }));

    if (account?.image) {
      dispatch(editAccountImage(requestData));
    } else if (!account?.image) {
      dispatch(addAccountImage(requestData));
    }
  }

  const handleFileInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement & {
      files: FileList;
    };
    const selectedFile = target.files[0];
    handleFile(selectedFile);
  };

  const allowedExtensions = ['jpg', 'jpeg'];

  const handleFile = (file: File | undefined) => {
    if (file) {
      const fileExtension = file.name.split('.').pop()?.toLowerCase();
      if (fileExtension && allowedExtensions.includes(fileExtension)) {
        const reader = new FileReader();
        reader.onload = () => {
          const base64String = reader.result as string;

          setImage({ file: file, base64: base64String });
        };
        reader.readAsDataURL(file);
      }
    }
  };

  return (
    <ModalWrapper isOpen={isOpen} title={text[language].EditingAccount}>
      <CommonModalContainer>
        <AvatarContainer>
          <div>
            {image ? (
              <img src={image.base64} alt="avatar" style={{ width: '100%', height: '100%', borderRadius: '50%' }} />
            ) : (
              <NoAvatar size={64} />
            )}
            <AddPhotoWrapper>
              <label htmlFor="fileInput" style={{ cursor: 'pointer' }}>
                <AddPhotoIcon />
              </label>
              <input
                id="fileInput"
                type="file"
                accept="image/jpeg"
                ref={inputRef}
                style={{ display: 'none' }}
                onChange={handleFileInputChange}
              />
            </AddPhotoWrapper>
          </div>
        </AvatarContainer>
        <InputWrapper>
          <Input
            autoFocus={true}
            placeholder={text[language].CompanyName}
            value={accountInfo.firstName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setAccountInfo({ ...accountInfo, firstName: event.target.value });
            }}
          />
          <Input
            placeholder={`${text[language].address} ${text[language].optional}`}
            value={accountInfo.lastName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setAccountInfo({ ...accountInfo, lastName: event.target.value });
            }}
          />
          <Input
            type="tel"
            placeholder={`${text[language].Phone} ${text[language].optional}`}
            value={accountInfo.phone}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const phoneNumber = formatPhoneNumber(event.target.value);
              setAccountInfo({ ...accountInfo, phone: phoneNumber });
            }}
          />
          <Input disabled={true} placeholder={text[language].Email} value={account?.email || ''} onChange={() => {}} />
        </InputWrapper>
      </CommonModalContainer>
      <ButtonsWrapper>
        <ActionButton text={text[language].Cancel} onClick={handleModalClose} />
        <ActionButton
          isPending={loadingEditing === LoadingResultsT.PENDING}
          text={text[language].Save}
          orangeBtn
          disabled={false}
          onClick={onEditAccountClick}
          isPriceListMainButton={true}
        />
      </ButtonsWrapper>
    </ModalWrapper>
  );
};
