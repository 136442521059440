import React from 'react';

export const CheckMarkIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M4.36949 10.2444C4.18344 10.0404 3.8672 10.0258 3.66314 10.2118C3.45907 10.3979 3.44447 10.7141 3.63051 10.9182L4.36949 10.2444ZM8.02854 15L7.65905 15.3369C7.75464 15.4417 7.89027 15.501 8.03214 15.5C8.17401 15.499 8.30877 15.4377 8.40284 15.3315L8.02854 15ZM16.3743 6.33152C16.5574 6.1248 16.5382 5.8088 16.3315 5.62571C16.1248 5.44261 15.8088 5.46177 15.6257 5.66848L16.3743 6.33152ZM3.63051 10.9182L7.65905 15.3369L8.39803 14.6631L4.36949 10.2444L3.63051 10.9182ZM8.40284 15.3315L16.3743 6.33152L15.6257 5.66848L7.65425 14.6685L8.40284 15.3315Z"
        fill="url(#paint0_linear_3866_115908)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3866_115908"
          x1="4"
          y1="15"
          x2="12.64"
          y2="3.48"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8C21" />
          <stop offset="0.880208" stopColor="#FFD200" />
        </linearGradient>
      </defs>
    </svg>
  );
};
