import { createSlice } from '@reduxjs/toolkit';
import { Roles } from 'entities/account';
import { LoadingResultsT } from '../../loadingType';
import { editCompanyInfo, fetchCompanyInfo, createCompany } from '../api/thunks';
import { ICompanyInfo } from '../';

interface IState {
  selectedCompany: ICompanyInfo | null;
  company: ICompanyInfo[] | null;
  loadingCompany: LoadingResultsT;
  loadingCompanyEditing: LoadingResultsT;
  loadingCompanyCreating: LoadingResultsT;
  error: string | null;
}

const initialState: IState = {
  selectedCompany: null,
  company: null,
  loadingCompany: LoadingResultsT.IDLE,
  loadingCompanyEditing: LoadingResultsT.IDLE,
  loadingCompanyCreating: LoadingResultsT.IDLE,
  error: null,
};

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    resetCompanyLoading(state) {
      state.loadingCompany = LoadingResultsT.IDLE;
      state.loadingCompanyEditing = LoadingResultsT.IDLE;
      state.loadingCompanyCreating = LoadingResultsT.IDLE;
    },
    setSelectedCompany(state, { payload }) {
      state.selectedCompany = payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchCompanyInfo.pending, (state) => {
        state.loadingCompany = LoadingResultsT.PENDING;
      })
      .addCase(fetchCompanyInfo.fulfilled, (state, { payload }) => {
        state.loadingCompany = LoadingResultsT.SUCCEEDED;

        const filteredCompaniesByRole = payload.filter(company => company.role !== Roles.customer)

        state.company = filteredCompaniesByRole;
        state.error = null;
      })
      .addCase(fetchCompanyInfo.rejected, (state, { error }) => {
        state.loadingCompany = LoadingResultsT.FAILED;
        state.error = error.message as string;
      })

      .addCase(createCompany.pending, (state) => {
        state.loadingCompanyCreating = LoadingResultsT.PENDING;
      })
      .addCase(createCompany.fulfilled, (state, { payload }) => {
        state.loadingCompanyCreating = LoadingResultsT.SUCCEEDED;

        state.company = [...(state.company || []), payload];
        state.error = null;
      })
      .addCase(createCompany.rejected, (state, { error }) => {
        state.loadingCompanyCreating = LoadingResultsT.FAILED;
        state.error = error.message as string;
      })

      .addCase(editCompanyInfo.pending, (state) => {
        state.loadingCompanyEditing = LoadingResultsT.PENDING;
      })
      .addCase(editCompanyInfo.fulfilled, (state, { payload }) => {
        state.loadingCompanyEditing = LoadingResultsT.SUCCEEDED;
        if (!state.company) return;

        const indexToReplace = state.company.findIndex((item) => item.id === payload.id);

        if (indexToReplace !== -1) {
          state.company[indexToReplace] = payload;
        }
        state.error = null;
      })
      .addCase(editCompanyInfo.rejected, (state, { error }) => {
        state.loadingCompanyEditing = LoadingResultsT.FAILED;
        state.error = error.message as string;
      }),
});

export const companyReducer = companySlice.reducer;
export const { resetCompanyLoading, setSelectedCompany } = companySlice.actions;
