import styled from '@emotion/styled';
import { COLORS } from 'shared/constants';

export const TableHeadRowContainer = styled('div')(({ className }) => ({
  fontFamily: "'Golos', sans-serif",
  position: 'relative',
  backgroundColor: className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.TEXT_WHITE,
  borderBottom: `1px solid ${className?.includes('dark') ? COLORS.LIGHT_GREY_BORDER : COLORS.BORDER_GREY}`,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '48px',
  padding: '10px 8px 10px 12px',
  textAlign: 'left',
  verticalAlign: 'middle',
  '@media (min-width: 968px)': {
    height: '56px',
    padding: '10px 8px 10px 8px',
  },
  '& > div': {
    flexShrink: 0,
  },
}));

export const TableHeadDropDownContainer = styled('div')(({ className }) => ({
  position: 'absolute',
  zIndex: 1,
  top: 0,
  left: 25,
}));
