import { CurrenciesType, stringFundsType } from 'entities/priceList';

export const defaultValues: ItemValues = {
  itemName: '',
  itemArticle: '',
  itemUnit: '',
  itemFunds: {} as Record<CurrenciesType, stringFundsType>,
};

export interface ItemValues {
  itemName: string;
  itemArticle: string;
  itemUnit: string;
  itemFunds: Record<CurrenciesType, stringFundsType>;
}
