import React from 'react';
import { InputErrorText } from './styles';

interface ErrorBarProps {
  error: string;
}

export const InputErrorBar: React.FC<ErrorBarProps> = ({ error }) => {
  return <>{error && <InputErrorText>{error}</InputErrorText>}</>;
};
