import React from 'react';
import { COLORS } from 'shared/constants';
import { useAppSelector } from 'shared/lib';

type Props = {
  size?: number;
};

export const Menu: React.FC<Props> = ({ size = 24 }) => {
  const theme = useAppSelector((state) => state.theme.theme);
  const isDarkTheme = theme === 'dark';

  const stroke = isDarkTheme ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK;
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 7L20 7M4 17H20M4 12H20" stroke={stroke} strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};
