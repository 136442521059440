import React from 'react';
import { Tooltip } from 'react-tooltip';
import { COLORS } from 'shared/constants';

const tooltipStyles = {
  backgroundColor: COLORS.TEXT_GREY,
  borderRadius: 4,
  opacity: 1,
  '& p': {
    color: COLORS.TEXT_WHITE,
    fontWeight: 500,
    fontSize: '12px',
    padding: 0,
    margin: 0,
  },
};

type Props = {
  text: string;
  anchorSelect: string;
};

export const CustomTooltip: React.FC<Props> = ({ text, anchorSelect }) => {
  return (
    <Tooltip anchorSelect={anchorSelect} place="bottom" style={tooltipStyles}>
      <p>{text}</p>
    </Tooltip>
  );
};
