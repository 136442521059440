import styled from '@emotion/styled';
import { COLORS } from 'shared/constants';

export const CategoryName = styled('span')(({ className }) => ({
  fontSize: className?.includes('modalPriceList') ? '8px' : '14px',
  lineHeight: className?.includes('modalPriceList') ? '12px' : '20px',
  fontWeight: 500,
  color: className?.includes('dark') ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK,
  marginRight: '16px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  '@media (min-width: 767px)': {
    lineHeight: '24px',
    fontSize: '16px',
  },
}));

export const CategoryCount = styled('span')(({ className }) => ({
  fontSize: className?.includes('modalPriceList') ? '8px' : '16px',
  lineHeight: className?.includes('modalPriceList') ? '12px' : '24px',
  fontWeight: 400,
  color: className?.includes('dark') ? COLORS.DISABLED_BTN_TEXT : COLORS.TEXT_GREY,
  width: '100px',
  '@media (min-width: 767px)': {
    lineHeight: '24px',
    fontSize: '16px',
  },
}));

export const CategoryDropDownContainer = styled('div')(({ className }) => ({
  position: 'absolute',
  zIndex: 1,
  top: 25,
  right: -50,
  '@media (min-width: 1100px)': {
    top: 50,
    right: 'unset',
  },
}));
