export function formatDate(inputDate: string, language: string): string {
  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'numeric',
    weekday: 'short',
  };

  const date = new Date(inputDate);
  const formattedDate: string = date.toLocaleDateString(language, options);

  const parts = formattedDate.split(', ');

  return `${parts[1].split('/').join('.')}, ${parts[0]}`;
}

export function formatFullDate(inputDate: string, language: string): string {
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  };

  const date = new Date(inputDate);

  const formattedDate: string = date.toLocaleDateString(language, options);
  return formattedDate.replace(/\//g, '.');
}
