import styled from '@emotion/styled';
import { COLORS } from 'shared/constants';

export const tooltipStyles = {
  background: COLORS.TEXT_WHITE,
  padding: '16px',
  borderRadius: '8px',
  border: `1px solid ${COLORS.BORDER_GREY}`,
  color: COLORS.TEXT_BLACK,
  boxShadow: COLORS.TABLE_DROPDOWN_BOX_SHADOW,
  opacity: 1,
};

export const tooltipDarkStyles = {
  background: COLORS.BACKGROUND_BLACK,
  padding: '16px',
  borderRadius: '8px',
  border: `1px solid ${COLORS.LIGHT_GREY_BORDER}`,
  color: COLORS.TEXT_WHITE,
  boxShadow: 'unset',
  opacity: 1,
};

export const CreateProjectModalContainer = styled('div')(({ className }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  margin: '16px auto',
  padding: '0px 14px',
  '@media (min-width: 480px)': {
    padding: '0 32px',
  },
  '@media (min-width: 1024px)': {
    margin: '32px auto',
    gap: '32px',
  },
}));

export const Subtitle = styled('div')(({ className }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '2px',
  color: className?.includes('dark') ? COLORS.BODY_TEXT_BLACK_20 : COLORS.TEXT_BLACK,
  '& p': {
    fontWeight: 500,
    fontSize: '8px',
    lineHeight: '12px',
  },
  '@media (min-width: 1024px)': {
    gap: '4px',
    '& p': {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
  '& > div': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const CustomerInfoTooltip = styled('div')(({ className }) => ({
  fontFamily: "'Golos', sans-serif",
  fontSize: '8px',
  fontWeight: 400,
  lineHeight: '12px',
  maxWidth: '325px',
  '& > div': {
    marginTop: '8px',
    paddingLeft: '8px',
  },
  '& li': {
    listStyle: 'inside',
    marginBottom: '4px',
  },
  '@media (min-width: 1024px)': {
    fontSize: '14px',
    lineHeight: '20px',
  },
}));

export const ButtonsWrapper = styled('div')(({ className }) => ({
  display: 'flex',
  paddingTop: '16px',
  gap: '4px',
  width: '100%',
  '& button': {
    width: '100%',
    height: '21px',
    padding: '8px 16px',
    fontSize: '8px',
    lineHeight: '12px',
  },
  padding: '0px 14px',
  '@media (min-width: 480px)': {
    padding: '0 32px',
  },
  '@media (min-width: 1024px)': {
    gap: '8px',
    '& button': {
      height: '40px',
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
}));

export const InfoProjectContainer = styled('div')(({ className }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  '& input': {
    width: '100%',
  },
  '@media (min-width: 1024px)': {
    gap: '16px',
  },
}));

export const GroupedInputs = styled('div')(({ className }) => ({
  display: 'flex',
  gap: '4px',
  '@media (min-width: 1024px)': {
    gap: '8px',
  },
}));

export const DropDownContainer = styled('div')(({ className }) => ({
  position: 'relative',
  left: 0,
  '& ul': {
    top: 2,
    width: '100%',
    maxHeight: '170px',
    height: '170px',
  },
  '& li': {
    width: '100%',
    justifyContent: 'flex-start',
  },
  '& > p': {
    marginTop: '4px',
    color: COLORS.TEXT_GREY,
    fontSize: '8px',
    fontWeight: 400,
    lineHeight: '10px',
  },
  '@media (min-width: 1024px)': {
    '& > p': {
      marginTop: '8px',
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
}));

export const FormErrorMsgContainer = styled('div')(({ className }) => ({
  display: 'flex',
  gap: '4px',
  alignItems: 'center',
  marginTop: '8px',
  '& > span': {
    color: COLORS.MSG_RED,
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
  },
}));
