import styled from '@emotion/styled';
import { COLORS } from 'shared/constants';

export const StyledLabel = styled('span')(({ className }) => ({
  fontFamily: '"Golos", sans-serif',
  position: 'absolute',
  color: className?.includes('dark') ? COLORS.BODY_TEXT_BLACK_20 : COLORS.ACTION_BAR_BLACK_BG,
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '16px',
  top: '8px',
  left: '16px',
  zIndex: 1,
}));

export const DatePickerWrapper = styled('div')(({ className }) => ({
  position: 'relative',
  '& div.react-datepicker-wrapper': {
    width: '100%',
  },
  '& input': {
    padding: '32px 12px 16px 16px',
    borderRadius: '8px',
    outline: 'none',
    fontSize: '16px',
    fontFamily: "'Golos', sans-serif",
    background: className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.TEXT_WHITE,
    color: className?.includes('dark') ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK,
  },
  '& div.react-datepicker__triangle': {
    display: 'none',
  },
  '& div.react-datepicker__view-calendar-icon svg': {
    right: '12px',
    top: '16px',
    padding: '0px',
    width: '24px',
    height: '24px',
    position: 'absolute',
  },
  '& div.react-datepicker': {
    width: '312px',
    fontFamily: "'Golos', sans-serif",
    padding: '16px',
    borderRadius: '8px',
    border: `1px solid ${className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.BORDER_GREY}`,
    background: className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.TEXT_WHITE,
    boxShadow: className?.includes('dark') ? 'unset' : COLORS.TABLE_DROPDOWN_BOX_SHADOW,
  },
  '& span.react-datepicker__navigation-icon::before': {
    borderWidth: '2px 2px 0 0',
    borderColor: className?.includes('dark') ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK,
    height: '13px',
    width: '13px',
    top: '5px',
  },
  '& button.react-datepicker__navigation--previous': {
    top: '28px',
    left: '26px',
    width: '24px',
    height: '24px',
  },
  '& button.react-datepicker__navigation--next': {
    top: '28px',
    right: '26px',
    width: '24px',
    height: '24px',
  },
  '& div.react-datepicker__month-container': {
    width: '100%',
  },
  '& div.react-datepicker__month': {
    margin: '0px',
  },
  '& div.react-datepicker__day--outside-month': {
    color: `${className?.includes('dark') ? COLORS.TEXT_GREY : COLORS.BODY_TEXT_BLACK_20}!important`,
  },
  '& div.react-datepicker__day--disabled': {
    color: `${className?.includes('dark') ? COLORS.ACTION_BAR_BLACK_BG : COLORS.BODY_TEXT_BLACK_20}!important`,
  },
  '& div.react-datepicker__day--disabled:hover': {
    background: 'none!important',
  },
  '& div.react-datepicker__day': {
    borderRadius: '50%',
    padding: '11px',
    height: '40px',
    width: '40px',
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '18px',
    margin: '0px',
    backgroundColor: 'transparent',
    color: className?.includes('dark') ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK,
  },
  '& div.react-datepicker__header': {
    background: 'transparent',
    border: 'none',
    padding: '0px',
    marginBottom: '8px',
  },
  '& div.react-datepicker__current-month': {
    textAlign: 'center',
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '26px',
    padding: '9px 0',
    textTransform: 'capitalize',
    color: className?.includes('dark') ? COLORS.TEXT_WHITE : COLORS.BODY_TEXT_BLACK_20,
  },
  '& div.react-datepicker__day-name': {
    padding: '11px',
    height: '40px',
    width: '40px',
    color: className?.includes('dark') ? COLORS.TEXT_WHITE : COLORS.BODY_TEXT_BLACK_20,
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '18px',
    margin: '0px 0px 4px 0px',
  },
  '& div.react-datepicker__day--today': {
    background: COLORS.ORANGE_TEXT_HOVERED,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
  '& div.react-datepicker__day--selected': {
    color: COLORS.TEXT_WHITE,
    background: `${COLORS.ORANGE_TEXT_HOVERED}!important`,
    WebkitTextFillColor: `${COLORS.TEXT_WHITE}!important`,
  },
  '& div.react-datepicker__day--today:hover': {
    background: COLORS.TABLE_BG,
    WebkitTextFillColor: COLORS.ORANGE_BTN,
  },
  '& div.react-datepicker__day:hover': {
    background: COLORS.TABLE_BG,
    color: COLORS.TEXT_BLACK,
  },
}));
