import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { isEmpty } from 'lodash';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from '@hello-pangea/dnd';
import PriceListCategoryTableRow from '../PriceListCategoryTableRow/PriceListCategoryTableRow';
import PriceListPositionTableRow from '../PriceListPositionTableRow/PriceListPositionTableRow';
import PriceListEmptyTableRow from '../PriceListEmptyTableRow/PriceListEmptyTableRow';

import { ICategory } from 'entities/category';
import { useAppDispatch, useAppSelector } from 'shared/lib';
import { COLORS } from 'shared/constants';
import { onDragEnd, onDragStart, onDragUpdate, sortCategoriesAndAddPositions, PlaceholderPropsType } from '../../model';
import { DropPlaceholder } from '../styles';

type Props = {
  isModalPriceList: boolean;
};

const PriceListTableBody: React.FC<Props> = ({ isModalPriceList }) => {
  const priceList = useAppSelector((state) => state.priceList.priceList);
  const filteredPriceList = useAppSelector((state) => state.priceList.filteredPriceList);
  const filteredByCurrencyPriceList = useAppSelector((state) => state.priceList.filteredByCurrencyPriceList);
  const filteredByCurrencyPriceListAndSearch = useAppSelector(
    (state) => state.priceList.filteredByCurrencyPriceListAndSearch,
  );
  const theme = useAppSelector((state) => state.theme.theme);
  const fetchedCategories = useAppSelector((state) => state.categories.categories);
  const filteredCategories = useAppSelector((state) => state.categories.filteredCategories);
  const checkedPositionsIds = useAppSelector((state) => state.positionsFilter.checkedPositionsIds);
  const companyCurrencies = useAppSelector((state) => state.currencies.companyCurrencies);
  const company = useAppSelector((state) => state.company.company);
  const language = useAppSelector((state) => state.language.language);
  const dispatch = useAppDispatch();

  const [openedCategoriesList, setOpenedCategoriesList] = useState<number[]>([]);
  const [categories, setCategories] = useState<ICategory[] | null>(null);
  const [placeholderProps, setPlaceholderProps] = useState<PlaceholderPropsType>({} as PlaceholderPropsType);
  const [dropPlaceholderText, setDropPlaceholderText] = useState('');
  const [isPlaceholderVisible, setIsPlaceholderVisible] = useState(true);
  const [hiddenPlaceholder, setHiddenPlaceholder] = useState(false);

  //TODO: refactoring
  useEffect(() => {
    if (!isModalPriceList && !filteredByCurrencyPriceList) return;
    if (filteredCategories?.length) {
      return setCategories(sortCategoriesAndAddPositions(filteredCategories, filteredByCurrencyPriceListAndSearch));
    } else if (fetchedCategories?.length) {
      return setCategories(sortCategoriesAndAddPositions(fetchedCategories, filteredByCurrencyPriceList));
    }
  }, [
    fetchedCategories,
    filteredByCurrencyPriceList,
    filteredCategories,
    isModalPriceList,
    filteredByCurrencyPriceListAndSearch,
    companyCurrencies,
  ]);

  useEffect(() => {
    if (isModalPriceList) return;
    if (filteredCategories?.length) {
      return setCategories(sortCategoriesAndAddPositions(filteredCategories, filteredPriceList));
    } else if (fetchedCategories?.length) {
      return setCategories(sortCategoriesAndAddPositions(fetchedCategories, priceList));
    }
  }, [fetchedCategories, filteredCategories, filteredPriceList, isModalPriceList, priceList]);

  const withoutPositions = categories?.filter((cat) => !cat.positions?.length).length === categories?.length;

  const backgroundDragging = (isDraggingOver: boolean) => {
    if (isDarkTheme) return COLORS.BACKGROUND_BLACK;
    if (isDraggingOver) return COLORS.TABLE_BG;
    return 'transparent';
  };

  const isDarkTheme = theme === 'dark';
  return (
    <div>
      <>{isModalPriceList && withoutPositions && <PriceListEmptyTableRow />}</>
      <DragDropContext
        onDragEnd={(event) =>
          onDragEnd(
            event,
            setPlaceholderProps,
            setIsPlaceholderVisible,
            categories,
            company,
            setCategories,
            dispatch,
            priceList,
            checkedPositionsIds,
          )
        }
        onDragStart={(event) => onDragStart(event, setPlaceholderProps)}
        onDragUpdate={(event) =>
          onDragUpdate(
            event,
            categories,
            setIsPlaceholderVisible,
            setHiddenPlaceholder,
            setDropPlaceholderText,
            language,
            setPlaceholderProps,
          )
        }
      >
        <Droppable droppableId="categories" type="category">
          {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{
                background: backgroundDragging(snapshot.isDraggingOver),
                width: '100%',
                position: 'relative',
              }}
            >
              {categories?.map((category, index) => (
                <Draggable key={category.id} draggableId={'category_' + category.id.toString()} index={index}>
                  {(provided: DraggableProvided, snapshot) => {
                    if (isModalPriceList && !category.positions?.length) {
                      return null;
                    }
                    const isOpened = openedCategoriesList.includes(category.id);

                    const setIsOpened = (v: boolean) => {
                      if (v) {
                        setOpenedCategoriesList((prev) => [...prev, category.id]);
                      } else {
                        setOpenedCategoriesList((prev) => prev.filter((id) => id !== category.id));
                      }
                    };
                    return (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={{
                          background: backgroundDragging(!!snapshot.draggingOver),
                          ...provided.draggableProps.style,
                        }}
                      >
                        <PriceListCategoryTableRow
                          provided={provided}
                          isOpened={isOpened}
                          setIsOpened={setIsOpened}
                          categoryData={category}
                          positionsLength={category.positions?.length}
                          isModalPriceList={isModalPriceList}
                        />
                        <AnimatePresence initial={false}>
                          {isOpened && (
                            <motion.div
                              initial={{ height: '0px', width: '100%', opacity: 0 }}
                              animate={{ height: 'auto', opacity: 1 }}
                              exit={{ height: '0px', opacity: 0 }}
                              transition={{ type: 'spring', duration: 0.4, bounce: 0 }}
                            >
                              <Droppable droppableId={category.id.toString()} type="position">
                                {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
                                  <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={{
                                      position: 'relative',
                                      background: backgroundDragging(snapshot.isDraggingOver),
                                    }}
                                  >
                                    {category.positions &&
                                      category.positions.length > 0 &&
                                      category.positions?.map((position, positionIndex) => (
                                        <Draggable
                                          key={position.id}
                                          draggableId={'position_' + position.id.toString()}
                                          index={positionIndex}
                                        >
                                          {(provided, snapshot: DraggableStateSnapshot) => (
                                            <div ref={provided.innerRef} {...provided.draggableProps}>
                                              <PriceListPositionTableRow
                                                key={position.id}
                                                position={position}
                                                isDragging={!!snapshot.draggingOver}
                                                provided={provided}
                                                isModalPriceList={isModalPriceList}
                                              />
                                            </div>
                                          )}
                                        </Draggable>
                                      ))}
                                    {category.primary === false && category.positions?.length === 0 && (
                                      <PriceListEmptyTableRow isEmptyCategory={true} />
                                    )}
                                    {/* TODO tech debt */}
                                    {provided.placeholder}
                                    {!isEmpty(placeholderProps) &&
                                      snapshot.isDraggingOver &&
                                      isPlaceholderVisible &&
                                      !hiddenPlaceholder && (
                                        <DropPlaceholder
                                          className={isDarkTheme ? 'dark' : ''}
                                          style={{
                                            top: placeholderProps.clientY,
                                            left: placeholderProps.clientX,
                                            height: placeholderProps.clientHeight,
                                          }}
                                        >
                                          <span>{dropPlaceholderText}</span>
                                        </DropPlaceholder>
                                      )}
                                  </div>
                                )}
                              </Droppable>
                            </motion.div>
                          )}
                        </AnimatePresence>
                      </div>
                    );
                  }}
                </Draggable>
              ))}
              {isPlaceholderVisible && (
                <>
                  {provided.placeholder}
                  {/* TODO tech debt (hiddenPlaceholder) */}
                  {!isEmpty(placeholderProps) && snapshot.isDraggingOver && !hiddenPlaceholder && (
                    <DropPlaceholder
                      className={isDarkTheme ? 'dark' : ''}
                      style={{
                        top: placeholderProps.clientY,
                        left: placeholderProps.clientX,
                        height: placeholderProps.clientHeight,
                      }}
                    >
                      <span>{dropPlaceholderText}</span>
                    </DropPlaceholder>
                  )}
                </>
              )}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default PriceListTableBody;
