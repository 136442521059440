import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiService, USER_STATUS, HEADERS } from 'shared/api';
import {
  CreateEstimateRequest,
  CreateTempPositionRequest,
  DeleteEstimatesRequest,
  FetchEstimatesRequest,
} from './types';
import { EstimateType } from '../model/types';
import { IPriceList, setPositions } from 'entities/priceList';

export const fetchEstimates = createAsyncThunk(
  'estimates/fetchEstimates',
  async ({ companyId, projectId }: FetchEstimatesRequest, { rejectWithValue }) => {
    try {
      const { data } = await ApiService.apiCall<EstimateType[]>({
        endpoint: `/v1/company/${companyId}/project/${projectId}/estimate`,
        headers: {
          ...HEADERS,
          userStatus: USER_STATUS,
        },
      });

      return data;
    } catch (error) {
      const errorT = error as string;
      return rejectWithValue(errorT);
    }
  },
);

export const createEstimate = createAsyncThunk(
  'estimates/createEstimate',
  async (reqBody: CreateEstimateRequest, { rejectWithValue }) => {
    const { companyId, projectId, description, paymentTime, name, priceItems } = reqBody;

    try {
      const { data } = await ApiService.apiCall<EstimateType>({
        endpoint: `/v1/company/${companyId}/project/${projectId}/estimate`,
        method: 'POST',
        headers: {
          ...HEADERS,
          userStatus: USER_STATUS,
        },
        query: JSON.stringify({
          description,
          paymentTime: paymentTime && paymentTime.toISOString().replace(/\.\d{3}Z$/, 'Z'),
          name,
          priceItems,
          isTemporary: true,
        }),
      });

      return data;
    } catch (error) {
      const errorT = error as string;
      return rejectWithValue(errorT);
    }
  },
);

export const editEstimate = createAsyncThunk(
  'estimates/editEstimate',
  async (reqBody: CreateEstimateRequest, { rejectWithValue }) => {
    const {
      companyId,
      projectId,
      description,
      paymentTime,
      name,
      priceItems,
      estimateId,
      workArticle,
      materialsArticle,
    } = reqBody;

    try {
      const { data } = await ApiService.apiCall<EstimateType>({
        endpoint: `/v1/company/${companyId}/project/${projectId}/estimate/${estimateId}`,
        method: 'PATCH',
        headers: {
          ...HEADERS,
          userStatus: USER_STATUS,
        },
        query: JSON.stringify({
          description,
          paymentTime,
          name,
          priceItems,
          workArticle,
          materialsArticle,
          isTemporary: true,
        }),
      });

      return data;
    } catch (error) {
      const errorT = error as string;
      return rejectWithValue(errorT);
    }
  },
);
export const deleteEstimate = createAsyncThunk(
  'estimates/deleteEstimate',
  async (reqBody: DeleteEstimatesRequest, { rejectWithValue }) => {
    const { companyId, projectId, estimateId } = reqBody;
    //returns message
    //TODO: ask to return current estimate after deleting
    try {
      const { data } = await ApiService.apiCall<any>({
        endpoint: `/v1/company/${companyId}/project/${projectId}/estimate/${estimateId}`,
        method: 'DELETE',
        headers: {
          ...HEADERS,
          userStatus: USER_STATUS,
        },
      });

      return data;
    } catch (error) {
      const errorT = error as string;
      return rejectWithValue(errorT);
    }
  },
);

export const createTemporaryPosition = createAsyncThunk(
  'estimates/createTemporaryPosition',
  async (
    reqBody: {
      data: CreateTempPositionRequest[];
      company: number;
      currency: string;
      prevPositionId?: number;
    },
    { rejectWithValue, dispatch },
  ) => {
    const { data: positionsData, company, currency, prevPositionId } = reqBody;

    try {
      const { data } = await ApiService.apiCall<IPriceList[]>({
        endpoint: `/v1/company/${company}/pricelist`,
        method: 'POST',
        headers: {
          ...HEADERS,
          userStatus: USER_STATUS,
        },
        query: positionsData,
      });

      dispatch(setPositions(data));
      return { data, currency, prevPositionId };
    } catch (error) {
      const errorT = error as string;
      return rejectWithValue(errorT);
    }
  },
);
