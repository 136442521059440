import styled from '@emotion/styled';
import { COLORS } from 'shared/constants';

export const BackButtonWrapper = styled('div')(({ className }) => ({
  width: '100%',
  '& button': {
    width: '100%',
    height: '56px',
    marginTop: '32px',
    fontSize: '16px',
    fontWeight: 500,
    '& p': {
      color: COLORS.TEXT_WHITE,
    },
  },
}));

export const NuSubscriptionContainer = styled('div')(({ className }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  overflowY: 'auto',
  height: '100vh',
  flex: 1,
  justifyContent: 'center',
  backgroundColor: COLORS.MODAL_BG,
  '& > div': {
    boxShadow: '0 1px 10px 0 rgba(0, 0, 0, 0.1)',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    maxWidth: '556px',
    padding: '40px',
    borderRadius: '32px',
    background: COLORS.TEXT_WHITE,
  },
  '& p': {
    fontFamily: "'Golos', sans-serif",
    fontStyle: 'normal',
    color: COLORS.TEXT_BLACK,
    textAlign: 'center',
  },
  '& p:first-of-type': {
    fontWeight: 600,
    fontSize: '24px',
    margin: '16px auto 24px',
  },
  '& p:last-of-type': {
    fontWeight: 500,
    fontSize: '18px',
  },
}));
