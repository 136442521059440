import { createAsyncThunk } from '@reduxjs/toolkit';
import { USER_STATUS, HEADERS, ApiService } from 'shared/api';
import { ICompanyInfo } from '../';
import { CreateCompanyRequestType, EditCompanyInfoRequest } from './types';

export const fetchCompanyInfo = createAsyncThunk('company/fetchCompanyInfo', async (_, { rejectWithValue }) => {
  try {
    const { data } = await ApiService.apiCall<ICompanyInfo[]>({
      endpoint: `/v1/company`,
      headers: {
        ...HEADERS,
        userStatus: USER_STATUS,
      },
    });

    return data;
  } catch (error) {
    const errorT = error as string;
    return rejectWithValue(errorT);
  }
});

export const editCompanyInfo = createAsyncThunk(
  'company/editCompanyInfo',
  async (reqBody: EditCompanyInfoRequest, { rejectWithValue }) => {
    const { companyId, body } = reqBody;
    try {
      const { data } = await ApiService.apiCall<ICompanyInfo>({
        endpoint: `/v1/company/${companyId}`,
        method: 'PATCH',
        headers: {
          ...HEADERS,
          userStatus: USER_STATUS,
        },
        query: JSON.stringify({
          ...body,
        }),
      });

      return data;
    } catch (error) {
      const errorT = error as string;
      return rejectWithValue(errorT);
    }
  },
);

export const createCompany = createAsyncThunk(
  'company/createCompany',
  async (reqBody: CreateCompanyRequestType, { rejectWithValue }) => {
    try {
      const { data } = await ApiService.apiCall<ICompanyInfo>({
        endpoint: `/v1/company`,
        method: 'POST',
        headers: {
          ...HEADERS,
          userStatus: USER_STATUS,
        },
        query: JSON.stringify(reqBody),
      });

      return data;
    } catch (error) {
      const errorT = error as string;
      return rejectWithValue(errorT);
    }
  },
);
