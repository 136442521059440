import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { COLORS } from 'shared/constants';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const TableWrapper = styled('div')(({ className }) => ({
  borderRadius: '8px 8px 0px 0px',
  border: `1px solid transparent`,
  width: '100%',
  minWidth: '1120px',
  color: className?.includes('dark') ? COLORS.DISABLED_BTN_TEXT : COLORS.TEXT_GREY,
  fontFamily: "'Golos', sans-serif",
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '20px',
  fontSize: '14px',
}));

export const DropdownBody = styled('ul')(({ className }) => ({
  listStyle: 'none',
  whiteSpace: 'nowrap',
  outline: 'none',
  background: className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.TEXT_WHITE,
  border: `1px solid ${className?.includes('dark') ? COLORS.LIGHT_GREY_BORDER : COLORS.BORDER_GREY}`,
  borderRadius: '8px',
  padding: '12px',
  animation: `${fadeIn} 0.2s ease`,
  boxShadow: className?.includes('dark') ? 'unset' : COLORS.TABLE_DROPDOWN_BOX_SHADOW,
}));

export const DropdownElement = styled('li')(({ className }) => ({
  fontSize: '12px',
  lineHeight: '24px',
  fontStyle: 'normal',
  fontWeight: 400,
  padding: '8px 0',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  cursor: 'pointer',
  background: className?.includes('active')
    ? COLORS.ORANGE_TEXT_HOVERED
    : className?.includes('dark')
    ? COLORS.TEXT_WHITE
    : COLORS.ACTION_BAR_BLACK_BG,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  '&:hover': {
    background: COLORS.ORANGE_TEXT_HOVERED,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    '& svg path': {
      stroke: className?.includes('without-flashing-svg') ? 'unset' : COLORS.ORANGE_BTN,
    },
  },
  '&:first-of-type': {
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
  },
  '&:last-of-type': {
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
  },
}));

export const RowButtonWrapper = styled('div')(({ className }) => ({
  position: className?.includes('relative') ? 'relative' : 'unset',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '40px',
  width: '40px',
  borderRadius: '8px',
}));

export const TableCell = styled('div')(({ className }) => ({
  color: className?.includes('dark')
    ? COLORS.TEXT_WHITE
    : className?.includes('position-count')
    ? COLORS.DISABLED_BTN_TEXT
    : 'inherit',
  padding: '14px 8px',
}));

export const EditableTableCell = styled('input')(({ className }) => ({
  fontFamily: "'Golos', sans-serif",
  color: className?.includes('dark')
    ? COLORS.TEXT_WHITE
    : className?.includes('position-count')
    ? COLORS.DISABLED_BTN_TEXT
    : COLORS.TEXT_BLACK,
  padding: '14px 8px',
  border: 'none',
  outline: 'none',
  background: 'transparent',
  fontWeight: 500,
  fontSize: '14px',
}));

export const ButtonTableCellContainer = styled('div')(({ className }) => ({
  minWidth: '40px',
}));

export const MainTableCellContainer = styled('div')(({ className }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '0 8px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  width: '240px',
  '& svg': {
    marginRight: '24px',
  },
  '@media (min-width: 1600px)': {
    width: '536px',
  },
}));

export const SecondaryTableCellContainer = styled('div')(({ className }) => ({
  paddingRight: '8px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  width: '130px',
}));

export const PriceTableCellContainer = styled('div')(({ className }) => ({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  width: '100px',
}));

export const AllTableRowContainer = styled('div')(({ className }) => ({
  fontFamily: "'Golos', sans-serif",
  color: className?.includes('dark') ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK,
  background: className?.includes('dark sum')
    ? COLORS.BACKGROUND_LIGHT_DARK
    : className?.includes('sum')
    ? COLORS.TABLE_BG
    : className?.includes('dark')
    ? COLORS.BACKGROUND_BLACK
    : COLORS.TEXT_WHITE,
  borderRadius: className?.includes('sum') ? '0px 0px 8px 8px' : 'unset',
  borderBottom: `1px solid ${className?.includes('dark') ? COLORS.LIGHT_GREY_BORDER : COLORS.BORDER_GREY}`,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  textAlign: 'left',
  verticalAlign: 'middle',
  height: '48px',
  padding: '10px 8px 10px 8px',
  '& > div': {
    fontWeight: 600,
    flexShrink: 0,
  },
}));

export const MainTableCell = styled('div')(({ className }) => ({
  fontWeight: '500!important',
  fontSize: '16px',
  lineHeight: '24px',
  padding: '0 8px',
  width: '240px',
  '@media (min-width: 1600px)': {
    width: '536px',
  },
}));
