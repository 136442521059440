import React from 'react';
import { COLORS } from 'shared/constants';
import { useAppSelector } from 'shared/lib';

type Props = {
  size?: number;
  id?: string;
};

export const GradientRoundPlus: React.FC<Props> = ({ size = 24, id }) => {
  const theme = useAppSelector((state) => state.theme.theme);

  const isDarkTheme = theme === 'dark';
  const stroke = isDarkTheme ? COLORS.TEXT_BLACK : COLORS.TEXT_WHITE;
  return (
    <svg id={id} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        d="M20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z"
        fill="url(#paint0_linear_1144_11694)"
      />
      <path
        d="M12.0001 8V11.9999M12.0001 11.9999V16M12.0001 11.9999H8M12.0001 11.9999H16M20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z"
        stroke={stroke}
        strokeLinecap="round"
      />
      <defs>
        <linearGradient id="paint0_linear_1144_11694" x1="4" y1="20" x2="20" y2="4" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF8C21" />
          <stop offset="0.880208" stopColor="#FFD200" />
        </linearGradient>
      </defs>
    </svg>
  );
};
