import styled from '@emotion/styled';
import { COLORS } from 'shared/constants';
import { keyframes } from '@emotion/react';

const rotation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const CircularSpinner = styled('div')(({ className }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  width: '24px',
  height: '24px',
  '& div': {
    position: 'absolute',
    width: '24px',
    height: '24px',
    border: `3px solid ${className?.includes('orangeSpinner') ? COLORS.ORANGE_BTN_ACTIVE : COLORS.TEXT_WHITE}`,
    borderRadius: '50%',
    animation: `${rotation} .7s cubic-bezier(0.5, 0, 0.5, 1) infinite`,
    borderColor: `${
      className?.includes('orangeSpinner') ? COLORS.ORANGE_BTN_ACTIVE : COLORS.TEXT_WHITE
    } transparent transparent transparent`,
  },
  '& div:nth-of-type(1)': {
    animationDelay: '-0.8s',
  },
  '& div:nth-of-type(2)': {
    animationDelay: '-0.15s',
  },
  '& div:nth-of-type(3)': {
    animationDelay: '-0.05s',
  },
}));
