import React from 'react';
import { DeleteItemsModalType } from 'widgets';
import { deleteCategories, removeCategories } from 'entities/category';
import { removePositionsById, removePosition } from 'entities/priceList';
import { removeCheckedCategories, removeCheckedPositions } from 'entities/positionsFilter';
import {
  removeCheckedCategories as removeCheckedEstimateCategories,
  removeCheckedPositions as removeCheckedEstimatePositions,
} from 'entities/estimatesFilter';
import { routes, text } from 'shared/constants';
import { useAppSelector, useAppDispatch } from 'shared/lib';
import { DeleteItemsIcons } from 'shared/assets/icons';
import { ModalWrapper } from 'shared/ui';
import { deleteItemsModalText } from '../model/deleteItemsModalText';
import { CommonModalContainer } from '../../styles';
import { DeleteItemsImages, DeleteItemsModalButton, DeleteItemsModalButtonsWrapper, DeleteItemsText } from './styles';
import { useLocation } from 'react-router-dom';
import { removeEstimateCategories, removeEstimatePositionsById } from 'entities/estimates';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  type: DeleteItemsModalType;
  categoryID?: number[];
  positionID?: number[];
}

export const DeleteItemsModal: React.FC<Props> = ({ isOpen, handleClose, type, categoryID, positionID }) => {
  const language = useAppSelector((state) => state.language.language);
  const company = useAppSelector((state) => state.company.company);
  const categoriesError = useAppSelector((state) => state.categories.error);
  const itemsError = useAppSelector((state) => state.priceList.error);
  const theme = useAppSelector((state) => state.theme.theme);
  const dispatch = useAppDispatch();

  const location = useLocation();
  const locationsArray = location.pathname.split('/');
  const isEstimatePage =
    locationsArray.includes(routes.estimateEditing) || locationsArray.includes(routes.estimateCreation);

  function DeletePositionsFunc() {
    if (!company || !positionID?.length) return;
    const positionIdsArray: number[] = positionID;
    dispatch(removePosition({ companyID: company[0].id, itemID: positionIdsArray }));
    if (!itemsError) {
      handleClose();
      // TODO: rewrite using loading state
      dispatch(removePositionsById(positionIdsArray));
      dispatch(removeCheckedPositions(positionIdsArray));
    }
  }

  function DeleteCategoriesFunc() {
    if (!company || !categoryID) return;
    const categoryIdsArray: number[] = categoryID;

    dispatch(deleteCategories({ companyID: company[0].id, categoryID: categoryIdsArray }));
    if (!categoriesError) {
      handleClose();
      // TODO: rewrite using loading state
      dispatch(removeCategories(categoryIdsArray));
      dispatch(removeCheckedCategories(categoryIdsArray));
    }
  }

  function onDeleteClick() {
    if (isEstimatePage) {
      dispatch(removeEstimatePositionsById(positionID));
      dispatch(removeCheckedEstimatePositions(positionID));
      dispatch(removeEstimateCategories(categoryID));
      dispatch(removeCheckedEstimateCategories(categoryID));
      handleClose();
      return;
    }
    if (type.type === 'category') {
      DeleteCategoriesFunc();
    } else if (type.type === 'position') {
      DeletePositionsFunc();
    } else if (type.type === 'multipleDeleting') {
      DeleteCategoriesFunc();
      DeletePositionsFunc();
    }
  }

  const isDarkTheme = theme === 'dark';
  return (
    <ModalWrapper
      isOpen={isOpen}
      title={
        type.type === 'category' || type.type === 'multipleDeleting'
          ? text[language].DeleteCategory
          : text[language].DeleteItem
      }
    >
      <CommonModalContainer>
        <DeleteItemsImages>
          <DeleteItemsIcons />
        </DeleteItemsImages>
        <DeleteItemsText className={isDarkTheme ? 'dark' : ''}>
          {deleteItemsModalText(type, language, isEstimatePage)}
        </DeleteItemsText>
      </CommonModalContainer>
      <DeleteItemsModalButtonsWrapper>
        <DeleteItemsModalButton
          className={isDarkTheme ? 'dark' : ''}
          onClick={() => {
            handleClose();
          }}
        >
          <p>{text[language].Cancel}</p>
        </DeleteItemsModalButton>
        <DeleteItemsModalButton className={isDarkTheme ? 'dark delete' : 'delete'} onClick={onDeleteClick}>
          {text[language].YesDelete}
        </DeleteItemsModalButton>
      </DeleteItemsModalButtonsWrapper>
    </ModalWrapper>
  );
};
