import React from 'react';
import { useLocation } from 'react-router-dom';
import { TextSkeleton, ProgressBar, LanguageSwitchButton } from 'shared/ui';
import { Menu, VerticalDivider } from 'shared/assets/icons';
import { useMediaQuery, useAppSelector, getCurrencySymbol } from 'shared/lib';
import { text, devices, routes, COLORS } from 'shared/constants';
import {
  Container,
  GoToWeb,
  HeaderInfoContainer,
  MenuBlock,
  MenuButton,
  SkeletonContainer,
  StyledHeader,
} from './styles';

type Props = {
  setOpenSidebar: (value: boolean) => void;
  isOpenedSidebar: boolean;
};

export const MainHeader: React.FC<Props> = ({ setOpenSidebar, isOpenedSidebar }) => {
  const isTablet = useMediaQuery(devices.tablet);
  const isMobile = useMediaQuery(devices.mobile);
  const language = useAppSelector((state) => state.language.language);
  const theme = useAppSelector((state) => state.theme.theme);
  const activeCurrency = useAppSelector((state) => state.currencies.activeCurrency);

  const location = useLocation();
  const locationsArray = location.pathname.split('/');
  const isProjectPage = locationsArray.includes(routes.projects);

  const mobOrTab = isMobile || isTablet;
  const isDarkTheme = theme === 'dark';
  return (
    <>
      <ProgressBar />
      {mobOrTab ? (
        <Container className="fullWidth">
          <MenuBlock>
            {!isOpenedSidebar && (
              <MenuButton onClick={() => setOpenSidebar(true)}>
                <Menu />
              </MenuButton>
            )}
          </MenuBlock>
          <GoToWeb className={isDarkTheme ? 'dark' : ''}>
            <p>{text[language].BetterGoToWeb}</p>
          </GoToWeb>
        </Container>
      ) : (
        <StyledHeader className={isDarkTheme ? 'dark' : ''}>
          <Container>
            <HeaderInfoContainer className={isDarkTheme ? 'dark' : ''}>
              {(() => {
                if (!isProjectPage) {
                  return (
                    <>
                      {activeCurrency ? (
                        <div>{`${activeCurrency} ${getCurrencySymbol(activeCurrency)}`}</div>
                      ) : (
                        <SkeletonContainer>
                          <TextSkeleton />
                        </SkeletonContainer>
                      )}
                      <VerticalDivider stroke={isDarkTheme ? COLORS.DIVIDER_DARK : COLORS.BODY_TEXT_BLACK_20} />
                    </>
                  );
                }
              })()}
              <LanguageSwitchButton />
            </HeaderInfoContainer>
          </Container>
        </StyledHeader>
      )}
    </>
  );
};
