import { createSlice } from '@reduxjs/toolkit';
import { LoadingResultsT } from '../../loadingType';
import { sendToken } from '../api/thunks';

interface IState {
  loading: LoadingResultsT;
  error: string | null;
}

const initialState: IState = {
  loading: LoadingResultsT.IDLE,
  error: null,
};

const accessCompanySlice = createSlice({
  name: 'accessCompany',
  initialState,
  reducers: {
    resetLoading(state) {
      state.loading = LoadingResultsT.IDLE;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(sendToken.pending, (state) => {
        state.loading = LoadingResultsT.PENDING;
      })
      .addCase(sendToken.fulfilled, (state, { payload }) => {
        state.loading = LoadingResultsT.SUCCEEDED;

        state.error = null;
      })
      .addCase(sendToken.rejected, (state, { error }) => {
        state.loading = LoadingResultsT.FAILED;
        state.error = error.message as string;
      }),
});

export const { resetLoading } = accessCompanySlice.actions;
export const accessCompanyReducer = accessCompanySlice.reducer;
