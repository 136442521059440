import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';
import { USER_STATUS, HEADERS, API_CONFIG, ApiService } from 'shared/api';

import { text } from 'shared/constants';
import { RootState } from 'app/store';
import { ICategory } from '../model/types';
import {
  CategoriesRequest,
  CreateCategoriesRequest,
  DeleteCategoriesRequest,
  EditCategoriesRequest,
  GroupPositionsToCategoryRequest,
  OrderCategoriesRequest,
  UngroupCategoryRequest,
} from './types';

export const fetchCategories = createAsyncThunk(
  'categories/fetchCategories',
  async (reqBody: CategoriesRequest, { rejectWithValue }) => {
    const { id, language } = reqBody;
    try {
      const { data } = await ApiService.apiCall<ICategory[]>({
        endpoint: `/v1/company/${id}/pricelist/category`,
        headers: {
          ...HEADERS,
          userStatus: USER_STATUS,
          languageCode: language.toLowerCase(),
        },
      });

      return data;
    } catch (error) {
      const errorT = error as string;
      return rejectWithValue(errorT);
    }
  },
);

export const deleteCategories = createAsyncThunk(
  'categories/deleteCategories',
  async (reqBody: DeleteCategoriesRequest, { rejectWithValue, getState }) => {
    const { companyID, categoryID } = reqBody;
    const activeLanguage = (getState() as RootState).language.language;
    try {
      // TODO: rewrite using middleware
      const { data } = await axios.delete(`${API_CONFIG.BASE_URL}/v1/company/${companyID}/pricelist/category`, {
        headers: {
          ...HEADERS,
          userStatus: USER_STATUS,
        },
        data: JSON.stringify({
          id: categoryID,
        }),
        withCredentials: true,
      });

      toast.success(text[activeLanguage].notifications.categoryDeletedSuccess);
      return data; // If success returns {message: {messageCode: "successDeleting", messageDescription: "Categories has been deleted."}}
    } catch (error) {
      toast.error(text[activeLanguage].Oops);
      const errorT = error as string;
      return rejectWithValue(errorT);
    }
  },
);

export const orderCategories = createAsyncThunk(
  'categories/orderCategories',
  async (reqBody: OrderCategoriesRequest, { rejectWithValue }) => {
    const { companyID, order } = reqBody;
    try {
      const { data } = await ApiService.apiCall<ICategory[]>({
        endpoint: `/v1/company/${companyID}/pricelist/category`,
        method: 'PATCH',
        headers: {
          ...HEADERS,
          userStatus: USER_STATUS,
        },
        query: JSON.stringify({
          order: order,
        }),
      });

      return data;
    } catch (error) {
      const errorT = error as string;
      return rejectWithValue(errorT);
    }
  },
);

export const createCategory = createAsyncThunk(
  'categories/createCategory',
  async (reqBody: CreateCategoriesRequest, { rejectWithValue, getState }) => {
    const { companyID, order, categoryName } = reqBody;
    const activeLanguage = (getState() as RootState).language.language;

    try {
      const { data } = await ApiService.apiCall<ICategory>({
        endpoint: `/v1/company/${companyID}/pricelist/category`,
        method: 'POST',
        headers: {
          ...HEADERS,
          userStatus: USER_STATUS,
        },
        query: JSON.stringify({
          name: categoryName,
          order: order,
        }),
      });

      toast.success(text[activeLanguage].notifications.categoryCreatedSuccess);
      return data;
    } catch (error) {
      toast.error(text[activeLanguage].Oops);
      const errorT = error as string;
      return rejectWithValue(errorT);
    }
  },
);

export const editCategory = createAsyncThunk(
  'categories/editCategory',
  async (reqBody: EditCategoriesRequest, { rejectWithValue, getState }) => {
    const { companyID, categoryID, category } = reqBody;
    const activeLanguage = (getState() as RootState).language.language;

    try {
      const { data } = await ApiService.apiCall<ICategory>({
        endpoint: `/v1/company/${companyID}/pricelist/category/${categoryID}`,
        method: 'PATCH',
        headers: {
          ...HEADERS,
          userStatus: USER_STATUS,
        },
        query: JSON.stringify({
          name: category,
        }),
      });

      toast.success(text[activeLanguage].notifications.categoryEditedSuccess);
      return data;
    } catch (error) {
      toast.error(text[activeLanguage].Oops);
      const errorT = error as string;
      return rejectWithValue(errorT);
    }
  },
);

export const ungroupCategory = createAsyncThunk(
  'categories/ungroupCategory',
  async (reqBody: UngroupCategoryRequest, { rejectWithValue, getState }) => {
    const { companyID, categoryIDs } = reqBody;
    const activeLanguage = (getState() as RootState).language.language;

    try {
      const { data } = await ApiService.apiCall<ICategory>({
        endpoint: `/v1/company/${companyID}/pricelist/category/ungroup`,
        method: 'POST',
        headers: {
          ...HEADERS,
          userStatus: USER_STATUS,
        },
        query: JSON.stringify({
          categories: categoryIDs,
        }),
      });

      toast.success(text[activeLanguage].notifications.categoryUngroupedSuccess);
      return data;
    } catch (error) {
      toast.error(text[activeLanguage].Oops);
      const errorT = error as string;
      return rejectWithValue(errorT);
    }
  },
);

export const groupPositionsToCategory = createAsyncThunk(
  'categories/groupPositionsToCategory',
  async (reqBody: GroupPositionsToCategoryRequest, { rejectWithValue, getState }) => {
    const { companyID, positionIds, categoryName, article } = reqBody;
    const activeLanguage = (getState() as RootState).language.language;
    try {
      const { data } = await ApiService.apiCall<ICategory>({
        endpoint: `/v1/company/${companyID}/pricelist/category`,
        method: 'POST',
        headers: {
          ...HEADERS,
          userStatus: USER_STATUS,
        },
        query: JSON.stringify({
          childItems: positionIds,
          name: categoryName,
          article,
        }),
      });

      toast.success(text[activeLanguage].notifications.positionGroupedSuccess);
      return data;
    } catch (error) {
      toast.error(text[activeLanguage].Oops);
      const errorT = error as string;
      return rejectWithValue(errorT);
    }
  },
);
