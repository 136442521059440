import styled from '@emotion/styled';
import { COLORS } from 'shared/constants';
//TODO remove div
export const BackButtonWrapper = styled('div')(({ className }) => ({
  width: '100%',
  '& button': {
    width: '100%',
    height: '56px',
    marginTop: '32px',
    fontSize: '16px',
    fontWeight: 500,
    '& p': {
      color: COLORS.TEXT_WHITE,
    },
  },
}));

export const NoSubscriptionContainer = styled('div')(({ className }) => ({
  backgroundColor: COLORS.TEXT_WHITE,
  borderRadius: '16px',
  padding: '40px',
  maxWidth: '556px',
  '& > div': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& > p': {
      textAlign: 'center',
    },
    '& > p:first-of-type': {
      fontSize: '24px',
      fontWeight: 600,
      marginBottom: '24px',
    },
  },
  //_____
  // height: '100vh',
  // width: '100%',
  // backgroundColor: className?.includes('dark') ? COLORS.MODAL_BG_DARK : COLORS.MODAL_BG,
  // padding: '88px 16px 32px',
  '@media (min-width: 1920px)': {
    // padding: '130px 160px 64px',
  },
}));
