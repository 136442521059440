import { CurrenciesType, IPriceList } from 'entities/priceList';
import { EstimateType, PriceItemType } from './types';

export const parsePriceListToPriceItems = (positions: IPriceList[], currentCurrency: string): PriceItemType[] => {
  return positions.map((position) => {
    const funds = position.funds[currentCurrency as CurrenciesType];
    return {
      article: position.article,
      funds,
      id: position.id,
      name: position.name,
      quantity: 1,
      units: position.units,
      isTemporary: position.isTemporary,
    };
  });
};

export const sortEstimatesArrayByDate = (array: EstimateType[]): EstimateType[] => {
  return array.sort((a, b) => {
    const dateA = new Date(a.paymentTime);
    const dateB = new Date(b.paymentTime);
    return dateB.getTime() - dateA.getTime();
  });
};
