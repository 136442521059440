export const routes = {
  auth: '/',
  profile: 'profile',
  priceList: 'pricelist',
  projects: 'projects',
  projectDetail: 'projects/:projectId',
  nosubscription: 'nosubscription',
  estimateCreation: 'new_estimate',
  estimatesDetail: ':estimateId',
  estimateEditing: 'estimate_editing',
  acceptCompany: 'acceptCompany',
  restoreAccess: 'restore',
  registration: 'registration',
  creatingPassword: 'creating_password',
  resetPasswordRedirect: 'resetPassword',
  confirmEmailRedirect: 'confirmEmail',
  createCompany: 'createCompany',
};
