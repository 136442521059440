import React from 'react';
import { COLORS } from 'shared/constants';

type Props = {
  size?: number;
  stroke?: string;
};

export const DragIcon: React.FC<Props> = ({ size = 24, stroke = COLORS.TEXT_GREY }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.5 13.9998H19.5037M4.5 10H19.5037" stroke={stroke} strokeLinecap="round"></path>
    </svg>
  );
};
