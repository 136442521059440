import styled from '@emotion/styled';
import { COLORS } from 'shared/constants';

export const SelectedInfoContainer = styled('div')(({ className }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '16px',
  position: 'relative',
  paddingTop: className?.includes('withLabel') ? '5px' : '0px',
  '@media (min-width: 1024px)': {
    paddingTop: className?.includes('withLabel') ? '6px' : '0px',
  },
}));

export const SelectLabel = styled('span')(({ className }) => ({
  fontFamily: '"Golos", sans-serif',
  position: 'absolute',
  color: className?.includes('dark') ? COLORS.BODY_TEXT_BLACK_20 : COLORS.ACTION_BAR_BLACK_BG,
  fontSize: '6px',
  fontWeight: 400,
  lineHeight: '8px',
  top: '-6px',
  left: '0px',
  '@media (min-width: 1024px)': {
    fontSize: '12px',
    lineHeight: '16px',
    top: '-11px',
  },
}));

export const ProjectImg = styled('img')(({ className }) => ({ width: '32px', height: '32px', borderRadius: '8px' }));

export const DropDownContainer = styled('div')(({ className }) => ({
  position: 'absolute',
  left: 0,
  width: '100%',
  '& ul': {
    top: 2,
    width: '100%',
  },
  '& li': {
    width: '100%',
    justifyContent: 'flex-start',
  },
}));

export const StyledSelect = styled('div')(({ className }) => ({
  fontFamily: '"Golos", sans-serif',
  fontSize: className?.includes('mobileModal') ? '8px' : '16px',
  fontWeight: 400,
  width: '100%',
  height: className?.includes('mobileModal') ? '30px' : '56px',
  padding: className?.includes('mobileModal') ? '8px' : '16px',
  color: className?.includes('placeholderdark')
    ? COLORS.TEXT_GREY
    : className?.includes('dark')
    ? COLORS.TEXT_WHITE
    : className?.includes('placeholder')
    ? '#7f7f7f'
    : COLORS.TEXT_BLACK,
  borderRadius: '8px',
  border: '1px solid transparent',
  cursor: 'pointer',
  backgroundColor: className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.TEXT_WHITE,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  '&:hover': {
    border: `1px solid ${COLORS.BODY_TEXT_BLACK_20}`,
  },
  '@media (min-width: 1024px)': {
    fontSize: '16px',
    height: '56px',
    padding: '16px',
  },
}));
