import styled from '@emotion/styled';
import { COLORS, getSecondaryWhiteColor } from 'shared/constants';

export const StyledTransparentButton = styled('button')(({ className }) => ({
  backgroundColor: 'transparent',
  border: 'none',
  color: 'inherit',
  fontSize: 'inherit',
  cursor: 'pointer',
  transition: 'all 0.5s ease-out',
  '&:hover': {
    backdropFilter: 'blur(6px)',
    backgroundColor: getSecondaryWhiteColor('0.20'),
  },
  '&:disabled': {
    cursor: 'default',
    color: COLORS.DISABLED_BTN_TEXT,
    backgroundColor: 'transparent',
  },
}));
