import React from 'react';
import { COLORS } from 'shared/constants';

type Props = {
  size?: number;
  id?: string;
  disabled?: boolean;
  isDarkTheme: boolean;
};

export const Upload: React.FC<Props> = ({ size = 24, id, disabled, isDarkTheme }) => {
  const stroke = () => {
    if (disabled && isDarkTheme) return COLORS.BODY_TEXT_BLACK_70;
    if (!disabled && isDarkTheme) return COLORS.TEXT_WHITE;
    if (disabled && !isDarkTheme) return COLORS.DISABLED_BTN_TEXT;
    if (!disabled && !isDarkTheme) return COLORS.TEXT_BLACK;
  };

  return (
    <svg id={id} width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 16V17C5 18.1046 5.89543 19 7 19H17C18.1046 19 19 18.1046 19 17V16M12 15V5M12 5L8 8M12 5L16 8"
        stroke={stroke()}
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
