import { PriceItemType } from 'entities/estimates';
import { EstimateMarkupsType } from 'entities/project';

export const getMarkupByArticle = (position: PriceItemType, markups: EstimateMarkupsType) => {
  if (position.article === 1) {
    return markups.materialsMarkup;
  } else if (position.article === 2) {
    return markups.workMarkup;
  } else {
    return 0;
  }
};
