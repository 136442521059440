import styled from '@emotion/styled';
import { COLORS } from 'shared/constants';

export const TableHeadRowContainer = styled('div')(({ className }) => ({
  fontFamily: "'Golos', sans-serif",
  position: 'relative',
  backgroundColor: className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.TEXT_WHITE,
  borderBottom: `1px solid ${className?.includes('dark') ? COLORS.LIGHT_GREY_BORDER : COLORS.BORDER_GREY}`,
  display: 'flex',
  gap: className?.includes('modalPriceList') ? '2px' : '16px',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '48px',
  padding: '10px 8px 10px 12px',
  textAlign: 'left',
  verticalAlign: 'middle',
  '@media (min-width: 1400px)': {
    height: '56px',
  },
}));

export const TableHeadDropDownContainer = styled('div')(({ className }) => ({
  position: 'absolute',
  zIndex: 1,
  top: 25,
  left: 0,
  '@media (min-width: 1100px)': {
    left: 'unset',
  },
}));
