import React, { useEffect, useState } from 'react';
import { CurrenciesType, ParsedFunds } from 'entities/priceList';
import { GetUniqueUnitsFromPriceList, useAppSelector, handleNumberValue } from 'shared/lib';
import { SearchInputWithDropDown } from 'shared/ui';
import { ErrorInputModalTypes } from '../../model/types';

type Props = {
  setHasWarning: (value: boolean) => void;
  preparedData?: ParsedFunds[];
  setImportRequestData: (data: ParsedFunds[]) => void;
  setIsError: (value: ErrorInputModalTypes) => void;
};

const ImportTable: React.FC<Props> = ({ preparedData, setImportRequestData, setHasWarning, setIsError }) => {
  const activeCurrency = useAppSelector((state) => state.currencies.activeCurrency);
  const priceList = useAppSelector((state) => state.priceList.priceList);
  const [data, setData] = useState([] as ParsedFunds[]);
  const [triggerImport, setTriggerImport] = useState(false); // TODO: remove this

  useEffect(() => {
    setImportRequestData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerImport]);

  useEffect(() => {
    if (!data.length) return;
    data.forEach((row) => {
      if (!activeCurrency) return;
      if (Number(row.funds[activeCurrency]?.cost) > Number(row.funds[activeCurrency]?.total)) {
        setHasWarning(true);
      }

      if (Number(row.funds[activeCurrency]?.total) === 0) {
        setIsError('totalAmountIsZero');
      }

      if (!row.name || !row.units) {
        setIsError('invalidData');
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, activeCurrency]);

  useEffect(() => {
    if (preparedData) {
      setData(preparedData);
    }
  }, [preparedData]);

  const onChange = (value: string, index: number, type: string) => {
    if (!data) return;
    setTriggerImport(!triggerImport);
    setHasWarning(false);
    setIsError('');

    setData((prevData) => {
      const copyRow = [...prevData];
      copyRow[index] = {
        ...copyRow[index],
        [type]: value,
      };
      return copyRow;
    });
  };

  const onChangeAmount = (value: string, index: number, type: string) => {
    if (!data) return;
    setTriggerImport(!triggerImport);
    setHasWarning(false);
    setIsError('');

    setData((prevData) => {
      const copyRow = [...prevData];
      copyRow[index] = {
        ...copyRow[index],
        funds: {
          ...copyRow[index].funds,
          [activeCurrency as CurrenciesType]: {
            ...copyRow[index].funds[activeCurrency as CurrenciesType],
            [type]: handleNumberValue(value),
          },
        },
      };
      return copyRow;
    });
  };

  return (
    <>
      {activeCurrency && data.length > 0 && (
        <div className="table-wrapper">
          {data?.map((row, index) => (
            <div key={index} className="table-row">
              <div className="table-cell">
                <input
                  type="text"
                  maxLength={50}
                  value={row.name}
                  onChange={(e) => onChange(e.target.value, index, 'name')}
                />
              </div>
              <div className={data.length > 2 && data.length - index < 3 ? 'table-cell open-upwards' : 'table-cell'}>
                <SearchInputWithDropDown
                  onChange={(value: string) => {
                    onChange(value, index, 'units');
                  }}
                  placeholder=""
                  value={row.units}
                  data={GetUniqueUnitsFromPriceList(priceList)}
                  isImportModal={true}
                />
              </div>
              <div className="table-cell">
                <input
                  type="text"
                  inputMode="numeric"
                  value={row.funds[activeCurrency]?.total}
                  onChange={(e) => onChangeAmount(e.target.value, index, 'total')}
                />
              </div>
              <div className="table-cell">
                <input
                  type="text"
                  inputMode="numeric"
                  value={row.funds[activeCurrency]?.cost}
                  onChange={(e) => onChangeAmount(e.target.value, index, 'cost')}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default ImportTable;
