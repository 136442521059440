import React from 'react';
import { COLORS } from 'shared/constants';

type Props = {
  isActive: boolean;
};

export const LightThemeIcon: React.FC<Props> = ({ isActive }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M10.0001 3.04687L10 3M10 17V16.9805M16.9531 10L17 9.99999M3 10H3.01953M14.9166 14.9166L14.9498 14.9497M5.05027 5.05028L5.06408 5.06409M5.08343 14.9166L5.05031 14.9498M14.9498 5.05026L14.936 5.06407M6.00003 10C6.00003 12.2091 7.79089 14 10 14C12.2092 14 14 12.2091 14 10C14 7.79086 12.2092 6 10 6C7.79089 6 6.00003 7.79086 6.00003 10Z"
        stroke={isActive ? COLORS.ORANGE_BTN : COLORS.DISABLED_BTN_TEXT}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
