import React, { useState, useEffect, useRef, ChangeEvent } from 'react';
import { DropDown, Input } from 'shared/ui';
import { TriangleArrow } from 'shared/assets/icons';
import { COLORS, devices } from 'shared/constants';
import { ArrowContainer, DropDownContainer } from './styles';
import { useAppSelector, useMediaQuery } from 'shared/lib';

interface CustomInputProps {
  data: string[];
  placeholder: string;
  value: string;
  onChange: (event: string) => void;
  isImportModal?: boolean;
}
export const SearchInputWithDropDown: React.FC<CustomInputProps> = ({
  data,
  placeholder,
  value,
  onChange,
  isImportModal = false,
}) => {
  const isMobile = useMediaQuery(devices.mobile);
  const isTablet = useMediaQuery(devices.tablet);
  const theme = useAppSelector((state) => state.theme.theme);
  const [inputValue, setInputValue] = useState<string>(value);
  const [filteredData, setFilteredData] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!inputValue) {
      setFilteredData(data);
    } else {
      setFilteredData(
        data.filter((item) => !item.toString().toLowerCase().includes(inputValue.toString().toLowerCase())),
      );
    }
  }, [inputValue, data, isOpen]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    onChange(e.target.value);
    setIsOpen(true);
  };

  const handleOptionClick = (value: string) => {
    setInputValue(value);
    onChange(value);
    setIsOpen(false);
  };

  const handleOutsideClick = (e: MouseEvent) => {
    if (inputRef.current && !inputRef.current.contains(e.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const dropDownElements = filteredData.map((item) => {
    return { text: item, onClick: () => handleOptionClick(item) };
  });

  const mobOrTab = isMobile || isTablet;
  const isDarkTheme = theme === 'dark';
  return (
    <div ref={inputRef} style={{ position: 'relative' }}>
      <Input
        value={inputValue}
        onChange={handleInputChange}
        placeholder={placeholder}
        onClick={() => setIsOpen(true)}
      />
      <ArrowContainer onClick={() => setIsOpen(!isOpen)} className={isImportModal ? 'import' : ''}>
        <TriangleArrow
          size={mobOrTab && isImportModal ? 8 : 24}
          stroke={isDarkTheme ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK}
        />
      </ArrowContainer>
      {isOpen && (
        <DropDownContainer>
          <DropDown elements={[...dropDownElements]} />
        </DropDownContainer>
      )}
    </div>
  );
};
