import styled from '@emotion/styled';
import { COLORS } from 'shared/constants';

export const ModalButtonsWrapper = styled('div')(({ className }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '4px',
  '& button': {
    fontSize: '8px',
    padding: '3px 8px',
    lineHeight: '12px',
    width: '100%',
    height: '19px',
    borderRadius: '4px',
  },
  '@media (min-width: 767px)': {
    gap: '16px',
    '& button': {
      fontSize: '16px',
      padding: '8px 16px',
      lineHeight: '24px',
      height: '48px',
      borderRadius: '8px',
    },
  },
}));

export const ClearSearchButton = styled('button')(({ className }) => ({
  marginRight: 0,
  borderWidth: 0,
  backgroundColor: 'transparent',
  cursor: 'pointer',
}));

export const SearchIconContainer = styled('div')(({ className }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '48px',
  marginRight: '8px',
}));

export const ScrollableContainer = styled('div')(({ className }) => ({
  maxHeight: '500px',
  minHeight: '400px',
  maxWidth: '704px',
  overflow: 'auto',
  '& > div:not(:first-of-type)': {
    marginBottom: '0px',
  },
  marginBottom: '16px',
}));

export const SearchContainer = styled('div')(({ className }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  maxWidth: '640px',
  height: '24px',
  padding: '6px',
  margin: '14px auto 11px',
  border: `1px solid ${className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.BORDER_GREY}`,
  borderRadius: '8px',
  background: className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.TEXT_WHITE,
  '&:hover': {
    borderColor: COLORS.BODY_TEXT_BLACK_20,
  },
  '&:focus-within': {
    borderColor: COLORS.TEXT_GREY,
  },
  '& input': {
    borderColor: 'transparent!important',
    padding: '0px',
    fontSize: '8px',
    lineHeight: '12px',
    minHeight: '20px',
  },
  '@media (min-width: 767px)': {
    margin: '32px auto 24px',
    height: '48px',
    padding: '12px 16px 12px 12px',
    '& input': {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
}));
