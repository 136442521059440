export function formatPhoneNumber(input: string): string {
  // Remove all non-numeric characters from the input
  const phoneNumber = input.replace(/\D/g, '');

  // Set a maximum length for the phone number
  const maxLength = 16;

  const truncatedPhoneNumber = phoneNumber.substring(0, maxLength);

  // Extract the international country code (first 1-4 digits)
  const countryCode = truncatedPhoneNumber.substring(0, 3);

  // Extract the rest of the phone number (after the international code)
  const restOfNumber = truncatedPhoneNumber.substring(3);

  // Add spaces between groups of 2, 3, and the rest of the digits
  const formattedNumber = restOfNumber.replace(/(\d{2})(\d{3})(\d+)/, ' $1 $2 $3').trim();

  return `+${countryCode} ${formattedNumber}`;
}
