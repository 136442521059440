import styled from '@emotion/styled';
import { COLORS } from 'shared/constants';

export const ActionBarContainer = styled('div')(({ className }) => ({
  padding: className?.includes('mobile') ? '0px 12px' : 'unset',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  flexDirection: 'column',
  margin: '0 auto',
  '& button': {
    fontSize: className?.includes('mobile') ? '14px' : '16px',
    fontWeight: className?.includes('mobile') ? 500 : 400,
    lineHeight: '24px',
    cursor: 'pointer',
    padding: className?.includes('mobile') ? '10px 16px' : '8px 16px 8px 11px',
    gap: '8px',
    width: className?.includes('mobile') ? '156px' : '195px',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '& > div': {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: '8px',
    alignSelf: 'stretch',
    flexWrap: 'wrap',
  },
  '@media (min-width: 968px)': { flexDirection: 'row', '& > div': { gap: '16px', justifyContent: 'flex-start' } },
}));

export const DeleteActionBtn = styled('button')(({ className }) => ({
  border: `1px solid ${className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.BORDER_GREY}`,
  color: className?.includes('dark') ? COLORS.DARK_RED : COLORS.MAIN_RED,
  background: className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.TEXT_WHITE,
  transition: 'background 0.3s linear',
  flexShrink: 0,
  '&:active': {
    background: className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.BTN_BG_PINK,
  },
  '&:hover': {
    background: className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.BTN_BG_PINK,
  },
  '&:disabled': {
    background: COLORS.GREY,
    color: COLORS.DISABLED_BTN_TEXT,
    cursor: 'default',
    '& svg': {
      stroke: 'red',
    },
  },
}));

export const DropDownContainer = styled('div')(({ className }) => ({
  position: 'absolute',
  top: 42,
  left: 0,
  width: '203px',
  '& ul': {
    width: '100%',
  },
  '& li': {
    width: '100%',
    justifyContent: 'flex-start',
  },
}));

export const WhiteActionBtn = styled('button')(({ className }) => ({
  border: `1px solid ${className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.BORDER_GREY}`,
  position: 'relative',
  color: className?.includes('dark') ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK,
  background: className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.TEXT_WHITE,
  transition: 'background 0.2s linear',
  whiteSpace: 'nowrap',
  '&:active': {
    background: className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.GREY,
  },
  '&:hover': {
    background: className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.GREY,
  },
  '&:disabled': {
    background: className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.GREY,
    color: COLORS.DISABLED_BTN_TEXT,
    cursor: 'default',
  },
}));
