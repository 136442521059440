import React from 'react';

type Props = {
  stroke?: string;
};

export const PercentIcon: React.FC<Props> = ({ stroke }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M10.7999 8.76184C10.7999 10.5525 9.50156 12.0041 7.89996 12.0041C6.29836 12.0041 5 10.5525 5 8.76184C5 6.9712 6.29836 5.5196 7.89996 5.5196C9.50156 5.5196 10.7999 6.9712 10.7999 8.76184ZM6.01499 8.76184C6.01499 9.92575 6.85892 10.8693 7.89996 10.8693C8.941 10.8693 9.78493 9.92575 9.78493 8.76184C9.78493 7.59792 8.941 6.65438 7.89996 6.65438C6.85892 6.65438 6.01499 7.59792 6.01499 8.76184Z"
        fill={stroke}
      />
      <path
        d="M19 15.2422C19 17.0329 17.7016 18.4845 16.1 18.4845C14.4984 18.4845 13.2001 17.0329 13.2001 15.2422C13.2001 13.4516 14.4984 12 16.1 12C17.7016 12 19 13.4516 19 15.2422ZM14.2151 15.2422C14.2151 16.4062 15.059 17.3497 16.1 17.3497C17.1411 17.3497 17.985 16.4062 17.985 15.2422C17.985 14.0783 17.1411 13.1348 16.1 13.1348C15.059 13.1348 14.2151 14.0783 14.2151 15.2422Z"
        fill={stroke}
      />
      <path
        d="M8.03563 18.5C7.63142 18.5 7.38972 18.0502 7.61283 17.7131L15.5474 5.72594C15.6408 5.58485 15.7988 5.5 15.968 5.5C16.3699 5.5 16.6103 5.94723 16.3886 6.28247L8.45855 18.2727C8.36467 18.4146 8.20583 18.5 8.03563 18.5Z"
        fill={stroke}
      />
    </svg>
  );
};
