import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { COLORS } from 'shared/constants';

const loading = keyframes`
0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
`;

export const SkeletonProjectCardsContainer = styled('div')(({ className }) => ({
  marginTop: '32px',
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '18px',
}));

export const SkeletonProjectCard = styled('div')(({ className }) => ({
  width: '190px',
  height: '200px',
  background: className?.includes('sideBarLoader') ? COLORS.SIDEBAR_SKELETON_BG : COLORS.SKELETON_BG,
  backgroundSize: '200% 100%',
  animation: `${loading} 1.5s infinite`,
  borderRadius: '16px',
}));
