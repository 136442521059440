import styled from '@emotion/styled';
import { COLORS } from 'shared/constants';

export const AuthInputContainer = styled('div')(({ className }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  maxHeight: '56px',
  border: className?.includes('error') ? `1px solid ${COLORS.MAIN_RED}` : `1px solid ${COLORS.TEXT_WHITE}`,
  borderRadius: '8px',
  backgroundColor: COLORS.TEXT_WHITE,
  '&:hover': {
    borderColor: className?.includes('error') ? `1px solid ${COLORS.MAIN_RED}` : COLORS.BODY_TEXT_BLACK_20,
  },
  '&:focus-within': {
    borderColor: className?.includes('error') ? `1px solid ${COLORS.MAIN_RED}` : COLORS.BODY_TEXT_BLACK_20,
  },
}));

export const AuthInput = styled('input')(({ className }) => ({
  fontSize: '16px',
  width: '100%',
  height: '100%',
  padding: className?.includes('showLabel') || className?.includes('creatingPassword') ? '24px 16px 8px 16px' : '16px',
  lineHeight: '24px',
  color: COLORS.TEXT_BLACK,
  border: 'none',
  outline: 'none',
  backgroundColor: 'transparent',
}));

export const PasswordToggle = styled('button')(({ className }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '48px',
  marginRight: '12px',
  borderWidth: 0,
  backgroundColor: COLORS.TEXT_WHITE,
}));

export const StyledLabel = styled('label')(({ className }) => ({
  fontFamily: '"Golos", sans-serif',
  display: className?.includes('showLabel') || className?.includes('creatingPassword') ? 'block' : 'none',
  position: 'absolute',
  color: COLORS.ACTION_BAR_BLACK_BG,
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '16px',
  top: '6px',
  left: '16px',
  '@media (min-width: 1024px)': {
    fontSize: '12px',
    lineHeight: '16px',
    top: '8px',
    left: '16px',
  },
}));
