import styled from '@emotion/styled';

export const PositionRowDropDownDotsContainer = styled('div')(({ className }) => ({
  position: 'absolute',
  zIndex: 1,
  top: 25,
  right: 0,
  '@media (min-width: 1100px)': {
    top: 50,
    right: 'unset',
  },
}));

export const PositionRowDropDownArticleContainer = styled('div')(({ className }) => ({
  position: 'absolute',
  zIndex: 1,
  top: 25,
  left: 0,
  '@media (min-width: 1100px)': {
    top: 40,
    left: 'unset',
  },
}));
