import { createSlice } from '@reduxjs/toolkit';
import { CustomerInfo, Package } from '@revenuecat/purchases-js';

interface IState {
  packages: Package[] | null;
  customerInfo: CustomerInfo | null;
}

const initialState: IState = {
  packages: null,
  customerInfo: null,
};

const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    setPackages(state, { payload }) {
      state.packages = payload;
    },
    setCustomerInfo(state, { payload }) {
      state.customerInfo = payload;
    },
  },
  extraReducers: (builder) => builder,
});

export const { setPackages, setCustomerInfo } = subscriptionsSlice.actions;
export const subscriptionsReducer = subscriptionsSlice.reducer;
