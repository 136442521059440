export const defaultValues: AddCustomerValues = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
};

export type AddCustomerValues = {
  firstName: string;
  lastName: string;
  phoneNumber?: string;
  email: string;
};
