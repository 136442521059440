import React from 'react';
import { StyledTransparentButton } from './styles';

interface Props {
  onClick: () => void;
  disabled?: boolean;
  children: JSX.Element;
}

export const TransparentButton: React.FC<Props> = ({ children, onClick, disabled }) => {
  return (
    <StyledTransparentButton onClick={onClick} disabled={disabled}>
      {children}
    </StyledTransparentButton>
  );
};
