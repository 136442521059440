import { ThemeType } from 'entities/theme';

/**
 * @description Class containing methods to work with localStorage
 */
export class LocalStorage {
  static getActiveLanguage() {
    return localStorage.getItem('activeLanguage');
  }
  static setActiveLanguage(lang: string) {
    localStorage.setItem('activeLanguage', lang);
  }

  static getTheme() {
    return localStorage.getItem('theme');
  }
  static setTheme(theme: ThemeType) {
    localStorage.setItem('theme', theme);
  }

  static getSelectedCompanyId() {
    return localStorage.getItem('selectedCompanyId');
  }
  static setSelectedCompanyId(id: number) {
    localStorage.setItem('selectedCompanyId', id.toString());
  }
}
