import styled from '@emotion/styled';

export const ButtonsWrapper = styled('div')(({ className }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
  '& button': {
    fontSize: '16px',
    padding: className?.includes('mobile') ? '3px 8px' : '8px 16px',
    lineHeight: '24px',
    height: '48px',
    width: '100%',
  },
  padding: '0px 14px',
  '@media (min-width: 360px)': {
    padding: '0 32px',
  },
  '@media (min-width: 768px)': {
    '& button': {
      width: '136px',
    },
  },
}));

export const InputWrapper = styled('div')(({ className }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '24px',
  '& input': {
    height: '56px',
  },
}));

export const AvatarContainer = styled('div')(({ className }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  '& > div': {
    position: 'relative',
    width: '64px',
    height: '64px',
  },
}));

export const AddPhotoWrapper = styled('div')(({ className }) => ({
  cursor: 'pointer',
  position: 'absolute',
  top: '-2px',
  right: '-2px',
}));
