export function isLessThan30days(dateString: string | null): boolean {
  if (dateString === null) return false;
  const date = new Date(dateString);
  const currentDate = new Date();
  const millisecondsInTwoWeeks = 30 * 24 * 60 * 60 * 1000;

  const difference = currentDate.getTime() - date.getTime();

  return difference < millisecondsInTwoWeeks;
}
