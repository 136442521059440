import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { addCheckedPositions, removeCheckedPositions } from 'entities/estimatesFilter';
import { PriceItemType, createTemporaryPosition, editEstimatePriceListPosition } from 'entities/estimates';
import { getMarkupByArticle } from 'widgets/EstimatePositionsTable/model';

import { MaterialsIcon, WorkIcon, HorizontalDivider } from 'shared/assets/icons';
import { Checkbox } from 'shared/ui';
import { useHover, useAppSelector, useAppDispatch, getCategoryById, handleNumberValue } from 'shared/lib';
import { text } from 'shared/constants';
import { editPosition, fundsType } from 'entities/priceList';
import EditableCell from '../EditableCell/ui/EditableCell';

import { PositionRowDropDownArticleContainer, TableRowContainer } from '../styles';
import {
  ButtonTableCellContainer,
  DropdownBody,
  DropdownElement,
  MainTableCellContainer,
  RowButtonWrapper,
  TableCell,
} from '../../styles';

interface Props {
  position: PriceItemType;
  positionCategoryId?: number;
  isPossibleToEdit: boolean;
}
//TODO: refactoring

const EstimatePosPositionTableRow: React.FC<Props> = ({ position, isPossibleToEdit, positionCategoryId }) => {
  const language = useAppSelector((state) => state.language.language);
  const categories = useAppSelector((state) => state.categories.categories);
  const tempMarkups = useAppSelector((state) => state.projects.tempProjectMarkup);
  const projects = useAppSelector((state) => state.projects.projects);
  const checkedPositionsIds = useAppSelector((state) => state.estimatesFilter.checkedPositionsIds);
  const theme = useAppSelector((state) => state.theme.theme);
  const dispatch = useAppDispatch();
  const selectedCompany = useAppSelector((state) => state.company.selectedCompany);
  const companyId = selectedCompany?.id;

  const { projectId } = useParams();
  const projectCurrency = projects?.find((proj) => proj.id.toString() === projectId)?.currency;

  const total = handleNumberValue(position.funds?.total.toString());
  const cost = handleNumberValue(position.funds?.cost?.toString() || total);
  const generalTotal = Number(total) * position.quantity;
  const generalCost = Number(cost) * position.quantity;

  const positionRow = useRef<HTMLTableRowElement | null>(null);
  const positionRowHovered = useHover(positionRow.current);

  const [isOpenedArticleDropDown, setIsOpenedArticleDropDown] = useState<boolean>(false);
  const [checked, setChecked] = useState(false);
  const [markup, setMarkup] = useState(0);

  const isPositionInCategory =
    categories && positionCategoryId && getCategoryById(positionCategoryId, categories)?.primary === false;

  function toggleCheckbox() {
    if (checked) {
      dispatch(removeCheckedPositions([position.id]));
    } else {
      dispatch(addCheckedPositions([position.id]));
    }
    setChecked(!checked);
  }

  useEffect(() => {
    if (checkedPositionsIds.includes(position.id)) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [checkedPositionsIds, position.id]);

  useEffect(() => {
    if (!positionRowHovered) {
      setIsOpenedArticleDropDown(false);
    }
  }, [positionRowHovered]);

  useEffect(() => {
    if (!tempMarkups) return;
    const currentMarkup = getMarkupByArticle(position, tempMarkups);
    setMarkup(currentMarkup);
  }, [tempMarkups, position]);

  function OnChangeArticleClick(article: number) {
    if (article === position.article || !companyId || !projectCurrency) return;

    if (position.isTemporary) {
      dispatch(
        editPosition({
          companyID: companyId,
          articleID: article,
          name: position.name,
          unit: position.units,
          funds: {
            [projectCurrency as string]: { cost: Number(position.funds.cost), total: Number(position.funds.total) },
          } as Record<'UAH' | 'USD' | 'EUR' | 'GBP', fundsType>,
          itemID: position.id,
          estimatePosition: true,
          isHidden: false,
        }),
      );
    } else {
      let category = position.category?.id;
      const categoryWithoutCategory = categories?.find((cat) => cat.primary === true);
      const itemCategoryID = categoryWithoutCategory?.id;
      if (!category) category = itemCategoryID;
      if (!category) return;
      dispatch(
        createTemporaryPosition({
          data: [
            {
              article: article,
              category,
              name: position.name,
              units: position.units,
              isTemporary: true,
              funds: {
                [projectCurrency as string]: { cost: Number(position.funds.cost), total: Number(position.funds.total) },
              },
            },
          ],
          currency: projectCurrency,
          company: companyId,
          prevPositionId: position.id,
        }),
      );
    }

    dispatch(editEstimatePriceListPosition({ ...position, article }));
  }

  function onChangeInputField(value: string, field: 'quantity' | 'cost' | 'total') {
    const numericValue = handleNumberValue(value) || '0';
    switch (field) {
      case 'quantity':
        dispatch(editEstimatePriceListPosition({ ...position, quantity: numericValue }));
        break;
      case 'total':
        dispatch(editEstimatePriceListPosition({ ...position, funds: { ...position.funds, total: numericValue } }));
        break;
      case 'cost':
        dispatch(editEstimatePriceListPosition({ ...position, funds: { ...position.funds, cost: numericValue } }));
        break;
      default:
        break;
    }
  }

  const onBlurInputField = (value: string, field: 'quantity' | 'cost' | 'total') => {
    const numericValue = Number(handleNumberValue(value));
    if (!companyId || !projectCurrency) return;
    let category = position.category?.id;
    const categoryWithoutCategory = categories?.find((cat) => cat.primary === true);
    const itemCategoryID = categoryWithoutCategory?.id;
    if (!category) category = itemCategoryID;

    switch (field) {
      case 'total':
        if (position.isTemporary) {
          dispatch(
            editPosition({
              companyID: companyId,
              articleID: position.article,
              name: position.name,
              unit: position.units,
              funds: {
                [projectCurrency]: { total: numericValue, cost: Number(position.funds.cost) },
              } as Record<'UAH' | 'USD' | 'EUR' | 'GBP', fundsType>,
              itemID: position.id,
              isHidden: false,
              estimatePosition: true,
            }),
          );
        } else {
          if (!category) return;
          dispatch(
            createTemporaryPosition({
              data: [
                {
                  article: position.article,
                  category,
                  name: position.name,
                  units: position.units,
                  isTemporary: true,
                  funds: { [projectCurrency]: { total: numericValue, cost: Number(position.funds.cost) } },
                },
              ],
              currency: projectCurrency,
              company: companyId,
              prevPositionId: position.id,
            }),
          );
        }
        break;
      case 'cost':
        if (position.isTemporary) {
          dispatch(
            editPosition({
              companyID: companyId,
              articleID: position.article,
              name: position.name,
              unit: position.units,
              funds: {
                [projectCurrency]: { cost: numericValue, total: Number(position.funds.total) },
              } as Record<'UAH' | 'USD' | 'EUR' | 'GBP', fundsType>,
              itemID: position.id,
              isHidden: false,
              estimatePosition: true,
            }),
          );
        } else {
          if (!category) return;
          dispatch(
            createTemporaryPosition({
              data: [
                {
                  article: position.article,
                  category,
                  name: position.name,
                  units: position.units,
                  isTemporary: true,
                  funds: {
                    [projectCurrency]: { cost: numericValue, total: Number(position.funds.total) },
                  } as Record<'UAH' | 'USD' | 'EUR' | 'GBP', fundsType>,
                },
              ],
              currency: projectCurrency,
              company: companyId,
              prevPositionId: position.id,
            }),
          );
        }
        break;
      default:
        break;
    }
  };

  const isDarkTheme = theme === 'dark';
  return (
    <TableRowContainer className={isDarkTheme ? 'dark' : ''} ref={positionRow}>
      {isPossibleToEdit && (
        <ButtonTableCellContainer>
          <div style={{ position: 'relative', paddingTop: '3px' }}>
            <Checkbox toggleCheckbox={toggleCheckbox} checked={checked} />
          </div>
        </ButtonTableCellContainer>
      )}
      <ButtonTableCellContainer>
        <RowButtonWrapper onClick={() => isPossibleToEdit && setIsOpenedArticleDropDown(!isOpenedArticleDropDown)}>
          {position.article === 1 ? <MaterialsIcon id="CostItem" /> : <WorkIcon id="CostItem" language={language} />}
          {isOpenedArticleDropDown && (
            <PositionRowDropDownArticleContainer>
              <DropdownBody className={isDarkTheme ? 'dark' : ''}>
                <DropdownElement
                  className={isDarkTheme ? 'dark without-flashing-svg' : 'without-flashing-svg'}
                  onClick={() => OnChangeArticleClick(1)}
                >
                  <MaterialsIcon />
                  <span>{text[language].Materials}</span>
                </DropdownElement>
                <DropdownElement
                  className={isDarkTheme ? 'dark without-flashing-svg' : 'without-flashing-svg'}
                  onClick={() => OnChangeArticleClick(2)}
                >
                  <WorkIcon language={language} />
                  <span>{text[language].Work}</span>
                </DropdownElement>
              </DropdownBody>
            </PositionRowDropDownArticleContainer>
          )}
        </RowButtonWrapper>
      </ButtonTableCellContainer>
      <MainTableCellContainer>
        {isPositionInCategory && <HorizontalDivider />}
        <span>{position.name}</span>
      </MainTableCellContainer>
      <EditableCell
        isPossibleToEdit={isPossibleToEdit}
        onChangeInputField={onChangeInputField}
        width={'80px'}
        value={position.quantity}
        type="quantity"
        className={!isPossibleToEdit || position.quantity !== 1 ? '' : 'position-count'}
      />
      <TableCell style={{ width: '130px' }}>{position.units}</TableCell>
      <EditableCell
        onChangeInputField={onChangeInputField}
        onBlurInputField={onBlurInputField}
        width={'100px'}
        value={total}
        type="total"
        isPossibleToEdit={isPossibleToEdit}
      />
      <EditableCell
        onChangeInputField={onChangeInputField}
        onBlurInputField={onBlurInputField}
        width={'100px'}
        value={cost}
        type="cost"
        isPossibleToEdit={isPossibleToEdit}
      />
      <TableCell style={{ width: '100px' }}>{generalTotal.toFixed(2)}</TableCell>
      <TableCell style={{ width: '100px' }}>{generalCost.toFixed(2)}</TableCell>
      <TableCell style={{ width: '70px' }}>{markup.toFixed(2)}</TableCell>
      <TableCell style={{ width: '120px' }}>{(generalTotal + generalTotal * (markup / 100)).toFixed(2)}</TableCell>
    </TableRowContainer>
  );
};

export default EstimatePosPositionTableRow;
