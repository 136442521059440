import styled from '@emotion/styled';
import { COLORS } from 'shared/constants';

export const EstimateAmountContainer = styled('div')(({ className }) => ({
  borderRadius: '16px',
  border: `1px solid ${className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.BORDER_GREY}`,
  background: className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.TEXT_WHITE,
  padding: '24px',
  flex: 1,
  display: 'flex',
  gap: '16px',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '310px',
  height: 'auto',
  boxShadow: className?.includes('dark') ? 'unset' : COLORS.TABLE_DROPDOWN_BOX_SHADOW,
}));

export const AmountTitleWrapper = styled('div')(({ className }) => ({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
  justifyContent: 'flex-start',
}));

export const AmountRow = styled('div')(({ className }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '& > span:first-of-type': {
    color: className?.includes('dark') ? COLORS.BODY_TEXT_BLACK_20 : COLORS.ACTION_BAR_BLACK_BG,
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
  },
  '& > span:last-of-type': {
    color: className?.includes('dark') ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK,
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
  },
}));

export const AmountGroup = styled('div')(({ className }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
}));

export const TotalAmountRowHeader = styled('div')(({ className }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '26px',
  marginBottom: '8px',
  color: className?.includes('dark') ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK,
  '& > span': {
    width: '168px',
  },
  '& > div': {
    width: '116px',
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
}));

export const TotalAmountRow = styled('div')(({ className }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '& > span': {
    color: className?.includes('dark') ? COLORS.BODY_TEXT_BLACK_20 : COLORS.ACTION_BAR_BLACK_BG,
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    width: className?.includes('unsetWidth') ? 'unset' : '116px',
    textAlign: className?.includes('unsetWidth') ? 'right' : 'left',
  },
  '& > span:first-of-type': {
    width: '168px',
    fontSize: '12px',
    lineHeight: '16px',
    textAlign: 'left',
  },
  '& > span:not(:first-of-type)': {
    textWrap: 'nowrap',
  },
  '& > span.colored': {
    color: className?.includes('dark') ? COLORS.DARK_THEME_GREEN : COLORS.MAIN_GREEN,
    width: 'unset',
    fontSize: '14px',
    fontWeight: 500,
  },
  '& > span.total': {
    fontWeight: 500,
    fontSize: '14px',
  },
}));

export const CardTitle = styled('p')(({ className }) => ({
  color: className?.includes('dark') ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK,
  fontSize: '18px',
  fontWeight: 500,
  lineHeight: '26px',
}));

export const EstimateSumHeader = styled('div')(({ className }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '& > p': {
    margin: '16px 0 6px',
  },
  '& > span': {
    background: COLORS.ORANGE_TEXT_HOVERED,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '30px',
  },
}));

export const EstimateContainer = styled('div')(({ className }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  justifyContent: 'center',
}));
