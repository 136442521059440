import { DeleteItemsModalType } from 'widgets';
import { text } from 'shared/constants';

export function deleteItemsModalText(type: DeleteItemsModalType, language: string, isEstimatePage: boolean) {
  if (isEstimatePage && type?.countEstimate === 'multiple') {
    return text[language].AreYouSureToDeleteItemsFromEstimate;
  } else if (isEstimatePage && type?.countEstimate === 'single') {
    return text[language].AreYouSureToDeleteItemFromEstimate;
  }
  if (type.type === 'category' || type.type === 'multipleDeleting') {
    if (type?.count === 'single' && type.currency === 'single') {
      return text[language].AreYouSureToDeleteSingleCategorySingleCurrency;
    }
    if (type?.count === 'multiple' && type.currency === 'single') {
      return text[language].AreYouSureToDeleteMultipleCategorySingleCurrency;
    }
    if (type?.count === 'single' && type.currency === 'multiple') {
      return text[language].AreYouSureToDeleteSingleCategoryMultipleCurrencies;
    }
    if (type?.count === 'multiple' && type.currency === 'multiple') {
      return text[language].AreYouSureToDeleteMultipleCategoryMultipleCurrencies;
    }
  }
  if (type.type === 'position') {
    if (type?.count === 'single' && type.currency === 'single') {
      return text[language].AreYouSureToDeleteItem;
    }
    if (type?.count === 'multiple' && type.currency === 'single') {
      return text[language].AreYouSureToDeleteItems;
    }
    if (type?.count === 'single' && type.currency === 'multiple') {
      return text[language].AreYouSureToDeleteItemWithCurrencies;
    }
    if (type?.count === 'multiple' && type.currency === 'multiple') {
      return text[language].AreYouSureToDeleteItemsWithCurrencies;
    }
  }
}
