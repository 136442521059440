import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const languageSlice = createSlice({
  name: 'language',
  initialState: {
    language: 'UA',
  },
  reducers: {
    setLanguage(state, value: PayloadAction<string>) {
      state.language = value.payload;
    },
  },
});

export const { setLanguage } = languageSlice.actions;
export const languageReducer = languageSlice.reducer;
