import styled from '@emotion/styled';
import { COLORS } from 'shared/constants';

export const EstimatePriceListContainer = styled('div')(({ className }) => ({
  flexDirection: 'column',
  display: 'flex',
  gap: '24px',
  '& > p': {
    color: className?.includes('dark') ? COLORS.BODY_TEXT_BLACK_20 : COLORS.ACTION_BAR_BLACK_BG,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
  },
}));

export const TableContainer = styled('div')(({ className }) => ({
  overflowY: 'clip',
  overflowX: 'auto',
  minHeight: '260px', // check table height and drop down
  background: className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.TEXT_WHITE,
  boxShadow: className?.includes('dark') ? 'unset' : COLORS.TABLE_DROPDOWN_BOX_SHADOW,
  marginTop: '24px',
  '@media (min-width: 1280px)': {
    overflow: 'visible',
    minHeight: 'unset',
    background: 'unset',
  },
}));

export const EstimateButtonsBar = styled('div')(({ className }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '8px',
  '& button': {
    width: '100%',
    maxWidth: '350px',
    minWidth: '160px',
    padding: '8px 0px',
  },
  '& p': {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 500,
  },
  '& > div': {
    maxWidth: '350px',
    width: '100%',
    display: 'flex',
    gap: '8px',
  },
  '@media (min-width: 768px)': {
    gap: '16px',
    flexWrap: 'nowrap',
    '& button': {
      maxWidth: '280px',
      padding: '8px 16px 8px 12px',
    },
    '& p': {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 400,
    },
    '& > div': {
      maxWidth: '570px',
      gap: '16px',
    },
  },
}));
