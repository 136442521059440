import React from 'react';
import { COLORS } from 'shared/constants';

type Props = {
  width?: number;
  height?: number;
  isDarkTheme: boolean;
};

export const CheckboxUnChecked: React.FC<Props> = ({ width = 24, height = 24, isDarkTheme }) => {
  const stroke = () => {
    if (isDarkTheme) return COLORS.DIVIDER_DARK;
    return COLORS.BODY_TEXT_BLACK_20;
  };
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2.5" y="2.5" width="19" height="19" rx="3.5" fill="transparent" stroke={stroke()} />
    </svg>
  );
};
