import { createSlice } from '@reduxjs/toolkit';
import { LoadingResultsT } from '../../loadingType';
import { createTemporaryPosition, fetchEstimates, createEstimate, editEstimate, deleteEstimate } from '../api/thunks';
import { EstimateType, PriceItemType } from './types';
import { parsePriceListToPriceItems, sortEstimatesArrayByDate } from './helpers';
import { ICategory } from 'entities/category';
import { IPriceList } from 'entities/priceList';
import { MarkupType, ArticleType } from 'entities/project';

interface IState {
  estimates: EstimateType[] | null;
  estimatePriceList: PriceItemType[] | null;
  estimatePriceListCategories: ICategory[] | null;
  loadingEstimates: LoadingResultsT;
  loadingEditingEstimate: LoadingResultsT;
  loadingCreatingEstimate: LoadingResultsT;
  loadingDeletingEstimate: LoadingResultsT;
  loadingCreatingTempPosition: LoadingResultsT;
  error: string | null;
  estimatesLength: number | null;
}

const initialState: IState = {
  estimates: null,
  estimatePriceList: null,
  estimatePriceListCategories: null,
  loadingEstimates: LoadingResultsT.IDLE,
  loadingEditingEstimate: LoadingResultsT.IDLE,
  loadingCreatingEstimate: LoadingResultsT.IDLE,
  loadingDeletingEstimate: LoadingResultsT.IDLE,
  loadingCreatingTempPosition: LoadingResultsT.IDLE,
  error: null,
  estimatesLength: null,
};

const estimatesSlice = createSlice({
  name: 'estimates',
  initialState,
  reducers: {
    resetEstimatesLoading: (state) => {
      state.loadingEstimates = LoadingResultsT.IDLE;
      state.loadingEditingEstimate = LoadingResultsT.IDLE;
      state.loadingCreatingEstimate = LoadingResultsT.IDLE;
      state.loadingCreatingTempPosition = LoadingResultsT.IDLE;
    },
    resetEstimateEditingLoading: (state) => {
      state.loadingEditingEstimate = LoadingResultsT.IDLE;
    },
    setEstimatesPriceList: (state, { payload }) => {
      const uniquePriceList = payload.reduce((acc: PriceItemType[], currentItem: PriceItemType) => {
        const existingItem = acc.find((item) => item.id === currentItem.id);
        if (!existingItem) {
          acc.push(currentItem);
        }
        return acc;
      }, []);

      state.estimatePriceList = uniquePriceList;
    },
    addPositionToEstimatePriceList: (
      state,
      { payload }: { payload: { data: IPriceList[]; projectCurrency: string } },
    ) => {
      const newPosition = parsePriceListToPriceItems(payload.data, payload.projectCurrency);
      state.estimatePriceList = [...newPosition, ...(state.estimatePriceList || [])];
    },
    editEstimatePriceListPosition: (state, { payload }) => {
      if (!state.estimatePriceList) return;
      const indexToReplace = state.estimatePriceList.findIndex((item) => item.id === payload.id);

      if (indexToReplace !== -1) {
        state.estimatePriceList[indexToReplace] = payload;
      }
    },
    groupEditEstimatePriceListPositionArticles: (state, { payload }) => {
      const idsToChange = payload.ids;
      const article = payload.article;
      if (!state.estimatePriceList) return;
      state.estimatePriceList = state.estimatePriceList.map((position) => {
        if (idsToChange.includes(position.id)) {
          return { ...position, article };
        }
        return position;
      });
    },
    setEstimatesPriceListCategories: (state, { payload }) => {
      const uniqueCategories = Array.from(new Set(payload.map((category: ICategory) => category.id))).map((id) =>
        payload.find((category: ICategory) => category.id === id),
      );

      state.estimatePriceListCategories = uniqueCategories;
    },
    resetEstimatesPriceList: (state) => {
      state.estimatePriceList = null;
      state.estimatePriceListCategories = null;
    },
    removeEstimatePositionsById: (state, { payload }) => {
      const filteredPositions = state.estimatePriceList?.filter((position) => !payload.includes(position.id));

      if (filteredPositions) {
        state.estimatePriceList = [...filteredPositions];
      }
    },
    removeEstimateCategories: (state, { payload }) => {
      const filteredCategories = state.estimatePriceListCategories?.filter(
        (category) => !payload.includes(category.id),
      );
      if (filteredCategories) {
        state.estimatePriceListCategories = [...filteredCategories];
      }
    },
    editEstimateMarkupId: (state, { payload }: { payload: { estimateId?: number; items: MarkupType[] } }) => {
      const { estimateId, items } = payload;
      if (!state.estimates) return;

      const workArticle = items.find((item) => item.name === ArticleType.workArticle);
      const materialsArticle = items.find((item) => item.name === ArticleType.materialsArticle);

      const estimateIndex = state.estimates.findIndex((estimate) => estimate.id === estimateId);

      if (estimateIndex === -1) return;
      const updatedEstimate = { ...state.estimates[estimateIndex] };
      if (workArticle) {
        updatedEstimate[ArticleType.workArticle] = workArticle.id;
      }
      if (materialsArticle) {
        updatedEstimate[ArticleType.materialsArticle] = materialsArticle.id;
      }
      const updatedEstimates = [...state.estimates];
      updatedEstimates[estimateIndex] = updatedEstimate;
      state.estimates = updatedEstimates;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchEstimates.pending, (state) => {
        state.loadingEstimates = LoadingResultsT.PENDING;
      })
      .addCase(fetchEstimates.fulfilled, (state, { payload }) => {
        state.loadingEstimates = LoadingResultsT.SUCCEEDED;
        state.estimatesLength = payload.length as number;

        state.estimates = sortEstimatesArrayByDate(payload);
        state.error = null;
      })
      .addCase(fetchEstimates.rejected, (state, { error }) => {
        state.loadingEstimates = LoadingResultsT.FAILED;
        state.error = error.message as string;
      })

      .addCase(createEstimate.pending, (state) => {
        state.loadingCreatingEstimate = LoadingResultsT.PENDING;
      })
      .addCase(createEstimate.fulfilled, (state, { payload }) => {
        state.loadingCreatingEstimate = LoadingResultsT.SUCCEEDED;

        state.estimates = [payload, ...(state.estimates || [])];
        state.error = null;
      })
      .addCase(createEstimate.rejected, (state, { error }) => {
        state.loadingCreatingEstimate = LoadingResultsT.FAILED;
        state.error = error.message as string;
      })

      .addCase(deleteEstimate.pending, (state) => {
        state.loadingDeletingEstimate = LoadingResultsT.PENDING;
      })
      .addCase(deleteEstimate.fulfilled, (state, { payload }) => {
        state.loadingDeletingEstimate = LoadingResultsT.SUCCEEDED;

        state.error = null;
      })
      .addCase(deleteEstimate.rejected, (state, { error }) => {
        state.loadingDeletingEstimate = LoadingResultsT.FAILED;
        state.error = error.message as string;
      })

      .addCase(editEstimate.pending, (state) => {
        state.loadingEditingEstimate = LoadingResultsT.PENDING;
      })
      .addCase(editEstimate.fulfilled, (state, { payload }) => {
        state.loadingEditingEstimate = LoadingResultsT.SUCCEEDED;

        const estimateIndex = state.estimates?.findIndex((estimate) => estimate.id === payload.id); // Find the index of the estimate to update by its ID

        if (!state.estimates || typeof estimateIndex === 'undefined' || estimateIndex === -1) return;
        const updatedEstimates = [...state.estimates]; // Create a copy of the estimates state to update
        updatedEstimates.splice(estimateIndex, 1, payload);

        state.estimates = updatedEstimates;
        state.error = null;
      })
      .addCase(editEstimate.rejected, (state, { error }) => {
        state.loadingEditingEstimate = LoadingResultsT.FAILED;
        state.error = error.message as string;
      })

      .addCase(createTemporaryPosition.pending, (state) => {
        state.loadingCreatingTempPosition = LoadingResultsT.PENDING;
      })
      .addCase(createTemporaryPosition.fulfilled, (state, { payload }) => {
        state.loadingCreatingTempPosition = LoadingResultsT.SUCCEEDED;
        const positions = parsePriceListToPriceItems(payload.data, payload.currency);
        const copiedEstimatePriceList = state.estimatePriceList || [];
        if (payload.prevPositionId) {
          const indexToReplace = copiedEstimatePriceList.findIndex((item) => item.id === payload.prevPositionId);

          if (indexToReplace !== -1) {
            copiedEstimatePriceList[indexToReplace].id = positions[0].id;
            copiedEstimatePriceList[indexToReplace].isTemporary = positions[0].isTemporary;
          }
          state.estimatePriceList = [...copiedEstimatePriceList];
        } else {
          state.estimatePriceList = [...positions, ...copiedEstimatePriceList];
        }
        state.error = null;
      })
      .addCase(createTemporaryPosition.rejected, (state, { error }) => {
        state.loadingCreatingTempPosition = LoadingResultsT.FAILED;
        state.error = error.message as string;
      }),
});

export const {
  resetEstimatesLoading,
  setEstimatesPriceList,
  resetEstimatesPriceList,
  resetEstimateEditingLoading,
  setEstimatesPriceListCategories,
  removeEstimateCategories,
  removeEstimatePositionsById,
  editEstimatePriceListPosition,
  addPositionToEstimatePriceList,
  groupEditEstimatePriceListPositionArticles,
  editEstimateMarkupId,
} = estimatesSlice.actions;
export const estimatesReducer = estimatesSlice.reducer;
