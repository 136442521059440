import { CustomerInfo } from '@revenuecat/purchases-js';
import { AccountInfoType } from 'entities/account';
import { isLessThan30days } from './isLessThan30days';
import { checkIsExpiredDate } from './checkIsExpiredDate';
import { add30Days } from './add30days';

export const checkUserSubscription = (accountInfo: AccountInfoType, customerInfo: CustomerInfo) => {
  try {
    // Check if the user has any active subscriptions
    if (customerInfo.activeSubscriptions.size > 0) {
      return true;
    }

    // Check if the web subscription is still active
    if (accountInfo?.subscriptionEndDate && !checkIsExpiredDate(accountInfo.subscriptionEndDate)) {
      //TODO: remove accountInfo.subscriptionEndDate 1.3
      return true;
    }

    // Check if the trial period is still active
    return isLessThan30days(accountInfo.trialSubscriptionStartDate);
  } catch (error) {
    console.error('Error checking user subscription:', error);
    return false;
  }
};

export const getSubscriptionEndsDate = (accountInfo: AccountInfoType, customerInfo: CustomerInfo) => {
  const trialEndDate = new Date(add30Days(new Date(accountInfo.trialSubscriptionStartDate)));
  const webSubscriptionEndDate = accountInfo.subscriptionEndDate && new Date(accountInfo.subscriptionEndDate);
  const revenueCatSubscriptionEndDate = getLatestDateFromObj(customerInfo.allExpirationDatesByProduct);

  const dates = [trialEndDate, webSubscriptionEndDate, revenueCatSubscriptionEndDate].filter(
    (date): date is Date => date !== null,
  );

  if (dates.length === 0) {
    return null;
  }

  const latestDate = new Date(Math.max(...dates.map((date) => date.getTime())));

  return latestDate;
};

const getLatestDateFromObj = (obj: { [key: string]: Date | null }): Date | null => {
  let latestDate: Date | null = null;

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const date = obj[key];

      if (date !== null && (!latestDate || date > latestDate)) {
        latestDate = date;
      }
    }
  }

  return latestDate;
};
