import React from 'react';

type Props = {
  size?: number;
};

export const NoAvatar: React.FC<Props> = ({ size = 34 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 34 34" fill="none">
      <circle cx="17" cy="17" r="17" fill="url(#paint0_linear_2387_33691)" />
      <path
        d="M10 25V22.7143C10 20.8207 11.567 19.2857 13.5 19.2857H20.5C22.433 19.2857 24 20.8207 24 22.7143V25M17 9C15.2172 9 13.5 10.3604 13.5 12.4286C13.5 14.1749 14.8887 15.8571 17 15.8571C19.1113 15.8571 20.5 14.1749 20.5 12.4286C20.5 10.3604 18.7828 9 17 9Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2387_33691"
          x1="1.06175e-06"
          y1="34"
          x2="34"
          y2="1.06175e-06"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8C21" />
          <stop offset="0.880208" stopColor="#FFD200" />
        </linearGradient>
      </defs>
    </svg>
  );
};
