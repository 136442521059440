import React from 'react';
import { text, devices } from 'shared/constants';
import { useMediaQuery, useAppSelector } from 'shared/lib';
import { Error404 } from 'shared/assets/icons';
import { Page404Container } from './styles';

export const Page404Modal: React.FC = () => {
  const language = useAppSelector((state) => state.language.language);
  const isMobile = useMediaQuery(devices.mobile);

  return (
    <Page404Container>
      <Error404 isMobile={isMobile} />
      <p>{text[language].Oops}</p>
      <p>{text[language].ThereIsNoPAge}</p>
    </Page404Container>
  );
};
