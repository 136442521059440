import React, { useEffect, useRef, useState } from 'react';
import { DraggableProvided } from '@hello-pangea/dnd';
import { AddCategoryModal, DeleteItemsModal, ExportPDFModal, EditCategoryModal } from 'widgets';
import { ICategory } from 'entities/category';
import {
  addCheckedCategories,
  addCheckedPositions,
  removeCheckedCategories,
  removeCheckedPositions,
} from 'entities/positionsFilter';

import { ToggleAccordion } from 'shared/ui';
import { Checkbox } from 'shared/ui';
import { useHover, useMediaQuery, useAppSelector, useAppDispatch, getArrayOfIds } from 'shared/lib';
import { Dots, DragIcon, GradientRoundPlus, Edit, Plus, Trash, Upload } from 'shared/assets/icons';
import { text, devices, COLORS } from 'shared/constants';
import { CategoryCount, CategoryDropDownContainer, CategoryName } from './styles';
import {
  AddCategoryButtonWrapper,
  TableRowContainer,
  DropdownBody,
  DropdownElement,
  RowButtonWrapper,
  ButtonTableCellContainer,
  MainTableCellContainer,
  SecondaryTableCellContainer,
  PriceTableCellContainer,
} from '../styles';

interface Props {
  categoryData: ICategory;
  positionsLength?: number;
  provided: DraggableProvided;
  isOpened: boolean;
  setIsOpened: (v: boolean) => void;
  isModalPriceList: boolean;
}

const PriceListCategoryTableRow: React.FC<Props> = ({
  categoryData,
  positionsLength,
  provided,
  isOpened,
  setIsOpened,
  isModalPriceList,
}) => {
  const isMobile = useMediaQuery(devices.mobile);
  const categoryRow = useRef<HTMLTableRowElement | null>(null);
  const categoryRowHovered = useHover(categoryRow.current);
  const [openAddCategoryModal, setOpenCategoryModal] = useState(false);
  const [openEditCategoryModal, setOpenEditCategoryModal] = useState(false);
  const [openDeleteCategoryModal, setOpenDeleteCategoryModal] = useState(false);

  const language = useAppSelector((state) => state.language.language);
  const categories = useAppSelector((state) => state.categories.categories);
  const positions = useAppSelector((state) => state.priceList.priceList);
  const userCurrencyList = useAppSelector((state) => state.currencies.userCurrencyList);
  const checkedCategoriesIds = useAppSelector((state) => state.positionsFilter.checkedCategoryIds);
  const isSearching = useAppSelector((state) => state.priceList.isSearching);
  const filteredCategories = useAppSelector((state) => state.categories.filteredCategories);
  const theme = useAppSelector((state) => state.theme.theme);
  const dispatch = useAppDispatch();

  const isDataUnCategorized = categoryData.primary === true;

  const [isActiveDotsMenu, setIsActiveDotsMenu] = useState<boolean>(false);
  const [checked, setChecked] = useState(false);
  const [openExportPDFModal, setOpenExportPDFModal] = useState(false);

  useEffect(() => {
    const filteredCategoriesIds = filteredCategories.map((cat) => cat.id);

    if (filteredCategoriesIds.includes(categoryData.id) || isDataUnCategorized) {
      setIsOpened(true);
    } else if (!filteredCategories.length) {
      setIsOpened(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearching, categoryData.primary, filteredCategories.length]);

  useEffect(() => {
    if (checkedCategoriesIds.includes(categoryData.id)) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [checkedCategoriesIds, categoryData.id]);

  useEffect(() => {
    if (!categoryRowHovered) setIsActiveDotsMenu(false);
  }, [categoryRowHovered]);

  const toggleCheckbox = () => {
    const currentCategory = categories?.find((cat) => cat.id === categoryData.id);
    const checkedPositions = positions?.filter((pos) => pos.category.id === currentCategory?.id);

    if (checked) {
      dispatch(removeCheckedCategories([currentCategory?.id]));
      dispatch(removeCheckedPositions(getArrayOfIds(checkedPositions)));
    } else {
      dispatch(addCheckedCategories([currentCategory?.id]));
      dispatch(addCheckedPositions(getArrayOfIds(checkedPositions)));
    }
    setChecked(!checked);
  };

  function OnUploadClick() {
    setOpenExportPDFModal(true);
  }

  function OnDotsClick() {
    setIsActiveDotsMenu(!isActiveDotsMenu);
  }

  function OnDeleteCategoryClick() {
    setOpenDeleteCategoryModal(true);
  }

  function OnEditCategoryClick() {
    setOpenEditCategoryModal(true);
  }

  function OnAddCategoryClick() {
    setOpenCategoryModal(true);
  }

  const rowClassName = () => {
    let cn = '';

    if (isDarkTheme) cn += 'dark';
    if (isModalPriceList) cn += 'modalPriceList';

    return cn;
  };

  const isDarkTheme = theme === 'dark';
  //TODO: refactoring classnames
  return (
    <>
      {!isDataUnCategorized && (
        <TableRowContainer ref={categoryRow} className={rowClassName()}>
          {!isModalPriceList && (
            <ButtonTableCellContainer {...provided.dragHandleProps}>
              {isMobile && (
                <RowButtonWrapper className={isDarkTheme ? 'dark' : ''}>
                  <DragIcon size={16} />
                </RowButtonWrapper>
              )}
              {!isMobile && (
                <RowButtonWrapper className={isDarkTheme ? 'hoveredContainer dark' : 'hoveredContainer'}>
                  <DragIcon />
                </RowButtonWrapper>
              )}
              {!isMobile && (
                <AddCategoryButtonWrapper className="hoveredContainer" onClick={OnAddCategoryClick}>
                  <GradientRoundPlus id="AddCategory" />
                </AddCategoryButtonWrapper>
              )}
            </ButtonTableCellContainer>
          )}
          <ButtonTableCellContainer className={isModalPriceList ? 'modalPriceList' : ''}>
            <Checkbox toggleCheckbox={toggleCheckbox} checked={checked} />
          </ButtonTableCellContainer>
          <ButtonTableCellContainer className={isModalPriceList ? 'modalPriceList' : ''}>
            <RowButtonWrapper className={isDarkTheme ? 'dark' : ''}>
              <ToggleAccordion
                size={isMobile ? 16 : 24}
                color={COLORS.TEXT_GREY}
                isOpen={isOpened}
                setIsOpen={() => setIsOpened(!isOpened)}
              />
            </RowButtonWrapper>
          </ButtonTableCellContainer>
          <MainTableCellContainer className={isModalPriceList ? 'modalPriceList' : ''}>
            <CategoryName className={rowClassName()}>{categoryData.name}</CategoryName>
            <CategoryCount className={rowClassName()}>({positionsLength})</CategoryCount>
          </MainTableCellContainer>
          <SecondaryTableCellContainer
            className={isModalPriceList ? 'modalPriceList' : ''}
          ></SecondaryTableCellContainer>
          <PriceTableCellContainer className={isModalPriceList ? 'modalPriceList' : ''}></PriceTableCellContainer>
          <PriceTableCellContainer className={isModalPriceList ? 'modalPriceList' : ''}></PriceTableCellContainer>
          {(() => {
            if (isModalPriceList) return null;
            if (isMobile) {
              return (
                <>
                  <ButtonTableCellContainer>
                    <RowButtonWrapper className={isDarkTheme ? 'dark' : ''} onClick={OnDotsClick}>
                      <Dots size={16} isActive={isActiveDotsMenu} />
                      {isActiveDotsMenu && (
                        <CategoryDropDownContainer>
                          <DropdownBody className={isDarkTheme ? 'dark table-mobile' : 'table-mobile'}>
                            <DropdownElement className={isDarkTheme ? 'dark' : ''} onClick={OnAddCategoryClick}>
                              <Plus size={16} stroke={COLORS.ACTION_BAR_BLACK_BG} />
                              <span>{text[language].AddItem}</span>
                            </DropdownElement>
                            {!!positionsLength && positionsLength > 0 && (
                              <DropdownElement className={isDarkTheme ? 'dark' : ''} onClick={OnUploadClick}>
                                <Upload size={16} isDarkTheme={isDarkTheme} />
                                <span>{text[language].ExportCategoryPDF}</span>
                              </DropdownElement>
                            )}
                            <DropdownElement className={isDarkTheme ? 'dark' : ''} onClick={OnEditCategoryClick}>
                              <Edit size={16} stroke={isDarkTheme ? COLORS.TEXT_WHITE : COLORS.ACTION_BAR_BLACK_BG} />
                              <span>{text[language].EditCategory}</span>
                            </DropdownElement>
                            <DropdownElement className={isDarkTheme ? 'dark' : ''} onClick={OnDeleteCategoryClick}>
                              <Trash size={16} stroke={isDarkTheme ? COLORS.TEXT_WHITE : COLORS.ACTION_BAR_BLACK_BG} />
                              <span>{text[language].DeleteCategory}</span>
                            </DropdownElement>
                          </DropdownBody>
                        </CategoryDropDownContainer>
                      )}
                    </RowButtonWrapper>
                  </ButtonTableCellContainer>
                </>
              );
            } else if (!isMobile) {
              return (
                <>
                  <ButtonTableCellContainer>
                    {!!positionsLength && positionsLength > 0 && (
                      <RowButtonWrapper
                        className={isDarkTheme ? 'hoveredContainer dark' : 'hoveredContainer'}
                        onClick={OnUploadClick}
                      >
                        <Upload id="ExportCategoryPDF" isDarkTheme={isDarkTheme} />
                      </RowButtonWrapper>
                    )}
                  </ButtonTableCellContainer>
                  <ButtonTableCellContainer>
                    <RowButtonWrapper
                      className={isDarkTheme ? 'hoveredContainer dark' : 'hoveredContainer'}
                      onClick={OnDotsClick}
                    >
                      <Dots id="More" isActive={isActiveDotsMenu} />
                      {isActiveDotsMenu && (
                        <CategoryDropDownContainer>
                          <DropdownBody className={isDarkTheme ? 'dark' : ''}>
                            <DropdownElement className={isDarkTheme ? 'dark' : ''} onClick={OnEditCategoryClick}>
                              <Edit stroke={isDarkTheme ? COLORS.TEXT_WHITE : COLORS.ACTION_BAR_BLACK_BG} />
                              <span>{text[language].EditCategory}</span>
                            </DropdownElement>
                            <DropdownElement className={isDarkTheme ? 'dark' : ''} onClick={OnDeleteCategoryClick}>
                              <Trash stroke={isDarkTheme ? COLORS.TEXT_WHITE : COLORS.ACTION_BAR_BLACK_BG} />
                              <span>{text[language].DeleteCategory}</span>
                            </DropdownElement>
                          </DropdownBody>
                        </CategoryDropDownContainer>
                      )}
                    </RowButtonWrapper>
                  </ButtonTableCellContainer>
                </>
              );
            } else {
              return (
                <>
                  <ButtonTableCellContainer></ButtonTableCellContainer>
                  <ButtonTableCellContainer></ButtonTableCellContainer>
                </>
              );
            }
          })()}
        </TableRowContainer>
      )}
      <ExportPDFModal
        isOpen={openExportPDFModal}
        handleClose={() => setOpenExportPDFModal(false)}
        type="category"
        categoryId={categoryData.id}
      />
      <AddCategoryModal
        order={categoryData.order + 1}
        handleClose={() => setOpenCategoryModal(false)}
        isOpen={openAddCategoryModal}
      />
      <EditCategoryModal
        handleClose={() => setOpenEditCategoryModal(false)}
        isOpen={openEditCategoryModal}
        defaultCategoryName={categoryData.name}
        categoryID={categoryData.id}
      />
      <DeleteItemsModal
        handleClose={() => setOpenDeleteCategoryModal(false)}
        isOpen={openDeleteCategoryModal}
        type={{ type: 'category', count: 'single', currency: userCurrencyList?.length > 1 ? 'multiple' : 'single' }}
        categoryID={[categoryData.id]}
      />
    </>
  );
};

export default PriceListCategoryTableRow;
