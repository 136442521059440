import React, { useEffect } from 'react';
import { DarkThemeIcon, LightThemeIcon } from 'shared/assets/icons';
import { text } from 'shared/constants';
import { LocalStorage, useAppDispatch, useAppSelector } from 'shared/lib';
import { ThemeType, setTheme } from 'entities/theme';
import { ControlsContainer, DarkThemeButton, LightThemeButton } from './styles';

export const ThemeControls: React.FC = () => {
  const language = useAppSelector((state) => state.language.language);
  const theme = useAppSelector((state) => state.theme.theme);
  const dispatch = useAppDispatch();

  const onChangeThemeClick = (theme: ThemeType) => {
    dispatch(setTheme(theme));
    LocalStorage.setTheme(theme);
  };

  useEffect(() => {
    const theme = LocalStorage.getTheme();
    if (theme) {
      dispatch(setTheme(theme));
    }
  }, [dispatch]);

  const isLightThemeActive = theme === 'light';
  const isDarkThemeActive = theme === 'dark';

  return (
    <ControlsContainer>
      <LightThemeButton className={isLightThemeActive ? 'active' : ''} onClick={() => onChangeThemeClick('light')}>
        <LightThemeIcon isActive={isLightThemeActive} />
        <span>{text[language].light}</span>
      </LightThemeButton>
      <DarkThemeButton className={isDarkThemeActive ? 'active' : ''} onClick={() => onChangeThemeClick('dark')}>
        <DarkThemeIcon isActive={isDarkThemeActive} />
        <span>{text[language].dark}</span>
      </DarkThemeButton>
    </ControlsContainer>
  );
};
