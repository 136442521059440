import styled from '@emotion/styled';
import { COLORS } from 'shared/constants';

export const CurrencyRateModalContainer = styled('div')(({ className }) => ({
  margin: '32px auto',
  '& > p:first-of-type': {
    margin: '0 auto 16px',
    lineHeight: '24px',
    textAlign: 'left',
    color: className?.includes('dark') ? COLORS.BODY_TEXT_BLACK_20 : COLORS.ACTION_BAR_BLACK_BG,
    maxWidth: '280px',
    fontWeight: 400,
  },
  padding: '0px 14px',
  '@media (min-width: 360px)': {
    padding: '0 32px',
  },
}));

export const InputsContainer = styled('div')(({ className }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '16px',
  '& > div': {
    width: '100%',
    position: 'relative',
  },
}));

export const CurrencyName = styled('p')(({ className }) => ({
  color: className?.includes('dark') ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK,
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
  position: 'absolute',
  top: '16px',
  right: '16px',
  zIndex: 1,
}));

export const CurrencyRateModalButtonsWrapper = styled('div')(({ className }) => ({
  '& button': {
    fontSize: '16px',
    padding: className?.includes('mobile') ? '3px 8px' : '8px 16px',
    lineHeight: '24px',
    width: '100%',
    height: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: '8px',
  },
  padding: '0px 14px',
  '@media (min-width: 360px)': {
    padding: '0 32px',
  },
}));
