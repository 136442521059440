import React from 'react';
import { COLORS } from 'shared/constants';

type Props = {
  size?: number;
  active: boolean;
  isDarkTheme: boolean;
};

export const SearchIcon: React.FC<Props> = ({ active, size = 24, isDarkTheme }) => {
  const stroke = () => {
    if (active && isDarkTheme) return COLORS.TEXT_WHITE;
    if (active && !isDarkTheme) return COLORS.TEXT_BLACK;
    if (!active && isDarkTheme) return COLORS.TEXT_GREY;
    if (!active && !isDarkTheme) return COLORS.BODY_TEXT_30;
  };
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 20L15.4253 15.3728M10.6667 17.3333C8 17.3333 4 15.3333 4 10.6667C4 6 8 4 10.6667 4C13.3333 4 17.3333 6 17.3333 10.6667C17.3333 15.3333 13.3333 17.3333 10.6667 17.3333Z"
        stroke={stroke()}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
