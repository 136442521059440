import React, { useEffect, useRef, useState } from 'react';
import { DropDown } from '../DropDown/DropDown';
import { CloseButton, NoAvatar, TriangleArrow } from 'shared/assets/icons';
import { COLORS, devices } from 'shared/constants';
import { useAppSelector, useMediaQuery } from 'shared/lib';
import { API_CONFIG } from 'shared/api';
import { DropDownContainer, ProjectImg, SelectLabel, SelectedInfoContainer, StyledSelect } from './styles';

export type SelectData = {
  value: string;
  id: number;
  img?: string;
};

type Props = {
  data?: SelectData[];
  placeholder: string;
  value: string;
  selectedImg?: string | null;
  onChange: (event: string, id: number, index: number, img?: string) => void;
  projectSelect?: boolean;
  withAvatar?: boolean;
  withClearButton?: boolean;
  actionButtonText?: string;
  onAddPositionClick?: () => void;
  disabled?: boolean;
  mobileModal?: boolean;
  withLabel?: boolean;
};

export const Select: React.FC<Props> = ({
  data,
  placeholder,
  onChange,
  value,
  withAvatar = false,
  withClearButton = false,
  actionButtonText,
  onAddPositionClick,
  disabled,
  mobileModal,
  projectSelect,
  selectedImg,
  withLabel,
}) => {
  const theme = useAppSelector((state) => state.theme.theme);
  const selectRef = useRef<HTMLInputElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isMobile = useMediaQuery(devices.mobile);
  const isTablet = useMediaQuery(devices.tablet);

  const localImage = require('shared/assets/icons/default_project_photo.png');

  const isTabletOrMobile = isMobile || isTablet;

  const handleElementClick = (value: string, id: number, index: number, img?: string) => {
    onChange(value, id, index, img);
    setIsOpen(false);
  };

  const handleClearClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    onChange('', 0, 0);
    setIsOpen(false);
  };

  const handleOutsideClick = (e: MouseEvent) => {
    if (selectRef.current && !selectRef.current.contains(e.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const dropDownElements = data?.map((item, index) => {
    const icon = () => {
      if (withAvatar) {
        return <NoAvatar size={isTabletOrMobile ? 17 : 34} />;
      } else if (projectSelect && item.img) {
        const imageUrl = API_CONFIG.BASE_URL + item.img;
        return <ProjectImg src={imageUrl} alt="ProjectIcon" />;
      } else if (projectSelect && !item.img) {
        return <ProjectImg src={localImage} alt="ProjectIcon" />;
      } else {
        return null;
      }
    };
    return {
      index,
      icon: icon(),
      text: item.value,
      id: item.id,
      onClick: () => handleElementClick(item.value, item.id, index, item.img),
    };
  });

  const isDarkTheme = theme === 'dark';

  const selectClassName = () => {
    let cn = '';
    if (!value || disabled) {
      cn += 'placeholder';
    }
    if (isDarkTheme) {
      cn += 'dark';
    }
    if (mobileModal) {
      cn += 'mobileModal';
    }
    if (withLabel) {
      cn += 'withLabel';
    }
    return cn;
  };

  return (
    <div style={{ position: 'relative' }} ref={selectRef}>
      <StyledSelect className={selectClassName()} onClick={() => setIsOpen(!isOpen)}>
        <SelectedInfoContainer className={withLabel && !!value.length ? 'withLabel' : ''}>
          {/* TODO: add avatars */}
          {(() => {
            if (withAvatar && value) {
              return <NoAvatar size={isTabletOrMobile ? 17 : 34} />;
            } else if (projectSelect && value && selectedImg) {
              const imageUrl = API_CONFIG.BASE_URL + selectedImg;
              return <ProjectImg src={imageUrl} alt="ProjectIcon" />;
            } else if (projectSelect && value && !selectedImg) {
              return <ProjectImg src={localImage} alt="ProjectIcon" />;
            }
          })()}
          {withLabel && !!value.length && (
            <SelectLabel className={isDarkTheme ? 'dark' : ''}>{placeholder}</SelectLabel>
          )}
          <p>{!value ? placeholder : value}</p>
        </SelectedInfoContainer>
        {(() => {
          if (disabled) {
            return <></>;
          }

          if (withClearButton && value) {
            return (
              <div onClick={handleClearClick}>
                <CloseButton size={24} stroke={isDarkTheme ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK} />
              </div>
            );
          }

          return <TriangleArrow size={24} stroke={isDarkTheme ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK} />;
        })()}
      </StyledSelect>
      {isOpen && !disabled && (
        <DropDownContainer>
          <DropDown
            mobileModal={mobileModal}
            elements={dropDownElements}
            withoutGradient={true}
            addPositionButtonText={actionButtonText}
            onAddPositionClick={() => {
              onAddPositionClick && onAddPositionClick();
              setIsOpen(!isOpen);
            }}
          />
        </DropDownContainer>
      )}
    </div>
  );
};
