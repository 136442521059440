import { CreatePositionRequest, CurrenciesType } from 'entities/priceList';

export const addCurrencyRatesToData = (
  requestData: CreatePositionRequest[],
  activeCurrency: CurrenciesType,
  selectedCurrencies: (string | null)[],
  currencyRate: {
    [x: string]: string;
  },
) => {
  if (!activeCurrency) return;
  const preparedRequest = requestData?.map((position) => {
    let fundsWithActiveCurrency = position.funds;

    const baseTotal = fundsWithActiveCurrency[activeCurrency as CurrenciesType]?.total;
    const baseCost = fundsWithActiveCurrency[activeCurrency as CurrenciesType]?.cost;

    const fundsWithOtherCurrencies = selectedCurrencies.reduce((acc, currency) => {
      if (baseCost === null || baseTotal === null) return acc;
      const rate = currencyRate[currency as CurrenciesType];

      const cost = baseCost / Number(rate);
      const total = baseTotal / Number(rate);
      return {
        ...acc,
        [currency as CurrenciesType]: {
          cost: cost === 0 ? 0 : cost < 0.01 ? 0.01 : cost,
          total: total === 0 ? 0 : total < 0.01 ? 0.01 : total,
        },
      };
    }, {});

    position.funds = { ...fundsWithActiveCurrency, ...fundsWithOtherCurrencies };
    return position;
  });
  return preparedRequest;
};
