import styled from '@emotion/styled';
import { COLORS } from 'shared/constants';


export const Progress = styled('div')(({ className }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '10px',
  height: '5px',
  transition: 'width 0.5s ease-in-out',
  backgroundColor: COLORS.ORANGE_BTN,
}));
