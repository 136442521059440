import React, { useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { CounterAgentType, resetCreatingProjectError, resetNewCustomer, resetProjectsLoading } from 'entities/project';
import { LoadingResultsT } from 'entities/loadingType';
import { InfoIcon } from 'shared/assets/icons';
import { COLORS, currencyNames, devices, text } from 'shared/constants';
import { handleNumberValue, useAppDispatch, useAppSelector, useMediaQuery } from 'shared/lib';
import { ImageUploader, Input, Select, SelectData, ImageValuesType } from 'shared/ui';
import {
  CreateProjectModalContainer,
  InfoProjectContainer,
  Subtitle,
  GroupedInputs,
  DropDownContainer,
  CustomerInfoTooltip,
  tooltipStyles,
  FormErrorMsgContainer,
  tooltipDarkStyles,
} from '../styles';
import { CreateProjectValues } from '../../model/defaultValues';

type Props = {
  projectInfo: CreateProjectValues;
  setProjectInfo: React.Dispatch<React.SetStateAction<CreateProjectValues>>;
  openCreateCustomerModal: React.Dispatch<React.SetStateAction<void>>;
};

const CreateProjectModalForm: React.FC<Props> = ({ projectInfo, setProjectInfo, openCreateCustomerModal }) => {
  const language = useAppSelector((state) => state.language.language);
  const counterAgents = useAppSelector((state) => state.projects.counterAgents);
  const newCustomer = useAppSelector((state) => state.projects.newCustomer);
  const createProjectError = useAppSelector((state) => state.projects.creatingProjectError);
  const loadingCreatingCustomer = useAppSelector((state) => state.projects.loadingCreatingCustomer);
  const theme = useAppSelector((state) => state.theme.theme);
  const [formError, setFormError] = useState(false);
  const dispatch = useAppDispatch();

  const isMobile = useMediaQuery(devices.mobile);
  const isTablet = useMediaQuery(devices.tablet);

  const isTabletOrMobile = isMobile || isTablet;

  const currencySelectItems = [
    { value: text[language].UAH, id: 0 },
    { value: text[language].USD, id: 1 },
    { value: text[language].EUR, id: 2 },
    { value: text[language].GBP, id: 3 },
  ];

  const customersInfo: SelectData[] | undefined = counterAgents
    ?.slice()
    .sort((a, b) => {
      return a.role === 'owner' ? -1 : b.role === 'owner' ? 1 : 0;
    })
    .map((customer: CounterAgentType) => {
      return {
        value: `${customer.firstName || '-'} ${customer.lastName || '-'}`,
        id: customer.id,
      };
    });

  useEffect(() => {
    if (createProjectError) {
      setFormError(true);
    }
  }, [createProjectError]);

  useEffect(() => {
    if (loadingCreatingCustomer === LoadingResultsT.SUCCEEDED) {
      if (!newCustomer) return;
      const value = `${newCustomer.firstName} ${newCustomer.lastName}`;
      const customer = { value, id: newCustomer.id };
      setProjectInfo({ ...projectInfo, customer });
      dispatch(resetNewCustomer());
      dispatch(resetProjectsLoading());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingCreatingCustomer, newCustomer, setProjectInfo, dispatch]);

  const isDarkTheme = theme === 'dark';
  return (
    <CreateProjectModalContainer>
      <InfoProjectContainer>
        <Subtitle className={isDarkTheme ? 'dark' : ''}>
          <p>{text[language].projectPage.projectInfo}</p>
        </Subtitle>
        <GroupedInputs>
          <Input
            placeholder={text[language].projectPage.projectName}
            value={projectInfo.name}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setProjectInfo({ ...projectInfo, name: event.target.value });
            }}
            smallModalInput={true}
          />
          <Input
            placeholder={text[language].projectPage.address}
            value={projectInfo.address || ''}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setProjectInfo({ ...projectInfo, address: event.target.value });
            }}
            smallModalInput={true}
          />
        </GroupedInputs>
        <GroupedInputs>
          <Input
            inputMode="decimal"
            placeholder={text[language].projectPage.square}
            value={projectInfo.square}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const square = handleNumberValue(event.target.value);
              setProjectInfo({ ...projectInfo, square });
            }}
            smallModalInput={true}
          />
          <Input
            inputMode="decimal"
            placeholder={text[language].projectPage.budgetProject}
            value={projectInfo.budget || ''}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const budgetAmount = handleNumberValue(event.target.value);
              setProjectInfo({ ...projectInfo, budget: budgetAmount });
            }}
            smallModalInput={true}
          />
        </GroupedInputs>
        <DropDownContainer>
          <Select
            mobileModal={true}
            data={currencySelectItems}
            placeholder={text[language].projectPage.currency}
            value={projectInfo.currency?.text || ''}
            onChange={(value, id) => {
              setProjectInfo({
                ...projectInfo,
                currency: {
                  title: currencyNames[id],
                  text: value,
                },
              });
            }}
            withLabel={true}
          />
          <p>{text[language].projectPage.cannotChangeCurrency}</p>
        </DropDownContainer>
      </InfoProjectContainer>
      <InfoProjectContainer>
        <Subtitle className={isDarkTheme ? 'dark' : ''}>
          <p>{text[language].projectPage.projectCustomer}</p>
          <div id="customerInfo">
            <InfoIcon size={isTabletOrMobile ? 8 : 16} />
          </div>
        </Subtitle>
        <DropDownContainer>
          <Select
            mobileModal={true}
            withAvatar={true}
            withClearButton={true}
            data={customersInfo}
            placeholder={text[language].projectPage.addCustomerToProject}
            value={projectInfo.customer.value}
            onChange={(value, id) => {
              const customer = { value, id };
              setProjectInfo({ ...projectInfo, customer });
            }}
            actionButtonText={text[language].projectPage.addCustomer}
            onAddPositionClick={openCreateCustomerModal}
          />
        </DropDownContainer>
      </InfoProjectContainer>
      <InfoProjectContainer>
        <Subtitle className={isDarkTheme ? 'dark' : ''}>
          <p>{text[language].projectPage.addPhoto}</p>
        </Subtitle>
        <div>
          <ImageUploader
            setProjectImage={(image: ImageValuesType) => {
              setProjectInfo({ ...projectInfo, image });
              setFormError(false);
              dispatch(resetCreatingProjectError());
            }}
            error={formError}
            setError={setFormError}
          />
          {formError && (
            <FormErrorMsgContainer>
              <InfoIcon stroke={COLORS.MSG_RED} />
              <span>{text[language].incorrectFormat}</span>
            </FormErrorMsgContainer>
          )}
        </div>
      </InfoProjectContainer>
      <Tooltip
        anchorSelect="#customerInfo"
        place={isMobile ? 'bottom' : 'right-start'}
        style={isDarkTheme ? tooltipDarkStyles : tooltipStyles}
      >
        <CustomerInfoTooltip>
          {text[language].projectPage.customerHasAccess}
          <div>
            <ul>
              <li>{text[language].projectPage.toOwnProjects}</li>
              <li>{text[language].projectPage.seeActions}</li>
              <li>{text[language].projectPage.toPriceList}</li>
              <li>{text[language].projectPage.toFullReport}</li>
            </ul>
          </div>
        </CustomerInfoTooltip>
      </Tooltip>
    </CreateProjectModalContainer>
  );
};

export default CreateProjectModalForm;
