import styled from '@emotion/styled';
import { COLORS } from 'shared/constants';

export const EmptyTableBodyCell = styled('div')(({ className }) => ({
  padding: '12px 0',
  verticalAlign: 'middle',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: `1px solid ${className?.includes('dark') ? COLORS.LIGHT_GREY_BORDER : COLORS.BORDER_GREY}`,
  '& p': {
    whiteSpace: 'nowrap',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    marginLeft: '5px',
  },
}));
