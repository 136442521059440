import React from 'react';

type Props = {
  size?: number;
  stroke?: string;
};

export const GoBackArrow: React.FC<Props> = ({ size = 24, stroke = 'black' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        d="M20 12L4 12M4 12L9.86667 19M4 12L9.86667 5"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
