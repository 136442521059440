export type AccountInfoType = {
  // accessId: number; TODO: add to 1.3
  avatarUrl: null | string;
  companies: CompanyType[];
  createTime: string;
  email: string;
  firstName: string;
  id: string;
  image: AccountImageType | null;
  language: string;
  lastName: string;
  phone: null | string;
  subscription: Subscription;
  subscriptionEndDate?: string;
  subscriptionStartDate: string;
  trialSubscriptionStartDate: string;
};

type AccountImageType = {
  account: number;
  account_image_path: string;
  id: number;
  original_name: string;
};

type CompanyType = {
  id: number;
  name: string;
  role: Roles;
};

export enum Roles {
  owner = 'owner',
  customer = 'customer',
  partner = 'partner',
  contractor = 'contractor',
  employee = 'employee',
}

export enum Subscription {
  Premium = 'Premium',
  Business = 'Business',
  Professional = 'Professional',
  None = 'None',
}
