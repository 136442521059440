import React from 'react';
import { COLORS } from 'shared/constants';
import { useAppSelector } from 'shared/lib';

type Props = {
  isActive: boolean;
};

export const DisplayElementsIcon: React.FC<Props> = ({ isActive }) => {
  const theme = useAppSelector((state) => state.theme.theme);
  const isDarkTheme = theme === 'dark';

  const stroke = () => {
    if (isActive && !isDarkTheme) return COLORS.TEXT_BLACK;
    if (!isActive && !isDarkTheme) return COLORS.BODY_TEXT_30;
    if (isActive && isDarkTheme) return COLORS.TEXT_WHITE;
    if (!isActive && isDarkTheme) return COLORS.TEXT_GREY;
  };
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M5 6.6C5 6.03995 5 5.75992 5.10899 5.54601C5.20487 5.35785 5.35785 5.20487 5.54601 5.10899C5.75992 5 6.03995 5 6.6 5H8.4C8.96005 5 9.24008 5 9.45399 5.10899C9.64215 5.20487 9.79513 5.35785 9.89101 5.54601C10 5.75992 10 6.03995 10 6.6V8.4C10 8.96005 10 9.24008 9.89101 9.45399C9.79513 9.64215 9.64215 9.79513 9.45399 9.89101C9.24008 10 8.96005 10 8.4 10H6.6C6.03995 10 5.75992 10 5.54601 9.89101C5.35785 9.79513 5.20487 9.64215 5.10899 9.45399C5 9.24008 5 8.96005 5 8.4V6.6Z"
        stroke={stroke()}
      />
      <path
        d="M5 15.6C5 15.0399 5 14.7599 5.10899 14.546C5.20487 14.3578 5.35785 14.2049 5.54601 14.109C5.75992 14 6.03995 14 6.6 14H8.4C8.96005 14 9.24008 14 9.45399 14.109C9.64215 14.2049 9.79513 14.3578 9.89101 14.546C10 14.7599 10 15.0399 10 15.6V17.4C10 17.9601 10 18.2401 9.89101 18.454C9.79513 18.6422 9.64215 18.7951 9.45399 18.891C9.24008 19 8.96005 19 8.4 19H6.6C6.03995 19 5.75992 19 5.54601 18.891C5.35785 18.7951 5.20487 18.6422 5.10899 18.454C5 18.2401 5 17.9601 5 17.4V15.6Z"
        stroke={stroke()}
      />
      <path
        d="M14 6.6C14 6.03995 14 5.75992 14.109 5.54601C14.2049 5.35785 14.3578 5.20487 14.546 5.10899C14.7599 5 15.0399 5 15.6 5H17.4C17.9601 5 18.2401 5 18.454 5.10899C18.6422 5.20487 18.7951 5.35785 18.891 5.54601C19 5.75992 19 6.03995 19 6.6V8.4C19 8.96005 19 9.24008 18.891 9.45399C18.7951 9.64215 18.6422 9.79513 18.454 9.89101C18.2401 10 17.9601 10 17.4 10H15.6C15.0399 10 14.7599 10 14.546 9.89101C14.3578 9.79513 14.2049 9.64215 14.109 9.45399C14 9.24008 14 8.96005 14 8.4V6.6Z"
        stroke={stroke()}
      />
      <path
        d="M14 15.6C14 15.0399 14 14.7599 14.109 14.546C14.2049 14.3578 14.3578 14.2049 14.546 14.109C14.7599 14 15.0399 14 15.6 14H17.4C17.9601 14 18.2401 14 18.454 14.109C18.6422 14.2049 18.7951 14.3578 18.891 14.546C19 14.7599 19 15.0399 19 15.6V17.4C19 17.9601 19 18.2401 18.891 18.454C18.7951 18.6422 18.6422 18.7951 18.454 18.891C18.2401 19 17.9601 19 17.4 19H15.6C15.0399 19 14.7599 19 14.546 18.891C14.3578 18.7951 14.2049 18.6422 14.109 18.454C14 18.2401 14 17.9601 14 17.4V15.6Z"
        stroke={stroke()}
      />
    </svg>
  );
};
