import styled from '@emotion/styled';
import { COLORS } from 'shared/constants';

export const DeleteItemsText = styled('p')(({ className }) => ({
  maxWidth: '281px',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
  color: className?.includes('dark') ? COLORS.BODY_TEXT_BLACK_20 : COLORS.ACTION_BAR_BLACK_BG,
  margin: '0 auto',
}));

export const DeleteItemsModalButton = styled('button')(({ className }) => ({
  textAlign: 'center',
  cursor: 'pointer',
  lineHeight: '24px',
  fontWeight: 400,
  border: `1px solid ${className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.BORDER_GREY}`,
  background: className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.TEXT_WHITE,
  color:
    className?.includes('dark') && className?.includes('delete')
      ? COLORS.DARK_RED
      : className?.includes('dark')
      ? COLORS.TEXT_WHITE
      : className?.includes('delete')
      ? COLORS.MAIN_RED
      : COLORS.TEXT_BLACK,
  fontSize: '16px',
  padding: '8px 16px',
  width: '140px',
  borderRadius: '8px',
  transition: 'background 0.2s linear',
  '&:active': {
    background: className?.includes('dark')
      ? COLORS.BACKGROUND_BLACK
      : className?.includes('delete')
      ? COLORS.BTN_BG_PINK
      : COLORS.GREY,
  },
  '&:hover': {
    background: className?.includes('dark')
      ? COLORS.BACKGROUND_BLACK
      : className?.includes('delete')
      ? COLORS.BTN_BG_PINK
      : COLORS.GREY,
  },
  '&:disabled': {
    background: className?.includes('dark') ? COLORS.BACKGROUND_LIGHT_DARK : COLORS.GREY,
    color: className?.includes('dark') ? COLORS.BODY_TEXT_BLACK_70 : COLORS.DISABLED_BTN_TEXT,
    cursor: 'default',
  },
}));

export const DeleteItemsModalButtonsWrapper = styled('div')(({ className }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: '16px',
  padding: '0px 14px',
  '@media (min-width: 360px)': {
    padding: '0 32px',
  },
}));

export const DeleteItemsImages = styled('div')(({ className }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '5px',
  marginBottom: '24px',
}));
