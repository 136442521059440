import React, { useState } from 'react';
import { deleteCompanyCurrency, setActiveCurrency } from 'entities/currencies';
import { useMediaQuery, useAppSelector, useAppDispatch } from 'shared/lib';

import { Plus, Download, Upload, Trash } from 'shared/assets/icons';

import { AddCategoryModal, CreatePositionsModal, ImportPositionsModal, ExportPDFModal, ExportPDFType } from 'widgets';
import { DropDown, ActionButton } from 'shared/ui';

import { text, devices, COLORS } from 'shared/constants';
import { ActionBarContainer, DeleteActionBtn, DropDownContainer, WhiteActionBtn } from './styles';

export const TableActionButtonsBar: React.FC = () => {
  const isMobile = useMediaQuery(devices.mobile);
  const language = useAppSelector((state) => state.language.language);
  const priceList = useAppSelector((state) => state.priceList.priceList);
  const activeCurrency = useAppSelector((state) => state.currencies.activeCurrency);
  const companyCurrencies = useAppSelector((state) => state.currencies.companyCurrencies);
  const company = useAppSelector((state) => state.company.company);
  const theme = useAppSelector((state) => state.theme.theme);
  const dispatch = useAppDispatch();

  const [openAddCategoryModal, setOpenCategoryModal] = useState(false);
  const [openAddPositionModal, setOpenAddPositionModal] = useState(false);
  const [openImportPositionModal, setOpenImportPositionModal] = useState(false);
  const [openExportPDFModal, setOpenExportPDFModal] = useState(false);
  const [isOpenExportPDFDropDown, setIsOpenExportPDFDropDown] = useState(false);
  const [exportType, setExportType] = useState<ExportPDFType>();

  function OnDeleteCurrency() {
    const activeCompanyCurrency = companyCurrencies.find((cur) => cur.currency === activeCurrency);
    if (!company || !activeCompanyCurrency) return;
    dispatch(deleteCompanyCurrency({ companyID: company[0].id, currencyId: activeCompanyCurrency.id }));
    dispatch(setActiveCurrency(company[0].currency));
  }

  function OnAddItemClick() {
    setOpenAddPositionModal(true);
  }

  function OnImportItemClick() {
    setOpenImportPositionModal(true);
  }

  function OnAddCategoryClick() {
    setOpenCategoryModal(true);
  }

  function OnExportPDFClick() {
    setIsOpenExportPDFDropDown(!isOpenExportPDFDropDown);
  }

  function OnExportPDFDropDownClick(type: ExportPDFType) {
    setExportType(type);
    setIsOpenExportPDFDropDown(false);
    setOpenExportPDFModal(true);
  }

  const isDeleteCurrencyButtonShowed =
    company && activeCurrency !== company[0].currency && !priceList?.length && companyCurrencies.length > 0;
  const exportDisabled = !priceList?.length;
  const isDarkTheme = theme === 'dark';
  return (
    <>
      <ActionBarContainer className={isMobile ? 'mobile' : ''}>
        <div>
          <ActionButton
            orangeBtn
            icon={!isMobile && <Plus />}
            text={text[language].AddItem}
            onClick={OnAddItemClick}
            isPriceListMainButton={true}
          />
          <ActionButton
            orangeBtn
            icon={!isMobile && <Download />}
            text={text[language].ImportItems}
            onClick={OnImportItemClick}
            isPriceListMainButton={true}
          />
          <WhiteActionBtn className={isDarkTheme ? 'dark' : ''} onClick={OnAddCategoryClick}>
            {!isMobile && <Plus stroke={isDarkTheme ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK} />}
            <p>{text[language].AddCategory}</p>
          </WhiteActionBtn>
          <WhiteActionBtn
            disabled={exportDisabled}
            className={isDarkTheme ? 'dark' : ''}
            onClick={OnExportPDFClick}
            onMouseLeave={() => setIsOpenExportPDFDropDown(false)}
          >
            {!isMobile && <Upload disabled={exportDisabled} isDarkTheme={isDarkTheme} />}
            <p>{text[language].ExportPDF}</p>
            {isOpenExportPDFDropDown && (
              <DropDownContainer>
                <DropDown
                  elements={[
                    { text: text[language].ExportAllPositions, onClick: () => OnExportPDFDropDownClick('positions') },
                    { text: text[language].ExportMaterials, onClick: () => OnExportPDFDropDownClick('materials') },
                    { text: text[language].ExportWork, onClick: () => OnExportPDFDropDownClick('work') },
                  ]}
                />
              </DropDownContainer>
            )}
          </WhiteActionBtn>
          {isDeleteCurrencyButtonShowed && isMobile && (
            <DeleteActionBtn onClick={OnDeleteCurrency} className={isDarkTheme ? 'dark' : ''}>
              <p>{text[language].DeleteCurrency}</p>
            </DeleteActionBtn>
          )}
        </div>
        {isDeleteCurrencyButtonShowed && !isMobile && (
          <DeleteActionBtn onClick={OnDeleteCurrency} className={isDarkTheme ? 'dark' : ''}>
            <Trash stroke={isDarkTheme ? COLORS.DARK_RED : COLORS.MAIN_RED} />
            <p>{text[language].DeleteCurrency}</p>
          </DeleteActionBtn>
        )}
      </ActionBarContainer>
      <AddCategoryModal handleClose={() => setOpenCategoryModal(false)} isOpen={openAddCategoryModal} />
      <ExportPDFModal isOpen={openExportPDFModal} handleClose={() => setOpenExportPDFModal(false)} type={exportType} />
      <CreatePositionsModal isOpen={openAddPositionModal} handleClose={() => setOpenAddPositionModal(false)} />
      <ImportPositionsModal isOpen={openImportPositionModal} handleClose={() => setOpenImportPositionModal(false)} />
    </>
  );
};
