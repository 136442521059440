import React, { useState } from 'react';
import { text } from 'shared/constants';
import { useAppSelector } from 'shared/lib';
import { GetPDF } from 'shared/api';
import { Upload } from 'shared/assets/icons';
import { ActionButton, ModalWrapper } from 'shared/ui';
import { PDFModalButtonsWrapper } from './styles';
import { ExportPDFType } from 'widgets';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  type?: ExportPDFType;
  categoryId?: number;
}

export const ExportPDFModal: React.FC<Props> = ({ isOpen, handleClose, type, categoryId }) => {
  const language = useAppSelector((state) => state.language.language);
  const activeCurrency = useAppSelector((state) => state.currencies.activeCurrency);
  const company = useAppSelector((state) => state.company.company);
  const theme = useAppSelector((state) => state.theme.theme);
  const [isLoading, setIsLoading] = useState({ owner: false, customer: false });

  async function getPDFbyTypeAndRole(role: string) {
    let requestByExportType = '';

    // Check the type and build the request string accordingly
    if (type === 'category' && categoryId) {
      requestByExportType = `category=${categoryId}&`;
    } else if (type === 'work') {
      requestByExportType = 'article=2&';
    } else if (type === 'materials') {
      requestByExportType = 'article=1&';
    }

    if (!company) {
      return;
    }

    // Build the currency parameter string if an activeCurrency is provided
    const currencyParam = activeCurrency ? `&currency=${activeCurrency}` : '';

    // Construct the final request string
    const requestType = `${requestByExportType}role=${role}${currencyParam}`;

    // TODO: rewrite using redux thunk
    // Fetching the PDF response
    setIsLoading({ ...isLoading, [role]: true });
    const pdfResponse = await GetPDF(company[0].id, requestType, language);

    // Create a URL for the PDF and open it in a new browser tab
    setIsLoading({ ...isLoading, [role]: false });
    const url = window.URL.createObjectURL(new Blob([pdfResponse], { type: 'application/pdf' }));
    handleClose();
    window.open(url, '_blank');
  }

  const isDarkTheme = theme === 'dark';
  return (
    <ModalWrapper isOpen={isOpen} onCloseButtonClick={() => handleClose()} title={text[language].ExportPDF}>
      <PDFModalButtonsWrapper>
        <ActionButton
          isPending={isLoading.owner}
          icon={<Upload isDarkTheme={isDarkTheme} />}
          text={text[language].ForContractor}
          onClick={() => getPDFbyTypeAndRole('owner')}
        />
        <ActionButton
          isPending={isLoading.customer}
          icon={<Upload isDarkTheme={isDarkTheme} />}
          text={text[language].ForClient}
          onClick={() => getPDFbyTypeAndRole('customer')}
        />
      </PDFModalButtonsWrapper>
    </ModalWrapper>
  );
};
