import styled from '@emotion/styled';
import { COLORS } from 'shared/constants';

export const DropPhotoContainer = styled('div')(({ className }) => ({
  padding: '16px',
  borderRadius: '8px',
  border: '1px dashed',
  borderColor: className?.includes('error')
    ? COLORS.MAIN_RED
    : className?.includes('draggingOver')
    ? COLORS.TEXT_GREY
    : className?.includes('dark')
    ? COLORS.LIGHT_GREY_BORDER
    : COLORS.BORDER_GREY,
  background: className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.TEXT_WHITE,
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '4px',
  '& p': { color: COLORS.TEXT_GREY, userSelect: 'none', fontSize: '8px', fontWeight: 400, lineHeight: '10px' },
  '& label p': {
    background: COLORS.ORANGE_TEXT_HOVERED,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
  '@media (min-width: 1024px)': {
    '& p': {
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
}));

export const DeleteUploadedImageBtn = styled('div')(({ className }) => ({
  position: 'absolute',
  cursor: 'pointer',
  top: '-2px',
  right: '0px',
  '@media (min-width: 1024px)': {
    top: '-2px',
    right: '-2px',
  },
}));
