import React from 'react';

export const AddPhotoIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <circle cx="12" cy="12" r="10" fill="#F8F8F8" />
      <circle cx="12" cy="12" r="8" fill="url(#paint0_linear_3842_176160)" />
      <path
        d="M8 10.0938C8 9.48973 8.48973 9 9.09384 9H9.29289C9.74565 9 10.1799 8.82014 10.5 8.5C10.8201 8.17986 11.2544 8 11.7071 8H12.2929C12.7456 8 13.1799 8.17986 13.5 8.5C13.8201 8.82014 14.2544 9 14.7071 9H14.9062C15.5103 9 16 9.48973 16 10.0938V14.1714C16 14.8115 16 15.1315 15.8754 15.376C15.7659 15.591 15.591 15.7659 15.376 15.8754C15.1315 16 14.8115 16 14.1714 16H9.82857C9.18851 16 8.86848 16 8.62401 15.8754C8.40897 15.7659 8.23413 15.591 8.12456 15.376C8 15.1315 8 14.8115 8 14.1714V10.0938Z"
        fill="url(#paint1_linear_3842_176160)"
      />
      <path
        d="M13.7143 12C13.7143 12.9468 12.9468 13.7143 12 13.7143C11.0532 13.7143 10.2857 12.9468 10.2857 12C10.2857 11.0532 11.0532 10.2857 12 10.2857C12.9468 10.2857 13.7143 11.0532 13.7143 12Z"
        fill="url(#paint2_linear_3842_176160)"
      />
      <path
        d="M8 10.0938C8 9.48973 8.48973 9 9.09384 9H9.29289C9.74565 9 10.1799 8.82014 10.5 8.5C10.8201 8.17986 11.2544 8 11.7071 8H12.2929C12.7456 8 13.1799 8.17986 13.5 8.5C13.8201 8.82014 14.2544 9 14.7071 9H14.9062C15.5103 9 16 9.48973 16 10.0938V14.1714C16 14.8115 16 15.1315 15.8754 15.376C15.7659 15.591 15.591 15.7659 15.376 15.8754C15.1315 16 14.8115 16 14.1714 16H9.82857C9.18851 16 8.86848 16 8.62401 15.8754C8.40897 15.7659 8.23413 15.591 8.12456 15.376C8 15.1315 8 14.8115 8 14.1714V10.0938Z"
        stroke="white"
      />
      <path
        d="M13.7143 12C13.7143 12.9468 12.9468 13.7143 12 13.7143C11.0532 13.7143 10.2857 12.9468 10.2857 12C10.2857 11.0532 11.0532 10.2857 12 10.2857C12.9468 10.2857 13.7143 11.0532 13.7143 12Z"
        stroke="white"
      />
      <defs>
        <linearGradient id="paint0_linear_3842_176160" x1="4" y1="20" x2="20" y2="4" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF8C21" />
          <stop offset="0.880208" stopColor="#FFD200" />
        </linearGradient>
        <linearGradient id="paint1_linear_3842_176160" x1="8" y1="16" x2="16" y2="8" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF8C21" />
          <stop offset="0.880208" stopColor="#FFD200" />
        </linearGradient>
        <linearGradient id="paint2_linear_3842_176160" x1="8" y1="16" x2="16" y2="8" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF8C21" />
          <stop offset="0.880208" stopColor="#FFD200" />
        </linearGradient>
      </defs>
    </svg>
  );
};
