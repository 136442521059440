import React from 'react';
import { COLORS } from 'shared/constants';
import { useAppSelector } from 'shared/lib';

type Props = {
  height?: number;
};

export const CardDividerVertical: React.FC<Props> = ({ height = 255 }) => {
  const theme = useAppSelector((state) => state.theme.theme);

  const isDarkTheme = theme === 'dark';

  const stroke = () => {
    let color = COLORS.BORDER_GREY;
    if (isDarkTheme) color = COLORS.LIGHT_GREY_BORDER;
    return color;
  };
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="2" height={height} viewBox="0 0 2 316" fill="none">
      <path d="M1 0V316" stroke={stroke()} />
    </svg>
  );
};
