import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiService, USER_STATUS, HEADERS } from 'shared/api';

export const sendToken = createAsyncThunk('invite/sendToken', async (token: string, { rejectWithValue }) => {
  try {
    const { data } = await ApiService.apiCall({
      endpoint: `/v1/company/accept?code=${token}`,
      method: 'POST',
      headers: {
        ...HEADERS,
        userStatus: USER_STATUS,
      },
    });

    return data;
  } catch (error) {
    const errorT = error as string;
    return rejectWithValue(errorT);
  }
});
