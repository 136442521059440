import { useLocation } from 'react-router-dom';

export const useLocationPath = (): string => {
  const location = useLocation();
  const locationPath = location.pathname.split('').splice(1);
  if (locationPath.includes('/')) {
    const index = locationPath.findIndex((item) => item === '/');
    return locationPath.splice(0, index).join('');
  }
  return locationPath.join('');
};
