import styled from '@emotion/styled';
import { COLORS } from 'shared/constants';

export const EmptyTableBodyCell = styled('div')(({ className }) => ({
  height: '48px',
  verticalAlign: 'middle',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderBottom: `1px solid ${className?.includes('dark') ? COLORS.LIGHT_GREY_BORDER : COLORS.BORDER_GREY}`,
  background: className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.TEXT_WHITE,
  '& > p': {
    whiteSpace: 'nowrap',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
  },
}));
