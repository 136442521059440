import React from 'react';
import { useMediaQuery, useAppSelector } from 'shared/lib';
import { text, devices } from 'shared/constants';
import { EmptyTableBodyCell } from './styles';
import {
  ButtonTableCellContainer,
  MainTableCellContainer,
  PriceTableCellContainer,
  SecondaryTableCellContainer,
} from '../styles';

type Props = {
  isEmptyCategory?: boolean;
};

const PriceListEmptyTableRow: React.FC<Props> = ({ isEmptyCategory = false }) => {
  const isMobile = useMediaQuery(devices.mobile);
  const isSearching = useAppSelector((state) => state.priceList.isSearching);
  const language = useAppSelector((state) => state.language.language);
  const theme = useAppSelector((state) => state.theme.theme);

  const notFoundText =
    isSearching && !isEmptyCategory ? text[language].PositionIsUndefined : text[language].NoPositions;

  const emptyCategory = isEmptyCategory && text[language].categoryIsEmpty;

  const isDarkTheme = theme === 'dark';
  return (
    <EmptyTableBodyCell className={isDarkTheme ? 'dark' : ''}>
      <ButtonTableCellContainer></ButtonTableCellContainer>
      <ButtonTableCellContainer>
        <p>{notFoundText || emptyCategory}</p>
      </ButtonTableCellContainer>
      <ButtonTableCellContainer></ButtonTableCellContainer>
      <MainTableCellContainer></MainTableCellContainer>
      <SecondaryTableCellContainer></SecondaryTableCellContainer>
      <PriceTableCellContainer></PriceTableCellContainer>
      <PriceTableCellContainer></PriceTableCellContainer>
      {!isMobile && <ButtonTableCellContainer></ButtonTableCellContainer>}
      <ButtonTableCellContainer></ButtonTableCellContainer>
    </EmptyTableBodyCell>
  );
};

export default PriceListEmptyTableRow;
