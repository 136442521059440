import styled from '@emotion/styled';
import { COLORS } from 'shared/constants';

export const ButtonsWrapper = styled('div')(({ className }) => ({
  display: 'flex',
  paddingTop: '16px',
  gap: '8px',
  width: '100%',
  '& button': {
    width: '100%',
    height: '40px',
    padding: '8px 16px',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 400,
  },
  padding: '16px 14px 0 14px',
  '@media (min-width: 360px)': {
    padding: '16px 32px 0 32px',
  },
}));

export const ContentWrapper = styled('div')(({ className }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: '32px auto',
  gap: '16px',
  width: '100%',
  maxWidth: '344px',
  padding: '0 14px',
  '& > p': {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 500,
    color: className?.includes('dark') ? COLORS.BODY_TEXT_BLACK_20 : COLORS.DISABLED_BTN_TEXT,
  },
  '@media (min-width: 360px)': {
    padding: '0 32px',
  },
}));

export const MarkupWrapper = styled('div')(({ className }) => ({
  display: 'flex',
  height: '56px',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: '8px',
  background: className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.TEXT_WHITE,
  color: className?.includes('dark') ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK,
}));

export const ArticleWrapper = styled('div')(({ className }) => ({
  display: 'flex',
  padding: '16px 0px 16px 16px',
  gap: '8px',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const InputWrapper = styled('div')(({ className }) => ({
  width: '80px',
  borderLeft: `1px solid ${className?.includes('dark') ? COLORS.LIGHT_GREY_BORDER : COLORS.BORDER_GREY}`,
  position: 'relative',
  '& input': {
    color: className?.includes('dark') ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK,
    background: className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.TEXT_WHITE,
    width: '100%',
    border: 'none',
    outline: 'none',
    textAlign: 'right',
    fontFamily: "'Golos', sans-serif",
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    padding: '16px 34px 16px 16px',
  },
  '& > div': {
    position: 'absolute',
    top: '16px',
    right: '16px',
    lineHeight: '24px',
  },
}));
