import axios, { AxiosResponse } from 'axios';
import { API_CONFIG } from './config';
import { unauthorizedLogout } from './Action';

export const ApiService = {
  apiCall: <T>({
    baseURL = API_CONFIG.BASE_URL,
    endpoint = '',
    method = 'GET',
    query = {},
    headers = {},
  }: {
    baseURL?: string;
    endpoint: string;
    method?: string;
    query?: any;
    transformRequest?: any;
    headers?: { [key: string]: string } | null;
  }): Promise<AxiosResponse<T>> => {
    try {
      const api = axios.create({
        baseURL,
        headers: headers || {},
        withCredentials: true,
      });

      api.interceptors.request.use(async (config) => {
        // TODO: add auth token
        return config;
      });

      api.interceptors.response.use(
        (response) => response,
        // TODO: add refresh token
      );

      //TODO: propose to implement 401 error code and rewrite using 401 error
      switch (method) {
        case 'GET':
          api.get(endpoint, query).catch((e: any) => {
            const expired = 'Expired';
            const errorCode = e.response.data.error.errorCode;
            if (errorCode.includes(expired)) {
              unauthorizedLogout();
            }
          });
          return api.get(endpoint, query);
        case 'PATCH':
          return api.patch(endpoint, query);
        case 'POST':
          return api.post(endpoint, query);
        case 'PUT':
          return api.put(endpoint, query);
        case 'DELETE':
          return api.delete(endpoint, query);
        default:
          return api.get(endpoint, query);
      }
    } catch (e) {
      throw e;
    }
  },
};
