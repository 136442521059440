import React from 'react';
import { COLORS } from 'shared/constants';

type Props = {
  size?: number;
  stroke?: string;
  strokeWidth?: number;
};

export const Trash: React.FC<Props> = ({ size = 24, stroke = COLORS.MAIN_RED, strokeWidth = 1 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 5.06667H6.07692M19 5.06667H17.9231M17.9231 5.06667V17.8667C17.9231 18.5778 17.4923 20 15.7692 20C14.0462 20 10.0256 20 8.23077 20C7.51282 20 6.07692 19.5733 6.07692 17.8667C6.07692 16.16 6.07692 8.62222 6.07692 5.06667M17.9231 5.06667H14.6923M6.07692 5.06667H9.30769M10.4318 8.26667V16.8M13.5714 8.26667V16.8M9.30769 5.06667V5C9.30769 4.44771 9.75541 4 10.3077 4H12H13.6923C14.2446 4 14.6923 4.44772 14.6923 5V5.06667M9.30769 5.06667H14.6923"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};
