import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { RootState } from 'app/store';
import axios from 'axios';
import { editEstimateMarkupId } from 'entities/estimates';
import { ApiService, USER_STATUS, HEADERS, API_CONFIG } from 'shared/api';
import { text } from 'shared/constants';
import { CounterAgentType, ProjectType, MarkupType, TeamMemberType, ProjectStatsResponse } from '../model/types';
import {
  CreateCustomerRequest,
  CreateProjectRequest,
  EditMarkupRequestType,
  EditMarkupRequestTypeHidden,
  EditProjectRequest,
  TogglePingProjectRequest,
} from './types';

export const fetchProjects = createAsyncThunk('projects/fetchProjects', async (id: number, { rejectWithValue }) => {
  try {
    const { data } = await ApiService.apiCall<ProjectType[]>({
      endpoint: `/v1/company/${id}/project`,
      headers: {
        ...HEADERS,
        userStatus: USER_STATUS,
      },
    });

    return data;
  } catch (error) {
    const errorT = error as string;
    return rejectWithValue(errorT);
  }
});

export const fetchProjectStats = createAsyncThunk(
  'projects/fetchProjectStats',
  async (id: number, { rejectWithValue }) => {
    try {
      const { data } = await ApiService.apiCall<ProjectStatsResponse>({
        endpoint: `/v1/company/${id}/project/stats`,
        headers: {
          ...HEADERS,
          userStatus: USER_STATUS,
        },
      });

      return data;
    } catch (error) {
      const errorT = error as string;
      return rejectWithValue(errorT);
    }
  },
);

export const createNewProject = createAsyncThunk(
  'projects/createNewProject',
  async (reqBody: CreateProjectRequest, { rejectWithValue, getState }) => {
    const activeLanguage = (getState() as RootState).language.language;
    const { companyId, requestData } = reqBody;
    try {
      const { data } = await axios.post(`${API_CONFIG.BASE_URL}/v1/company/${companyId}/project`, requestData, {
        withCredentials: true,
      });

      toast.success(text[activeLanguage].notifications.projectCreatedSuccess);
      return data;
    } catch (error) {
      const errorT = error as string;
      return rejectWithValue(errorT);
    }
  },
);

export const editProject = createAsyncThunk(
  'projects/editProject',
  async (reqBody: EditProjectRequest, { rejectWithValue }) => {
    const { companyId, projectId, requestData } = reqBody;
    try {
      const { data } = await axios.patch(
        `${API_CONFIG.BASE_URL}/v1/company/${companyId}/project/${projectId}`,
        requestData,
        {
          withCredentials: true,
        },
      );

      return data;
    } catch (error) {
      const errorT = error as string;
      return rejectWithValue(errorT);
    }
  },
);

export const editPinProject = createAsyncThunk(
  'projects/editPinProject',
  async (reqBody: TogglePingProjectRequest, { rejectWithValue }) => {
    const { companyId, projectId, isPinned } = reqBody;
    try {
      const { data } = await ApiService.apiCall<ProjectType>({
        endpoint: `/v1/company/${companyId}/project/${projectId}`,
        method: 'PATCH',
        headers: {
          ...HEADERS,
          userStatus: USER_STATUS,
        },

        query: JSON.stringify({
          isPinned,
        }),
      });

      return data;
    } catch (error) {
      const errorT = error as string;
      return rejectWithValue(errorT);
    }
  },
);

export const fetchCounterAgents = createAsyncThunk(
  'projects/fetchCounterAgents',
  async (id: number, { rejectWithValue }) => {
    try {
      const { data } = await ApiService.apiCall<CounterAgentType[]>({
        endpoint: `/v1/company/${id}/counteragents`,
        headers: {
          ...HEADERS,
          userStatus: USER_STATUS,
        },
      });

      return data;
    } catch (error) {
      const errorT = error as string;
      return rejectWithValue(errorT);
    }
  },
);

export const fetchTeam = createAsyncThunk(
  'projects/fetchTeam',
  async (reqBody: { companyId: number; projectId: string }, { rejectWithValue }) => {
    const { companyId, projectId } = reqBody;
    try {
      const { data } = await ApiService.apiCall<TeamMemberType[]>({
        endpoint: `/v1/company/${companyId}/project/${projectId}/team`,
        headers: {
          ...HEADERS,
          userStatus: USER_STATUS,
        },
      });

      return data;
    } catch (error) {
      const errorT = error as string;
      return rejectWithValue(errorT);
    }
  },
);

// TODO: fix and test request
export const createCustomer = createAsyncThunk(
  'projects/createCustomer',
  async (reqBody: CreateCustomerRequest, { rejectWithValue }) => {
    const { companyId, firstName, lastName, phoneNumber, email } = reqBody;
    try {
      const { data } = await ApiService.apiCall<CounterAgentType>({
        endpoint: `/v1/company/${companyId}/counteragents`,
        method: 'POST',
        headers: {
          ...HEADERS,
          userStatus: USER_STATUS,
        },
        query: {
          firstName,
          lastName,
          phoneNumber,
          email,
          role: 'customer',
        },
      });

      return data;
    } catch (error: any) {
      const errorT = error as string;
      const alreadyExistErrorMsg = 'associated';
      const errorDescription = error!.response.data.error.errorDescription as string;
      if (errorDescription.includes(alreadyExistErrorMsg)) {
        return rejectWithValue(errorT);
      }
      return;
    }
  },
);

export const fetchMarkups = createAsyncThunk(
  'projects/fetchMarkups',
  async (reqBody: { companyId: number; projectId: string }, { rejectWithValue }) => {
    const { companyId, projectId } = reqBody;
    try {
      const { data } = await ApiService.apiCall<MarkupType[]>({
        endpoint: `/v1/company/${companyId}/project/${projectId}/article`,
        headers: {
          ...HEADERS,
          userStatus: USER_STATUS,
        },
      });

      return data;
    } catch (error) {
      const errorT = error as string;
      return rejectWithValue(errorT);
    }
  },
);

export const editMarkups = createAsyncThunk(
  'projects/editMarkups',
  async (reqBody: EditMarkupRequestType, { rejectWithValue }) => {
    const { companyId, projectId, markup, id } = reqBody;
    try {
      const { data } = await ApiService.apiCall<MarkupType>({
        endpoint: `/v1/company/${companyId}/project/${projectId}/article/${id}`,
        method: 'PATCH',
        headers: {
          ...HEADERS,
          userStatus: USER_STATUS,
        },
        query: {
          markup,
        },
      });

      return data;
    } catch (error) {
      const errorT = error as string;
      return rejectWithValue(errorT);
    }
  },
);

export const editMarkupsHiddenCopy = createAsyncThunk(
  'projects/editMarkupsHiddenCopy',
  async (reqBody: EditMarkupRequestTypeHidden, { rejectWithValue, dispatch }) => {
    const { companyId, projectId, items, estimateId } = reqBody;

    try {
      const { data } = await ApiService.apiCall<MarkupType[]>({
        endpoint: `/v1/company/${companyId}/project/${projectId}/article/hiddenCopy`,
        method: 'POST',
        headers: {
          ...HEADERS,
          userStatus: USER_STATUS,
        },
        query: {
          items,
        },
      });

      dispatch(editEstimateMarkupId({ estimateId, items: data }));
      return data;
    } catch (error) {
      const errorT = error as string;
      return rejectWithValue(errorT);
    }
  },
);
