import React, { useState } from 'react';
import {
  ChoosePositionsFromPriceListModal,
  AddTempPositionModal,
  EstimatePositionsTable,
  EditMarkupModal,
  CreatePositionsModal,
} from 'widgets';
import { ActionButton } from 'shared/ui';
import { PercentIcon, Plus, SaleIcon } from 'shared/assets/icons';
import { COLORS, devices, text } from 'shared/constants';
import { useAppSelector, useMediaQuery } from 'shared/lib';
import { EstimateButtonsBar, EstimatePriceListContainer, TableContainer } from './styles';

type Props = {
  projectId: number | null;
  estimateId?: number;
};

export const EstimatePriceList: React.FC<Props> = ({ projectId, estimateId }) => {
  const language = useAppSelector((state) => state.language.language);
  const theme = useAppSelector((state) => state.theme.theme);
  const projects = useAppSelector((state) => state.projects.projects);
  const [isOpenChooseFromPLModal, setIsOpenChooseFromPLModal] = useState(false);
  const [isOpenAddTempPositionModal, setIsOpenAddTempPositionModal] = useState(false);
  const [isOpenMarkupModal, setIsOpenMarkupModal] = useState(false);
  const [isOpenAddNewPositionModal, setIsOpenAddNewPositionModal] = useState(false);
  const isMobile = useMediaQuery(devices.mobile);
  const isTablet = useMediaQuery(devices.tablet);
  const projectCurrency = projects?.find((proj) => proj.id === projectId)?.currency;

  const isTabletOrMobile = isMobile || isTablet;

  const onChooseFromPriceListClick = () => {
    setIsOpenChooseFromPLModal(true);
  };

  const onAddTempPositionClick = () => {
    setIsOpenAddTempPositionModal(true);
  };

  const onAddNewPositionClick = () => {
    setIsOpenAddNewPositionModal(true);
  };

  const onMarkupExpensesClick = () => {
    setIsOpenMarkupModal(true);
  };

  const isDarkTheme = theme === 'dark';
  return (
    <EstimatePriceListContainer className={isDarkTheme ? 'dark' : ''}>
      <p>{text[language].positionsFromPriceList}</p>
      <EstimateButtonsBar>
        <ActionButton
          orangeBtn
          icon={!isTabletOrMobile && <SaleIcon />}
          text={text[language].chooseFromPriceList}
          onClick={onChooseFromPriceListClick}
          isPriceListMainButton={true}
        />
        <div>
          <ActionButton
            icon={!isTabletOrMobile && <Plus stroke={isDarkTheme ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK} />}
            text={isTabletOrMobile ? text[language].NewItem : text[language].AddNewItem}
            onClick={onAddNewPositionClick}
          />
          <ActionButton
            icon={!isTabletOrMobile && <Plus stroke={isDarkTheme ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK} />}
            text={isTabletOrMobile ? text[language].tempPosition : text[language].addTempPosition}
            onClick={onAddTempPositionClick}
          />
        </div>
        <ActionButton
          icon={!isTabletOrMobile && <PercentIcon stroke={isDarkTheme ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK} />}
          text={text[language].markupOnArticleExpenses}
          onClick={onMarkupExpensesClick}
        />
      </EstimateButtonsBar>
      <TableContainer className={isDarkTheme ? 'dark' : ''}>
        <EstimatePositionsTable />
      </TableContainer>
      <ChoosePositionsFromPriceListModal
        isOpen={isOpenChooseFromPLModal}
        handleClose={() => setIsOpenChooseFromPLModal(false)}
        projectId={projectId}
      />
      <EditMarkupModal
        isOpen={isOpenMarkupModal}
        handleClose={() => setIsOpenMarkupModal(false)}
        selectedProjectId={projectId}
        currentEstimateId={estimateId}
      />
      <AddTempPositionModal
        isOpen={isOpenAddTempPositionModal}
        handleClose={() => setIsOpenAddTempPositionModal(false)}
      />
      <CreatePositionsModal
        isOpen={isOpenAddNewPositionModal}
        handleClose={() => setIsOpenAddNewPositionModal(false)}
        projectCurrency={projectCurrency}
      />
    </EstimatePriceListContainer>
  );
};
