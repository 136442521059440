import React from 'react';
import { COLORS } from 'shared/constants';

type Props = {
  size?: number;
  isDarkTheme: boolean;
};

export const ProjectsIcon: React.FC<Props> = ({ size = 24, isDarkTheme }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        d="M4.5 10.871V17.1818C4.5 18.186 5.31403 19 6.31818 19H17.6818C18.686 19 19.5 18.186 19.5 17.1818V10.871M4.5 10.871V9.07626C4.5 8.07211 5.31402 7.25808 6.31818 7.25807L9.5 7.25806M4.5 10.871L11.5035 12.8591C11.8281 12.9512 12.1719 12.9512 12.4965 12.8591L19.5 10.871M19.5 10.871V9.07626C19.5 8.07211 18.686 7.25808 17.6818 7.25808H14.5M14.5 7.25808L9.5 7.25806M14.5 7.25808V6.12905V5.9091C14.5 5.40703 14.093 5.00001 13.5909 5.00001L10.4091 5C9.90702 5 9.5 5.40702 9.5 5.90909V6.12903V7.25806"
        stroke={isDarkTheme ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK}
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};
