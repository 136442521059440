import styled from '@emotion/styled';
import { COLORS, getSecondaryWhiteColor } from 'shared/constants';

export const SkeletonContainer = styled('div')(({ className }) => ({
  width: '50px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '28px',
  '& div': {
    height: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const LogoutButton = styled('button')(({ className }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  alignSelf: 'start',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  cursor: 'pointer',
  border: 'none',
  background: 'transparent',
  padding: '16px 32px',
  width: '100%',
  transition: 'all 0.2s linear',
  '& p': {
    lineHeight: '24px',
    fontSize: '16px',
    fontWeight: 500,
    color: className?.includes('dark') ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK,
  },
  '&:hover': {
    background: getSecondaryWhiteColor('0.2'),
    backdropFilter: 'blur(6px)',
  },
}));

export const ExitMenuButton = styled('button')(({ className }) => ({
  border: 0,
  backgroundColor: 'transparent',
  cursor: 'pointer',
}));

export const SideBarMenuElement = styled('div')(({ className }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  padding: '16px 32px',
  height: '56px',
  cursor: 'pointer',
  background: className?.includes('activeDark')
    ? `${COLORS.BACKGROUND_LIGHT_DARK}`
    : className?.includes('active')
    ? COLORS.ORANGE_LIGHT
    : 'transparent',
  backdropFilter: className?.includes('active') ? 'blur(6px)' : 'unset',
  borderRight: `2px solid ${className?.includes('active') ? COLORS.ORANGE_BTN_ACTIVE : 'transparent'}`,
  '& > div': {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  },
}));

export const TabTitle = styled('p')(({ className }) => ({
  lineHeight: '24px',
  fontSize: '16px',
  fontWeight: 500,
  color: className?.includes('dark') ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK,
}));

export const SideBarDownButtonsWrapper = styled('div')(({ className }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '24px',
  width: '100%',
  marginTop: 'auto',
  paddingBottom: '90px',
  '@media (min-width: 1024px)': {
    paddingBottom: '0px',
  },
}));

export const ElementsCounter = styled('div')(({ className }) => ({
  textAlign: 'center',
  '& > p': {
    color: COLORS.TEXT_WHITE,
    background: COLORS.ORANGE_BTN,
    borderRadius: '4px',
    padding: '0 4px',
    lineHeight: '20px',
    fontSize: '14px',
    fontWeight: 500,
  },
  '& > div': {
    minWidth: '50px',
  },
}));

export const SideBarContentWrapper = styled('div')(({ className }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
}));

export const SideBarMainContentContainer = styled('div')(({ className }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  gap: '40px',
  width: '100%',
  '& > div': {
    width: '100%',
  },
}));

export const LogoContainer = styled('div')(({ className }) => ({
  padding: '16px 32px 0px 32px',
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const BetaContainer = styled('div')(({ className }) => ({
  borderRadius: '4px',
  padding: '2px 6px',
  background: className?.includes('dark') ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK,
  '& > span': {
    color: className?.includes('dark') ? COLORS.TEXT_BLACK : COLORS.TEXT_WHITE,
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
  },
}));

export const SideBarHeader = styled('div')(({ className }) => ({
  display: 'flex',
  padding: '16px',
  justifyContent: 'space-between',
}));

export const SideBarContainer = styled('div')(({ className }) => ({
  fontFamily: "'Golos', sans-serif",
  position: 'fixed',
  zIndex: 4,
  left: 0,
  top: 0,
  bottom: 0,
  height: '100vh',
  width: '260px',
  textOverflow: 'ellipsis',
  borderRight: className?.includes('dark')
    ? `1px solid ${COLORS.LIGHT_GREY_BORDER}`
    : `1px solid ${COLORS.BORDER_GREY}`,
  background: className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.TEXT_WHITE,
  paddingBottom: '64px',
  '@media (min-width: 1024px)': {
    height: '100%',
    width: '306px',
    zIndex: 0,
  },
}));
