import React from 'react';
import { useNavigate } from 'react-router-dom';
import { routes, text } from 'shared/constants';
import { ErrorIcon } from 'shared/assets/icons';
import { useAppSelector } from 'shared/lib';
import { ActionButton } from 'shared/ui';
import { BackButtonWrapper, NoSubscriptionContainer } from './styles';

export const NoSubscriptionModal: React.FC = () => {
  const language = useAppSelector((state) => state.language.language);

  const navigate = useNavigate();

  //TODO: replace with new version 1.3
  return (
    <>
      <div
        style={{
          width: '100%',
          height: '100vh',
          backgroundColor: '#F8F8F8',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <NoSubscriptionContainer>
          <div>
            <ErrorIcon />
            <p>{text[language].subscriptionExpired}</p>
            <p>{text[language].pleaseUpdatePayment}</p>
            <BackButtonWrapper>
              <ActionButton
                text={text[language].Back}
                orangeBtn
                onClick={() => {
                  navigate(routes.auth);
                }}
              />
            </BackButtonWrapper>
          </div>
        </NoSubscriptionContainer>
      </div>
    </>
  );
};
