import styled from '@emotion/styled';
import { COLORS } from 'shared/constants';

export const ModalSmallContainer = styled('div')(({ className }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  width: '280px',
}));

export const DropDownContainer = styled('div')(({ className }) => ({
  position: 'absolute',
  top: 59,
  left: 0,
  width: '280px',
  '& ul': {
    width: '100%',
  },
  '& li': {
    width: '100%',
    justifyContent: 'flex-start',
  },
}));

export const ErrorMsg = styled('p')(({ className }) => ({
  maxWidth: '280px',
  color: COLORS.ORANGE_BTN,
  fontSize: '14px',
  lineHeight: '20px',
  marginTop: '8px',
  opacity: className?.includes('show') ? 1 : 0,
  height: className?.includes('show') ? '40px' : '0px',
  overflow: 'hidden',
  transition: 'height 0.3s ease-in-out, height 0.3s ease-in-out',
  top: 0,
  left: 0,
  right: 0,
}));

export const CurrenciesContainer = styled('div')(({ className }) => ({
  display: 'flex',
  flexDirection: 'row',
  '& button': {
    width: `calc(280px / ${className})`,
    padding: '11px 0',
  },
}));

export const DropDownSelect = styled('div')(({ className }) => ({
  position: 'relative',
}));

export const ModalArticleSelect = styled('div')(({ className }) => ({
  fontFamily: '"Golos", sans-serif',
  fontSize: '16px',
  fontWeight: 400,
  width: '100%',
  height: '56px',
  padding: className?.includes('placeholder') ? '16px' : '24px 16px 8px 16px',
  color: className?.includes('placeholderDark')
    ? COLORS.TEXT_GREY
    : className?.includes('Dark')
    ? COLORS.TEXT_WHITE
    : className?.includes('placeholder')
    ? COLORS.BODY_TEXT_35
    : COLORS.TEXT_BLACK,
  borderRadius: '8px',
  cursor: 'pointer',
  background: className?.includes('Dark') ? COLORS.BACKGROUND_BLACK : COLORS.TEXT_WHITE,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  border: '1px solid transparent',
  position: 'relative',
  '&:hover': {
    border: `1px solid ${className?.includes('warning') ? COLORS.ORANGE_BTN : COLORS.BODY_TEXT_BLACK_20}`,
  },
  '&:focus-within': {
    border: `1px solid ${className?.includes('warning') ? COLORS.ORANGE_BTN : COLORS.TEXT_GREY}`,
  },
  '& > span': {
    position: 'absolute',
    display: className?.includes('placeholder') ? 'none' : 'block',
    fontFamily: '"Golos", sans-serif',
    color: className?.includes('Dark') ? COLORS.BODY_TEXT_BLACK_20 : COLORS.ACTION_BAR_BLACK_BG,
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    top: '8px',
    left: '16px',
  },
}));

export const EditPositionButtonsWrapper = styled('div')(({ className }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  gap: className?.includes('mobile') ? '3px' : '8px',
  marginTop: className?.includes('mobile') ? '14px' : '32px',
  fontWeight: 400,
  '& button': {
    fontSize: '16px',
    padding: className?.includes('mobile') ? '3px 8px' : '8px 16px',
    lineHeight: '24px',
    width: '136px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: '8px',
  },
}));

export const EditPositionButton = styled('button')(({ className }) => ({
  border: `1px solid ${className?.includes('dark') ? 'transparent' : COLORS.BORDER_GREY}`,
  background: className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.TEXT_WHITE,
  transition: 'background 0.3s ease',
  color: className?.includes('dark') ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK,
  '&:active': {
    background: className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.GREY,
  },
  '&:hover': {
    background: className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.GREY,
  },
}));
