import React, { useEffect, useState } from 'react';
import { AddCustomerModal } from 'widgets/modals/AddCustomerModal/ui/AddCustomerModal';
import { createNewProject, resetProjectsLoading } from 'entities/project';
import { LoadingResultsT } from 'entities/loadingType';
import { text } from 'shared/constants';
import { useAppDispatch, useAppSelector } from 'shared/lib';
import { ActionButton, ModalWrapper } from 'shared/ui';
import CreateProjectModalForm from './CreateProjectModalForm/CreateProjectModalForm';
import { defaultValues } from '../model/defaultValues';
import { ButtonsWrapper } from './styles';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
}

export const CreateProjectModal: React.FC<Props> = ({ isOpen, handleClose }) => {
  const language = useAppSelector((state) => state.language.language);
  const company = useAppSelector((state) => state.company.company);
  const loadingCreatingProject = useAppSelector((state) => state.projects.loadingCreatingProject);
  const dispatch = useAppDispatch();
  const companyId = company && (company[0].id as number);
  const requestData = new FormData();

  const [projectInfo, setProjectInfo] = useState(defaultValues);
  const [openCreateCustomerModal, setIsOpenCreateCustomerModal] = useState(false);

  useEffect(() => {
    if (loadingCreatingProject === LoadingResultsT.SUCCEEDED) {
      handleClose();
      dispatch(resetProjectsLoading());
      setProjectInfo(defaultValues);
    }
  }, [loadingCreatingProject, handleClose, dispatch]);

  const createProject = async () => {
    requestData.append('name', projectInfo.name);
    projectInfo.address && requestData.append('address', projectInfo.address);
    requestData.append('square', projectInfo.square);
    projectInfo.budget && requestData.append('budget', projectInfo.budget);
    projectInfo.currency && requestData.append('currency', projectInfo.currency.title);
    projectInfo.image && requestData.append('image', projectInfo.image?.file);
    projectInfo.customer.id && requestData.append('customer', projectInfo.customer.id?.toString());

    if (companyId) {
      dispatch(
        createNewProject({
          companyId,
          requestData,
        }),
      );
    }
  };

  const isSaveButtonDisabled =
    !projectInfo.name ||
    !projectInfo.square ||
    Number(projectInfo.square) === 0 ||
    !projectInfo.currency ||
    !projectInfo.customer.id;

  return (
    <ModalWrapper isOpen={isOpen} title={text[language].projectPage.newProject} titleClassName="smallTitleSize">
      <CreateProjectModalForm
        projectInfo={projectInfo}
        setProjectInfo={setProjectInfo}
        openCreateCustomerModal={() => setIsOpenCreateCustomerModal(true)}
      />
      <ButtonsWrapper>
        <ActionButton
          text={text[language].Cancel}
          onClick={() => {
            handleClose();
            setProjectInfo(defaultValues);
          }}
        />
        <ActionButton
          isPending={loadingCreatingProject === LoadingResultsT.PENDING}
          text={text[language].projectPage.createProject}
          orangeBtn
          disabled={isSaveButtonDisabled}
          onClick={createProject}
          isPriceListMainButton={true}
        />
      </ButtonsWrapper>
      <AddCustomerModal isOpen={openCreateCustomerModal} handleClose={() => setIsOpenCreateCustomerModal(false)} />
    </ModalWrapper>
  );
};
