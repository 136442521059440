import styled from '@emotion/styled';
import { COLORS } from 'shared/constants';

export const StyledButton = styled('button')(({ className }) => ({
  fontFamily: "'Golos', sans-serif",
  background: className?.includes('dark')
    ? COLORS.BACKGROUND_BLACK
    : className?.includes('orangeBtn')
    ? COLORS.ORANGE_BTN
    : COLORS.TEXT_WHITE,
  color: className?.includes('dark')
    ? COLORS.TEXT_WHITE
    : className?.includes('orangeBtn')
    ? COLORS.TEXT_WHITE
    : COLORS.TEXT_BLACK,
  border: `1px solid ${
    className?.includes('orangeBtn') || className?.includes('dark') ? 'transparent' : COLORS.BORDER_GREY
  }`,
  transition: className?.includes('orangeBtn') ? 'unset' : 'background 0.2s linear',
  whiteSpace: 'nowrap',
  cursor: 'pointer',
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '8px',
  '&:active': {
    background: className?.includes('dark')
      ? COLORS.BACKGROUND_BLACK
      : className?.includes('orangeBtn')
      ? COLORS.ORANGE_BTN_ACTIVE
      : COLORS.GREY,
  },
  '&:hover': {
    background: className?.includes('dark')
      ? COLORS.BACKGROUND_BLACK
      : className?.includes('orangeBtn')
      ? COLORS.ORANGE_BTN_HOVERED
      : COLORS.GREY,
  },
  '&:disabled': {
    cursor: 'default',
    color:
      className?.includes('orangeDark') || className?.includes('orangeBtn')
        ? COLORS.BODY_TEXT_BLACK_70
        : COLORS.DISABLED_BTN_TEXT,
    background:
      className?.includes('orangeDark') && className?.includes('orangeBtn')
        ? COLORS.BACKGROUND_LIGHT_DARK
        : COLORS.GREY,
  },
}));
