import styled from '@emotion/styled';

export const AuthHeaderContainer = styled('div')(({ className }) => ({
  height: '56px',
  background: 'transparent',
  width: '100%',
  zIndex: 3,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '16px',
  position: 'fixed',
  '@media (min-width: 1024px)': {
    padding: '16px 80px',
  },
  '@media (min-width: 1920px)': {
    padding: '16px 160px',
  },
}));

export const ArrowBackContainer = styled('div')(({ className }) => ({
  cursor: 'pointer',
  border: '4px',
  position: 'absolute',
  left: '0px',
  '@media (min-width: 1024px)': {
    left: '-44px',
  },
}));

export const NavigationContainer = styled('div')(({ className }) => ({
  position: 'relative',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '44px',
  '@media (min-width: 1024px)': {
    paddingLeft: '0',
  },
  '& > div': {
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
}));
