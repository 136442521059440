import styled from '@emotion/styled';
import { COLORS } from 'shared/constants';

export const CurrencyButton = styled('button')(({ className }) => ({
  width: '100%',
  height: '50px',
  padding: '11px 16px',
  cursor: 'pointer',
  border: 0,
  backgroundColor: 'transparent',
  '&:disabled': {
    cursor: 'default',
  },
  '& p': {
    fontFamily: "'Golos', sans-serif",
    color: className?.includes('dark') ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
  },
  '@media (max-width: 769px)': {
    '& p': {
      fontSize: '18px',
      fontWeight: 500,
      lineHeight: '26px',
    },
  },
}));
