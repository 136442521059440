import React from 'react';
import { COLORS } from 'shared/constants';

type Props = {
  size?: number;
  stroke?: string;
  language: string;
  id?: string;
};

export const WorkIcon: React.FC<Props> = ({ size = 20, stroke = COLORS.ICON_GREEN, language, id }) => {
  if (language === 'EN') {
    return (
      <svg id={id} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
        <rect width="20" height="20" rx="4" fill={stroke} />
        <path
          d="M3.5 5H5.65745L7.16489 12.0571L9.03192 5H10.9681L12.8766 12.0857L14.5085 5H16.5L14.0106 15H11.9362L9.93085 7.85714L8.06383 15H5.98936L3.5 5Z"
          fill="white"
        />
      </svg>
    );
  }
  return (
    <svg id={id} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
      <rect width="20" height="20" rx="4" fill={stroke} />
      <path
        d="M6.5 5H10.4273C11.8042 5 12.8273 5.3 13.4964 5.9C14.1655 6.49048 14.5 7.28571 14.5 8.28571C14.5 9.28571 14.1655 10.0857 13.4964 10.6857C12.8273 11.2762 11.8042 11.5714 10.4273 11.5714H8.68182V15H6.5V5ZM10.2091 9.8C10.9848 9.8 11.5279 9.67143 11.8382 9.41429C12.1582 9.15714 12.3182 8.78095 12.3182 8.28571C12.3182 7.8 12.1582 7.42857 11.8382 7.17143C11.5279 6.90476 10.9848 6.77143 10.2091 6.77143H8.68182V9.8H10.2091Z"
        fill="white"
      />
    </svg>
  );
};
