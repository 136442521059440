import styled from '@emotion/styled';
import { COLORS } from 'shared/constants';

export const ButtonsWrapper = styled('div')(({ className }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
  '& button': {
    fontSize: '16px',
    padding: className?.includes('mobile') ? '3px 8px' : '8px 16px',
    lineHeight: '24px',
    height: '48px',
    width: '100%',
  },
  padding: '0px 14px',
  '@media (min-width: 360px)': {
    padding: '0 32px',
  },
  '@media (min-width: 768px)': {
    '& button': {
      width: '136px',
    },
  },
}));

export const InputWrapper = styled('div')(({ className }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  alignItems: 'center',
  justifyContent: 'center',
  '& > p': {
    fontFamily: "'Golos', sans-serif",
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'left',
    width: '100%',
    color: className?.includes('dark') ? COLORS.BODY_TEXT_BLACK_20 : COLORS.TEXT_BLACK,
  },
  '& input': {
    height: '56px',
  },
}));
