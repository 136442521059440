import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { API_CONFIG, ApiService, HEADERS } from 'shared/api';
import { AccountInfoType } from '../model/types';
import { EditAccountInfoRequest, TrialSubscriptionRequest } from './types';
import { editLocalAccountInfo } from '../model/slice';

export const getAccountInfo = createAsyncThunk('account/getInfo', async (_, { rejectWithValue }) => {
  try {
    const { data } = await ApiService.apiCall<AccountInfoType>({
      endpoint: `/v1/access`,
      headers: {
        ...HEADERS,
      },
    });

    return data;
  } catch (error) {
    const errorT = error as string;
    return rejectWithValue(errorT);
  }
});

export const editAccountInfo = createAsyncThunk(
  'account/editAccountInfo',
  async (reqBody: EditAccountInfoRequest, { rejectWithValue }) => {
    try {
      const { data } = await ApiService.apiCall<AccountInfoType>({
        endpoint: `/v1/access`,
        method: 'PATCH',
        headers: {
          ...HEADERS,
        },
        query: JSON.stringify({
          ...reqBody,
        }),
      });

      return data;
    } catch (error) {
      const errorT = error as string;
      return rejectWithValue(errorT);
    }
  },
);

export const addAccountImage = createAsyncThunk(
  'projects/addAccountImage',
  async (reqBody: FormData, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axios.post(`${API_CONFIG.BASE_URL}/v1/access/image`, reqBody, {
        withCredentials: true,
      });

      dispatch(editLocalAccountInfo(data));
      return data;
    } catch (error) {
      const errorT = error as string;
      return rejectWithValue(errorT);
    }
  },
);

export const editAccountImage = createAsyncThunk(
  'projects/editAccountImage',
  async (reqBody: FormData, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axios.patch(`${API_CONFIG.BASE_URL}/v1/access/image`, reqBody, {
        withCredentials: true,
      });

      dispatch(editLocalAccountInfo(data));
      return data;
    } catch (error) {
      const errorT = error as string;
      return rejectWithValue(errorT);
    }
  },
);

export const getTrialSubscription = createAsyncThunk(
  'account/getTrialSubscription',
  async (subscription: TrialSubscriptionRequest, { rejectWithValue }) => {
    try {
      const { data } = await ApiService.apiCall<AccountInfoType>({
        endpoint: `/v1/access`,
        method: 'PATCH',
        headers: {
          ...HEADERS,
        },
        query: JSON.stringify({
          ...subscription,
        }),
      });

      return data;
    } catch (error) {
      const errorT = error as string;
      return rejectWithValue(errorT);
    }
  },
);
