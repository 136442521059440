import { ICategory } from 'entities/category';
import { IPriceList } from 'entities/priceList';

export const sortCategoriesAndAddPositions = (categories: ICategory[], priceList: IPriceList[] | null) => {
  const sortedCategories = [...categories].sort((a, b) => {
    if (a.primary && !b.primary) {
      return 1;
    }
    if (!a.primary && b.primary) {
      return -1;
    }
    return 0;
  });

  return sortedCategories.map((cat) => {
    const positions = priceList?.filter((pos) => pos.category?.id === cat?.id);
    return {
      ...cat,
      positions: positions || [],
    };
  });
};
