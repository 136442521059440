import styled from '@emotion/styled';
import { COLORS } from 'shared/constants';

export const ButtonsWrapper = styled('div')(({ className }) => ({
  display: 'flex',
  paddingTop: '16px',
  gap: '8px',
  width: '100%',
  '& button': {
    width: '100%',
    height: '40px',
    padding: '8px 16px',
  },
  padding: '0px 24px',
  '@media (min-width: 361px)': {
    padding: '0 32px',
  },
}));

export const AddCustomerModalContainer = styled('div')(({ className }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  margin: '32px auto',
  '& input': {
    width: '280px',
  },
  padding: '0px 24px',
  '@media (min-width: 361px)': {
    padding: '0 32px',
  },
}));

export const InputGroups = styled('div')(({ className }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  '& span': {
    fontFamily: "'Golos', sans-serif",
    color: className?.includes('dark') ? COLORS.BODY_TEXT_BLACK_20 : COLORS.ACTION_BAR_BLACK_BG,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
  },
}));
