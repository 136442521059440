import styled from '@emotion/styled';
import { COLORS } from 'shared/constants';

export const BreadcrumbsWrapper = styled('div')(({ className }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '4px',
  maxWidth: '300px',
  width: '100%',
  overflowX: 'auto',
  '& > div': {
    maxWidth: `calc(300px / ${className || 1})`,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  '@media (min-width: 480px)': {
    maxWidth: '400px',
    '& > div': {
      maxWidth: '300px',
    },
  },
  '@media (min-width: 680px)': {
    maxWidth: 'unset',
    '& > div': {
      maxWidth: 'unset',
      fontSize: '14px',
    },
  },
}));

export const SinglePathElement = styled('div')(({ className }) => ({
  fontSize: '12px',
  lineHeight: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:first-of-type': {
    flexShrink: 0,
  },
  '& a, & span': {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: className?.includes('dark') ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK,
  },
  '& > svg': {
    transform: 'rotate(-90deg)',
    flexShrink: 0,
  },
}));
