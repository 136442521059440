import React, { useState } from 'react';
import { CloseButton, SearchIcon } from 'shared/assets/icons';
import { resetFilteredPriceList, resetSearchingPriceList, setFilteredPriceList } from 'entities/priceList';
import { resetFilteredCategories, setFilteredCategories } from 'entities/category';
import { text, devices, COLORS } from 'shared/constants';
import { useMediaQuery, useAppSelector, useAppDispatch, GetSearchedPositions, GetSearchedCategories } from 'shared/lib';
import { Input } from 'shared/ui';
import { ClearSearchButton, PriseListHeaderContainer, SearchContainer, SearchIconContainer, Title } from './styles';

export const PriceListHeader: React.FC = () => {
  const isMobile = useMediaQuery(devices.mobile);
  const isTablet = useMediaQuery(devices.tablet);
  const language = useAppSelector((state) => state.language.language);
  const priceList = useAppSelector((state) => state.priceList.priceList);
  const categories = useAppSelector((state) => state.categories.categories);
  const theme = useAppSelector((state) => state.theme.theme);

  const dispatch = useAppDispatch();
  const [search, setSearch] = useState<string>('');

  const clearSearch = () => {
    setSearch('');
    dispatch(resetFilteredPriceList());
    dispatch(resetFilteredCategories());
    dispatch(resetSearchingPriceList());
  };

  const isDarkTheme = theme === 'dark';
  return (
    <PriseListHeaderContainer className={isMobile || isTablet ? 'portraitPLHeader' : ''}>
      <Title className={isDarkTheme ? 'dark' : ''}>{text[language].PriceList}</Title>
      <SearchContainer className={isDarkTheme ? 'dark' : ''}>
        <SearchIconContainer>
          <SearchIcon active={!!search} isDarkTheme={isDarkTheme} />
        </SearchIconContainer>
        <Input
          placeholder={text[language].SearchItemByName}
          className={isDarkTheme ? 'dark' : ''}
          value={search}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            if (!search && !event.target.value.trim()) {
              return false;
            }
            if (!priceList || !categories) return;
            dispatch(setFilteredPriceList(GetSearchedPositions(event.target.value, priceList)));
            dispatch(setFilteredCategories(GetSearchedCategories(event.target.value, categories, priceList)));

            setSearch(event.target.value);
            if (!event.target.value.trim().length) {
              clearSearch();
            }
          }}
          withoutLabel={true}
        />
        {!!search && (
          <ClearSearchButton
            onClick={() => {
              clearSearch();
            }}
          >
            <CloseButton stroke={isDarkTheme ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK} />
          </ClearSearchButton>
        )}
      </SearchContainer>
    </PriseListHeaderContainer>
  );
};
