import React from 'react';
import { text } from 'shared/constants';
import { useAppSelector } from 'shared/lib';
import { ActionButton, ModalWrapper } from 'shared/ui';
import { ContentWrapper } from './styles';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  email: string;
}

const CreateCustomerErrorModal: React.FC<Props> = ({ isOpen, handleClose, email }) => {
  const language = useAppSelector((state) => state.language.language);
  const theme = useAppSelector((state) => state.theme.theme);

  const isDarkTheme = theme === 'dark';
  return (
    <ModalWrapper isOpen={isOpen} title={text[language].unableToAddCustomer}>
      <ContentWrapper className={isDarkTheme ? 'dark' : ''}>
        <p>{`${text[language].customerWithEmail} ${email} ${text[language].wasAlreadyInvited}`}</p>
        <ActionButton text={text[language].Cancel} onClick={handleClose} />
      </ContentWrapper>
    </ModalWrapper>
  );
};

export default CreateCustomerErrorModal;
