import React from 'react';

type Props = {
  width?: number;
  height?: number;
};

export const CheckboxChecked: React.FC<Props> = ({ width = 24, height = 24 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2" y="2" width="20" height="20" rx="4" fill="url(#paint0_linear_398_7526)" />
      <path
        opacity="0.99"
        d="M10.1576 16.5C10.0142 16.5002 9.87215 16.4718 9.73968 16.4165C9.60721 16.3612 9.4869 16.28 9.38565 16.1777L6.31492 13.0845C6.11232 12.8778 5.9991 12.5987 6.00001 12.3083C6.00091 12.0178 6.11585 11.7395 6.31973 11.5341C6.52362 11.3287 6.79989 11.2129 7.08824 11.212C7.37658 11.2111 7.65357 11.3251 7.85875 11.5292L10.1575 13.8449L16.1413 7.81721C16.3464 7.61312 16.6234 7.49909 16.9118 7.50001C17.2001 7.50093 17.4764 7.61672 17.6803 7.82212C17.8842 8.02751 17.9991 8.30583 18 8.59629C18.0009 8.88676 17.8877 9.16579 17.6851 9.37246L10.9295 16.1778C10.8282 16.2801 10.7079 16.3612 10.5754 16.4165C10.443 16.4718 10.301 16.5002 10.1576 16.5Z"
        fill="white"
      />
      <defs>
        <linearGradient id="paint0_linear_398_7526" x1="2" y1="22" x2="22" y2="2" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF8C21" />
          <stop offset="0.880208" stopColor="#FFD200" />
        </linearGradient>
      </defs>
    </svg>
  );
};
