import React from 'react';
import { CircularSpinner } from './styles';

type Props = {
  isOrange?: boolean;
};

export const CircularProgress: React.FC<Props> = ({ isOrange = false }) => {
  return (
    <CircularSpinner className={isOrange ? 'orangeSpinner' : ''}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </CircularSpinner>
  );
};
