export function getCurrencySymbol(currencyCode: string | null) {
  switch (currencyCode) {
    case 'UAH':
      return '₴';
    case 'USD':
      return '$';
    case 'EUR':
      return '€';
    case 'GBP':
      return '£';
    default:
      return '';
  }
}
