export const defaultValues: ItemValues = {
  positionName: '',
  positionArticle: '',
  positionUnit: '',
  positionTotal: '',
  positionCost: '',
};

export interface ItemValues {
  positionName: string;
  positionArticle: string;
  positionUnit: string;
  positionTotal: string;
  positionCost?: string;
}
