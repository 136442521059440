import React from 'react';
import { COLORS } from 'shared/constants';
import { useAppSelector } from 'shared/lib';

type Props = {
  isActive: boolean;
};

export const DisplayListIcon: React.FC<Props> = ({ isActive }) => {
  const theme = useAppSelector((state) => state.theme.theme);
  const isDarkTheme = theme === 'dark';

  const stroke = () => {
    if (isActive && !isDarkTheme) return COLORS.TEXT_BLACK;
    if (!isActive && !isDarkTheme) return COLORS.BODY_TEXT_30;
    if (isActive && isDarkTheme) return COLORS.TEXT_WHITE;
    if (!isActive && isDarkTheme) return COLORS.TEXT_GREY;
  };
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M19 6H5M19 10H5M19 14H5M19 18H5" stroke={stroke()} strokeLinecap="round" />
    </svg>
  );
};
