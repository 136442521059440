import { PriceItemType } from 'entities/estimates';
import { EstimateMarkupsType } from 'entities/project';
import { getMarkupByArticle } from './getMarkupByArticle';

export const getTotalCounts = (priceItems: PriceItemType[], markups: EstimateMarkupsType) => {
  return priceItems.reduce(
    (accumulator, item) => {
      if (!item.funds) return accumulator;
      const { total, cost } = item.funds;
      const generalTotal = +total * item.quantity;
      const generalCost = cost ? +cost * item.quantity : generalTotal;
      const currentMarkup = getMarkupByArticle(item, markups);

      const totalWithMarkup = generalTotal + generalTotal * (currentMarkup / 100);
      accumulator.cost += cost ? +cost : +total;
      accumulator.total += +total;
      accumulator.generalTotal += generalTotal;
      accumulator.generalCost += generalCost;
      accumulator.totalWithMarkup += totalWithMarkup;
      accumulator.quantity += Number(item.quantity);

      return accumulator;
    },
    {
      total: 0,
      cost: 0,
      generalTotal: 0,
      generalCost: 0,
      totalWithMarkup: 0,
      quantity: 0,
    },
  );
};
