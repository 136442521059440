import React from 'react';
import ReactDOM from 'react-dom';
import { COLORS } from 'shared/constants';
import { CloseButton } from 'shared/assets/icons';
import { ModalBackground, ModalCloseBtn, ModalSubTitle, ModalTitle, StyledModalWrapper } from './styles';
import { useAppSelector } from 'shared/lib';

interface Props {
  children: React.ReactNode;
  isOpen: boolean;
  onCloseButtonClick?: () => void;
  title: string;
  subtitle?: string;
  titleClassName?: string;
}
export const ModalWrapper: React.FC<Props> = ({
  children,
  isOpen,
  onCloseButtonClick,
  title,
  subtitle,
  titleClassName,
}) => {
  const theme = useAppSelector((state) => state.theme.theme);
  if (!isOpen) return null;
  const modalRoot = document.getElementById('modal-root');
  if (!modalRoot) return null;

  const isDarkTheme = theme === 'dark';

  return ReactDOM.createPortal(
    <ModalBackground className={isDarkTheme ? 'dark' : ''}>
      <StyledModalWrapper className={isDarkTheme ? `dark ${titleClassName}` : titleClassName}>
        {!!onCloseButtonClick && (
          <ModalCloseBtn onClick={onCloseButtonClick}>
            <CloseButton stroke={isDarkTheme ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK} />
          </ModalCloseBtn>
        )}
        <ModalTitle className={isDarkTheme ? `dark ${titleClassName}` : titleClassName}>
          <p>{title}</p>
        </ModalTitle>
        {subtitle && (
          <ModalSubTitle className={isDarkTheme ? 'dark' : ''}>
            <p>{subtitle}</p>
          </ModalSubTitle>
        )}
        {children}
      </StyledModalWrapper>
    </ModalBackground>,
    modalRoot,
  );
};
