import React from 'react';
import { motion } from 'framer-motion';
import { useAppSelector } from 'shared/lib';
import { COLORS, text } from 'shared/constants';
import { CurrenciesType } from 'entities/priceList';

import { CurrencyButton } from './styles';

type Props = {
  item: CurrenciesType;
  setSelectedCurrency: (item: CurrenciesType) => void;
  disabled?: boolean;
  isSelected: boolean;
};

export const CurrenciesTabs: React.FC<Props> = ({ item, setSelectedCurrency, disabled = false, isSelected }) => {
  const language = useAppSelector((state) => state.language.language);
  const theme = useAppSelector((state) => state.theme.theme);

  const isDarkTheme = theme === 'dark';
  return (
    <motion.div
      onClick={() => {
        setSelectedCurrency(item);
      }}
      style={{
        position: 'relative',
      }}
    >
      <CurrencyButton disabled={disabled} className={isDarkTheme ? 'dark' : ''}>
        <p>{text[language][item]}</p>
      </CurrencyButton>
      {isSelected && <ActiveLine />}
    </motion.div>
  );
};

function ActiveLine() {
  return (
    <motion.div
      layoutId="activeItem"
      style={{
        zIndex: 2,
        width: '100%',
        height: '2px',
        position: 'absolute',
        bottom: 2,
        left: 0,
        backgroundColor: COLORS.ORANGE_BTN,
      }}
    />
  );
}
