export type CreateProjectRequest = {
  companyId?: number;
  requestData: FormData;
};

export type CreateCustomerRequest = {
  companyId: number;
  firstName: string;
  lastName: string;
  phoneNumber?: string;
  email: string;
};

export type EditMarkupRequestType = {
  companyId: number;
  projectId: number;
  id: number;
  markup: number;
  articleType?: ArticleType;
  estimateId?: number;
};

export type EditMarkupRequestTypeHidden = {
  companyId: number;
  projectId: number;
  items: MarkupRequestType[];
  estimateId?: number;
};

export type MarkupRequestType = {
  id: number;
  markup: number;
  name: ArticleType;
};

export enum ArticleType {
  materialsArticle = 'materialsArticle',
  workArticle = 'workArticle',
}

export type TogglePingProjectRequest = {
  companyId?: number;
  projectId: number;
  isPinned: boolean;
};

export type EditProjectRequest = {
  companyId?: number;
  projectId: number;
  requestData: FormData;
};
