import styled from '@emotion/styled';
import { COLORS } from 'shared/constants';

export const ProjectWrapper = styled('div')(({ className }) => ({
  width: '160px',
  height: '170px',
  borderRadius: '16px',
  boxShadow: className?.includes('dark') ? 'unset' : COLORS.TABLE_DROPDOWN_BOX_SHADOW,
  backgroundColor: className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.TEXT_WHITE,
  cursor: 'pointer',
  '@media (min-width: 1024px)': {
    width: '190px',
    height: '200px',
  },
}));

export const ExistingProject = styled('div')(({ className }) => ({
  position: 'relative',
  display: 'flex',
  height: '100%',
  borderRadius: '16px',
  transition: 'transform 0.3s ease',
  '& > div.image-wrapper': {
    position: 'absolute',
    width: '160px',
    height: '170px',
    borderRadius: '16px',
    background: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    '@media (min-width: 1024px)': {
      width: '190px',
      height: '200px',
    },
  },
  '& .hidden ': {
    display: 'block',
  },
  '&:hover .hidden': {
    display: 'block',
  },
  '@media (min-width: 1024px)': {
    '& .hidden ': {
      display: 'none',
    },
  },
}));

export const PinWrapper = styled('div')(({ className }) => ({
  position: 'absolute',
  top: '12px',
  right: '12px',
  padding: '5px',
  borderRadius: '8px',
  width: '34px',
  height: '34px',
  backdropFilter: 'blur(5px)',
  background: COLORS.PROJECT_PIN_BG,
}));

export const ProjectInfo = styled('div')(({ className }) => ({
  marginTop: 'auto',
  display: 'flex',
  flexDirection: 'column',
  padding: '12px 16px',
  background: COLORS.PROJECT_PREVIEW_INFO_BG,
  backdropFilter: 'blur(5px)',
  color: COLORS.TEXT_WHITE,
  width: '100%',
  borderBottomLeftRadius: '16px',
  borderBottomRightRadius: '16px',
  '& span:not(:first-of-type)': {
    fontSize: '10px',
    fontWeight: 400,
    lineHeight: '14px',
  },
  '& span:nth-of-type(2)': {
    margin: '4px 0 2px',
  },
  '@media (min-width: 1024px)': {
    '& span:not(:first-of-type)': {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
}));

export const ProjectTitle = styled('span')(({ className }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '20px',
  '@media (min-width: 1024px)': {
    fontSize: '16px',
    lineHeight: '24px',
  },
}));

export const NewProject = styled('div')(({ className }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '16px',
  height: '100%',
  borderRadius: '16px',
  background: className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.TEXT_WHITE,
  '& p': {
    fontFamily: "'Golos', sans-serif",
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    color: className?.includes('dark') ? COLORS.TEXT_WHITE : COLORS.BODY_TEXT_BLACK_20,
  },
  '&:hover p': {
    background: COLORS.ORANGE_TEXT_HOVERED,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },

  '&:hover stop:first-of-type': {
    stopColor: COLORS.ORANGE_BTN_ACTIVE,
  },
  '&:hover stop:last-of-type': {
    stopColor: COLORS.ORANGE_SECOND_GRAD_COLOR,
  },

  '@media (min-width: 1024px)': {
    '& p': {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
}));
