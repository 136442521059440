import styled from '@emotion/styled';
import { COLORS } from 'shared/constants';

export const Page404Container = styled('div')(({ className }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  overflowY: 'auto',
  height: '100vh',
  flex: 1,
  backgroundColor: COLORS.MODAL_BG,
  '& p': {
    fontFamily: "'Golos', sans-serif",
    fontStyle: 'normal',
    color: COLORS.TEXT_BLACK,
    textAlign: 'center',
  },
  '& p:first-of-type': {
    fontWeight: 600,
    fontSize: '18px',
    '@media (min-width: 768px)': {
      fontSize: '24px',
    },
  },
  '& p:last-of-type': {
    fontWeight: 500,
    fontSize: '16px',
    '@media (min-width: 768px)': {
      fontSize: '18px',
    },
  },
}));
