import React from 'react';
import { COLORS } from 'shared/constants';

type Props = {
  size?: number;
  stroke?: string;
  id?: string;
};

export const MaterialsIcon: React.FC<Props> = ({ size = 20, stroke = COLORS.ICON_PURPLE, id }) => {
  return (
    <svg id={id} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
      <rect width="20" height="20" rx="4" fill={stroke} />
      <path
        d="M5 5H7.08333L10.0417 10.6286L12.9861 5H15V15H12.9167V8.64286L10.625 13H9.375L7.08333 8.64286V15H5V5Z"
        fill="white"
      />
    </svg>
  );
};
