import React from 'react';
import { COLORS } from 'shared/constants';

type Props = {
  size?: number;
  isDarkTheme: boolean;
};

export const PriceListIcon: React.FC<Props> = ({ size = 24, isDarkTheme }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        d="M8.87947 8.93077L8.87952 8.87945M4.5 8.00357L4.50001 10.4758C4.50001 11.0266 4.50001 11.3019 4.5637 11.5602C4.62017 11.7892 4.71324 12.0075 4.83934 12.2068C4.98158 12.4316 5.18026 12.6223 5.57761 13.0036L10.7826 17.9989C11.6511 18.8324 12.0853 19.2492 12.5808 19.4023C13.0167 19.5371 13.4838 19.5323 13.9169 19.3886C14.4091 19.2253 14.8347 18.7997 15.6859 17.9485L17.9485 15.6859C18.7997 14.8347 19.2253 14.4091 19.3886 13.9169C19.5323 13.4838 19.5371 13.0167 19.4023 12.5808C19.2492 12.0853 18.8324 11.6511 17.9989 10.7825L13.0036 5.57761C12.6223 5.18025 12.4316 4.98157 12.2068 4.83933C12.0076 4.71324 11.7892 4.62016 11.5603 4.56369C11.302 4.5 11.0266 4.5 10.4759 4.5L8.00356 4.5C6.77719 4.5 6.16401 4.5 5.6956 4.73867C5.28358 4.9486 4.9486 5.28359 4.73866 5.69562C4.5 6.16403 4.5 6.77721 4.5 8.00357Z"
        stroke={isDarkTheme ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
