import styled from '@emotion/styled';
import { COLORS } from 'shared/constants';

export const ControlsContainer = styled('div')(({ className }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '8px',
  padding: '0 16px',
  width: '100%',
  '& > button': {
    fontFamily: "'Golos', sans-serif",
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '4px',
    outline: 'none',
    cursor: 'pointer',
    borderRadius: '8px',
    height: '40px',
  },
  '@media (min-width: 1024px)': {
    padding: '0 32px',
  },
}));

export const LightThemeButton = styled('button')(({ className }) => ({
  border: className?.includes('active') ? 'none' : `1px solid ${COLORS.LIGHT_GREY_BORDER}`,
  background: className?.includes('active') ? COLORS.ORANGE_LIGHT : 'transparent',
  color: className?.includes('active') ? COLORS.ORANGE_BTN : COLORS.TEXT_GREY,
}));

export const DarkThemeButton = styled('button')(({ className }) => ({
  border: className?.includes('active') ? 'none' : `1px solid ${COLORS.BORDER_GREY}!important`,
  background: className?.includes('active') ? COLORS.BACKGROUND_LIGHT_DARK : 'transparent',
  color: className?.includes('active') ? COLORS.ORANGE_BTN : COLORS.DISABLED_BTN_TEXT,
}));
