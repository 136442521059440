import { CurrenciesType, IPriceList } from '../';

export function updateArticleById(priceList: IPriceList[], idsToUpdate: number[], newArticle: number): IPriceList[] {
  return priceList.map((item) => {
    if (idsToUpdate.includes(item.id)) {
      return { ...item, article: newArticle };
    }
    return item;
  });
}

export function filterModalPriceListByProjectCurrency(
  priceList: IPriceList[],
  currency?: CurrenciesType,
): IPriceList[] {
  if (!currency) return priceList;
  return priceList.filter((position) => position.funds[currency]);
}
