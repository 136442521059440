import styled from '@emotion/styled';
import { COLORS } from 'shared/constants';

export const StyledHeader = styled('header')(({ className }) => ({
  fontFamily: "'Golos', sans-serif",
  display: 'flex',
  paddingTop: '34px',
  justifyContent: 'flex-end',
  padding: '16px 0',
  background: className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.TEXT_WHITE,
  marginBottom: '32px',
  fontSize: '14px',
}));

export const HeaderInfoContainer = styled('div')(({ className }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
  gap: '16px',
  alignItems: 'center',
  lineHeight: '18px',
  '& > div': {
    color: className?.includes('dark') ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK,
  },
}));

export const SkeletonContainer = styled('div')(({ className }) => ({
  width: '50px',
  height: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const GoToWeb = styled('div')(({ className }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '10px',
  border: `1px solid ${className?.includes('dark') ? COLORS.LIGHT_GREY_BORDER : COLORS.BORDER_GREY}`,
  background: className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.TEXT_WHITE,
  '& p': {
    textAlign: 'center',
    fontWeight: 500,
    fontSize: '12px',
    background: COLORS.ORANGE_TEXT_HOVERED,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
}));

export const MenuBlock = styled('div')(({ className }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: 'calc(100vw - 20px)',
  height: '60px',
  minHeight: '60px',
}));

export const MenuButton = styled('button')(({ className }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '40px',
  height: '40px',
  border: 0,
  backgroundColor: 'transparent',
}));

export const Container = styled('div')(({ className }) => ({
  width: className?.includes('fullWidth') ? '100%' : '80%',
  maxWidth: '1200px',
  margin: '0 auto',
}));
