import React, { useEffect, useState } from 'react';
import {
  addCheckedCategories,
  addCheckedPositions,
  removeCheckedCategories,
  removeCheckedPositions,
} from 'entities/estimatesFilter';
import { ICategoryEstimates } from 'entities/category';

import { ToggleAccordion } from 'shared/ui';
import { Checkbox } from 'shared/ui';
import { useAppSelector, useAppDispatch, getArrayOfIds } from 'shared/lib';
import { COLORS } from 'shared/constants';
import { getTotalCounts } from 'widgets/EstimatePositionsTable/model';
import { CategoryCount, CategoryName, TableRowContainer } from './styles';
import { ButtonTableCellContainer, MainTableCellContainer, RowButtonWrapper, TableCell } from '../styles';

interface Props {
  categoryData: ICategoryEstimates;
  positionsLength?: number;
  isOpened: boolean;
  setIsOpened: () => void;
  isPossibleToEdit: boolean;
}

const EstimatePosCategoryTableRow: React.FC<Props> = ({
  categoryData,
  positionsLength,
  isOpened,
  setIsOpened,
  isPossibleToEdit,
}) => {
  const categories = useAppSelector((state) => state.estimates.estimatePriceListCategories);
  const positions = useAppSelector((state) => state.estimates.estimatePriceList);
  const tempMarkups = useAppSelector((state) => state.projects.tempProjectMarkup);
  const checkedCategoriesIds = useAppSelector((state) => state.estimatesFilter.checkedCategoryIds);
  const theme = useAppSelector((state) => state.theme.theme);
  const dispatch = useAppDispatch();

  const [checked, setChecked] = useState(false);

  const calculatePriceItems = () => {
    if (!categoryData.positions || !tempMarkups) return;
    return getTotalCounts(categoryData.positions, tempMarkups);
  };

  useEffect(() => {
    if (checkedCategoriesIds.includes(categoryData.id)) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [checkedCategoriesIds, categoryData.id]);

  const toggleCheckbox = () => {
    const currentCategory = categories?.find((cat) => cat.id === categoryData.id);
    const checkedPositions = positions?.filter((pos) => pos.category?.id === currentCategory?.id);

    if (checked) {
      dispatch(removeCheckedCategories([currentCategory?.id]));
      dispatch(removeCheckedPositions(getArrayOfIds(checkedPositions)));
    } else {
      dispatch(addCheckedCategories([currentCategory?.id]));
      dispatch(addCheckedPositions(getArrayOfIds(checkedPositions)));
    }
    setChecked(!checked);
  };
  const isDarkTheme = theme === 'dark';
  return (
    <TableRowContainer className={isDarkTheme ? 'dark' : ''}>
      {isPossibleToEdit && (
        <ButtonTableCellContainer>
          <div style={{ position: 'relative' }}>
            <Checkbox toggleCheckbox={toggleCheckbox} checked={checked} />
          </div>
        </ButtonTableCellContainer>
      )}
      <ButtonTableCellContainer>
        <RowButtonWrapper>
          <ToggleAccordion
            size={24}
            color={isDarkTheme ? COLORS.TEXT_WHITE : COLORS.TEXT_GREY}
            isOpen={isOpened}
            setIsOpen={setIsOpened}
          />
        </RowButtonWrapper>
      </ButtonTableCellContainer>
      <MainTableCellContainer>
        <CategoryName className={isDarkTheme ? 'dark' : ''}>{categoryData.name}</CategoryName>
        <CategoryCount className={isDarkTheme ? 'dark' : ''}>({positionsLength})</CategoryCount>
      </MainTableCellContainer>
      <TableCell style={{ width: '80px' }}>{calculatePriceItems()?.quantity}</TableCell>
      <TableCell style={{ width: '130px' }}></TableCell>
      <TableCell style={{ width: '100px' }}>{calculatePriceItems()?.total.toFixed(2)}</TableCell>
      <TableCell style={{ width: '100px' }}>{calculatePriceItems()?.cost.toFixed(2)}</TableCell>
      <TableCell style={{ width: '100px' }}>{calculatePriceItems()?.generalTotal.toFixed(2)}</TableCell>
      <TableCell style={{ width: '100px' }}>{calculatePriceItems()?.generalCost.toFixed(2)}</TableCell>
      <TableCell style={{ width: '70px' }}></TableCell>
      <TableCell style={{ width: '120px' }}>{calculatePriceItems()?.totalWithMarkup.toFixed(2)}</TableCell>
    </TableRowContainer>
  );
};

export default EstimatePosCategoryTableRow;
