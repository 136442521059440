import { EstimateType } from 'entities/estimates';
import { MarkupType, EstimateMarkupsType } from 'entities/project';
import { text } from 'shared/constants';

export const currentEstimateMarkups = (estimate: EstimateType, markups: MarkupType[] | null): EstimateMarkupsType => {
  const workMarkupId = estimate.workArticle;
  const workMarkup = markups?.find((markup) => markup.id === workMarkupId)?.markup || 0;
  const materialsMarkupId = estimate.materialsArticle;
  const materialsMarkup = markups?.find((markup) => markup.id === materialsMarkupId)?.markup || 0;
  return {
    workMarkup,
    materialsMarkup,
  };
};

export const getDefaultProjectMarkups = (markups: MarkupType[]): EstimateMarkupsType => {
  const activeProjectMarkups = markups?.filter((markup) => !markup.isHidden);
  const workMarkup =
    activeProjectMarkups?.find((markup) => markup.name === text['UA'].Work || markup.name === text['EN'].Work)
      ?.markup || 0;
  const materialsMarkup =
    activeProjectMarkups?.find(
      (markup) => markup.name === text['UA'].Materials || markup.name === text['EN'].Materials,
    )?.markup || 0;
  return {
    workMarkup,
    materialsMarkup,
  };
};