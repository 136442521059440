export const text: any = {
  UA: {
    projectPage: {
      project: 'Проєкти',
      singleProject: 'Проєкт',
      createNewProject: 'Створити проєкт',
      newProject: 'Новий проєкт',
      createProject: 'Створити проект',
      currency: 'Валюта',
      projectInfo: 'Інформація про проєкт',
      projectName: 'Назва проєкту',
      address: 'Адреса (необов’язково)',
      square: 'Площа, м2',
      budgetProject: 'Бюджет проєкту (необов’язково)',
      budget: 'Бюджет',
      budgetRequired: 'Бюджет проєкту',
      cannotChangeCurrency: 'Будьте уважні при виборі валюти, після створення проєкту, змінити її неможливо.',
      projectCustomer: 'Замовник проєкту',
      addCustomerToProject: 'Додати замовника проєкту',
      addPhoto: 'Додати фото',
      dropPhotoHere: 'Перетягніть сюди одне фото проєку',
      customerHasAccess: 'Замовник має доступ:',
      toOwnProjects: 'до своїх проєктів;',
      seeActions: 'на перегляд всіх подій з цінами без собівартості по його проєктах;',
      toPriceList: 'до прайс-листа з цінами, без собівартості;',
      toFullReport: 'до повного звіту по своїми проєктами.',
      addingCustomer: 'Додавання замовника',
      addCustomer: 'Додати замовника',
    },
    profile: 'Профіль',
    subscriptionExpired: 'Термін вашої підписки вичерпався',
    pleaseUpdatePayment: 'Будь ласка, оновіть платіжні дані для доступу до всіх функцій застосунку та веб-платформи.',
    light: 'Світла',
    dark: 'Темна',
    incorrectFormat: 'Неправильний формат зображення, використовуйте лише jpg/jpeg.',
    ownProfit: 'Власний прибуток',
    customerWithEmail: 'Замовник з електронною поштою',
    wasAlreadyInvited: 'вже був запрошений до компанії.',
    unableToAddCustomer: 'Неможливо додати замовника',
    personalFunds: 'Особисті кошти',
    addCurrencyToPriceList:
      'Прайс-лист у валюті вашого проекту відсутній. Додайте потрібну валюту до вашого прайс-листа',
    currencyPriceList: 'Прайс-лист у валюті',
    deleteFromEstimate: 'Видалити з кошторису',
    enterMarkup: 'Вкажіть націнку для кожної статті витрат у відсотках.',
    editingMarkups: 'Редагування націнок',
    tempPosition: 'Тимчасова позиція',
    choosePositions: 'Вибрати позиції',
    choosePosFromPriceList: 'Вибір позицій з прайс-листа',
    createEstimate: 'Створити кошторис',
    saveChanges: 'Зберегти зміни',
    estimateEditing: 'Редагування кошторису',
    inTotal: 'Всього',
    estimateConvertedToAnInvoice: 'Кошторис був перетворений на рахунок',
    downloadEstimate: 'Завантажити кошторис',
    projectEditing: 'Редагування проєкту',
    totalSumWithMarkup: 'Загальна сума з націнкою',
    markup: 'Націнка',
    theTotalCost: 'Загальна вартість',
    totalCost: 'Загальна собівартість',
    number: 'Кількість',
    units: 'Одиниця виміру',
    noPositionsFromPriceList: 'Позиції з прайс-листа відсутні',
    positionsFromPriceList: 'Позиції з прайс-листа',
    addTempPosition: 'Додати тимчасову позицію',
    chooseFromPriceList: 'Вибрати з прайс-листа',
    totalSum: 'Загальна сума',
    markupOnArticleExpenses: 'Націнка на ст. витрат',
    profitShare: 'Частка прибутку',
    totalMarkup: 'Загальна націнка',
    estimateCreation: 'Створення кошторису',
    estimateDescription: 'Опис кошторису',
    estimateDescriptionNotRequired: 'Опис кошторису (необов’язково)',
    commonInfo: 'Загальна інформація',
    chooseProject: 'Оберіть проєкт',
    thereIsNoEstimates: 'Кошториси відсутні',
    estimates: 'Кошториси',
    searchEstimateByName: 'Пошук кошториса за назвою',
    addEstimate: 'Додати кошторис',
    profit: 'Прибуток',
    estimateAmount: 'Сума кошторису',
    date: 'Дата',
    estimateName: 'Назва кошторису',
    spent: 'Витрачено',
    remainder: 'Залишок',
    receipts: 'Надходження',
    calculated: 'Нараховано',
    customerDebt: 'Борг замовника',
    costs: 'Витрати',
    accountableFunds: 'Підзвітні кошти',
    address: 'Адреса',
    square: 'Площа',
    m2: 'м2',
    owner: 'Власник',
    partner: 'Партнер',
    customer: 'Замовник',
    manager: 'Менеджер',
    firstName: 'Ім’я',
    lastName: 'Прізвище',
    phoneNumberNotRequired: 'Номер телефону (необов`язково)',
    customerEmail: 'Електронна пошта замовника',
    customersInfo: 'Дані замовника',
    accessToCompany: 'Доступ до компанії',
    notifications: {
      positionEditedSuccess: 'Позицію успішно відредаговано!',
      positionDuplicatedSuccess: 'Позицію успішно дубльовано!',
      positionCreatedSuccess: 'Позицію успішно створено!',
      positionDeletedSuccess: 'Позицію успішно видалено!',
      currencyCreatedSuccess: 'Валюту успішно створено!',
      positionGroupedSuccess: 'Позиції успішно згруповано!',
      categoryDeletedSuccess: 'Категорію успішно видалено!',
      categoryEditedSuccess: 'Категорію успішно відредаговано!',
      categoryCreatedSuccess: 'Категорію успішно створено!',
      categoryUngroupedSuccess: 'Категорії успішно розгруповано!',
      articlesChangedSuccess: 'Статті витрат успішно змінено!',
      projectCreatedSuccess: 'Проєкт успішно створений!',
    },
    dragToCategory: 'Перетягнути до категорії',
    dragHere: 'Перетягнути сюди',
    importModalTotalFieldZero: 'Поле "Вартість" не може бути порожнім або дорівнювати 0.Будь ласка, заповніть це поле.',
    categoryIsEmpty: 'Категорія порожня',
    PayAttentionPrimeCostHigherCostSingle: 'Зверніть увагу, ви вказали собівартість більшу за вартість.',
    PayAttentionPrimeCostHigherCost: 'Зверніть увагу, що собівартість деяких позицій більша за вартість!',
    AddTotal: 'Заповніть вартість для всіх валют',
    ExportAllPositions: 'Експортувати всі позиції',
    ExportMaterials: 'Експортувати матеріали',
    ExportWork: 'Експортувати роботу',
    UnCategorized: 'Без категорії',
    DeleteCategory: 'Видалити категорію',
    NoPositions: 'Позиції відсутні',
    PositionIsUndefined: 'Позицію не знайдено',
    ChooseAll: 'Обрати все',
    ChooseMaterials: 'Обрати матеріали',
    ChooseWork: 'Обрати роботу',
    ChooseAllCategories: 'Обрати всі категорії',
    ChoosePositionsWithoutCategory: 'Обрати позиціЇ без категорії',
    PositionName: 'Назва позиціЇ',
    Units: 'Одиниці виміру',
    CostItem: 'Стаття витрат',
    GroupToCategory: "Об'єднати в категорію",
    SelectedPositions: 'Обрано позиції',
    Positions: 'позиції',
    UnGroup: 'Розгрупувати',
    ImportInputPlaceHolder: 'Скопіюйте дані з іншої програми та вставте їх в таблицю',
    ImportInputPlaceHolderMultiCurrencies:
      'Скопіюйте дані з іншої програми та вставте їх в таблицю. Після імпорту, введіть\n курс валюти за яким будуть конвертовані ціни імпортованих позиці.',
    ImportInputError: 'Некоректний формат даних, повторіть спробу.',
    TourSolution: 'Ваше комплексне рішення для обліку фінансів та управління будівельно-ремонтними проєктами!',
    WebAppLetYou: 'Веб-платформа надає можливість власнику компанії:',
    ManagePriceList: 'Керувати прайс-листом, додавати нові позиції вручну або імпортувати їх із власного прайс-листа.',
    ManageProjects: 'Керувати проєктами, створювати нові та запрошувати до них замовників;',
    CreateEstimates: 'Зручно формувати нові кошториси за допомогою позицій з прайс-листа.',
    Authentication: 'Авторизуватися!',
    deleteEstimate: 'Видалення кошторису',
    AreYouSureToDeleteThisEstimate: 'Ви дійсно хочете видалити обраний кошторис?',
    pleaseAddCompanyInfo: 'Будь ласка, заповніть інформацію про компанію, щоб увійти до кабінету.',
    createCompany: 'Створити компанію',
    creatingCompany: 'Створення компанії',
    impossibleToChangeCurrency:
      'Будьте уважні при виборі валюти за замовчуванням, після створення компанії, ви не зможете її змінити.',
    defaultCurrency: 'Валюта за замовчуванням',
    PasswordsDoNotMatch: 'Паролі не збігаються, повторіть спробу.',
    BusinessDescription: 'Спеціалізований тариф для командного керування проєктами',
    Transfers: 'Перекази',
    InviteContractors: 'Запрошення Підрядників',
    InviteEmployees: 'Запрошення Співробітників',
    InvitePartners: 'Запрошення Партнерів',
    WebPlatformForTheOwner: 'Веб-платформа для Власника',
    WebPlatformForPartners: 'Веб-платформа для Партнерів',
    ReportsInPDF: 'Звіти в pdf',
    ReceiptsAndAccounts: 'Надходження та рахунки',
    expendituresAndCategories: '20 статей витрат та категорії',
    WorkScheduleAndStages: 'Графік та етапи робіт',
    InviteCustomer: 'Запрошення Замовника',
    OwnCompany: 'Власна компанія',
    MainFunctions: 'Основні функції:',
    ProfessionalDescription: 'Професійний тариф для управління проєктами разом із замовником',
    Get2monthFree: 'Платіть щороку та отримайте 2 місяці в',
    EveryYear: 'Щорічно',
    EveryMonth: 'Щомісяця',
    YourSubscriptionHasExpired:
      'Термін вашої підписки вичерпано. Будь ласка, оновіть платіжні дані для доступу до всіх функцій програми.',
    StartYourFreeTrial: 'Почніть ваш 30-денний безкоштовний період вже сьогодні!',
    ChooseYourPlan: 'Виберіть план, який найкраще відповідає вашим потребам',
    Subscription: 'Підписка',
    Unsubscribe: 'Відключити підписку',
    EditingAccount: 'Редагування профілю',
    Phone: 'Номер телефону',
    Email: 'Електронна пошта',
    optional: '(необов`язково)',
    CompanyName: 'Назва компанії',
    CompanyInfoEditing: 'Редагування даних компанії',
    CompanyInfo: 'Інформація про компанію',
    PaidTo: 'Сплачено до',
    Rate: 'Тариф',
    PhoneNumber: 'Телефон',
    CompanyBalance: 'Баланс компанії',
    Finances: 'Фінанси',
    TotalFunds: 'Усього коштів',
    UserAlreadyTaken: 'Користувач з вказаною поштою вже існує.',
    UserAlreadyRegistered:
      'Користувач з вказаною поштою зареєстрований через соціальну мережу. Спробуйте увійти в обліковий запис через соціальну мережу, яку ви обрали при реєстрації.',
    UserNotFound:
      'На жаль, ми не знайшли користувача з такою поштою. Перевірте чи немає помилок в адресі, або заново зареєструйтеся.',
    IncorrectEmail: 'Некоректний формат електронної пошти, повторіть спробу.',
    AtLeast8: 'Щонайменше 8 символів',
    WelcomeToGubadoo: 'Вітаємо в gubadoo!',
    AcceptingEmail: 'Підтвердження пошти',
    IHaveAcc: 'У мене є обліковий запис',
    IDontHaveAcc: 'Не маю облікового запису',
    ForgotPassword: 'Забули пароль?',
    Registration: 'Зареєструватися',
    RegistrationButton: 'Реєстрація',
    DouYouHaveAnAcc: 'Вже маєте обліковий запис?',
    DontYouHaveAnAcc: 'Не маєте облікового запису?',
    PersonalDataPolicy: 'Політикою обробки персональних даних',
    CreatingAccYouAccept: 'Створюючи обліковий запис, ви погоджуєтесь з ',
    GetList: 'Отримати листа',
    AddActualMail: 'Вкажіть вашу актуальну пошту, на яку ми відправимо лист з підтвердженням.',
    RestoreAccess: 'Відновлення доступу',
    CheckYourEmail: 'Будь ласка, перевірте вашу пошту',
    AfterEmailWithConfirmation:
      ', на яку був надісланий лист з підтвердженням. Після підтвердження пошти ви зможете завершити відновлення доступу.',
    AfterEmailWithConfirmationRegistration:
      ', на яку був надісланий лист з підтвердженням. Після підтвердження пошти ви зможете завершити реєстрацію.',
    DidntYouRecieveLetter: 'Лист не надійшов?',
    SendAgain: 'Надіслати повторно',
    EmailConfirmed: 'Електронну пошту підтверджено. Створіть новий пароль для входу',
    EmailConfirmedRegistration: 'Електронну пошту підтверджено. Створіть пароль для завершення реєстрації',
    Start30Free: 'Розпочати 30 днів безкоштовно',
    BuySubscription: 'Придбати підписку',
    ContinueSubscription: 'Продовжити підписку',
    Or: 'або',
    EnterEmail: 'Введіть електронну пошту',
    EnterPassword: 'Введіть пароль',
    Enter: 'Увійти',
    ItIsRequiredField: "Це поле є обов'язковим для заповнення.",
    MinimumPasswordIs8: 'Пароль заслабкий. Ваш пароль має складатися щонайменше з 8 символів.',
    WrongEnteredData: 'Невірно введені дані. Перевірте правильність написання електронної пошти та пароля',
    PriceList: 'Прайс-лист',
    LogOut: 'Вихід',
    ImportExcel: 'Імпорт з Excel',
    ImportItems: 'Імпорт позицій',
    ImportData: 'Імпорт даних',
    ExportPDF: 'Експорт у PDF',
    DownloadAndFillTemplate: 'Завантажте та заповніть шаблон',
    DownloadTemplate: 'Завантажити шаблон',
    UploadXLSX: 'Завантажте заповнений шаблон у форматі',
    XLSXFormat: '.xlsx',
    DropFile: 'Перетягніть сюди заповнений файл Excel',
    ChooseFile: 'Виберіть файл',
    ForContractor: 'Для підрядника',
    ForClient: 'Для клієнта',
    AddCategory: 'Додати категорію',
    AddNewItem: 'Додати нову позицію',
    AddItem: 'Додати позицію',
    ExportArticlesPDF: 'Експорт статті витрат в PDF',
    ExportCategoryPDF: 'Експорт категорії в PDF',
    More: 'Більше',
    Materials: 'Матеріали',
    Work: 'Робота',
    Back: 'Назад',
    ItemName: 'Назва позиції',
    Unit: 'Одиниця виміру',
    Total: 'Вартість',
    Cost: 'Собівартість',
    Cancel: 'Скасувати',
    Create: 'Створити',
    Save: 'Зберегти',
    NewCategory: 'Нова категорія',
    EditCategory: 'Редагувати категорії',
    NewItem: 'Нова позиція',
    EditingItem: 'Редагування позиції',
    ItemInfo: 'Інформація про позицію',
    Optional: 'необов’язково',
    Clear: 'Очистити',
    Import: 'Імпортувати',
    EnterCategoryName: 'Назва категорії',
    EditItem: 'Редагувати позицію',
    DoubleItem: 'Дублювати позицію',
    DeleteItem: 'Видалити позицію',
    SearchItemByName: 'Пошук позиції та категорії за назвою',
    Edit: 'Редагувати',
    ChooseAndDeleteItems: 'Позначити та видалити позиції',
    ChooseAndDeleteCategories: 'Позначити та видалити категорії',
    All: 'Всі',
    Delete: 'Видалити',
    DragFileHere: 'Перетягніть файл сюди',
    UAH: 'Гривня',
    USD: 'Долар',
    EUR: 'Євро',
    GBP: 'Фунт',
    AddCurrency: 'Додати валюту',
    Add: 'Додати',
    ChooseOneCurrency: 'Виберіть одну з доступних валют',
    CurrencyRate: 'Курс валюти',
    EnterCurrencyRate: 'Введіть, будь ласка, курс валюти, за яким будуть конвертовані ціни позицій прайс-листа.',
    SaveCurrencyRate: 'Застосувати курс',
    DeleteCurrency: 'Видалити валюту',
    FileNeedToBeXLSX: 'Завантажуваний файл має бути у форматі .xlsx',
    FileNeedToBeAsTemplate: 'Файл має відповідати вимогам зазначеним у шаблоні',
    DeletingItems: 'Видалення позицій',
    DeletingItem: 'Видалення позиції',
    DeletingCategories: 'Видалення категорій',
    AreYouSureToDeleteItemsFromEstimate: 'Ви дійсно хочете видалити обрані позиції з кошторису?',
    AreYouSureToDeleteItemFromEstimate: 'Ви дійсно хочете видалити обрану позицію з кошторису?',
    AreYouSureToDeleteItems: 'Ви дійсно хочете видалити обрані позиції з прайс-листу?',
    AreYouSureToDeleteItem: 'Ви дійсно хочете видалити обрану позицію з прайс-листу?',
    AreYouSureToDeleteItemsWithCurrencies:
      'Усі обрані позиції будуть видалені з прайс-листа у всіх наявних валютах. Ви дійсно хочете видалити обрані позиції?',
    AreYouSureToDeleteItemWithCurrencies:
      'Обрану позицію буде видалено з прайс-листа у всіх наявних валютах. Ви дійсно хочете видалити обрану позицію?',
    AreYouSureToDeleteMultipleCategorySingleCurrency:
      'Усі позиції, що входять до обраних категорій, будуть видалені. Ви дійсно хочете видалити обрані категорії?',
    AreYouSureToDeleteMultipleCategoryMultipleCurrencies:
      'Усі позиції, що входять до обраних категорій, будуть видалені у всіх наявних валютах. Ви дійсно хочете видалити обрані категорії?',
    AreYouSureToDeleteSingleCategorySingleCurrency:
      'Усі позиції, що входять до обраної категорії, будуть видалені. Ви дійсно хочете видалити обрану категорію?',
    AreYouSureToDeleteSingleCategoryMultipleCurrencies:
      'Усі позиції, що входять до обраної категорії, будуть видалені в усіх наявних валютах. Ви дійсно хочете видалити обрану категорію? ',
    YesDelete: 'Так, видалити',
    Oops: 'Упс... щось пішло не так. Будь ласка спробуйте пізніше.',
    ThereIsNoPAge: 'Сторінка, яку ви шукаєте, не існує.',
    ErrorWhileRequest: 'Під час роботи сталася помилка. Будь ласка, спробуйте ще раз.',
    TryAgain: 'Повторити спробу',
    CostCantBeBiggerTotal: 'Собівартість не може бути більшою за вартість.',
    BetterGoToWeb: `Для більшої зручності, скопіюйте посилання на сайт та відкрийте його на вашому комп'ютері або ноутбуці`,
    UA: 'Укр',
    EN: 'Eng',
  },
  EN: {
    projectPage: {
      project: 'Projects',
      singleProject: 'Project',
      createNewProject: 'Create new project',
      newProject: 'New Project',
      createProject: 'Create Project',
      currency: 'Currency',
      projectInfo: 'Project Information',
      projectName: 'Project Name',
      address: 'Address (optional)',
      square: 'Area, m2',
      budgetProject: 'Project Budget (optional)',
      budget: 'Budget',
      budgetRequired: 'Project Budget',
      cannotChangeCurrency: 'Be careful when choosing the currency, it cannot be changed after the project is created.',
      projectCustomer: 'Project Customer',
      addCustomerToProject: 'Add Customer to the Project',
      addPhoto: 'Add Photo',
      dropPhotoHere: 'Drag and drop one project photo here',
      customerHasAccess: 'The customer has access to:',
      toOwnProjects: 'their projects;',
      seeActions: 'view all events with prices excluding costs for their projects;',
      toPriceList: 'price list with prices, excluding costs;',
      toFullReport: 'full report on their projects.',
      addingCustomer: 'Add Customer',
      addCustomer: 'Add a customer',
    },
    profile: 'Профіль',
    subscriptionExpired: 'Your subscription has expired',
    pleaseUpdatePayment: 'Please update your payment details to access all features of the app and web platform.',
    light: 'Light',
    dark: 'Dark',
    incorrectFormat: 'Incorrect image format, use jpg/jpeg only.',
    ownProfit: 'Own profit',
    customerWithEmail: 'The customer with the email address',
    wasAlreadyInvited: 'has already been invited to the company.',
    unableToAddCustomer: 'Unable to add customer',
    personalFunds: 'Personal funds',
    addCurrencyToPriceList:
      'The price list in the currency of your project is missing. Add the required currency to your price list.',
    currencyPriceList: 'Price list in currency',
    deleteFromEstimate: 'Remove from the estimate',
    enterMarkup: 'Specify the markup for each cost item in percentages.',
    editingMarkups: 'Editing markups',
    tempPosition: 'Temporary item',
    choosePositions: 'Select positions',
    choosePosFromPriceList: 'Select positions from the price list',
    createEstimate: 'Create estimate',
    saveChanges: 'Save changes',
    estimateEditing: 'Editing estimate',
    inTotal: 'Total',
    estimateConvertedToAnInvoice: 'The estimate has been converted into an bill',
    downloadEstimate: 'Download the estimate',
    projectEditing: 'Editing the project',
    totalSumWithMarkup: 'Total amount with markup',
    markup: 'Markup',
    theTotalCost: 'Total cost',
    totalCost: 'Total prime cost',
    number: 'Quantity',
    units: 'Measurement unit',
    noPositionsFromPriceList: 'Positions from the price list are missing',
    positionsFromPriceList: 'Positions from the price list',
    addTempPosition: 'Add a temporary position',
    chooseFromPriceList: 'Select from the price list',
    totalSum: 'Total amount',
    markupOnArticleExpenses: 'Markup on cost items',
    profitShare: 'Share of the profit',
    totalMarkup: 'Total markup',
    estimateCreation: 'Create estimate',
    estimateDescription: 'Estimate description',
    estimateDescriptionNotRequired: 'Estimate description (optional)',
    commonInfo: 'General information',
    chooseProject: 'Select project',
    thereIsNoEstimates: 'No estimates',
    estimates: 'Estimates',
    searchEstimateByName: 'Search for an estimate by name',
    addEstimate: 'Add estimate',
    profit: 'Profit',
    estimateAmount: 'Estimate amount',
    date: 'Date',
    estimateName: 'Estimate name',
    spent: 'Spent',
    remainder: 'Balance',
    receipts: 'Income',
    calculated: 'Accrued',
    customerDebt: 'Customer debt',
    costs: 'Costs',
    accountableFunds: 'Accountable costs',
    address: 'Address',
    square: 'Area',
    m2: 'm2',
    owner: 'Owner',
    partner: 'Partner',
    customer: 'Customer',
    manager: 'Manager',
    firstName: 'Name',
    lastName: 'Surname',
    phoneNumberNotRequired: 'Phone number (optional)',
    customerEmail: 'Customer`s email',
    customersInfo: 'Customer information',
    accessToCompany: 'Access to the company',
    notifications: {
      positionEditedSuccess: 'Position edited successfully!',
      positionDuplicatedSuccess: 'Position duplicated successfully!',
      positionCreatedSuccess: 'Position created successfully!',
      positionDeletedSuccess: 'Position deleted successfully!',
      currencyCreatedSuccess: 'Currency created successfully!',
      positionGroupedSuccess: 'Position grouped successfully!',
      categoryDeletedSuccess: 'Category deleted successfully!',
      categoryEditedSuccess: 'Category edited successfully!',
      categoryCreatedSuccess: 'Category created successfully!',
      categoryUngroupedSuccess: 'Category ungrouped successfully!',
      articlesChangedSuccess: 'Articles changed successfully!',
      projectCreatedSuccess: 'Project created successfully!',
    },
    dragToCategory: 'Drag to category',
    dragHere: 'Drag here',
    importModalTotalFieldZero: 'The "Cost" field cannot be empty or equal to 0. Please fill in this field.',
    categoryIsEmpty: 'Category is empty',
    PayAttentionPrimeCostHigherCostSingle: 'Please pay attention that the prime cost price is higher than the cost!',
    PayAttentionPrimeCostHigherCost:
      'Please pay attention that the prime cost price of some items is higher than the cost!',
    UnCategorized: 'Uncategorized',
    AddTotal: 'Fill in the cost for all currencies.',
    ExportAllPositions: 'Export all positions',
    ExportMaterials: 'Export materials',
    ExportWork: 'Export work',
    DeleteCategory: 'Delete category',
    NoPositions: 'Positions are not available',
    PositionIsUndefined: 'Position not found',
    ChooseAll: 'Select all',
    ChooseMaterials: 'Select materials',
    ChooseWork: 'Choose a work',
    ChooseAllCategories: 'Select all categories',
    ChoosePositionsWithoutCategory: 'Select positions without category',
    PositionName: 'Item name',
    Units: 'Units of measurement',
    CostItem: 'Expense item',
    GroupToCategory: 'Combine into a category',
    SelectedPositions: 'Items have been selected.',
    Positions: 'items',
    UnGroup: 'Ungroup',
    ImportInputPlaceHolder: 'Copy data from another program and paste it into the table.',
    ImportInputPlaceHolderMultiCurrencies:
      'Copy the data from another program and paste it into the table. After importing, enter\n the exchange rate at which the prices of imported items will be converted.',
    ImportInputError: 'Incorrect data format, please try again.',
    TourSolution:
      'Your comprehensive solution for financial accounting and management of construction and renovation projects!',
    WebAppLetYou: 'Веб-платформа надає можливість власнику компанії:',
    ManagePriceList: 'Керувати прайс-листом, додавати нові позиції вручну або імпортувати їх із власного прайс-листа.',
    ManageProjects: 'Керувати проєктами, створювати нові та запрошувати до них замовників;',
    CreateEstimates: 'Зручно формувати нові кошториси за допомогою позицій з прайс-листа.',
    Authentication: 'Authorization!',
    deleteEstimate: 'Deletion of estimate',
    AreYouSureToDeleteThisEstimate: 'Are you sure you want to delete the estimate?',
    //TODO
    pleaseAddCompanyInfo: 'Будь ласка, заповніть інформацію про компанію, щоб увійти до кабінету.',
    createCompany: 'Створити компанію',
    creatingCompany: 'Створення компанії',
    impossibleToChangeCurrency:
      'Будьте уважні при виборі валюти за замовчуванням, після створення компанії, ви не зможете її змінити. ',
    defaultCurrency: 'Валюта за замовчуванням',
    PasswordsDoNotMatch: 'Паролі не збігаються, повторіть спробу.',
    BusinessDescription: 'Спеціалізований тариф для командного керування проєктами',
    Transfers: 'Перекази',
    InviteContractors: 'Запрошення Підрядників',
    InviteEmployees: 'Запрошення Співробітників',
    InvitePartners: 'Запрошення Партнерів',
    WebPlatformForTheOwner: 'Веб-платформа для Власника',
    WebPlatformForPartners: 'Веб-платформа для Партнерів',
    ReportsInPDF: 'Звіти в pdf',
    ReceiptsAndAccounts: 'Надходження та рахунки',
    expendituresAndCategories: '20 статей витрат та категорії',
    WorkScheduleAndStages: 'Графік та етапи робіт',
    InviteCustomer: 'Запрошення Замовника',
    OwnCompany: 'Власна компанія',
    MainFunctions: 'Основні функції:',
    ProfessionalDescription: 'Професійний тариф для управління проєктами разом із замовником',
    Get2monthFree: 'Платіть щороку та отримайте 2 місяці в',
    EveryYear: 'Щорічно',
    EveryMonth: 'Щомісяця',
    YourSubscriptionHasExpired:
      'Термін вашої підписки вичерпано. Будь ласка, оновіть платіжні дані для доступу до всіх функцій програми.',
    StartYourFreeTrial: 'Почніть ваш 30-денний безкоштовний період вже сьогодні!',
    ChooseYourPlan: 'Виберіть план, який найкраще відповідає вашим потребам',
    Subscription: 'Підписка',
    Unsubscribe: 'Відключити підписку',
    EditingAccount: 'Редагування профілю',
    Phone: 'Номер телефону',
    Email: 'Електронна пошта',
    optional: '(optional)',
    CompanyName: 'Назва компанії',
    CompanyInfoEditing: 'Редагування даних компанії',
    CompanyInfo: 'Інформація про компанію',
    PaidTo: 'Сплачено до',
    Rate: 'Тариф',
    PhoneNumber: 'Телефон',
    CompanyBalance: 'Баланс компанії',
    Finances: 'Фінанси',
    TotalFunds: 'Усього коштів',
    UserAlreadyTaken: 'Користувач з вказаною поштою вже існує.',
    UserAlreadyRegistered:
      'Користувач з вказаною поштою зареєстрований через соціальну мережу. Спробуйте увійти в обліковий запис через соціальну мережу, яку ви обрали при реєстрації.',
    UserNotFound: 'UserNotFound',
    IncorrectEmail: 'Некоректний формат електронної пошти, повторіть спробу.',
    AtLeast8: 'Щонайменше 8 символів',
    WelcomeToGubadoo: 'Вітаємо в gubadoo!',
    AcceptingEmail: 'Підтвердження пошти',
    IHaveAcc: 'У мене є обліковий запис',
    IDontHaveAcc: 'Не маю облікового запису',
    ForgotPassword: 'Забули пароль?',
    Registration: 'Зареєструватися',
    RegistrationButton: 'Реєстрація',
    DouYouHaveAnAcc: 'Вже маєте обліковий запис?',
    DontYouHaveAnAcc: 'Не маєте облікового запису?',
    PersonalDataPolicy: 'Політикою обробки персональних даних',
    CreatingAccYouAccept: 'Створюючи обліковий запис, ви погоджуєтесь з ',
    GetList: 'Отримати листа',
    AddActualMail: 'Вкажіть вашу актуальну пошту, на яку ми відправимо лист з підтвердженням.',
    RestoreAccess: 'Відновлення доступу',
    CheckYourEmail: 'Будь ласка, перевірте вашу пошту',
    AfterEmailWithConfirmation:
      ', на яку був надісланий лист з підтвердженням. Після підтвердження пошти ви зможете завершити відновлення доступу.',
    AfterEmailWithConfirmationRegistration:
      ', на яку був надісланий лист з підтвердженням. Після підтвердження пошти ви зможете завершити реєстрацію.',
    DidntYouRecieveLetter: 'Лист не надійшов?',
    SendAgain: 'Надіслати повторно',
    EmailConfirmed: 'Електронну пошту підтверджено. Створіть новий пароль для входу',
    EmailConfirmedRegistration: 'Електронну пошту підтверджено. Створіть пароль для завершення реєстрації',
    Start30Free: 'Розпочати 30 днів безкоштовно',
    BuySubscription: 'Придбати підписку',
    ContinueSubscription: 'Продовжити підписку',
    Or: 'or',
    EnterEmail: 'Enter your email address',
    EnterPassword: 'Enter your password',
    Enter: 'Log in',
    ItIsRequiredField: 'This field cannot be left empty.',
    MinimumPasswordIs8: 'The minimum number of characters for this field is 8.',
    WrongEnteredData: 'Incorrect data entered. Please check the accuracy of the email and password spelling.',
    PriceList: 'Price List',
    LogOut: 'Log out',
    ImportExcel: 'Import from Excel',
    ImportItems: 'Import items',
    ImportData: 'Import data',
    ExportPDF: 'Export to PDF',
    DownloadAndFillTemplate: 'Download and complete the template',
    DownloadTemplate: 'Download the template',
    UploadXLSX: 'Upload the filled template in the specified format',
    XLSXFormat: '.xlsx',
    DropFile: 'Drag and drop the filled Excel file here',
    ChooseFile: 'Select the file',
    ForContractor: 'For the contractor',
    ForClient: 'For the client',
    AddCategory: 'New category',
    AddItem: 'New item',
    AddNewItem: 'Add New item',
    ExportArticlesPDF: 'Export expense item to PDF',
    ExportCategoryPDF: 'Export category to PDF',
    More: 'More',
    Materials: 'Materials',
    Work: 'Work',
    Back: 'Back',
    ItemName: 'Item title',
    Unit: 'Measurement units',
    Total: 'Cost',
    Cost: 'Prime cost',
    Cancel: 'Cancel',
    Create: 'Create',
    Save: 'Save',
    NewCategory: 'New category',
    EditCategory: 'Edit category',
    NewItem: 'New Item',
    EditingItem: 'Edit item',
    ItemInfo: 'Item info',
    Optional: 'optional',
    Clear: 'Clear',
    Import: 'Import',
    EnterCategoryName: 'Category name',
    EditItem: 'Edit item',
    DoubleItem: 'Duplicate item',
    DeleteItem: 'Delete item',
    SearchItemByName: 'Search item and category by name.',
    Edit: 'Edit',
    ChooseAndDeleteItems: 'Mark and Delete Items',
    ChooseAndDeleteCategories: 'Mark and Delete Categories',
    All: 'All',
    Delete: 'Delete',
    DragFileHere: 'Drag and drop the file here',
    UAH: 'Hryvnia',
    USD: 'Dollar',
    EUR: 'Euro',
    GBP: 'Pound',
    AddCurrency: 'Add currency',
    Add: 'Add',
    ChooseOneCurrency: 'Select one of the available currencies',
    CurrencyRate: 'Currency exchange rate',
    EnterCurrencyRate:
      'Please enter the currency exchange rate at which the prices of the items in the price list will be converted.',
    SaveCurrencyRate: 'Apply exchange rate',
    DeleteCurrency: 'Delete currency',
    FileNeedToBeXLSX: 'The uploaded file should be in .xlsx format',
    FileNeedToBeAsTemplate: 'The file should meet the requirements specified in the template',
    DeletingItems: 'Deletion of items',
    DeletingItem: 'Deletion of item',
    DeletingCategories: 'Deletion of categories',
    AreYouSureToDeleteItemsFromEstimate: 'Are you sure you want to delete the selected items from the estimate?',
    AreYouSureToDeleteItemFromEstimate: 'Are you sure you want to delete the selected item from the estimate?',
    AreYouSureToDeleteItems: 'Are you sure you want to delete the selected items from the price list?',
    AreYouSureToDeleteItem: 'Are you sure you want to delete the selected item from the price list?',
    AreYouSureToDeleteItemsWithCurrencies:
      'All selected items will be deleted from the price list in all available currencies. Are you sure you want to delete the selected items?',
    AreYouSureToDeleteItemWithCurrencies:
      'The selected item will be deleted from the price list in all available currencies. Are you sure you want to delete the selected item?',
    AreYouSureToDeleteMultipleCategorySingleCurrency:
      'All items within the selected categories will be deleted. Are you sure you want to delete the selected categories?',
    AreYouSureToDeleteMultipleCategoryMultipleCurrencies:
      'All items within the selected categories will be deleted in all available currencies. Are you sure you want to delete the selected categories?',
    AreYouSureToDeleteSingleCategorySingleCurrency:
      'All items within the selected category will be deleted. Are you sure you want to delete the selected category?',
    AreYouSureToDeleteSingleCategoryMultipleCurrencies:
      'All items within the selected category will be deleted in all available currencies. Are you sure you want to delete the selected category?',
    YesDelete: 'Yes, delete',
    Oops: 'Oops... Something went wrong. Please, try again later.',
    ThereIsNoPAge: 'The page you are looking for does not exist.',
    ErrorWhileRequest: 'An error occurred during the operation. Please try again.',
    TryAgain: 'Try again',
    CostCantBeBiggerTotal: 'The prime cost cannot be greater than the price.',
    BetterGoToWeb: `For your convenience, please copy the link to the website and open it on your computer or laptop`,
    UA: 'Укр',
    EN: 'Eng',
  },
};
