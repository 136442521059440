import React from 'react';
import { useAppSelector } from 'shared/lib';

export const RoundProgressGradient: React.FC = () => {
  const theme = useAppSelector((state) => state.theme.theme);
  const isDarkTheme = theme === 'dark';
  return (
    <>
      {isDarkTheme ? (
        <svg xmlns="http://www.w3.org/2000/svg" width="162" height="161" viewBox="0 0 162 161" fill="none">
          <mask
            id="path-1-outside-1_3235_96327"
            maskUnits="userSpaceOnUse"
            x="0"
            y="-1"
            width="162"
            height="162"
            fill="black"
          >
            <rect fill="white" y="-1" width="162" height="162" />
            <path d="M161 80C161 124.183 125.183 160 81 160C36.8172 160 1 124.183 1 80C1 35.8172 36.8172 0 81 0C125.183 0 161 35.8172 161 80ZM33 80C33 106.51 54.4903 128 81 128C107.51 128 129 106.51 129 80C129 53.4903 107.51 32 81 32C54.4903 32 33 53.4903 33 80Z" />
          </mask>
          <path
            d="M161 80C161 124.183 125.183 160 81 160C36.8172 160 1 124.183 1 80C1 35.8172 36.8172 0 81 0C125.183 0 161 35.8172 161 80ZM33 80C33 106.51 54.4903 128 81 128C107.51 128 129 106.51 129 80C129 53.4903 107.51 32 81 32C54.4903 32 33 53.4903 33 80Z"
            fill="url(#paint0_linear_3235_96327)"
          />
          <path
            d="M161 80C161 124.183 125.183 160 81 160C36.8172 160 1 124.183 1 80C1 35.8172 36.8172 0 81 0C125.183 0 161 35.8172 161 80ZM33 80C33 106.51 54.4903 128 81 128C107.51 128 129 106.51 129 80C129 53.4903 107.51 32 81 32C54.4903 32 33 53.4903 33 80Z"
            fill="black"
            fillOpacity="0.25"
          />
          <path
            d="M161 80C161 124.183 125.183 160 81 160C36.8172 160 1 124.183 1 80C1 35.8172 36.8172 0 81 0C125.183 0 161 35.8172 161 80ZM33 80C33 106.51 54.4903 128 81 128C107.51 128 129 106.51 129 80C129 53.4903 107.51 32 81 32C54.4903 32 33 53.4903 33 80Z"
            stroke="#0D0D0D"
            strokeWidth="2"
            mask="url(#path-1-outside-1_3235_96327)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_3235_96327"
              x1="1"
              y1="160"
              x2="161"
              y2="4.99648e-06"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FF8C21" />
              <stop offset="0.880208" stopColor="#FFD200" />
            </linearGradient>
          </defs>
        </svg>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="162" height="161" viewBox="0 0 162 161" fill="none">
          <mask
            id="path-1-outside-1_2389_34272"
            maskUnits="userSpaceOnUse"
            x="0"
            y="-1"
            width="162"
            height="162"
            fill="black"
          >
            <rect fill="white" y="-1" width="162" height="162" />
            <path d="M161 80C161 124.183 125.183 160 81 160C36.8172 160 1 124.183 1 80C1 35.8172 36.8172 0 81 0C125.183 0 161 35.8172 161 80ZM33 80C33 106.51 54.4903 128 81 128C107.51 128 129 106.51 129 80C129 53.4903 107.51 32 81 32C54.4903 32 33 53.4903 33 80Z" />
          </mask>
          <path
            d="M161 80C161 124.183 125.183 160 81 160C36.8172 160 1 124.183 1 80C1 35.8172 36.8172 0 81 0C125.183 0 161 35.8172 161 80ZM33 80C33 106.51 54.4903 128 81 128C107.51 128 129 106.51 129 80C129 53.4903 107.51 32 81 32C54.4903 32 33 53.4903 33 80Z"
            fill="url(#paint0_linear_2389_34272)"
          />
          <path
            d="M161 80C161 124.183 125.183 160 81 160C36.8172 160 1 124.183 1 80C1 35.8172 36.8172 0 81 0C125.183 0 161 35.8172 161 80ZM33 80C33 106.51 54.4903 128 81 128C107.51 128 129 106.51 129 80C129 53.4903 107.51 32 81 32C54.4903 32 33 53.4903 33 80Z"
            fill="white"
            fillOpacity="0.8"
          />
          <path
            d="M161 80C161 124.183 125.183 160 81 160C36.8172 160 1 124.183 1 80C1 35.8172 36.8172 0 81 0C125.183 0 161 35.8172 161 80ZM33 80C33 106.51 54.4903 128 81 128C107.51 128 129 106.51 129 80C129 53.4903 107.51 32 81 32C54.4903 32 33 53.4903 33 80Z"
            stroke="white"
            strokeWidth="2"
            mask="url(#path-1-outside-1_2389_34272)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_2389_34272"
              x1="1"
              y1="160"
              x2="161"
              y2="4.99648e-06"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FF8C21" />
              <stop offset="0.880208" stopColor="#FFD200" />
            </linearGradient>
          </defs>
        </svg>
      )}
    </>
  );
};
