import React, { useEffect, useState } from 'react';
import { CurrenciesType } from 'entities/priceList';
import { LoadingResultsT } from 'entities/loadingType';

import { ActionButton, CurrenciesTabs, SearchInputWithDropDown, DropDown, CustomTooltip, Input } from 'shared/ui';
import { GetUniqueUnitsFromPriceList, handleNumberValue, useAppSelector } from 'shared/lib';
import { TriangleArrow, WorkIcon, MaterialsIcon } from 'shared/assets/icons';
import { text, COLORS } from 'shared/constants';
import { ItemValues } from '../../model/defaultValues';
import { CommonModalContainer } from '../../../styles';
import {
  CurrenciesContainer,
  DropDownContainer,
  DropDownSelect,
  EditPositionButton,
  EditPositionButtonsWrapper,
  ModalArticleSelect,
  ModalSmallContainer,
  ErrorMsg,
} from '../styles';

interface Props {
  onSaveClick: () => void;
  itemValues: ItemValues;
  setItemValues: React.Dispatch<React.SetStateAction<ItemValues>>;
  onModalClose: () => void;
}

const ModalLayout: React.FC<Props> = ({ onSaveClick, itemValues, setItemValues, onModalClose }) => {
  const language = useAppSelector((state) => state.language.language);
  const userCurrencyList = useAppSelector((state) => state.currencies.userCurrencyList);
  const activeCurrency = useAppSelector((state) => state.currencies.activeCurrency);
  const loadingPosition = useAppSelector((state) => state.priceList.loadingPosition);
  const priceList = useAppSelector((state) => state.priceList.priceList);
  const theme = useAppSelector((state) => state.theme.theme);

  const [isOpenArticleDropDown, setIsOpenArticleDropDown] = useState(false);
  const [isWarning, setIsWarning] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState<CurrenciesType>(activeCurrency ?? userCurrencyList[0]);
  const [activeIndex, setActive] = useState(userCurrencyList.findIndex((cur) => cur === activeCurrency)); // For currencies lighting

  const cost = itemValues.itemFunds[selectedCurrency]?.cost;
  const total = itemValues.itemFunds[selectedCurrency]?.total;
  useEffect(() => {
    if (Number(cost) > Number(total)) {
      setIsWarning(true);
    } else {
      setIsWarning(false);
    }
  }, [total, cost]);

  function onChangeArticleClick(articleID: string) {
    setItemValues({ ...itemValues, itemArticle: articleID });
    setIsOpenArticleDropDown(false);
  }

  const articleCN = () => {
    let cn = '';

    if (!itemValues.itemArticle) cn += 'placeholder';
    if (isDarkTheme) cn += 'Dark';
    return cn;
  };

  const isEmptyAnyCurrencyTotal = Object.values(itemValues.itemFunds).some((item) => !item.total);
  const isSaveButtonDisabled =
    !itemValues.itemName || !itemValues.itemArticle || !itemValues.itemUnit || isEmptyAnyCurrencyTotal;

  const isDarkTheme = theme === 'dark';
  return (
    <>
      <CommonModalContainer>
        <ModalSmallContainer>
          {/* ITEM NAME */}
          <Input
            placeholder={text[language].ItemName}
            value={itemValues.itemName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setItemValues({ ...itemValues, itemName: event.target.value });
            }}
          />
          {/* ITEM ARTICLE */}
          <DropDownSelect>
            <ModalArticleSelect
              className={articleCN()}
              onClick={() => setIsOpenArticleDropDown(!isOpenArticleDropDown)}
            >
              <span>{text[language].CostItem}</span>
              <p>
                {itemValues.itemArticle === '1'
                  ? text[language].Materials
                  : itemValues.itemArticle === '2'
                  ? text[language].Work
                  : text[language].CostItem}
              </p>
              <div>
                <TriangleArrow stroke={isDarkTheme ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK} />
              </div>
            </ModalArticleSelect>
            {isOpenArticleDropDown && (
              <DropDownContainer>
                <DropDown
                  elements={[
                    {
                      icon: <MaterialsIcon />,
                      text: text[language].Materials,
                      onClick: () => onChangeArticleClick('1'),
                    },
                    {
                      icon: <WorkIcon language={language} />,
                      text: text[language].Work,
                      onClick: () => onChangeArticleClick('2'),
                    },
                  ]}
                />
              </DropDownContainer>
            )}
          </DropDownSelect>
          {/* ITEM UNITS */}
          <SearchInputWithDropDown
            onChange={(value: string) => {
              setItemValues({ ...itemValues, itemUnit: value });
            }}
            value={itemValues.itemUnit}
            placeholder={text[language].Unit}
            data={GetUniqueUnitsFromPriceList(priceList)}
          />
          {userCurrencyList?.length > 1 && (
            // ClassName calculation is needed to get correct size of buttons
            <CurrenciesContainer className={`${userCurrencyList?.length}`}>
              {userCurrencyList?.map((item: CurrenciesType, index: number) => (
                <CurrenciesTabs
                  key={item}
                  item={item}
                  isSelected={activeIndex === index}
                  setSelectedCurrency={() => {
                    setSelectedCurrency(item);
                    setActive(index);
                  }}
                />
              ))}
            </CurrenciesContainer>
          )}
          {/* ITEM TOTAl */}
          <Input
            placeholder={text[language].Total}
            value={handleNumberValue(itemValues.itemFunds[selectedCurrency]?.total || '')}
            inputMode="decimal"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const value = event.target.value;
              const totalValue = handleNumberValue(value);

              let currentFunds = itemValues.itemFunds;
              const cost = currentFunds[selectedCurrency]?.cost;
              const total = totalValue;

              currentFunds[selectedCurrency] = { cost, total };

              setItemValues({ ...itemValues, itemFunds: currentFunds });
            }}
          />
          {/* ITEM COST */}
          <Input
            placeholder={`${text[language].Cost} (${text[language].Optional})`}
            className={isWarning ? 'warning' : ''}
            value={handleNumberValue(itemValues.itemFunds[selectedCurrency]?.cost || '')}
            inputMode="decimal"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const costValue = handleNumberValue(event.target.value);

              let currentFunds = itemValues.itemFunds;
              const cost = costValue;
              const total = currentFunds[selectedCurrency]?.total;
              currentFunds[selectedCurrency] = { total, cost };

              setItemValues({ ...itemValues, itemFunds: currentFunds });
            }}
          />
        </ModalSmallContainer>
        {
          <ErrorMsg className={isWarning ? 'show' : ''}>
            {text[language].PayAttentionPrimeCostHigherCostSingle}
          </ErrorMsg>
        }
      </CommonModalContainer>
      <EditPositionButtonsWrapper>
        <EditPositionButton
          className={isDarkTheme ? 'dark' : ''}
          onClick={() => {
            onModalClose();
          }}
        >
          <p>{text[language].Cancel}</p>
        </EditPositionButton>
        <ActionButton
          id="CreateEditPositionButton"
          isPending={loadingPosition === LoadingResultsT.PENDING}
          text={text[language].Save}
          orangeBtn
          disabled={isSaveButtonDisabled}
          onClick={onSaveClick}
          isPriceListMainButton={true}
        />
      </EditPositionButtonsWrapper>
      {isEmptyAnyCurrencyTotal && (
        <CustomTooltip text={text[language].AddTotal} anchorSelect="#CreateEditPositionButton" />
      )}
    </>
  );
};

export default ModalLayout;
