import { COLORS } from 'shared/constants';

export const setDarkScrollBar = () => {
  document.documentElement.style.setProperty('--scroll-bar-track-color', COLORS.BACKGROUND_BLACK);
  document.documentElement.style.setProperty('--scroll-bar-thumb-color', COLORS.ACTION_BAR_BLACK_BG);
};

export const setLightScrollBar = () => {
  document.documentElement.style.setProperty('--scroll-bar-track-color', COLORS.TEXT_WHITE);
  document.documentElement.style.setProperty('--scroll-bar-thumb-color', COLORS.BODY_TEXT_BLACK_20);
};
