export function getCurrentDateInISOFormat(): string {
  const currentDate = new Date();
  const year = currentDate.getUTCFullYear();
  const month = (currentDate.getUTCMonth() + 1).toString().padStart(2, '0');
  const day = currentDate.getUTCDate().toString().padStart(2, '0');
  const hours = currentDate.getUTCHours().toString().padStart(2, '0');
  const minutes = currentDate.getUTCMinutes().toString().padStart(2, '0');
  const seconds = currentDate.getUTCSeconds().toString().padStart(2, '0');
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
}

export function add30Days(date: Date) {
  const futureDate = new Date(date.getTime() + 30 * 24 * 60 * 60 * 1000);
  const year = futureDate.getUTCFullYear();
  const month = (futureDate.getUTCMonth() + 1).toString().padStart(2, '0');
  const day = futureDate.getUTCDate().toString().padStart(2, '0');
  const hours = futureDate.getUTCHours().toString().padStart(2, '0');
  const minutes = futureDate.getUTCMinutes().toString().padStart(2, '0');
  const seconds = futureDate.getUTCSeconds().toString().padStart(2, '0');
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
}
