import React, { useState } from 'react';
import { InputWrapper, StyledInput, StyledLabel } from './styles';
import { useAppSelector } from 'shared/lib';

type InputModeType = 'text' | 'none' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search' | undefined;

type Props = {
  maxLength?: number;
  disabled?: boolean;
  type?: string;
  value: string;
  placeholder?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: () => void;
  className?: string;
  autoFocus?: boolean;
  defaultValue?: string;
  inputMode?: InputModeType;
  smallModalInput?: boolean;
  withoutLabel?: boolean;
};

export const Input: React.FC<Props> = ({
  type = 'text',
  maxLength = 50,
  disabled,
  inputMode = 'text',
  value,
  placeholder,
  onChange,
  onClick,
  className,
  autoFocus,
  defaultValue,
  smallModalInput,
  withoutLabel,
}) => {
  const theme = useAppSelector((state) => state.theme.theme);
  const [isFocused, setIsFocused] = useState(false);

  const showLabel = placeholder && (isFocused || !!value.length);
  const isDarkTheme = theme === 'dark';

  const cn = () => {
    let res = className ? className : '';
    if (disabled) {
      res += ' disabled';
    }
    if (smallModalInput) {
      res += ' smallModalInput';
    }
    if (!withoutLabel && showLabel) {
      res += ' showLabel';
    }
    if (isDarkTheme) {
      res += 'dark';
    }
    return res;
  };

  return (
    <InputWrapper className={cn()}>
      <StyledInput
        inputMode={inputMode}
        defaultValue={defaultValue}
        autoFocus={autoFocus}
        className={cn()}
        maxLength={maxLength}
        disabled={disabled}
        type={type}
        value={value}
        onChange={onChange}
        placeholder={!showLabel ? placeholder : ''}
        onClick={onClick}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      {!withoutLabel && placeholder && <StyledLabel className={cn()}>{placeholder}</StyledLabel>}
    </InputWrapper>
  );
};
