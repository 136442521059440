import React from 'react';
import { SkeletonLine, SkeletonTextContainer } from './styles';

export const TextSkeleton: React.FC<{ isSideBar?: boolean }> = ({ isSideBar = false }) => {
  return (
    <SkeletonTextContainer>
      <SkeletonLine className={isSideBar ? 'sideBarLoader' : ''} />
    </SkeletonTextContainer>
  );
};
