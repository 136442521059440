import React from 'react';
import { Plus } from 'shared/assets/icons';
import { COLORS, devices } from 'shared/constants';
import { AddPositionButton, DropdownBody, DropdownElement, DropdownEmptyElement } from './styles';
import { useAppSelector, useMediaQuery } from 'shared/lib';

interface Props {
  elements?: Element[];
  withoutGradient?: boolean;
  mobileModal?: boolean;
  addPositionButtonText?: string;
  onAddPositionClick?: () => void;
}

interface Element {
  icon?: React.ReactNode;
  text: string;
  onClick: () => void;
}

export const DropDown: React.FC<Props> = ({
  elements,
  mobileModal,
  withoutGradient = false,
  addPositionButtonText,
  onAddPositionClick,
}) => {
  const theme = useAppSelector((state) => state.theme.theme);
  const isMobile = useMediaQuery(devices.mobile);
  const isTablet = useMediaQuery(devices.tablet);
  const isDarkTheme = theme === 'dark';

  const isTabletOrMobile = isMobile || isTablet;

  const cn = () => {
    let className = '';
    if (isDarkTheme) className += 'dark';
    if (withoutGradient) className += 'withoutGradient';

    return className;
  };

  return (
    <DropdownBody className={mobileModal ? `mobileModal ${cn()}` : cn()}>
      {elements ? (
        elements.map((element, index) => (
          <DropdownElement key={index} onClick={element.onClick} className={cn()}>
            {element.icon}
            <span>{element.text}</span>
          </DropdownElement>
        ))
      ) : (
        <DropdownEmptyElement>
          <span>no elements</span>
        </DropdownEmptyElement>
      )}
      {addPositionButtonText && (
        <AddPositionButton className={isDarkTheme ? 'dark' : ''} onClick={onAddPositionClick}>
          <Plus size={isTabletOrMobile ? 8 : 16} stroke={isDarkTheme ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK} />{' '}
          <span>{addPositionButtonText}</span>
        </AddPositionButton>
      )}
    </DropdownBody>
  );
};
