const sizes = {
  mobile: 200,
  tablet: 768,
  desktop: 1024,
  desktopL: 1440,
};

export const devices = {
  mobile: `(min-width: ${sizes.mobile}px) and (max-width: ${sizes.tablet - 1}px)`,
  tablet: `(min-width: ${sizes.tablet}px) and (max-width: ${sizes.desktop - 1}px)`,
  desktop: `(min-width: ${sizes.desktop}px)`,
  desktopL: `(min-width: ${sizes.desktopL}px)`,
};
