import { createSlice } from '@reduxjs/toolkit';
import { LoadingResultsT } from '../../loadingType';
import { getAccountInfo, editAccountInfo, getTrialSubscription } from '../api/thunks';
import { AccountInfoType } from './types';

interface IState {
  accountInfo: AccountInfoType | null;
  loading: LoadingResultsT;
  loadingEditing: LoadingResultsT;
  error: string | null;
}

const initialState: IState = {
  accountInfo: null,
  loading: LoadingResultsT.IDLE,
  loadingEditing: LoadingResultsT.IDLE,
  error: null,
};

const accountInfoSlice = createSlice({
  name: 'accountInfo',
  initialState,
  reducers: {
    resetLoading(state) {
      state.loading = LoadingResultsT.IDLE;
      state.loadingEditing = LoadingResultsT.IDLE;
    },
    editLocalAccountInfo(state, { payload }) {
      state.accountInfo = payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getAccountInfo.pending, (state) => {
        state.loading = LoadingResultsT.PENDING;
      })
      .addCase(getAccountInfo.fulfilled, (state, { payload }) => {
        state.loading = LoadingResultsT.SUCCEEDED;
        state.accountInfo = payload;
        state.error = null;
      })
      .addCase(getAccountInfo.rejected, (state, { error }) => {
        state.loading = LoadingResultsT.FAILED;
        state.error = error.message as string;
      })

      .addCase(editAccountInfo.pending, (state) => {
        state.loadingEditing = LoadingResultsT.PENDING;
      })
      .addCase(editAccountInfo.fulfilled, (state, { payload }) => {
        state.loadingEditing = LoadingResultsT.SUCCEEDED;
        state.accountInfo = payload;
        state.error = null;
      })
      .addCase(editAccountInfo.rejected, (state, { error }) => {
        state.loadingEditing = LoadingResultsT.FAILED;
        state.error = error.message as string;
      })

      .addCase(getTrialSubscription.pending, (state) => {
        state.loadingEditing = LoadingResultsT.PENDING;
      })
      .addCase(getTrialSubscription.fulfilled, (state, { payload }) => {
        state.loadingEditing = LoadingResultsT.SUCCEEDED;
        state.accountInfo = payload;
        state.error = null;
      })
      .addCase(getTrialSubscription.rejected, (state, { error }) => {
        state.loadingEditing = LoadingResultsT.FAILED;
        state.error = error.message as string;
      }),
});

export const { resetLoading, editLocalAccountInfo } = accountInfoSlice.actions;
export const accountInfoReducer = accountInfoSlice.reducer;
