import React from 'react';
import { COLORS } from 'shared/constants';
import { useAppSelector } from 'shared/lib';
import { CircularProgress } from 'shared/ui';

export const PageSpinner: React.FC = () => {
  const theme = useAppSelector((state) => state.theme.theme);
  const backgroundColor = theme === 'dark' ? COLORS.MODAL_BG_DARK : 'transparent';
  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor,
      }}
    >
      <CircularProgress isOrange />
    </div>
  );
};
