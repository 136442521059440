import React from 'react';
import { COLORS } from 'shared/constants';
import { useAppSelector } from 'shared/lib';

export const HorizontalDivider: React.FC = () => {
  const theme = useAppSelector((state) => state.theme.theme);
  const isDarkTheme = theme === 'dark';
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="2" height="40" viewBox="0 0 2 40" fill="none">
      <path d="M1 0V40" stroke={isDarkTheme ? COLORS.DIVIDER_DARK : COLORS.BODY_TEXT_BLACK_20} />
    </svg>
  );
};
