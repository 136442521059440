import { useState, useEffect } from 'react';

export const useHover = (element: HTMLElement | null) => {
  const [isHovered, setIsHovered] = useState(false);

  // Event handlers for mouse enter and mouse leave events
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    if (!element) {
      return;
    }

    // Add event listeners when the component mounts
    element.addEventListener('mouseenter', handleMouseEnter);
    element.addEventListener('mouseleave', handleMouseLeave);

    // Remove event listeners when the component unmounts
    return () => {
      element.removeEventListener('mouseenter', handleMouseEnter);
      element.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [element]);

  return isHovered;
};
