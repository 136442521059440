import React from 'react';
import { SkeletonProjectCard, SkeletonProjectCardsContainer } from './styles';

export const ProjectsSkeletonLoader: React.FC = () => {
  return (
    <SkeletonProjectCardsContainer>
      <SkeletonProjectCard />
      <SkeletonProjectCard />
      <SkeletonProjectCard />
      <SkeletonProjectCard />
      <SkeletonProjectCard />
      <SkeletonProjectCard />
      <SkeletonProjectCard />
    </SkeletonProjectCardsContainer>
  );
};
