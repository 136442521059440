import React from 'react';
import { TriangleArrow } from 'shared/assets/icons';

type Props = { isOpen: boolean; setIsOpen: (v: boolean) => void; color?: string; size?: number };

export const ToggleAccordion: React.FC<Props> = ({ isOpen, setIsOpen, color, size = 24 }) => {
  return (
    <div
      style={{
        width: `${size}px`,
        height: `${size}px`,
        transition: 'transform 0.25s linear',
        transform: isOpen ? 'rotate(360deg)' : 'rotate(270deg)',
      }}
      onClick={() => {
        setIsOpen(!isOpen);
      }}
    >
      <TriangleArrow size={size} stroke={color} />
    </div>
  );
};
