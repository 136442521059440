import React from 'react';

type Props = {
  size?: number;
  stroke?: string;
};

export const Plus: React.FC<Props> = ({ size = 24, stroke = 'white' }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 4.49805V19.5017M4.49805 11.9998H19.5017" stroke={stroke} strokeLinecap="round" />
    </svg>
  );
};
