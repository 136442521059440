import React from 'react';
import { useAppSelector } from 'shared/lib';
import { ReactComponent as AppleWhiteIcon } from 'shared/assets/icons/svg/LoginScreen/apple_white.svg';
import { ReactComponent as GoogleWhiteIcon } from 'shared/assets/icons/svg/LoginScreen/google_play_white.svg';
import { text } from 'shared/constants';
import {
  AuthInfoButtonsWrapper,
  AuthInfoContainer,
  BackgroundContainer,
  GreetingsText,
  List,
  ListHeader,
  Subtitle,
} from './styles';

export const AuthInfoCard: React.FC = () => {
  const { language } = useAppSelector((state) => state.language);
  return (
    <BackgroundContainer>
      <AuthInfoContainer>
        <GreetingsText>{text[language].WelcomeToGubadoo}</GreetingsText>
        <div>
          <Subtitle>{text[language].TourSolution}</Subtitle>
          <ListHeader>{text[language].WebAppLetYou}</ListHeader>
          <List>
            <li>{text[language].ManagePriceList}</li>
            <li>{text[language].ManageProjects}</li>
            <li>{text[language].CreateEstimates}</li>
          </List>
        </div>
        <AuthInfoButtonsWrapper>
          <button
            onClick={() => {
              window.open('https://play.google.com/store/apps/details?id=com.gubadoo');
            }}
          >
            <GoogleWhiteIcon />
            <p>Google play</p>
          </button>
          <button
            onClick={() => {
              window.open('https://apps.apple.com/ua/app/gubadoo/id1671784565');
            }}
          >
            <AppleWhiteIcon />
            <p>App Store</p>
          </button>
        </AuthInfoButtonsWrapper>
      </AuthInfoContainer>
    </BackgroundContainer>
  );
};
