import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { COLORS } from 'shared/constants';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const DropdownBody = styled('ul')(({ className }) => ({
  zIndex: 3,
  position: 'absolute',
  top: 0,
  maxHeight: '150px',
  overflow: 'auto',
  listStyle: 'none',
  outline: 'none',
  background: className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.TEXT_WHITE,
  borderTop: 'none',
  borderRadius: '8px',
  animation: `${fadeIn} 0.3s ease`,
  boxShadow: className?.includes('dark') ? 'unset' : COLORS.TABLE_DROPDOWN_BOX_SHADOW,

  '& li': {
    fontSize: className?.includes('mobileModal') ? '8px' : '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    padding: '8px 16px',
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    borderRight: `1px solid ${className?.includes('dark') ? COLORS.LIGHT_GREY_BORDER : COLORS.BORDER_GREY}`,
    borderLeft: `1px solid ${className?.includes('dark') ? COLORS.LIGHT_GREY_BORDER : COLORS.BORDER_GREY}`,
  },

  '& li span': {
    lineHeight: className?.includes('mobileModal') ? '12px' : '24px',
  },
  '@media (min-width: 1024px)': {
    '& li': {
      fontSize: '14px',
    },
    '& li span': {
      lineHeight: '24px',
    },
  },
}));

export const DropdownElement = styled('li')(({ className }) => ({
  color: className?.includes('dark') ? COLORS.BODY_TEXT_BLACK_20 : COLORS.TEXT_BLACK,
  gap: '16px',
  '&:hover': {
    background:
      className?.includes('dark') && className?.includes('withoutGradient')
        ? COLORS.BACKGROUND_LIGHT_DARK
        : className?.includes('withoutGradient')
        ? COLORS.TABLE_BG
        : COLORS.ORANGE_TEXT_HOVERED,
    WebkitBackgroundClip: className?.includes('withoutGradient') ? 'unset' : 'text',
    WebkitTextFillColor: className?.includes('withoutGradient') ? 'unset' : 'transparent',
  },
  '&:first-of-type': {
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    border: `1px solid ${className?.includes('dark') ? COLORS.LIGHT_GREY_BORDER : COLORS.BORDER_GREY}`,
    borderBottom: '1px solid transparent',
  },
  '&:last-of-type': {
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    border: `1px solid ${className?.includes('dark') ? COLORS.LIGHT_GREY_BORDER : COLORS.BORDER_GREY}`,
    borderTop: '1px solid transparent',
  },
}));

export const DropdownEmptyElement = styled('li')(({ className }) => ({
  borderTopLeftRadius: '8px',
  borderTopRightRadius: '8px',
  border: `1px solid ${COLORS.BORDER_GREY}`,
  borderBottom: '1px solid transparent',
}));

export const AddPositionButton = styled('li')(({ className }) => ({
  color: className?.includes('dark') ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK,
  gap: '8px',
  '&:hover': {
    background: className?.includes('dark') ? COLORS.BACKGROUND_LIGHT_DARK : COLORS.TABLE_BG,
  },
}));
