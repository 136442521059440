import { DragStart, DragUpdate, DropResult } from '@hello-pangea/dnd';
import { AppDispatch } from 'app/store';

import { createPosition, orderPositions, removePosition, IPriceList } from 'entities/priceList';
import { ICategory, orderCategories } from 'entities/category';
import { removeCheckedPositions } from 'entities/positionsFilter';
import { ICompanyInfo } from 'entities/company';

import { getArrayOfIds, getCategoryById } from 'shared/lib';
import { text } from 'shared/constants';

import { getIdFromString, reorder, massPositionsReorder, getPositionsByIds, PlaceholderPropsType } from './';

export const onDragEnd = (
  result: DropResult,
  setPlaceholderProps: React.Dispatch<PlaceholderPropsType>,
  setIsPlaceholderVisible: React.Dispatch<React.SetStateAction<boolean>>,
  categories: ICategory[] | null,
  company: ICompanyInfo[] | null,
  setCategories: React.Dispatch<React.SetStateAction<ICategory[] | null>>,
  dispatch: AppDispatch,
  priceList: IPriceList[] | null,
  checkedPositionsIds: number[],
) => {
  setPlaceholderProps({} as PlaceholderPropsType);
  setIsPlaceholderVisible(true);
  if (!result.destination || !categories || !company) return;
  const sourceCategory = getCategoryById(parseInt(result.source.droppableId), categories);
  const destinationCategory = getCategoryById(parseInt(result.destination.droppableId), categories);

  if (result.draggableId.includes('category')) {
    const reorderedCategories: ICategory[] = reorder(categories, result.source.index, result.destination.index);
    const newOrder = getArrayOfIds(reorderedCategories);

    if (!company) return;
    dispatch(orderCategories({ companyID: company[0].id, order: newOrder }));
    setCategories(reorderedCategories);
  } else if (result.draggableId.includes('position')) {
    const draggablePosition = priceList?.filter(
      (i: IPriceList) => i.id.toString() === getIdFromString(result.draggableId),
    )[0];
    if (!draggablePosition) return;

    if (sourceCategory?.id === destinationCategory?.id) {
      dragToSameCategory(
        result,
        categories,
        checkedPositionsIds,
        draggablePosition,
        priceList,
        setCategories,
        dispatch,
        company,
      );
    } else if (sourceCategory?.id !== destinationCategory?.id && destinationCategory) {
      const order = result.destination.index;
      dragToAnotherCategory(
        checkedPositionsIds,
        dispatch,
        company,
        priceList,
        destinationCategory,
        order,
        draggablePosition,
      );
    }
  }
};

const dragToSameCategory = (
  result: DropResult,
  categories: ICategory[],
  checkedPositionsIds: number[],
  draggablePosition: IPriceList,
  priceList: IPriceList[],
  setCategories: React.Dispatch<React.SetStateAction<ICategory[] | null>>,
  dispatch: AppDispatch,
  company: ICompanyInfo[],
) => {
  const currentCategory = getCategoryById(parseInt(result.source.droppableId), categories);
  if (!currentCategory || !result.destination || !currentCategory.positions) return;
  let reorderedPositions: IPriceList[] = [];
  if (checkedPositionsIds.length > 0 && checkedPositionsIds.includes(draggablePosition.id)) {
    const checkedPositions = getPositionsByIds(checkedPositionsIds, priceList, currentCategory.id);
    reorderedPositions = massPositionsReorder(
      currentCategory.positions,
      result.source.index,
      result.destination.index,
      checkedPositions,
    );
  } else {
    reorderedPositions = reorder(currentCategory.positions, result.source.index, result.destination.index);
  }
  const newOrder = getArrayOfIds(reorderedPositions);
  currentCategory.positions = reorderedPositions;
  setCategories((prevCats) => [...(prevCats || [])]);
  dispatch(
    orderPositions({
      companyID: company[0].id,
      articleID: draggablePosition.article,
      categoryID: draggablePosition.category.id,
      order: newOrder,
    }),
  );
};

const dragToAnotherCategory = (
  checkedPositionsIds: number[],
  dispatch: AppDispatch,
  company: ICompanyInfo[],
  priceList: IPriceList[],
  destinationCategory: ICategory,
  order: number,
  draggablePosition: IPriceList,
) => {
  if (checkedPositionsIds.length > 1) {
    dispatch(removePosition({ companyID: company[0].id, itemID: checkedPositionsIds, isDragAndDrop: true }));
    dispatch(removeCheckedPositions(checkedPositionsIds));
    const data = getPositionsByIds(checkedPositionsIds, priceList, destinationCategory.id);
    dispatch(
      createPosition({
        data: data,
        company: company[0].id,
        isDragAndDrop: true,
        indexToInsert: order,
      }),
    );
  } else {
    dispatch(removePosition({ companyID: company[0].id, itemID: [draggablePosition.id], isDragAndDrop: true }));
    dispatch(removeCheckedPositions([draggablePosition.id]));
    dispatch(
      createPosition({
        data: [
          {
            article: draggablePosition.article,
            name: draggablePosition.name,
            category: destinationCategory.id,
            isTemporary: false,
            units: draggablePosition.units,
            funds: draggablePosition.funds,
            order: order + 1,
          },
        ],
        company: company[0].id,
        isDragAndDrop: true,
        indexToInsert: order,
      }),
    );
  }
};

export const onDragStart = (event: DragStart, setPlaceholderProps: React.Dispatch<PlaceholderPropsType>) => {
  const draggedDOM = getDraggedDom(event.draggableId);
  if (!draggedDOM?.parentNode) return;
  const { clientHeight } = draggedDOM;
  const sourceIndex = event.source.index;
  const clientY =
    parseFloat(window.getComputedStyle(draggedDOM.parentNode as Element).paddingTop) +
    [...draggedDOM.parentNode.children].slice(0, sourceIndex).reduce((total, curr) => {
      const style = window.getComputedStyle(curr);
      const marginBottom = parseFloat(style.marginBottom);
      return total + curr.clientHeight + marginBottom;
    }, 0);

  setPlaceholderProps({
    clientHeight,
    clientY,
    clientX: parseFloat(window.getComputedStyle(draggedDOM.parentNode as Element).paddingLeft),
  });
};

export const onDragUpdate = (
  event: DragUpdate,
  categories: ICategory[] | null,
  setIsPlaceholderVisible: React.Dispatch<React.SetStateAction<boolean>>,
  setHiddenPlaceholder: React.Dispatch<React.SetStateAction<boolean>>,
  setDropPlaceholderText: React.Dispatch<React.SetStateAction<string>>,
  language: string,
  setPlaceholderProps: React.Dispatch<any>,
) => {
  if (!event.destination) return;
  const destinationId = event.destination.droppableId;
  const sourceId = event.source.droppableId;
  const categoryPrimaryId = categories?.filter((cat) => cat.primary === true)[0].id.toString();

  if (categories && destinationId === 'categories' && categories.length - event.destination.index === 1) {
    setIsPlaceholderVisible(false);
  } else {
    setIsPlaceholderVisible(true);
  }

  // TODO tech debt
  if (destinationId === categoryPrimaryId) {
    setHiddenPlaceholder(true);
  } else {
    setHiddenPlaceholder(false);
  }

  if (destinationId === sourceId || destinationId === categoryPrimaryId) {
    setDropPlaceholderText(text[language].dragHere);
  } else {
    setDropPlaceholderText(text[language].dragToCategory);
  }

  const draggedDOM = getDraggedDom(event.draggableId);
  if (!draggedDOM?.parentNode) return;

  const { clientHeight } = draggedDOM;
  const destinationIndex = event.destination.index;
  const sourceIndex = event.source.index;

  const childrenArray = [...draggedDOM.parentNode.children];
  const movedItem = childrenArray[sourceIndex];
  childrenArray.splice(sourceIndex, 1);

  const updatedArray = [
    ...childrenArray.slice(0, destinationIndex),
    movedItem,
    ...childrenArray.slice(destinationIndex + 1),
  ];

  const clientY =
    parseFloat(window.getComputedStyle(draggedDOM.parentNode as Element).paddingTop) +
    updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
      const style = window.getComputedStyle(curr);
      const marginBottom = parseFloat(style.marginBottom);
      return total + curr.clientHeight + marginBottom;
    }, 0);

  setPlaceholderProps({
    clientHeight,
    clientY,
    clientX: parseFloat(window.getComputedStyle(draggedDOM.parentNode as Element).paddingLeft),
  });
};

const getDraggedDom = (draggableId: string) => {
  const domQuery = `[data-rfd-draggable-id='${draggableId}']`;
  const draggedDOM = document.querySelector(domQuery);

  return draggedDOM;
};
