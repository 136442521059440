import styled from '@emotion/styled';
import { COLORS } from 'shared/constants';

export const CurrenciesBarWrapper = styled('div')(({ className }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  width: '100%',
  gap: '0px',
  borderBottom: `2px solid ${className?.includes('dark') ? COLORS.LIGHT_GREY_BORDER : COLORS.BORDER_GREY}`,
  height: '50px',
  marginBottom: '32px',
  overflowY: 'hidden',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  '& div button': {
    width: className?.includes('single') ? '180px' : '120px',
  },
  '@media (min-width: 769px)': {
    gap: '16px',
    '& div button': {
      width: '120px',
    },
  },
  '@media (max-width: 1024px)': {
    maxWidth: '100vw!important',
  },
}));

export const SkeletonContainer = styled('div')(({ className }) => ({
  width: '120px',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const AddCurrencyButton = styled('button')(({ className }) => ({
  fontFamily: "'Golos', sans-serif",
  padding: '13px 12px',
  display: 'flex',
  alignItems: 'center',
  minWidth: '156px',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: '8px',
  height: '100%',
  cursor: 'pointer',
  border: 0,
  background: 'transparent',
  '& p': {
    whiteSpace: 'nowrap',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    color: className?.includes('dark') ? COLORS.BODY_TEXT_BLACK_20 : COLORS.ACTION_BAR_BLACK_BG,
  },
  '&:hover': {
    '& svg path': {
      stroke: COLORS.ORANGE_BTN,
    },
    '& p': { background: COLORS.ORANGE_TEXT_HOVERED, WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' },
  },
  '@media (min-width: 769px)': {
    padding: '13px 4px',
    '& p': {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
}));
