import { ICategoryEstimates } from 'entities/category';

export const filterDuplicateCategories = (categories: ICategoryEstimates[]): ICategoryEstimates[] => {
  const uniqueCategories: { [key: number]: ICategoryEstimates } = {};

  categories.forEach((category) => {
    uniqueCategories[category.id] = category;
  });

  return Object.values(uniqueCategories);
};
