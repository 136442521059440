import React, { useEffect, useRef, useState } from 'react';
import { LoadingResultsT } from 'entities/loadingType';
import { CompanyIcon, TriangleArrow } from 'shared/assets/icons';
import { TextSkeleton } from 'shared/ui';
import { LocalStorage, getMemberFirstLastNames, useAppDispatch, useAppSelector } from 'shared/lib';
import { Subscription } from 'entities/account';
import { ICompanyInfo, setSelectedCompany } from 'entities/company';
import { CompanyInfo, DropDownContainer, SelectContainer, SkeletonContainer } from './styles';

export const CompanySelect: React.FC = () => {
  const accountInfoLoading = useAppSelector((state) => state.account.loading);
  const theme = useAppSelector((state) => state.theme.theme);
  const accountInfo = useAppSelector((state) => state.account.accountInfo);
  const selectedCompany = useAppSelector((state) => state.company.selectedCompany);
  const companies = useAppSelector((state) => state.company.company);
  const selectRef = useRef<HTMLInputElement>(null);

  const dispatch = useAppDispatch();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOutsideClick = (e: MouseEvent) => {
    if (selectRef.current && !selectRef.current.contains(e.target as Node)) {
      setIsOpen(false);
    }
  };

  const onSelectCompanyClick = (company: ICompanyInfo) => {
    setIsOpen(false);
    dispatch(setSelectedCompany(company));
    LocalStorage.setSelectedCompanyId(company.id);
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const isDarkTheme = theme === 'dark';

  const isBusinessSubscription = accountInfo?.subscription === Subscription.Business;

  return (
    <SelectContainer ref={selectRef}>
      {accountInfoLoading === LoadingResultsT.PENDING || !selectedCompany ? (
        <SkeletonContainer>
          <TextSkeleton isSideBar={true} />
          <TextSkeleton isSideBar={true} />
        </SkeletonContainer>
      ) : (
        <CompanyInfo
          className={isDarkTheme ? 'dark' : ''}
          style={{ cursor: isBusinessSubscription ? 'pointer' : 'auto' }}
          onClick={() => isBusinessSubscription && setIsOpen(!isOpen)}
        >
          <CompanyIcon />
          <div>
            <div>
              <p>{selectedCompany.name}</p>
              {isBusinessSubscription ? (
                <p>{selectedCompany.role}</p>
              ) : (
                <p>{accountInfo && getMemberFirstLastNames(accountInfo)}</p>
              )}
            </div>
            {isBusinessSubscription && <TriangleArrow stroke="black" />}
          </div>
        </CompanyInfo>
      )}
      {isOpen && (
        <DropDownContainer className={isDarkTheme ? 'dark' : ''}>
          {companies?.map((company) => (
            <CompanyInfo className={isDarkTheme ? 'dark' : ''} onClick={() => onSelectCompanyClick(company)}>
              <CompanyIcon />
              <div>
                <div>
                  <p>{company.name}</p>
                  <p>{company.role}</p>
                </div>
              </div>
            </CompanyInfo>
          ))}
        </DropDownContainer>
      )}
    </SelectContainer>
  );
};
