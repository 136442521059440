import styled from '@emotion/styled';
import { COLORS } from 'shared/constants';

export const InputErrorText = styled('p')(({ className }) => ({
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
  marginTop: '8px',
  color: COLORS.MSG_RED,
  fontFamily: "'Golos', sans-serif",
  fontStyle: 'normal',
  alignItems: 'center',
}));
