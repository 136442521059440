import React from 'react';
import { useAppSelector } from 'shared/lib';
import { text } from 'shared/constants';
import { EmptyTableBodyCell } from './styles';

type Props = {
  isEmptyCategory?: boolean;
};

const EstimatePosEmptyTableRow: React.FC<Props> = ({ isEmptyCategory = false }) => {
  const language = useAppSelector((state) => state.language.language);
  const theme = useAppSelector((state) => state.theme.theme);

  const isDarkTheme = theme === 'dark';
  return (
    <EmptyTableBodyCell className={isDarkTheme ? 'dark' : ''}>
      <p>{text[language].noPositionsFromPriceList}</p>
    </EmptyTableBodyCell>
  );
};

export default EstimatePosEmptyTableRow;
