import React from 'react';
import { COLORS } from 'shared/constants';

type Props = {
  isPinned: boolean;
  stroke?: string;
};

export const PinIcon: React.FC<Props> = ({ isPinned, stroke = COLORS.DISABLED_BTN_TEXT }) => {
  return (
    <>
      {isPinned ? (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M4 20L9.58305 14.417M10.9044 8.8551L12.9324 4L20 11.0676L15.145 13.0957L13.1169 17.9507L6.04925 10.8831L10.9044 8.8551Z"
            stroke="url(#paint0_linear_2423_384)"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <defs>
            <linearGradient id="paint0_linear_2423_384" x1="4" y1="20" x2="20" y2="4" gradientUnits="userSpaceOnUse">
              <stop stopColor="#FF8C21" />
              <stop offset="0.880208" stopColor="#FFD200" />
            </linearGradient>
          </defs>
        </svg>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M4 20L9.58305 14.417M10.9044 8.8551L12.9324 4L20 11.0676L15.145 13.0957L13.1169 17.9507L6.04925 10.8831L10.9044 8.8551Z"
            stroke={stroke}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </>
  );
};
