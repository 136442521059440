import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { COLORS } from 'shared/constants';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const ActionBarContainer = styled('div')(({ className }) => ({
  fontFamily: "'Golos', sans-serif",
  bottom: className?.includes('mobile') ? 80 : 32,
  position: 'sticky',
  zIndex: 1,
  width: '100%',
  flexGrow: 1,
  display: 'flex',
  animation: `${fadeIn} 0.2s ease`,
}));

export const ActionBarWrapper = styled('div')(({ className }) => ({
  color: COLORS.TEXT_WHITE,
  fontSize: className?.includes('mobile') ? '12px' : '14px',
  fontWeight: 500,
  height: 'auto',
  minHeight: '48px',
  lineHeight: className?.includes('mobile') ? '16px' : '20px',
  margin: 'auto auto 0',
  width: className?.includes('mobile') ? '95%' : '80%',
  maxWidth: '1150px',
  backgroundColor: COLORS.ACTION_BAR_BLACK_BG,
  padding: className?.includes('mobile') ? '0 8px' : '0 32px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: '8px',
  '& button': {
    padding: '6px',
    borderRadius: '8px',
  },
}));

export const SingleButtonContainer = styled('div')(({ className }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '4px',
  '& svg': {
    transition: 'transform 0.2s linear',
    transform: className?.includes('open') ? 'rotate(-180deg)' : 'rotate(0deg)',
  },
}));

export const ActionButtonsWrapper = styled('div')(({ className }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: className?.includes('mobile') ? '12px' : '25px',
}));

export const CloseButtonWrapper = styled('div')(({ className }) => ({
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  gap: '32px',
}));

export const PositionsWrapper = styled('div')(({ className }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '& span': {
    fontSize: '10px',
    lineHeight: '14px',
  },
}));

export const DropdownBody = styled('ul')(({ className }) => ({
  position: 'absolute',
  bottom: 36,
  listStyle: 'none',
  outline: 'none',
  background: COLORS.BODY_TEXT_BLACK_70,
  borderTop: 'none',
  borderRadius: '8px',
  animation: `${fadeIn} 0.2s ease`,
}));

export const DropdownElement = styled('li')(({ className }) => ({
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  padding: '8px 16px',
  display: 'flex',
  gap: '8px',
  cursor: 'pointer',
  '& span': {
    lineHeight: '24px',
  },
  '& svg': {
    transform: 'rotate(360deg)',
  },
  '&:hover': {
    background: COLORS.TEXT_GREY,
  },
  '&:first-of-type': {
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
  },
  '&:last-of-type': {
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
  },
}));
