import { createSlice } from '@reduxjs/toolkit';
import { LoadingResultsT } from '../../loadingType';

import { filterModalPriceListByProjectCurrency, updateArticleById } from './helpers';
import {
  fetchPriceList,
  duplicatePosition,
  editPosition,
  removePosition,
  createPosition,
  changeArticles,
} from '../api/thunks';
import { IPriceList } from '../';

interface IState {
  filteredPriceList: IPriceList[] | null;
  priceList: IPriceList[] | null;
  fullPriceList: IPriceList[] | null;
  filteredByCurrencyPriceList: IPriceList[] | null;
  filteredByCurrencyPriceListAndSearch: IPriceList[] | null;
  loadingPriceList: LoadingResultsT;
  loadingPosition: LoadingResultsT;
  loadingCreatingPosition: LoadingResultsT;
  error: string | null;
  priceListLength: number | null;
  isSearching: boolean;
}

const initialState: IState = {
  priceList: null,
  fullPriceList: null,
  filteredPriceList: null,
  filteredByCurrencyPriceList: null,
  filteredByCurrencyPriceListAndSearch: null,
  loadingPriceList: LoadingResultsT.IDLE,
  loadingPosition: LoadingResultsT.IDLE,
  loadingCreatingPosition: LoadingResultsT.IDLE,
  error: null,
  priceListLength: null,
  isSearching: false,
};

const priceListSlice = createSlice({
  name: 'priceList',
  initialState,
  reducers: {
    setPriceList(state, { payload }) {
      state.priceList = payload;
      state.priceListLength = payload.length as number;
    },
    setPositions(state, { payload }) {
      if (state.priceList) state.priceList = [...payload, ...state.priceList];
      state.priceListLength = payload.length as number;
    },
    resetFilteredPriceList(state) {
      state.filteredByCurrencyPriceList = [];
      state.filteredByCurrencyPriceListAndSearch = [];
      state.filteredPriceList = [];
    },
    resetSearchingPriceList(state) {
      state.isSearching = false;
    },
    setFilteredPriceList(state, { payload }) {
      state.filteredByCurrencyPriceListAndSearch = payload;
      state.filteredPriceList = payload;
      state.isSearching = true;
    },
    removePositionsById: (state, { payload }) => {
      const filteredPositions = state.priceList?.filter((position) => !payload.includes(position.id));

      if (filteredPositions) {
        state.priceList = [...filteredPositions];
      }
    },
    resetPositionLoading: (state) => {
      state.loadingPosition = LoadingResultsT.IDLE;
    },
    resetPriceListLoading: (state) => {
      state.loadingPriceList = LoadingResultsT.IDLE;
      state.loadingCreatingPosition = LoadingResultsT.IDLE;
      state.loadingPosition = LoadingResultsT.IDLE;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchPriceList.pending, (state) => {
        state.loadingPriceList = LoadingResultsT.PENDING;
      })
      .addCase(fetchPriceList.fulfilled, (state, { payload }) => {
        state.loadingPriceList = LoadingResultsT.SUCCEEDED;

        const filteredPriceList = payload.data.filter((pos) => !pos.isHidden && !pos.isTemporary);
        state.fullPriceList = payload.data;
        state.filteredByCurrencyPriceList = filterModalPriceListByProjectCurrency(filteredPriceList, payload.currency);
        state.priceList = filteredPriceList;

        state.priceListLength = filteredPriceList.length as number;
        state.error = null;
      })
      .addCase(fetchPriceList.rejected, (state, { error }) => {
        state.loadingPriceList = LoadingResultsT.FAILED;
        state.error = error.message as string;
      })

      .addCase(editPosition.pending, (state) => {
        state.loadingPosition = LoadingResultsT.PENDING;
      })
      .addCase(editPosition.fulfilled, (state, { payload }) => {
        state.loadingPosition = LoadingResultsT.SUCCEEDED;
        if (!state.priceList) return;
        const indexToReplace = state.priceList.findIndex((item) => item.id === payload.id);

        if (indexToReplace !== -1) {
          state.priceList[indexToReplace] = payload;
        }
        state.error = null;
      })
      .addCase(editPosition.rejected, (state, { error }) => {
        state.loadingPosition = LoadingResultsT.FAILED;
        state.error = error.message as string;
      })

      .addCase(duplicatePosition.pending, (state) => {
        state.loadingPosition = LoadingResultsT.PENDING;
      })
      .addCase(duplicatePosition.fulfilled, (state, { payload }) => {
        state.loadingPosition = LoadingResultsT.SUCCEEDED;
        const createdDuplicate = payload.data[0];
        const duplicatedItemId = payload.nextTo;
        if (!state.priceList) return;

        const indexToInsertAfter = state.priceList.findIndex((item) => item.id === duplicatedItemId);
        if (indexToInsertAfter !== -1) {
          state.priceList.splice(indexToInsertAfter + 1, 0, createdDuplicate);
        }

        state.priceListLength = state.priceList?.length as number;
        state.error = null;
      })
      .addCase(duplicatePosition.rejected, (state, { error }) => {
        state.loadingPosition = LoadingResultsT.FAILED;
        state.error = error.message as string;
      })

      .addCase(createPosition.pending, (state) => {
        state.loadingCreatingPosition = LoadingResultsT.PENDING;
      })
      .addCase(createPosition.fulfilled, (state, { payload }) => {
        state.loadingCreatingPosition = LoadingResultsT.SUCCEEDED;

        const nextTo = payload.nextTo;
        const indexToInsert = payload.indexToInsert;
        if (!state.priceList) return;
        if (typeof indexToInsert !== 'undefined') {
          state.priceList.splice(indexToInsert, 0, ...payload.data);
        } else if (nextTo) {
          const indexToInsertAfter = state.priceList.findIndex((item) => item.id === nextTo);
          if (indexToInsertAfter !== -1) {
            state.priceList.splice(indexToInsertAfter + 1, 0, ...payload.data);
          }
        } else {
          state.priceList = [...state.priceList, ...payload.data];
        }

        state.priceListLength = state.priceList?.length as number;
        state.error = null;
      })
      .addCase(createPosition.rejected, (state, { error }) => {
        state.loadingCreatingPosition = LoadingResultsT.FAILED;
        state.error = error.message as string;
      })

      .addCase(removePosition.pending, (state) => {
        state.loadingPosition = LoadingResultsT.PENDING;
      })
      .addCase(removePosition.fulfilled, (state, { payload }) => {
        state.loadingPosition = LoadingResultsT.SUCCEEDED;
        const messageDescription = payload.message.messageDescription;
        const match = messageDescription.match(/\[(.*?)\]/); // TODO: getting id from response
        const itemIdsToRemove = match[1].split(',').map(Number);

        if (!state.priceList) return;
        state.priceList = state.priceList.filter((item) => !itemIdsToRemove.includes(item.id));
        state.priceListLength = state.priceList.length as number;
        state.error = null;
      })
      .addCase(removePosition.rejected, (state, { error }) => {
        state.loadingPosition = LoadingResultsT.FAILED;
        state.error = error.message as string;
      })

      .addCase(changeArticles.pending, (state) => {
        state.loadingPosition = LoadingResultsT.PENDING;
      })
      .addCase(changeArticles.fulfilled, (state, { payload }) => {
        state.loadingPosition = LoadingResultsT.SUCCEEDED;

        const idsToUpdateArray: number[] = payload.items;
        const newArticleValue: number = payload.article;
        if (state.priceList) state.priceList = updateArticleById(state.priceList, idsToUpdateArray, newArticleValue);
        state.error = null;
      })
      .addCase(changeArticles.rejected, (state, { error }) => {
        state.loadingPosition = LoadingResultsT.FAILED;
        state.error = error.message as string;
      }),
});

export const {
  resetPositionLoading,
  resetPriceListLoading,
  setPriceList,
  removePositionsById,
  setPositions,
  resetFilteredPriceList,
  setFilteredPriceList,
  resetSearchingPriceList,
} = priceListSlice.actions;
export const priceListReducer = priceListSlice.reducer;
