import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { COLORS } from 'shared/constants';

const loading = keyframes`
0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
`;

export const SkeletonContainer = styled('div')(({ className }) => ({
  width: '100%',
  padding: '20px 50px',
}));

export const SkeletonLine = styled('div')(({ className }) => ({
  width: '100%',
  height: '10px',
  background: COLORS.SKELETON_BG,
  backgroundSize: '200% 100%',
  animation: `${loading} 1.5s infinite`,
  borderRadius: '5px',
}));

export const SkeletonSquare = styled('div')(({ className }) => ({
  width: '30px',
  height: '30px',
  flexShrink: 0,
  borderRadius: '5px',
  background: COLORS.SKELETON_BG,
  backgroundSize: '200% 100%',
  animation: `${loading} 1.5s infinite`,
}));

export const SkeletonRow = styled('div')(({ className }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '30px',
  height: '40px',
  width: '100%',
}));
