import React from 'react';
import { COLORS } from 'shared/constants';

type Props = {
  width?: number;
  height?: number;
  stroke?: string;
};

export const VerticalDivider: React.FC<Props> = ({ width = 2, height = 20, stroke = COLORS.BODY_TEXT_BLACK_20 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 2 20" fill="none">
      <path d="M1 0V20" stroke={stroke} />
    </svg>
  );
};
