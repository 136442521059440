import React from 'react';
import { useAppSelector } from 'shared/lib';

export const DeleteItemsIcons: React.FC = () => {
  const theme = useAppSelector((state) => state.theme.theme);
  const isDarkTheme = theme === 'dark';

  const stroke = isDarkTheme ? 'white' : 'black';

  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
        <path
          d="M26.6857 26.9044L26.6859 26.6854M7.99994 22.9483L7.99997 33.4967C7.99997 35.8465 7.99998 37.0214 8.27174 38.1234C8.51265 39.1003 8.90978 40.0318 9.44778 40.8821C10.0547 41.8412 10.9024 42.6547 12.5977 44.2818L34.8055 65.5951C38.5112 69.1515 40.364 70.9297 42.4779 71.5831C44.3379 72.158 46.331 72.1375 48.1787 71.5245C50.2788 70.8278 52.0947 69.0119 55.7265 65.3801L65.3803 55.7263C69.0121 52.0944 70.828 50.2785 71.5247 48.1785C72.1377 46.3308 72.1582 44.3377 71.5833 42.4777C70.9299 40.3638 69.1517 38.511 65.5953 34.8053L44.2822 12.5975C42.6551 10.9022 41.8415 10.0545 40.8824 9.44757C40.0322 8.90956 39.1006 8.51243 38.1237 8.27152C37.0217 7.99976 35.8468 7.99976 33.497 7.99976L22.9485 7.99976C17.716 7.99976 15.0997 7.99976 13.1012 9.01807C11.3432 9.9138 9.91395 11.3431 9.01822 13.1011C7.99992 15.0996 7.99992 17.7158 7.99994 22.9483Z"
          stroke={stroke}
          strokeWidth="3"
          strokeLinecap="round"
        />
        <path
          d="M44.3036 35.8183L38.5903 41.5316C37.335 42.7869 37.335 44.8221 38.5903 46.0773V46.0773C39.8455 47.3326 41.8807 47.3326 43.1359 46.0773L46.5764 42.6369C47.8317 41.3816 49.8668 41.3816 51.1221 42.6369V42.6369C52.3773 43.8921 52.3773 45.9273 51.1221 47.1826L44.9096 53.395M37.5 37L52 51.5"
          stroke={stroke}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="14" viewBox="0 0 32 14" fill="none">
        <path
          d="M1 7H31M31 7L21 1M31 7L21 13"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
        <path
          d="M12 12.8215H16.3077M68 12.8215H63.6923M63.6923 12.8215V63.5459C63.6923 66.364 61.9692 72 55.0769 72C48.1846 72 32.1026 72 24.9231 72C22.0513 72 16.3077 70.3092 16.3077 63.5459C16.3077 56.7827 16.3077 26.9116 16.3077 12.8215M63.6923 12.8215H50.7692M16.3077 12.8215H29.2308M33.727 25.5026V59.3189M46.2857 25.5026V59.3189M29.2308 12.8215V12.8215C29.2308 10.1586 31.3894 8 34.0522 8H40H45.9478C48.6106 8 50.7692 10.1586 50.7692 12.8215V12.8215M29.2308 12.8215H50.7692"
          stroke={stroke}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};
