import React from 'react';

type Props = {
  width?: number;
  height?: number;
  stroke?: string;
};

export const Download: React.FC<Props> = ({ width = 24, height = 24, stroke = 'white' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 4.49976V15.2141M12 15.2141L16.2857 11.9999M12 15.2141L7.71429 11.9999M4.5 16.2855V17.3569C4.5 18.5404 5.45939 19.4998 6.64286 19.4998H17.3571C18.5406 19.4998 19.5 18.5404 19.5 17.3569V16.2855"
        stroke={stroke}
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
