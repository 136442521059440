import React, { useEffect } from 'react';
import PriceListTableBody from './PriceListTableBody/PriceListTableBody';
import PriceListTableHead from './PriceListTableHead/PriceListTableHead';
import PriseListEmptyTableRow from './PriceListEmptyTableRow/PriceListEmptyTableRow';
import { fetchCategories } from 'entities/category';
import { LoadingResultsT } from 'entities/loadingType';
import { CurrenciesType, fetchPriceList } from 'entities/priceList';
import { PriceListTableSkeletonLoader, CustomTooltip } from 'shared/ui';
import { useAppDispatch, useAppSelector } from 'shared/lib';
import { text } from 'shared/constants';
import { TableScrollWrapper, TableWrapper } from './styles';

type Props = {
  isModalPriceList?: boolean;
  projectCurrency?: CurrenciesType;
};

export const PriceListTable: React.FC<Props> = ({ isModalPriceList = false, projectCurrency }) => {
  const selectedCompany = useAppSelector((state) => state.company.selectedCompany);
  const loadingPriceList = useAppSelector((state) => state.priceList.loadingPriceList);
  const loadingGroupPositionsToCategory = useAppSelector((state) => state.categories.loadingGroupPositionsToCategory);
  const userCurrencyList = useAppSelector((state) => state.currencies.userCurrencyList);
  const filteredPriceList = useAppSelector((state) => state.priceList.filteredPriceList);
  const priceList = useAppSelector((state) => state.priceList.priceList);
  const language = useAppSelector((state) => state.language.language);
  const isSearching = useAppSelector((state) => state.priceList.isSearching);
  const categories = useAppSelector((state) => state.categories.categories);
  const filteredCategories = useAppSelector((state) => state.categories.filteredCategories);
  const groupPositions = useAppSelector((state) => state.categories.loadingGroupPositionsToCategory);
  const theme = useAppSelector((state) => state.theme.theme);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (groupPositions === LoadingResultsT.SUCCEEDED && selectedCompany) {
      dispatch(fetchPriceList({ id: selectedCompany.id })); // reload PL after successful grouping positions
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupPositions]);

  useEffect(() => {
    if (isModalPriceList && selectedCompany && projectCurrency) {
      dispatch(fetchPriceList({ id: selectedCompany.id, currency: projectCurrency })); // fetch positions in price list modal
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectCurrency, userCurrencyList.length]);

  useEffect(() => {
    // TODO: replace to category component
    if (!selectedCompany) return;
    dispatch(fetchCategories({ id: selectedCompany.id, language }));
  }, [selectedCompany, language, dispatch]);

  const isDarkTheme = theme === 'dark';
  const tableCN = () => {
    let cn = '';

    if (isDarkTheme) cn += 'dark';
    if (isModalPriceList) cn += ' modalPriceList';

    return cn;
  };
  return (
    <TableScrollWrapper className={tableCN()}>
      <TableWrapper className={isDarkTheme ? 'dark' : ''}>
        <PriceListTableHead isModalPriceList={isModalPriceList} />
        {(() => {
          if (
            (!priceList?.length &&
              loadingPriceList !== LoadingResultsT.PENDING &&
              loadingGroupPositionsToCategory !== LoadingResultsT.PENDING &&
              categories &&
              categories?.length <= 1) || // 1 category 'UnCategorized' is necessary and are not shown
            (!filteredPriceList?.length && !filteredCategories?.length && isSearching)
          ) {
            return <PriseListEmptyTableRow />;
          } else if (
            loadingPriceList === LoadingResultsT.PENDING ||
            loadingGroupPositionsToCategory === LoadingResultsT.PENDING
          ) {
            return <PriceListTableSkeletonLoader />;
          } else {
            return <PriceListTableBody isModalPriceList={isModalPriceList} />;
          }
        })()}
        {!isModalPriceList && (
          <div>
            <CustomTooltip text={text[language].AddCategory} anchorSelect="#AddCategory" />
            <CustomTooltip text={text[language].ExportCategoryPDF} anchorSelect="#ExportCategoryPDF" />
            <CustomTooltip text={text[language].More} anchorSelect="#More" />
            <CustomTooltip text={text[language].AddItem} anchorSelect="#AddItem" />
            <CustomTooltip text={text[language].CostItem} anchorSelect="#CostItem" />
          </div>
        )}
      </TableWrapper>
    </TableScrollWrapper>
  );
};
