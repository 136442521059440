import axios from 'axios';
import { API_CONFIG, USER_STATUS } from 'shared/api';

export async function GetEstimatePDF(companyId: number, projectId: string, estimateId: string) {
  try {
    const response = await axios.get(
      `${API_CONFIG.BASE_URL}/v1/company/${companyId}/project/${projectId}/estimate/${estimateId}/pdf`,
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/pdf',
          userStatus: USER_STATUS,
        },
        responseType: 'arraybuffer',
        responseEncoding: 'binary',
      },
    );

    const data = await response.data;
    return data;
  } catch (error) {}
}
