import React, { useEffect, useRef, useState } from 'react';
import { CurrencyRateModal } from 'widgets';
import ImportTable from './ImportTable/ImportTable';

import {
  createPosition,
  resetPositionLoading,
  ParsedFunds,
  fundsType,
  CurrenciesType,
  CreatePositionRequest,
} from 'entities/priceList';
import { LoadingResultsT } from 'entities/loadingType';

import { ActionButton, ModalWrapper } from 'shared/ui';
import { useMediaQuery, useAppSelector, useAppDispatch, useDebounce } from 'shared/lib';
import { devices, text } from 'shared/constants';
import { ErrorInputModalTypes } from '../model/types';
import { parseExcelTextObject } from '../model/helpers';

import {
  ButtonsWrapper,
  InputHelperText,
  InputPlaceHolder,
  InputWrapper,
  ModalTable,
  ModalTableBody,
  ModalTableHeaderRow,
  ModalTableInput,
  ModalTableWrapper,
  ParsedTableWrapper,
} from './styles';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
}

export const ImportPositionsModal: React.FC<Props> = ({ isOpen, handleClose }) => {
  const isMobile = useMediaQuery(devices.mobile);
  const language = useAppSelector((state) => state.language.language);
  const company = useAppSelector((state) => state.company.company);
  const userCurrencies = useAppSelector((state) => state.currencies.userCurrencyList);
  const activeCurrency = useAppSelector((state) => state.currencies.activeCurrency);
  const loadingPosition = useAppSelector((state) => state.priceList.loadingPosition);
  const theme = useAppSelector((state) => state.theme.theme);
  const dispatch = useAppDispatch();
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const [textareaValue, setTextareaValue] = useState(['']);
  const [showPlaceholder, setShowPlaceholder] = useState(true);

  const [debouncedHasWarning, hasWarning, setHasWarning] = useDebounce<boolean>(false, 100);
  const [debouncedIsError, isError, setIsError] = useDebounce<ErrorInputModalTypes>('', 100);

  const [preparedToRequestData, setPreparedToRequestData] = useState<CreatePositionRequest[] | null>(null);
  const [isOpenCurrencyRateModal, setIsOpenCurrencyRateModal] = useState(false);

  const currencyListWithoutActive = userCurrencies.filter((currency) => currency !== activeCurrency);
  const [importRequestData, setImportRequestData] = useState([] as ParsedFunds[]);

  useEffect(() => {
    const preparedData: ParsedFunds[] = parseExcelTextObject(textareaValue, activeCurrency);
    if (preparedData) {
      setImportRequestData(preparedData);
    } else if (!preparedData && textareaValue[0].length > 0) {
      setIsError('invalidData');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textareaValue, activeCurrency]);

  useEffect(() => {
    if (loadingPosition === LoadingResultsT.SUCCEEDED) {
      CloseImportPositionModal();
      dispatch(resetPositionLoading());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingPosition, dispatch]);

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleBlur = () => {
    if (!textareaValue) {
      setShowPlaceholder(true);
    }
  };

  const handleFocus = () => {
    setShowPlaceholder(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
        setShowPlaceholder(true);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const uniqueArray = Array.from(new Set(importRequestData.map((obj) => JSON.stringify(obj)))).map((str) =>
      JSON.parse(str),
    );

    const requestData = uniqueArray.map((item) => {
      const total = item.funds[activeCurrency as CurrenciesType]?.total;
      const cost = item.funds[activeCurrency as CurrenciesType]?.cost || total;

      return {
        ...item,
        article: 2,
        funds: {
          [activeCurrency as CurrenciesType]: {
            total: Number(total),
            cost: Number(cost),
          },
        } as Record<CurrenciesType, fundsType>,
      };
    });

    setPreparedToRequestData(requestData);
  }, [activeCurrency, importRequestData, setIsError]);

  function CloseImportPositionModal() {
    setIsOpenCurrencyRateModal(false);
    setHasWarning(false);
    handleClose();
    setTextareaValue(['']);
  }

  function ImportDataRequest() {
    if (!company) return;
    if (!importRequestData.length) {
      return setIsError('invalidData');
    } else {
      setIsError('');
    }

    if (currencyListWithoutActive?.length) {
      setIsOpenCurrencyRateModal(true);
    } else if (preparedToRequestData && !currencyListWithoutActive?.length) {
      dispatch(createPosition({ data: preparedToRequestData, company: company[0].id }));
    }
  }

  const placeHolderCN = () => {
    let cn = '';

    if (isDarkTheme) cn += 'dark';
    if (showPlaceholder) cn += 'show';
    return cn;
  };

  const isDarkTheme = theme === 'dark';
  return (
    <ModalWrapper
      titleClassName="titleToLeft"
      isOpen={isOpen}
      onCloseButtonClick={CloseImportPositionModal}
      title={text[language].ImportData}
    >
      <ModalTableWrapper>
        <ModalTable className={isDarkTheme ? 'dark' : ''}>
          <ModalTableHeaderRow className={isDarkTheme ? 'dark' : ''}>
            <div>{text[language].ItemName}</div>
            <div>{text[language].Unit}</div>
            <div>{text[language].Total}</div>
            <div>{text[language].Cost}</div>
          </ModalTableHeaderRow>
          <ModalTableBody className={debouncedIsError ? 'error' : debouncedHasWarning ? 'warning' : ''}>
            <ParsedTableWrapper className={isDarkTheme ? 'dark' : ''}>
              <ImportTable
                setHasWarning={setHasWarning}
                preparedData={importRequestData}
                setImportRequestData={(data) => setImportRequestData(data)}
                setIsError={setIsError}
              />
            </ParsedTableWrapper>
            <InputWrapper onClick={handleClick}>
              <InputPlaceHolder className={placeHolderCN()} onClick={handleClick}>
                <p>
                  {userCurrencies?.length > 1
                    ? text[language].ImportInputPlaceHolderMultiCurrencies
                    : text[language].ImportInputPlaceHolder}
                </p>
              </InputPlaceHolder>
              <ModalTableInput
                className={isDarkTheme ? 'dark' : ''}
                rows={1}
                ref={inputRef}
                placeholder={''}
                value={''}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                  setHasWarning(false);
                  const value = event.target.value;
                  setIsError('');

                  setTextareaValue([value, ...textareaValue]);
                }}
                onBlur={handleBlur}
                onFocus={handleFocus}
              />
            </InputWrapper>
          </ModalTableBody>
        </ModalTable>
      </ModalTableWrapper>
      {(debouncedIsError || debouncedHasWarning) && (
        <InputHelperText className={!debouncedIsError && debouncedHasWarning ? 'warning' : ''}>
          {!debouncedIsError && debouncedHasWarning && <p>{text[language].PayAttentionPrimeCostHigherCost}</p>}
          {debouncedIsError && (
            <p>
              {debouncedIsError === 'invalidData'
                ? text[language].ImportInputError
                : debouncedIsError === 'totalAmountIsZero'
                ? text[language].importModalTotalFieldZero
                : ''}
            </p>
          )}
        </InputHelperText>
      )}
      <ButtonsWrapper className={isMobile ? 'mobile' : ''}>
        <ActionButton
          text={text[language].Clear}
          onClick={() => {
            setHasWarning(false);
            setTextareaValue(['']);
            setIsError('');
          }}
        />
        <ActionButton
          isPending={loadingPosition === LoadingResultsT.PENDING}
          orangeBtn
          text={text[language].Import}
          disabled={!importRequestData?.length || debouncedIsError.length > 0}
          onClick={ImportDataRequest}
          isPriceListMainButton={true}
        />
      </ButtonsWrapper>
      <CurrencyRateModal
        isOpen={isOpenCurrencyRateModal}
        handleClose={() => setIsOpenCurrencyRateModal(false)}
        selectedCurrencies={currencyListWithoutActive as CurrenciesType[]}
        type="addingRateToRequest"
        requestData={preparedToRequestData}
        handleCloseImportModal={CloseImportPositionModal}
        isImportPositions={true}
      />
    </ModalWrapper>
  );
};
