import React from 'react';
import { SkeletonSquare, SkeletonLine, SkeletonRow, SkeletonContainer } from './styles';

export const PriceListTableSkeletonLoader: React.FC = () => {
  return (
    <SkeletonContainer>
      <SkeletonRow>
        <SkeletonSquare></SkeletonSquare>
        <SkeletonLine></SkeletonLine>
        <SkeletonLine></SkeletonLine>
        <SkeletonLine></SkeletonLine>
      </SkeletonRow>
      <SkeletonRow>
        <SkeletonSquare></SkeletonSquare>
        <SkeletonLine></SkeletonLine>
        <SkeletonLine></SkeletonLine>
        <SkeletonLine></SkeletonLine>
      </SkeletonRow>
    </SkeletonContainer>
  );
};
