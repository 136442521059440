import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useParams } from 'react-router-dom';
import { addPriceItemsToCategories, filterDuplicateCategories } from 'widgets/EstimatePositionsTable/model';
import { ICategoryEstimates } from 'entities/category';
import { getArrayOfIds, useAppSelector } from 'shared/lib';
import EstimatePosCategoryTableRow from '../EstimatePosCategoryTableRow/EstimatePosCategoryTableRow';
import EstimatePosPositionTableRow from '../EstimatePosPositionTableRow/ui/EstimatePosPositionTableRow';
import { PriceItemType } from 'entities/estimates';

type Props = {
  isPossibleToEdit: boolean;
};

const EstimatePosTableBody: React.FC<Props> = ({ isPossibleToEdit }) => {
  const estimatePriceList = useAppSelector((state) => state.estimates.estimatePriceList);
  const estimates = useAppSelector((state) => state.estimates.estimates);
  const allCategories = useAppSelector((state) => state.categories.categories);

  const [openedCategoriesList, setOpenedCategoriesList] = useState<number[]>([]);

  const [categories, setCategories] = useState<ICategoryEstimates[] | null>(null);

  const { estimateId } = useParams();
  const currentEstimate = estimates?.find((estimate) => estimate.id.toString() === estimateId);

  useEffect(() => {
    if (currentEstimate) {
      const defaultCategories = currentEstimate.categories;
      setCategories(defaultCategories);
    }
  }, [currentEstimate]);

  useEffect(() => {
    if (allCategories) {
      const filteredCategories = filterDuplicateCategories([
        ...(categories || []),
        ...addPriceItemsToCategories(allCategories, estimatePriceList),
      ]);
      setCategories(filteredCategories);
    }
  }, [estimatePriceList, allCategories]);

  const filteredPositionsWithoutCategory = (positions: PriceItemType[] | null) => {
    if (!positions) return [];
    const priceItemsFromCategories = categories?.map((cat) => {
      return cat.positions;
    });

    const flattenedArray = priceItemsFromCategories?.flat().map((innerArray) => innerArray);

    const positionsWithCategoryIds = getArrayOfIds(flattenedArray);
    return positions.filter((pos) => !positionsWithCategoryIds.includes(pos.id));
  };
  //TODO: refactoring
  return (
    <div>
      {estimatePriceList &&
        estimatePriceList.length > 0 &&
        filteredPositionsWithoutCategory(estimatePriceList).map((position) => (
          <EstimatePosPositionTableRow key={position.id} position={position} isPossibleToEdit={isPossibleToEdit} />
        ))}
      {categories
        ?.filter((cat) => cat.primary)
        .map((category) => {
          if (!category.positions || !category.positions.length) return null;
          return (
            <>
              {category.positions?.map((position) => (
                <EstimatePosPositionTableRow
                  key={position.id}
                  position={position}
                  positionCategoryId={category.id}
                  isPossibleToEdit={isPossibleToEdit}
                />
              ))}
            </>
          );
        })}
      {categories
        ?.filter((cat) => !cat.primary)
        .map((category) => {
          if (!category.positions || !category.positions.length) return null;
          let isOpened = false;
          if (category.primary) {
            isOpened = true;
          } else {
            isOpened = openedCategoriesList.includes(category.id);
          }
          const setIsOpened = () => {
            if (!isOpened) {
              setOpenedCategoriesList((prev) => [...prev, category.id]);
            } else {
              setOpenedCategoriesList((prev) => prev.filter((id) => id !== category.id));
            }
          };
          return (
            <>
              <EstimatePosCategoryTableRow
                key={category.id}
                categoryData={category}
                positionsLength={category.positions?.length}
                setIsOpened={setIsOpened}
                isOpened={isOpened}
                isPossibleToEdit={isPossibleToEdit}
              />
              <AnimatePresence initial={false}>
                {isOpened && (
                  <motion.div
                    initial={{ height: '0px', width: '100%', opacity: 0 }}
                    animate={{ height: 'auto', opacity: 1 }}
                    exit={{ height: '0px', opacity: 0 }}
                    transition={{ type: 'spring', duration: 0.4, bounce: 0 }}
                  >
                    {category.positions?.map((position) => (
                      <EstimatePosPositionTableRow
                        key={position.id}
                        position={position}
                        positionCategoryId={category.id}
                        isPossibleToEdit={isPossibleToEdit}
                      />
                    ))}
                  </motion.div>
                )}
              </AnimatePresence>
            </>
          );
        })}
    </div>
  );
};

export default EstimatePosTableBody;
