import styled from '@emotion/styled';
import { COLORS } from 'shared/constants';

export const ButtonsWrapper = styled('div')(({ className }) => ({
  '& button': {
    fontSize: '16px',
    lineHeight: '24px',
    width: '100%',
    height: '48px',
  },
  padding: '0px 14px',
  '@media (min-width: 360px)': {
    padding: '0 32px',
  },
}));

export const TextWrapper = styled('div')(({ className }) => ({
  maxWidth: '280px',
  '& p': {
    color: className?.includes('dark') ? COLORS.BODY_TEXT_BLACK_20 : COLORS.ACTION_BAR_BLACK_BG,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
  },
}));
