import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { createTemporaryPosition, resetEstimatesLoading } from 'entities/estimates';
import { LoadingResultsT } from 'entities/loadingType';
import { COLORS, text } from 'shared/constants';
import { GetUniqueUnitsFromPriceList, handleNumberValue, useAppDispatch, useAppSelector } from 'shared/lib';
import { ActionButton, DropDown, Input, ModalWrapper, SearchInputWithDropDown } from 'shared/ui';
import { MaterialsIcon, TriangleArrow, WorkIcon } from 'shared/assets/icons';
import { ItemValues, defaultValues } from '../model/defaultValues';
import { CommonModalContainer } from 'widgets/modals/styles';
import {
  ButtonsWrapper,
  DropDownContainer,
  DropDownSelect,
  ErrorMsg,
  ModalArticleSelect,
  ModalSmallContainer,
} from './styles';

type Props = {
  isOpen: boolean;
  handleClose: () => void;
};

export const AddTempPositionModal: React.FC<Props> = ({ isOpen, handleClose }) => {
  const language = useAppSelector((state) => state.language.language);
  const priceList = useAppSelector((state) => state.priceList.priceList);
  const selectedCompany = useAppSelector((state) => state.company.selectedCompany);
  const projects = useAppSelector((state) => state.projects.projects);
  const theme = useAppSelector((state) => state.theme.theme);
  const categories = useAppSelector((state) => state.categories.categories);
  const loadingCreatingTempPosition = useAppSelector((state) => state.estimates.loadingCreatingTempPosition);

  const [itemValues, setItemValues] = useState<ItemValues>(defaultValues);
  const [isOpenArticleDropDown, setIsOpenArticleDropDown] = useState(false);
  const [isWarning, setIsWarning] = useState(false);

  const dispatch = useAppDispatch();
  const companyID = selectedCompany?.id;

  const { projectId } = useParams();
  const currentProjectCurrency = projects?.find((proj) => proj.id.toString() === projectId)?.currency;

  function onChangeArticleClick(articleID: string) {
    setItemValues({ ...itemValues, positionArticle: articleID });
    setIsOpenArticleDropDown(false);
  }

  const onModalClose = () => {
    setItemValues(defaultValues);
    handleClose();
    dispatch(resetEstimatesLoading());
  };

  const onSaveClick = () => {
    if (!categories) return;
    const categoryWithoutCategory = categories.find((cat) => cat.primary === true);
    const itemCategoryID = categoryWithoutCategory?.id;

    if (!companyID || !currentProjectCurrency || !itemCategoryID) return;
    dispatch(
      createTemporaryPosition({
        data: [
          {
            article: Number(itemValues.positionArticle),
            isTemporary: true,
            name: itemValues.positionName,
            units: itemValues.positionUnit,
            category: itemCategoryID,
            funds: {
              [currentProjectCurrency]: {
                cost: Number(itemValues.positionCost || itemValues.positionTotal),
                total: Number(itemValues.positionTotal),
              },
            },
          },
        ],
        currency: currentProjectCurrency,
        company: companyID,
      }),
    );
  };

  useEffect(() => {
    if (loadingCreatingTempPosition === LoadingResultsT.SUCCEEDED) {
      onModalClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingCreatingTempPosition]);

  useEffect(() => {
    if (Number(itemValues.positionCost) > Number(itemValues.positionTotal)) {
      setIsWarning(true);
    } else {
      setIsWarning(false);
    }
  }, [itemValues.positionCost, itemValues.positionTotal]);

  const isSaveButtonDisabled =
    !itemValues.positionName || !itemValues.positionArticle || !itemValues.positionUnit || !itemValues.positionTotal;

  const articleDropDownElements = [
    {
      icon: <MaterialsIcon />,
      text: text[language].Materials,
      onClick: () => onChangeArticleClick('1'),
    },
    {
      icon: <WorkIcon language={language} />,
      text: text[language].Work,
      onClick: () => onChangeArticleClick('2'),
    },
  ];

  const articleCN = () => {
    let cn = '';

    if (!itemValues.positionArticle) cn += 'placeholder';
    if (isDarkTheme) cn += 'Dark';
    return cn;
  };
  const isDarkTheme = theme === 'dark';
  return (
    <ModalWrapper isOpen={isOpen} title={text[language].tempPosition} subtitle={text[language].ItemInfo}>
      <CommonModalContainer>
        <ModalSmallContainer>
          {/* POSITION NAME */}
          <Input
            placeholder={text[language].ItemName}
            value={itemValues.positionName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setItemValues({ ...itemValues, positionName: event.target.value });
            }}
          />
          {/* POSITION ARTICLE */}
          <DropDownSelect>
            <ModalArticleSelect
              className={articleCN()}
              onClick={() => setIsOpenArticleDropDown(!isOpenArticleDropDown)}
            >
              <span>{text[language].CostItem}</span>
              <p>
                {itemValues.positionArticle === '1'
                  ? text[language].Materials
                  : itemValues.positionArticle === '2'
                  ? text[language].Work
                  : text[language].CostItem}
              </p>
              <div>
                <TriangleArrow stroke={isDarkTheme ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK} />
              </div>
            </ModalArticleSelect>
            {isOpenArticleDropDown && (
              <DropDownContainer>
                <DropDown elements={[...articleDropDownElements]} />
              </DropDownContainer>
            )}
          </DropDownSelect>
          {/* POSITION UNITS */}
          <SearchInputWithDropDown
            onChange={(value: string) => {
              setItemValues({ ...itemValues, positionUnit: value });
            }}
            value={itemValues.positionUnit}
            placeholder={text[language].Unit}
            data={GetUniqueUnitsFromPriceList(priceList)}
          />
          {/* POSITION TOTAl */}
          <Input
            placeholder={text[language].Total}
            value={itemValues.positionTotal || ''}
            inputMode="decimal"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const value = event.target.value;
              const positionTotal = handleNumberValue(value);

              setItemValues({ ...itemValues, positionTotal });
            }}
          />
          {/* POSITION COST */}
          <Input
            placeholder={`${text[language].Cost} (${text[language].Optional})`}
            className={isWarning ? 'warning' : ''}
            value={itemValues.positionCost || ''}
            inputMode="decimal"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const positionCost = handleNumberValue(event.target.value);

              setItemValues({ ...itemValues, positionCost });
            }}
          />
        </ModalSmallContainer>
        {
          <ErrorMsg className={isWarning ? 'show' : ''}>
            {text[language].PayAttentionPrimeCostHigherCostSingle}
          </ErrorMsg>
        }
      </CommonModalContainer>
      <ButtonsWrapper>
        <ActionButton text={text[language].Cancel} onClick={onModalClose} />
        <ActionButton
          isPending={loadingCreatingTempPosition === LoadingResultsT.PENDING}
          text={text[language].Save}
          orangeBtn
          disabled={isSaveButtonDisabled}
          onClick={onSaveClick}
          isPriceListMainButton={true}
        />
      </ButtonsWrapper>
    </ModalWrapper>
  );
};
