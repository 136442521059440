import styled from '@emotion/styled';
import { COLORS } from 'shared/constants';

export const CategoryName = styled('span')(({ className }) => ({
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '24px',
  color: className?.includes('dark') ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK,
  marginRight: '16px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}));

export const CategoryCount = styled('span')(({ className }) => ({
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  color: className?.includes('dark') ? COLORS.DISABLED_BTN_TEXT : COLORS.TEXT_GREY,
}));

export const TableRowContainer = styled('div')(({ className }) => ({
  fontFamily: "'Golos', sans-serif",
  color: className?.includes('dark') ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK,
  position: 'relative',
  background: className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.TEXT_WHITE,
  borderBottom: `1px solid ${className?.includes('dark') ? COLORS.LIGHT_GREY_BORDER : COLORS.BORDER_GREY}`,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  textAlign: 'left',
  verticalAlign: 'middle',
  height: '48px',
  padding: '10px 8px 10px 8px',
  '&:hover': {
    background: className?.includes('dark') ? COLORS.BACKGROUND_LIGHT_DARK : COLORS.MODAL_BG,
  },
  '& .hoveredContainer': {
    opacity: 0,
  },
  '&:hover .hoveredContainer': {
    opacity: 1,
  },
  '@media (max-width: 767px)': {
    '& .hoveredContainer': {
      opacity: 1,
    },
  },
  '@media (min-width: 968px)': {
    padding: '10px 8px 10px 8px',
  },
  '& > div': {
    fontWeight: 600,
    flexShrink: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));
