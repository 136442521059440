import { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Purchases } from '@revenuecat/purchases-js';
import { GetUserCurrencies } from 'widgets/MainHeader';
import { setLanguage } from 'entities/language';
import {
  createCompanyCurrency,
  deleteCompanyCurrency,
  fetchCompanyCurrencies,
  setActiveCurrency,
} from 'entities/currencies';
import { CurrenciesType } from 'entities/priceList';
import { setTheme } from 'entities/theme';
import { getAccountInfo } from 'entities/account';
import { setSelectedCompany } from 'entities/company';
import { setCustomerInfo, setPackages } from 'entities/subscriptions';

import { PageSpinner } from 'shared/ui';
import { LocalStorage, checkUserSubscription, useAppDispatch, useAppSelector, useMediaQuery } from 'shared/lib';
import { devices, routes } from 'shared/constants';

import 'shared/styles/base.css';

const AuthPage = lazy(() => import('../pages/AuthPage/ui/AuthPage'));
const RestoreAccessPage = lazy(() => import('../pages/RestoreAccessPage/ui/RestoreAccessPage'));
const RegistrationPage = lazy(() => import('../pages/RegistrationPage/ui/RegistrationPage'));
const PriceListPage = lazy(() => import('../pages/PriceList/ui/PriceListPage'));
const ProjectsPage = lazy(() => import('../pages/Projects/ui/ProjectsPage'));
const ProfilePage = lazy(() => import('../pages/ProfilePage/ui/ProfilePage'));
const ProjectDetailPage = lazy(() => import('../pages/ProjectDetail/ui/ProjectDetailPage'));
const EstimateCreationPage = lazy(() => import('../pages/EstimateCreation/ui/EstimateCreationPage'));
const EstimateDetailPage = lazy(() => import('../pages/EstimateDetail/ui/EstimateDetailPage'));
const EstimateEditingPage = lazy(() => import('../pages/EstimateEditing/ui/EstimateEditingPage'));
const NoSubscriptionPage = lazy(() => import('../pages/NoSubscriptionPage/ui/NoSubscriptionPage'));
const AcceptCompanyPage = lazy(() => import('../pages/AcceptCompanyPage/ui/AcceptCompanyPage'));
const CompanyCreatingPage = lazy(() => import('../pages/CompanyCreatingPage/ui/CompanyCreatingPage'));
const Page404 = lazy(() => import('../pages/Page404/ui/Page404'));
const ResetPasswordRedirect = lazy(() => import('../pages/ResetPasswordRedirect/ResetPasswordRedirect'));
const ConfirmEmailRedirect = lazy(() => import('../pages/ConfirmEmailRedirect/ConfirmEmailRedirect'));
const TemporaryPaywall = lazy(() => import('../widgets/Paywall/Paywall'));

export default function App() {
  const priceList = useAppSelector((state) => state.priceList.priceList);
  const categories = useAppSelector((state) => state.categories.categories);
  const projects = useAppSelector((state) => state.projects.projects);
  const userCurrencies = useAppSelector((state) => state.currencies.userCurrencyList);
  const companyCurrencies = useAppSelector((state) => state.currencies.companyCurrencies);
  const selectedCompany = useAppSelector((state) => state.company.selectedCompany);
  const companies = useAppSelector((state) => state.company.company);
  const accountInfo = useAppSelector((state) => state.account.accountInfo);
  const customerInfo = useAppSelector((state) => state.subscriptions.customerInfo);

  const isMobile = useMediaQuery(devices.mobile);
  const dispatch = useAppDispatch();
  const widgetFab = document.getElementsByTagName('sp-live-chat')[0] as HTMLElement | null;

  const location = window.location;

  const REVENUECAT_BILLING_PUBLIC_API_KEY = process.env.REACT_APP_REVENUE_CAT_API_KEY;

  const companyId = selectedCompany?.id;

  const currentCompanyId = LocalStorage.getSelectedCompanyId();
  useEffect(() => {
    if (!companies) return;
    if (!currentCompanyId) dispatch(setSelectedCompany(companies[0]));

    const companyFromLocalStorage = companies?.find((company) => company.id.toString() === currentCompanyId);
    if (!companyFromLocalStorage) return;
    dispatch(setSelectedCompany(companyFromLocalStorage));
  }, [companies, dispatch, currentCompanyId]);

  useEffect(() => {
    dispatch(getAccountInfo());
  }, [companyId, location.pathname, priceList?.length, userCurrencies.length, categories?.length, projects?.length]);

  useEffect(() => {
    if (!accountInfo) return;
    //connect to revenueCat
    const userId = accountInfo.id?.toString(); //TODO: replace to accessId 1.3
    if (REVENUECAT_BILLING_PUBLIC_API_KEY) {
      try {
        Purchases.configure(REVENUECAT_BILLING_PUBLIC_API_KEY, userId);
        (async () => {
          const offerings = await Purchases.getSharedInstance().getOfferings();
          const customerInfo = await Purchases.getSharedInstance().getCustomerInfo();
          const availablePackages = offerings.current?.availablePackages;
          if (availablePackages) {
            dispatch(setPackages(availablePackages));
          }
          if (customerInfo) {
            dispatch(setCustomerInfo(customerInfo));
          }
        })();
      } catch (error) {
        console.log('RevenueCat connection failed.', error);
      }
    }
  }, [REVENUECAT_BILLING_PUBLIC_API_KEY, accountInfo, accountInfo?.id, dispatch]); //TODO: replace to accessId 1.3

  useEffect(() => {
    //redirect to expired subscription modal
    if (!customerInfo || !accountInfo) return;
    const isActiveSubscription = checkUserSubscription(accountInfo, customerInfo);
    if (!isActiveSubscription) {
      if (location.pathname === `/${routes.nosubscription}` || location.pathname === routes.auth) return;
      location.href = `${location.origin}/${routes.nosubscription}`;
      return;
    }
  }, [accountInfo, customerInfo, location.pathname, location]);

  useEffect(() => {
    if (widgetFab && isMobile) {
      widgetFab.style.zIndex = '1';
    }
    if (widgetFab && !isMobile) {
      widgetFab.style.zIndex = '9999';
    }
  }, [widgetFab, isMobile]);

  useEffect(() => {
    let lang = navigator.language;
    if (lang.includes('uk') || lang.includes('ru')) {
      dispatch(setLanguage('UA'));
      LocalStorage.setActiveLanguage('UA');
    } else {
      dispatch(setLanguage('EN'));
      LocalStorage.setActiveLanguage('EN');
    }
  }, [dispatch]);

  useEffect(() => {
    const theme = LocalStorage.getTheme();
    if (theme) {
      dispatch(setTheme(theme));
    }
  }, [dispatch]);

  useEffect(() => {
    const currencies = GetUserCurrencies(priceList, userCurrencies);
    dispatch(setActiveCurrency(currencies[0]));

    const parsedCompanyCurrencies = companyCurrencies.map((currency) => currency.currency);
    if (currencies.length > 0 && companyId && parsedCompanyCurrencies.length > 0) {
      currencies.forEach((currency) => {
        if (!parsedCompanyCurrencies.includes(currency as CurrenciesType)) {
          dispatch(createCompanyCurrency({ companyID: companyId, currency: currency as CurrenciesType }));
        }
      });
    }

    if (companyCurrencies.length > 0 && companyId) {
      const occurrencesMap: Record<CurrenciesType, number> = {} as Record<CurrenciesType, number>;

      companyCurrencies.forEach((currency) => {
        const currencyType = currency.currency as CurrenciesType;
        if (currencies.includes(currencyType)) {
          occurrencesMap[currencyType] = (occurrencesMap[currencyType] || 0) + 1;

          if (occurrencesMap[currencyType] > 1) {
            dispatch(deleteCompanyCurrency({ companyID: companyId, currencyId: currency.id }));
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceList, dispatch, selectedCompany, userCurrencies]);

  useEffect(() => {
    if (!companyId) return;
    dispatch(fetchCompanyCurrencies(companyId));
  }, [companyId, dispatch]);

  return (
    // TODO: change to another loader and refactoring routes tree
    <Suspense fallback={<PageSpinner />}>
      <Router>
        <Routes>
          <Route path={routes.auth} element={<AuthPage />} />
          <Route path={`${routes.restoreAccess}`} element={<RestoreAccessPage />} />
          <Route path={`${routes.registration}`} element={<RegistrationPage />} />
          <Route path={`${routes.registration}/${routes.creatingPassword}`} element={<RegistrationPage />} />
          <Route path={`${routes.priceList}`} element={<PriceListPage />} />
          <Route path={`${routes.projects}`} element={<ProjectsPage />} />
          <Route path={`${routes.profile}`} element={<ProfilePage />} />
          <Route path={`${routes.profile}/${routes.nosubscription}`} element={<NoSubscriptionPage />} />
          <Route path={`${routes.projectDetail}`} element={<ProjectDetailPage />} />
          <Route path={`${routes.projectDetail}/${routes.estimateCreation}`} element={<EstimateCreationPage />} />
          <Route path={`${routes.priceList}/${routes.estimateCreation}`} element={<EstimateCreationPage />} />
          <Route path={`${routes.nosubscription}`} element={<NoSubscriptionPage />} />
          <Route path={`${routes.projectDetail}/${routes.estimatesDetail}`} element={<EstimateDetailPage />} />
          <Route
            path={`${routes.projectDetail}/${routes.estimatesDetail}/${routes.estimateEditing}`}
            element={<EstimateEditingPage />}
          />
          <Route path={`${routes.acceptCompany}`} element={<AcceptCompanyPage />} />
          <Route path={`${routes.resetPasswordRedirect}`} element={<ResetPasswordRedirect />} />
          <Route path={`${routes.confirmEmailRedirect}`} element={<ConfirmEmailRedirect />} />
          <Route path={`${routes.createCompany}`} element={<CompanyCreatingPage />} />
          <Route path="*" element={<Page404 />} />
          {/* TODO: remove with 1.3 */}
          <Route path={'paywall'} element={<TemporaryPaywall />} />
        </Routes>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          pauseOnHover
          theme="light"
        />
      </Router>
    </Suspense>
  );
}
