import styled from '@emotion/styled';
import { COLORS } from 'shared/constants';

export const SkeletonContainer = styled('div')(({ className }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '48px',
  gap: '10px',
  '& div': {
    height: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const CompanyInfo = styled('div')(({ className }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: '0 16px',
  '& p:first-of-type': {
    fontSize: '18px',
    lineHeight: '26px',
    fontWeight: 600,
    color: className?.includes('dark') ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK,
  },
  '& p': {
    color: className?.includes('dark') ? COLORS.BODY_TEXT_BLACK_20 : COLORS.ACTION_BAR_BLACK_BG,
    textOverflow: 'ellipsis',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 500,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  '& > div': {
    marginLeft: '16px',
    width: '100%',
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  '@media (min-width: 1024px)': {
    '& > div': {
      maxWidth: '178px',
    },
    padding: '0 32px',
  },
}));

export const SelectContainer = styled('div')(({ className }) => ({
  position: 'relative',
}));

export const DropDownContainer = styled('div')(({ className }) => ({
  position: 'absolute',
  borderRadius: '8px',
  border: `1px solid ${className?.includes('dark') ? COLORS.LIGHT_GREY_BORDER : COLORS.BORDER_GREY}`,
  background: className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.TEXT_WHITE,
  boxShadow: className?.includes('dark') ? COLORS.BOX_SHADOW_DARK : COLORS.BANNER_BOX_SHADOW,
  width: '328px',
  padding: '8px 0px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  zIndex: 3,
  top: '56px',
  left: '16px',
  '& > div': {
    padding: '8px 16px',
    width: '100%',
  },
  '& > div:hover': {
    background: className?.includes('dark') ? COLORS.MODAL_BG_DARK : COLORS.TABLE_BG,
    cursor: 'pointer',
  },
  '@media (min-width: 1024px)': {
    width: '380px',
    left: '32px',
  },
}));
