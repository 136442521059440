import styled from '@emotion/styled';
import { COLORS } from 'shared/constants';

export const StyledInput = styled('input')(({ className }) => ({
  fontFamily: '"Golos", sans-serif',
  fontSize: className?.includes('smallModalInput') ? '8px' : '16px',
  fontWeight: 400,
  width: '100%',
  height: '100%',
  minHeight: className?.includes('smallModalInput') ? '30px' : '54px',
  padding: className?.includes('smallModalInput')
    ? '4px 8px'
    : className?.includes('showLabel')
    ? '24px 16px 8px 16px'
    : '16px',
  color: className?.includes('dark') ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK,
  border: className?.includes('error')
    ? `1px solid ${COLORS.MAIN_RED}`
    : className?.includes('warning')
    ? `1px solid ${COLORS.ORANGE_BTN}`
    : '1px solid transparent',
  borderRadius: '8px',
  outline: 'none',
  background: className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.TEXT_WHITE,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  '&:hover': {
    border: className?.includes('error')
      ? `1px solid ${COLORS.MAIN_RED}`
      : className?.includes('warning')
      ? `1px solid ${COLORS.ORANGE_BTN}`
      : `1px solid ${COLORS.BODY_TEXT_BLACK_20}`,
  },
  '&:focus-within': {
    border: className?.includes('error')
      ? `1px solid ${COLORS.MAIN_RED}`
      : className?.includes('warning')
      ? `1px solid ${COLORS.ORANGE_BTN}`
      : `1px solid ${COLORS.TEXT_GREY}`,
  },
  '&:placeholder': {
    color: className?.includes('dark') ? COLORS.TEXT_GREY : COLORS.BODY_TEXT_30,
  },
  '&:disabled': {
    color: COLORS.TEXT_GREY,
  },
  '@media (min-width: 1024px)': {
    padding: className?.includes('showLabel') ? '24px 16px 8px 16px' : '16px',
    fontSize: '16px',
  },
}));

export const InputWrapper = styled('div')(({ className }) => ({
  position: 'relative',
  width: '100%',
}));

export const StyledLabel = styled('label')(({ className }) => ({
  fontFamily: '"Golos", sans-serif',
  display: className?.includes('showLabel') ? 'block' : 'none',
  position: 'absolute',
  color: className?.includes('disabled')
    ? COLORS.TEXT_GREY
    : className?.includes('dark')
    ? COLORS.BODY_TEXT_BLACK_20
    : COLORS.ACTION_BAR_BLACK_BG,
  fontSize: className?.includes('smallModalInput') ? '6px' : '12px',
  fontWeight: 400,
  lineHeight: className?.includes('smallModalInput') ? '8px' : '16px',
  top: className?.includes('smallModalInput') ? '2px' : '6px',
  left: className?.includes('smallModalInput') ? '8px' : '16px',
  '@media (min-width: 1024px)': {
    fontSize: '12px',
    lineHeight: '16px',
    top: '8px',
    left: '16px',
  },
}));
