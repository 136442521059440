import styled from '@emotion/styled';
import { COLORS } from 'shared/constants';

export const PositionRowDropDownArticleContainer = styled('div')(({ className }) => ({
  position: 'absolute',
  zIndex: 1,
  top: 25,
  left: 0,
  '@media (min-width: 1100px)': {
    top: 40,
    left: 'unset',
  },
}));

export const TableRowContainer = styled('div')(({ className }) => ({
  fontFamily: "'Golos', sans-serif",
  color: className?.includes('dark') ? COLORS.TEXT_WHITE : COLORS.TEXT_BLACK,
  position: 'relative',
  background: className?.includes('dark') ? COLORS.BACKGROUND_BLACK : COLORS.TEXT_WHITE,
  borderBottom: `1px solid ${className?.includes('dark') ? COLORS.LIGHT_GREY_BORDER : COLORS.BORDER_GREY}`,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  textAlign: 'left',
  verticalAlign: 'middle',
  height: '48px',
  padding: '10px 8px 10px 8px',
  '&:hover': {
    background: className?.includes('dark') ? COLORS.BACKGROUND_LIGHT_DARK : COLORS.MODAL_BG,
  },
  '& .hoveredContainer': {
    opacity: 0,
  },
  '&:hover .hoveredContainer': {
    opacity: 1,
  },
  '& > div': {
    fontWeight: 500,
    flexShrink: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));
