import React from 'react';
import { text } from 'shared/constants';
import { useAppSelector, useAppDispatch } from 'shared/lib';
import { ModalWrapper } from 'shared/ui';
import { deleteEstimate } from 'entities/estimates';
import { DeleteEstimateIcons } from 'shared/assets/icons';
import { ModalImages, ModalModalButton, ModalModalButtonsWrapper, ModalText } from './styles';
import { CommonModalContainer } from '../../styles';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  estimateInfo: {
    companyId?: number;
    projectId?: string;
    estimateId?: string;
  };
}

export const DeleteEstimateModal: React.FC<Props> = ({ isOpen, handleClose, estimateInfo }) => {
  const language = useAppSelector((state) => state.language.language);
  const theme = useAppSelector((state) => state.theme.theme);
  const dispatch = useAppDispatch();

  const onDeleteClick = () => {
    const { companyId, projectId, estimateId } = estimateInfo;
    if (!companyId || !projectId || !estimateId) return;
    dispatch(deleteEstimate({ companyId, projectId, estimateId }));
  };

  const isDarkTheme = theme === 'dark';
  return (
    <ModalWrapper isOpen={isOpen} title={text[language].deleteEstimate}>
      <CommonModalContainer>
        <div>
          <ModalImages>
            <DeleteEstimateIcons />
          </ModalImages>
          <ModalText className={isDarkTheme ? 'dark' : ''}>{text[language].AreYouSureToDeleteThisEstimate}</ModalText>
        </div>
      </CommonModalContainer>
      <ModalModalButtonsWrapper>
        <ModalModalButton
          className={isDarkTheme ? 'dark' : ''}
          onClick={() => {
            handleClose();
          }}
        >
          <p>{text[language].Cancel}</p>
        </ModalModalButton>
        <ModalModalButton className={isDarkTheme ? 'dark delete' : 'delete'} onClick={onDeleteClick}>
          {text[language].YesDelete}
        </ModalModalButton>
      </ModalModalButtonsWrapper>
    </ModalWrapper>
  );
};
