import React, { useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { ProjectType, TeamMemberType, editProject, resetProjectsLoading } from 'entities/project';
import { LoadingResultsT } from 'entities/loadingType';
import { devices, text } from 'shared/constants';
import { handleNumberValue, useAppDispatch, useAppSelector, useMediaQuery } from 'shared/lib';
import { InfoIcon } from 'shared/assets/icons';
import { ActionButton, ImageUploader, ImageValuesType, Input, ModalWrapper, Select, SelectData } from 'shared/ui';
import { defaultValues } from '../model/defaultValues';
import {
  ButtonsWrapper,
  ContentWrapper,
  CustomerInfoTooltip,
  DropDownContainer,
  GroupedInputs,
  InfoProjectContainer,
  Subtitle,
  tooltipDarkStyles,
  tooltipStyles,
} from './styles';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  currentProject: ProjectType;
}

export const EditProjectModal: React.FC<Props> = ({ isOpen, handleClose, currentProject }) => {
  const language = useAppSelector((state) => state.language.language);
  const team = useAppSelector((state) => state.projects.team);
  const theme = useAppSelector((state) => state.theme.theme);
  const loadingEditProject = useAppSelector((state) => state.projects.loadingEditProject);

  const requestData = new FormData();

  const dispatch = useAppDispatch();
  const [projectInfo, setProjectInfo] = useState(defaultValues);

  const [isFormChanged, setIsFormChanged] = useState(false);

  const isMobile = useMediaQuery(devices.mobile);
  const isTablet = useMediaQuery(devices.tablet);

  const isTabletOrMobile = isMobile || isTablet;

  const customerSelectItems: SelectData[] | undefined = team?.map((member: TeamMemberType) => {
    return {
      value: `${member.firstName || '-'} ${member.lastName || '-'}`,
      id: member.id,
    };
  });

  useEffect(() => {
    if (currentProject) {
      setProjectInfo({
        name: currentProject.name,
        address: currentProject.address,
        square: currentProject.square.toString(),
        budget: currentProject.funds.budget.toString(),
        currency: currentProject.currency,
        image: null,
        customer: null,
      });
    }
  }, [currentProject]);

  useEffect(() => {
    if (loadingEditProject === LoadingResultsT.SUCCEEDED) {
      dispatch(resetProjectsLoading());
      handleClose();
    }
  }, [loadingEditProject, dispatch, handleClose]);

  const onEditProjectClick = () => {
    requestData.append('name', projectInfo.name);
    projectInfo.address && requestData.append('address', projectInfo.address);
    requestData.append('square', projectInfo.square);
    projectInfo.budget && requestData.append('budget', projectInfo.budget);
    projectInfo.image && requestData.append('image', projectInfo.image?.file);

    dispatch(
      editProject({
        companyId: currentProject.company,
        projectId: currentProject.id,
        requestData,
      }),
    );
  };

  const handleInputChange = (field: string, value: string | number | {}) => {
    setProjectInfo((prevInputs) => ({
      ...prevInputs,
      [field]: value,
    }));

    setIsFormChanged(true);
  };

  const isNameAndSquareEmpty =
    projectInfo.name.trim() === '' || projectInfo.square.toString().trim() === '' || Number(projectInfo.square) === 0;

  const isSaveButtonDisabled = !isFormChanged || isNameAndSquareEmpty;
  const isDarkTheme = theme === 'dark';
  return (
    <ModalWrapper isOpen={isOpen} title={text[language].projectEditing} titleClassName="smallTitleSize">
      <ContentWrapper>
        <InfoProjectContainer>
          <Subtitle className={isDarkTheme ? 'dark' : ''}>
            <p>{text[language].projectPage.projectInfo}</p>
          </Subtitle>
          <GroupedInputs>
            <Input
              placeholder={text[language].projectPage.projectName}
              value={projectInfo.name}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleInputChange('name', event.target.value);
              }}
              smallModalInput={true}
            />
            <Input
              placeholder={text[language].projectPage.address}
              value={projectInfo.address || ''}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleInputChange('address', event.target.value);
              }}
              smallModalInput={true}
            />
          </GroupedInputs>
          <GroupedInputs>
            <Input
              inputMode="decimal"
              placeholder={text[language].projectPage.square}
              value={projectInfo.square}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const square = handleNumberValue(event.target.value);
                handleInputChange('square', square);
              }}
              smallModalInput={true}
            />
            <Input
              inputMode="decimal"
              placeholder={text[language].projectPage.budgetProject}
              value={projectInfo.budget}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const budgetAmount = handleNumberValue(event.target.value);
                handleInputChange('budget', budgetAmount);
              }}
              smallModalInput={true}
            />
          </GroupedInputs>
        </InfoProjectContainer>
        <InfoProjectContainer>
          <Subtitle className={isDarkTheme ? 'dark' : ''}>
            <p>{text[language].projectPage.projectCustomer}</p>
            <div id="customerInfo">
              <InfoIcon size={isTabletOrMobile ? 8 : 16} />
            </div>
          </Subtitle>
          <DropDownContainer>
            <Select
              projectSelect={true}
              withAvatar={true}
              withClearButton={true}
              data={customerSelectItems}
              placeholder={text[language].projectPage.addCustomerToProject}
              value={customerSelectItems ? customerSelectItems[0].value : ''}
              onChange={(value) => {}}
              actionButtonText={text[language].projectPage.addCustomer}
              disabled={true}
              mobileModal={true}
            />
          </DropDownContainer>
        </InfoProjectContainer>
        <InfoProjectContainer>
          <Subtitle className={isDarkTheme ? 'dark' : ''}>
            <p>{text[language].projectPage.addPhoto}</p>
          </Subtitle>
          <ImageUploader
            setProjectImage={(image: ImageValuesType) => {
              handleInputChange('image', image);
            }}
            defaultImage={currentProject.image?.project_image_path}
          />
        </InfoProjectContainer>
      </ContentWrapper>
      <ButtonsWrapper>
        <ActionButton text={text[language].Cancel} onClick={handleClose} />
        <ActionButton
          isPending={loadingEditProject === LoadingResultsT.PENDING}
          text={text[language].Save}
          orangeBtn
          disabled={isSaveButtonDisabled}
          onClick={onEditProjectClick}
          isPriceListMainButton={true}
        />
      </ButtonsWrapper>
      <Tooltip
        anchorSelect="#customerInfo"
        place={isMobile ? 'bottom' : 'right-start'}
        style={isDarkTheme ? tooltipDarkStyles : tooltipStyles}
      >
        <CustomerInfoTooltip>
          {text[language].projectPage.customerHasAccess}
          <div>
            <ul>
              <li>{text[language].projectPage.toOwnProjects}</li>
              <li>{text[language].projectPage.seeActions}</li>
              <li>{text[language].projectPage.toPriceList}</li>
              <li>{text[language].projectPage.toFullReport}</li>
            </ul>
          </div>
        </CustomerInfoTooltip>
      </Tooltip>
    </ModalWrapper>
  );
};
