import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';
import { RootState } from 'app/store';
import { CurrenciesType, IPriceList } from '../';
import {
  DuplicatePositionRequest,
  EditPositionRequest,
  OrderPositionsRequest,
  RemovePositionRequest,
  CreatePositionRequest,
} from './types';
import { ApiService, USER_STATUS, HEADERS, API_CONFIG } from 'shared/api';
import { text } from 'shared/constants';
import { addPositionToEstimatePriceList } from 'entities/estimates';

export const fetchPriceList = createAsyncThunk(
  'priceList/fetchPriseList',
  async (reqBody: { id: number; currency?: CurrenciesType }, { rejectWithValue }) => {
    try {
      const { data } = await ApiService.apiCall<IPriceList[]>({
        endpoint: `/v1/company/${reqBody.id}/pricelist`,
        headers: {
          ...HEADERS,
          userStatus: USER_STATUS,
        },
      });

      return { data, currency: reqBody.currency };
    } catch (error) {
      const errorT = error as string;
      return rejectWithValue(errorT);
    }
  },
);

export const editPosition = createAsyncThunk(
  'priceList/editPosition',
  async (reqBody: EditPositionRequest, { rejectWithValue, getState }) => {
    const { companyID, articleID, name, unit, funds, itemID, estimatePosition, isHidden } = reqBody;
    const activeLanguage = (getState() as RootState).language.language;
    try {
      const { data } = await ApiService.apiCall<IPriceList>({
        endpoint: `/v1/company/${companyID}/pricelist/${itemID}`,
        method: 'PATCH',
        headers: {
          ...HEADERS,
          userStatus: USER_STATUS,
        },
        query: JSON.stringify({
          article: articleID,
          name: name,
          units: unit,
          funds: funds,
          isTemporary: estimatePosition ? true : false,
          isHidden,
        }),
      });

      !estimatePosition && toast.success(text[activeLanguage].notifications.positionEditedSuccess);
      return data;
    } catch (error) {
      !estimatePosition && toast.error(text[activeLanguage].Oops);
      const errorT = error as string;
      return rejectWithValue(errorT);
    }
  },
);

export const duplicatePosition = createAsyncThunk(
  'priceList/duplicatePosition',
  async (reqBody: DuplicatePositionRequest, { rejectWithValue, getState }) => {
    const { companyID, articleID, name, categoryID, unit, funds, order, isDragAndDrop = false, nextTo } = reqBody;
    const activeLanguage = (getState() as RootState).language.language;
    try {
      const { data } = await ApiService.apiCall<IPriceList[]>({
        endpoint: `/v1/company/${companyID}/pricelist`,
        method: 'POST',
        headers: {
          ...HEADERS,
          userStatus: USER_STATUS,
        },
        query: JSON.stringify([
          {
            article: articleID,
            name: name,
            category: categoryID,
            units: unit,
            funds: funds,
            order: order,
            isTemporary: false,
          },
        ]),
      });

      !isDragAndDrop && toast.success(text[activeLanguage].notifications.positionDuplicatedSuccess);
      return { data, nextTo };
    } catch (error) {
      toast.error(text[activeLanguage].Oops);
      const errorT = error as string;
      return rejectWithValue(errorT);
    }
  },
);

export const createPosition = createAsyncThunk(
  'priceList/createPosition',
  async (
    reqBody: {
      data: CreatePositionRequest[];
      company: number;
      isDragAndDrop?: boolean;
      nextTo?: number;
      indexToInsert?: number;
      projectCurrency?: string;
    },
    { rejectWithValue, getState, dispatch },
  ) => {
    const { data: positionsData, company, isDragAndDrop = false, nextTo, indexToInsert, projectCurrency } = reqBody;
    const activeLanguage = (getState() as RootState).language.language;
    try {
      const { data } = await ApiService.apiCall<IPriceList[]>({
        endpoint: `/v1/company/${company}/pricelist`,
        method: 'POST',
        headers: {
          ...HEADERS,
          userStatus: USER_STATUS,
        },
        query: positionsData,
      });

      if (projectCurrency) dispatch(addPositionToEstimatePriceList({ data, projectCurrency }));

      !isDragAndDrop && toast.success(text[activeLanguage].notifications.positionCreatedSuccess);
      return { data, nextTo, indexToInsert };
    } catch (error) {
      toast.error(text[activeLanguage].Oops);
      const errorT = error as string;
      return rejectWithValue(errorT);
    }
  },
);

export const removePosition = createAsyncThunk(
  'priceList/removePosition',
  async (reqBody: RemovePositionRequest, { rejectWithValue, getState }) => {
    const { companyID, itemID, isDragAndDrop = false } = reqBody;
    const activeLanguage = (getState() as RootState).language.language;
    try {
      // TODO: rewrite using middleware
      const { data } = await axios.delete(`${API_CONFIG.BASE_URL}/v1/company/${companyID}/pricelist`, {
        headers: {
          ...HEADERS,
          userStatus: USER_STATUS,
        },
        data: JSON.stringify({
          id: itemID,
        }),
        withCredentials: true,
      });

      !isDragAndDrop && toast.success(text[activeLanguage].notifications.positionDeletedSuccess);
      return data; // (data) If success returns {message: {messageCode: "successDeleting", messageDescription: "Price list item has been deleted [ ID ]"}}
    } catch (error) {
      toast.error(text[activeLanguage].Oops);
      const errorT = error as string;
      return rejectWithValue(errorT);
    }
  },
);

export const changeArticles = createAsyncThunk(
  'priceList/changeArticles',
  async (reqBody: { items: number[]; company: number; article: number }, { rejectWithValue, getState }) => {
    const { items, company, article } = reqBody;
    const activeLanguage = (getState() as RootState).language.language;
    try {
      const { data } = await ApiService.apiCall<any>({
        endpoint: `/v1/company/${company}/pricelist/massiveArticlesChange`,
        method: 'PATCH',
        headers: {
          ...HEADERS,
          userStatus: USER_STATUS,
        },
        query: {
          items,
          article,
        },
      });

      toast.success(text[activeLanguage].notifications.articlesChangedSuccess);
      return { data, items, article };
    } catch (error) {
      toast.error(text[activeLanguage].Oops);
      const errorT = error as string;
      return rejectWithValue(errorT);
    }
  },
);

export const orderPositions = createAsyncThunk(
  'item/orderPositions',
  async (reqBody: OrderPositionsRequest, { rejectWithValue }) => {
    const { companyID, articleID, categoryID, order } = reqBody;

    try {
      const { data } = await ApiService.apiCall<IPriceList>({
        endpoint: `/v1/company/${companyID}/pricelist`,
        method: 'PATCH',
        headers: {
          ...HEADERS,
          userStatus: USER_STATUS,
        },
        query: JSON.stringify({
          article: articleID,
          category: categoryID,
          order: order,
        }),
      });

      return data;
    } catch (error) {
      const errorT = error as string;
      return rejectWithValue(errorT);
    }
  },
);
