import React, { useEffect, useState } from 'react';
import { createCustomer, resetCustomerError, resetProjectsLoading } from 'entities/project';
import { LoadingResultsT } from 'entities/loadingType';
import { text } from 'shared/constants';
import { isValidEmail, useAppDispatch, useAppSelector } from 'shared/lib';
import { ActionButton, ModalWrapper } from 'shared/ui';
import { CreateCustomerForm } from './CreateCustomerForm/CreateCustomerForm';
import CreateCustomerErrorModal from './CreateCustomerErrorModal/CreateCustomerErrorModal';
import { defaultValues } from '../model/defaultValues';
import { ButtonsWrapper } from './styles';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
}

export const AddCustomerModal: React.FC<Props> = ({ isOpen, handleClose }) => {
  const language = useAppSelector((state) => state.language.language);
  const company = useAppSelector((state) => state.company.company);
  const creatingCustomerError = useAppSelector((state) => state.projects.creatingCustomerError);
  const loadingCreatingCustomer = useAppSelector((state) => state.projects.loadingCreatingCustomer);
  const dispatch = useAppDispatch();

  const [customerInfo, setCustomerInfo] = useState(defaultValues);
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);
  const companyId = company && (company[0].id as number);

  const clearFieldsAndClose = () => {
    handleClose();
    setCustomerInfo(defaultValues);
  };

  useEffect(() => {
    if (creatingCustomerError) {
      setIsOpenErrorModal(true);
    }
  }, [creatingCustomerError]);

  const handleCloseErrorModal = () => {
    setIsOpenErrorModal(false);
    dispatch(resetCustomerError());
  };

  useEffect(() => {
    if (loadingCreatingCustomer === LoadingResultsT.SUCCEEDED) {
      clearFieldsAndClose();
      dispatch(resetProjectsLoading());
      setCustomerInfo(defaultValues);
    }
  }, [loadingCreatingCustomer, dispatch]);

  const createCustomerRequest = () => {
    if (!companyId) return;
    dispatch(createCustomer({ companyId, ...customerInfo }));
  };

  const isSaveButtonDisabled = !customerInfo.firstName || !customerInfo.lastName || !isValidEmail(customerInfo.email);

  return (
    <ModalWrapper isOpen={isOpen} title={text[language].projectPage.addingCustomer}>
      <CreateCustomerForm customerInfo={customerInfo} setCustomerInfo={setCustomerInfo} />
      <ButtonsWrapper>
        <ActionButton text={text[language].Cancel} onClick={clearFieldsAndClose} />
        <ActionButton
          isPending={loadingCreatingCustomer === LoadingResultsT.PENDING}
          text={text[language].Save}
          orangeBtn
          disabled={isSaveButtonDisabled}
          onClick={createCustomerRequest}
          isPriceListMainButton={true}
        />
      </ButtonsWrapper>
      <CreateCustomerErrorModal
        isOpen={isOpenErrorModal}
        handleClose={handleCloseErrorModal}
        email={customerInfo.email}
      />
    </ModalWrapper>
  );
};
