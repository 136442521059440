import { PriceItemType } from 'entities/estimates';
import { EstimateMarkupsType } from 'entities/project';

export const calculateTotalAndCostByArticle = (article: number, items: PriceItemType[] | null | undefined) => {
  const defaultValues = { cost: 0, total: 0 };
  if (!items) return defaultValues;
  const filteredItemsById = items.filter((item) => item.article === article);
  return filteredItemsById.reduce((acc, item) => {
    if (!item.funds) return acc;
    const total = acc.total + item.funds?.total * item.quantity;
    const cost = acc.cost + (item.funds.cost ?? total) * item.quantity;
    return (acc = { ...acc, total, cost });
  }, defaultValues);
};

export const getMarkupByArticle = (position: PriceItemType, markups: EstimateMarkupsType) => {
  if (position.article === 1) {
    return markups.materialsMarkup;
  } else if (position.article === 2) {
    return markups.workMarkup;
  } else {
    return 0;
  }
};

export const calculateTotalCounts = (
  priceItems: PriceItemType[] | null | undefined,
  markups: EstimateMarkupsType | null,
) => {
  if (!priceItems) return 0;
  return priceItems.reduce((accumulator, item) => {
    if (!markups || !item.funds) return accumulator;
    const generalTotal = item.funds.total * item.quantity;
    const currentMarkup = getMarkupByArticle(item, markups);
    const totalWithMarkup = generalTotal + generalTotal * (currentMarkup / 100);

    return (accumulator += totalWithMarkup);
  }, 0);
};

export const markupCalculation = (total: number, markup?: number) => {
  if (!markup) return 0;
  return total * (markup / 100);
};
