import styled from '@emotion/styled';
import { COLORS } from 'shared/constants';

export const SwitchLanguage = styled('button')(({ className }) => ({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  fontFamily: 'Golos',
  fontSize: '14px',
  fontWeight: 400,
  border: '0',
  backgroundColor: 'transparent',
}));

export const LanguageItemContainer = styled('span')(({ className }) => ({
  color: className?.includes('activeDark')
    ? COLORS.TEXT_WHITE
    : className?.includes('active')
    ? COLORS.TEXT_BLACK
    : className?.includes('Dark')
    ? COLORS.TEXT_GREY
    : COLORS.BODY_TEXT_BLACK_20,
}));
