import React, { useState } from 'react';
import { ProjectType } from 'entities/project';
import { useAppSelector } from 'shared/lib';
import { DatePicker, Input, Select, SelectData } from 'shared/ui';
import { text } from 'shared/constants';
import { EstimateInfoType } from '../model/defaultValues';
import {
  DropDownContainer,
  EstimateDescriptionWrapper,
  EstimateInfoContainer,
  EstimateInputsContainer,
  EstimateInputsGroup,
  StyledTextarea,
} from './styles';

type Props = {
  isEditingPage?: boolean;
  estimateInfo: EstimateInfoType;
  setEstimateInfo: (values: EstimateInfoType) => void;
};

export const EstimateInfo: React.FC<Props> = ({ isEditingPage = false, estimateInfo, setEstimateInfo }) => {
  const language = useAppSelector((state) => state.language.language);
  const projects = useAppSelector((state) => state.projects.projects);
  const theme = useAppSelector((state) => state.theme.theme);
  const [isFocused, setIsFocused] = useState(false);
  const descriptionMaxLength = 200;

  const projectsInfo: SelectData[] | undefined = projects?.map((project: ProjectType) => {
    return {
      value: `${project.name || '-'}`,
      id: project.id,
      img: project.image?.project_image_path,
    };
  });

  const textAreaClassName = () => {
    let cn = '';

    if (showTextAreaLabel) cn += 'showLabel';
    if (isDarkTheme) cn += 'dark';

    return cn;
  };

  const textAreaRows = Math.ceil((estimateInfo.description.length / descriptionMaxLength) * 2) + 1;

  const showTextAreaLabel = isFocused || !!estimateInfo.description;
  const isDarkTheme = theme === 'dark';
  return (
    <EstimateInfoContainer className={isDarkTheme ? 'dark' : '  '}>
      <p>{isEditingPage ? text[language].estimateEditing : text[language].estimateCreation}</p>
      <p>{text[language].commonInfo}</p>
      <EstimateInputsContainer>
        <DropDownContainer>
          <Select
            projectSelect={true}
            withClearButton={true}
            data={projectsInfo}
            disabled={true}
            placeholder={text[language].chooseProject}
            value={estimateInfo.project.value}
            selectedImg={estimateInfo.project.img}
            onChange={(value, id, index, img) => {
              const project = {
                value,
                id,
                img,
              };
              setEstimateInfo({ ...estimateInfo, project });
            }}
          />
        </DropDownContainer>
        <EstimateInputsGroup>
          <Input
            placeholder={text[language].estimateName}
            value={estimateInfo.name}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setEstimateInfo({ ...estimateInfo, name: event.target.value });
            }}
          />
          <DatePicker
            placeholder={text[language].date}
            defaultDate={estimateInfo.paymentTime}
            onChange={(paymentTime: Date) => {
              setEstimateInfo({ ...estimateInfo, paymentTime });
            }}
          />
        </EstimateInputsGroup>
        <EstimateDescriptionWrapper className={isDarkTheme ? 'dark' : '  '}>
          <StyledTextarea
            rows={textAreaRows}
            maxLength={descriptionMaxLength}
            placeholder={text[language].estimateDescriptionNotRequired}
            value={estimateInfo.description}
            className={textAreaClassName()}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
              setEstimateInfo({ ...estimateInfo, description: event.target.value });
            }}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
          {showTextAreaLabel && <span>{text[language].estimateDescriptionNotRequired}</span>}
          <p>
            {estimateInfo.description.length}/{descriptionMaxLength}
          </p>
        </EstimateDescriptionWrapper>
      </EstimateInputsContainer>
    </EstimateInfoContainer>
  );
};
