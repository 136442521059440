export const getSecondaryWhiteColor = (opacity?: string): string => {
  if (opacity) {
    return `rgba(225, 225, 225, ${opacity})`;
  }
  return 'rgba(225, 225, 225, 1)';
};

export const COLORS = {
  DIVIDER_DARK: '#404040',
  BACKGROUND_BLACK: '#0D0D0D',
  BACKGROUND_LIGHT_DARK: '#1A1A1A',
  LIGHT_GREY_BORDER: '#1C1C1C',
  TEXT_WHITE: '#ffffff',
  TEXT_BLACK: '#000000',
  TEXT_GREY: '#666666',
  ICON_PURPLE: '#D263FF',
  ICON_GREEN: '#1DDE87',
  BODY_TEXT_30: '#B3B3B3',
  BODY_TEXT_35: '#848484',
  BODY_TEXT_BLACK_20: '#CCCCCC',
  BODY_TEXT_BLACK_70: '#4D4D4D',
  BORDER_GREY: '#F0F0F0',
  DISABLED_BTN_TEXT: '#999999',
  BTN_BG_PINK: '#FFE0E1',
  ACTION_BAR_BLACK_BG: '#333333',
  MODAL_OVERLAY: '#00000026',
  MODAL_BG: '#F8F8F8',
  MODAL_BG_DARK: '#141414',
  TABLE_BG: '#F3F3F3',
  GREY: '#EBEBEB',
  MAIN_RED: '#E00E0F',
  MSG_RED: '#B40709',
  DARK_RED: '#FF4843',
  MAIN_GREEN: '#11851D',
  DARK_THEME_GREEN: '#20E934',
  ORANGE_LIGHT: '#FFF9DA',
  ORANGE_BTN: '#FF9B1A',
  ORANGE_BTN_ACTIVE: '#FF8C21',
  ORANGE_BTN_HOVERED: 'linear-gradient(0deg, #FF9B1A, #FFA726)',
  ORANGE_TEXT_HOVERED: 'linear-gradient(45deg, #FF8C21 0%, #FFD200 88.02%)',
  ORANGE_TEXT: '#F48D07',
  ORANGE_SECOND_GRAD_COLOR: '#FFD200',
  SKELETON_BG: 'linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)',
  SIDEBAR_SKELETON_BG: 'linear-gradient(90deg, #ffffff33 25%, #e0e0e0 50%, #ffffff33 75%)',
  PROJECT_PREVIEW_INFO_BG: 'linear-gradient(0deg, #262626 0%, rgba(0, 0, 0, 0.02) 100%)',
  PROJECT_PIN_BG: 'rgba(38, 38, 38, 0.20)',
  TABLE_DRAGGING: 'rgba(248, 248, 248, 0.9)',
  BOX_SHADOW_DARK:
    '15px 116px 33px 0px rgba(0, 0, 0, 0.00), 10px 74px 30px 0px rgba(0, 0, 0, 0.01), 5px 42px 25px 0px rgba(0, 0, 0, 0.05), 2px 19px 19px 0px rgba(0, 0, 0, 0.09), 1px 5px 10px 0px rgba(0, 0, 0, 0.12), 0px 0px 0px 0px rgba(0, 0, 0, 0.15)',
  BANNER_BOX_SHADOW:
    '20px 50px 17px 0px rgba(183, 186, 194, 0.01), 10px 40px 16px 0px rgba(183, 186, 194, 0.02), 5px 22px 13px 0px rgba(183, 186, 194, 0.04), 3px 10px 10px 0px rgba(183, 186, 194, 0.06), 1px 2px 5px 0px rgba(183, 186, 194, 0.07), 0px 0px 0px 0px rgba(183, 186, 194, 0.08)',
  TABLE_DROPDOWN_BOX_SHADOW:
    '0px 0px 0px 0px rgba(183, 186, 194, 0.08), 1px 2px 5px 0px rgba(183, 186, 194, 0.07), 3px 10px 10px 0px rgba(183, 186, 194, 0.06), 5px 22px 13px 0px rgba(183, 186, 194, 0.04), 10px 40px 16px 0px rgba(183, 186, 194, 0.02), 20px 50px 17px 0px rgba(183, 186, 194, 0.01)',
};
