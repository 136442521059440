import { createSlice } from '@reduxjs/toolkit';
import { LoadingResultsT } from '../../loadingType';
import { CurrenciesType } from 'entities/priceList';
import {
  createCurrency,
  getCurrencyRate,
  fetchCompanyCurrencies,
  createCompanyCurrency,
  deleteCompanyCurrency,
} from '../api/thunks';
import { CompanyCurrency } from '../api/types';

interface IState {
  baseCompanyCurrency: CurrenciesType | null;
  activeCurrency: CurrenciesType | null;
  userCurrencyList: CurrenciesType[];
  companyCurrencies: CompanyCurrency[];
  currencyRateList: Record<CurrenciesType, string>;
  loadingCurrency: LoadingResultsT;
  loadingCurrencyRate: LoadingResultsT;
  error: string | null;
}

const initialState: IState = {
  baseCompanyCurrency: null,
  activeCurrency: null,
  userCurrencyList: [],
  companyCurrencies: [],
  currencyRateList: {} as Record<CurrenciesType, string>,
  loadingCurrency: LoadingResultsT.IDLE,
  loadingCurrencyRate: LoadingResultsT.IDLE,
  error: null,
};

const currenciesSlice = createSlice({
  name: 'currencies',
  initialState,
  reducers: {
    setActiveCurrency(state, { payload }) {
      state.activeCurrency = payload;
    },
    resetCurrenciesLoading: (state) => {
      state.loadingCurrency = LoadingResultsT.IDLE;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(createCurrency.pending, (state) => {
        state.loadingCurrency = LoadingResultsT.PENDING;
      })
      .addCase(createCurrency.fulfilled, (state, { payload }) => {
        state.loadingCurrency = LoadingResultsT.SUCCEEDED;
        state.error = null;
      })
      .addCase(createCurrency.rejected, (state, { error }) => {
        state.loadingCurrency = LoadingResultsT.FAILED;
        state.error = error.message as string;
      })

      .addCase(getCurrencyRate.pending, (state) => {
        state.loadingCurrencyRate = LoadingResultsT.PENDING;
      })
      .addCase(getCurrencyRate.fulfilled, (state, { payload }) => {
        state.loadingCurrencyRate = LoadingResultsT.SUCCEEDED;
        const rate = payload[0];
        if (!rate) return;

        state.currencyRateList = { ...state.currencyRateList, [rate.cc]: rate.rate.toFixed(2) };
        state.error = null;
      })
      .addCase(getCurrencyRate.rejected, (state, { error }) => {
        state.loadingCurrencyRate = LoadingResultsT.FAILED;
        state.error = error.message as string;
      })

      .addCase(fetchCompanyCurrencies.pending, (state) => {
        state.loadingCurrency = LoadingResultsT.PENDING;
      })
      .addCase(fetchCompanyCurrencies.fulfilled, (state, { payload }) => {
        state.loadingCurrency = LoadingResultsT.SUCCEEDED;
        const preparedCurrencies = payload.sort((a, b) => a.id - b.id).map((curr) => curr.currency);

        state.companyCurrencies = payload;
        state.userCurrencyList = [...new Set(preparedCurrencies)];
        state.error = null;
      })
      .addCase(fetchCompanyCurrencies.rejected, (state, { error }) => {
        state.loadingCurrency = LoadingResultsT.FAILED;
        state.error = error.message as string;
      })

      .addCase(createCompanyCurrency.pending, (state) => {
        state.loadingCurrency = LoadingResultsT.PENDING;
      })
      .addCase(createCompanyCurrency.fulfilled, (state, { payload }) => {
        state.loadingCurrency = LoadingResultsT.SUCCEEDED;

        state.companyCurrencies = [...state.companyCurrencies, payload];
        state.userCurrencyList = [...state.userCurrencyList, payload.currency];
        state.error = null;
      })
      .addCase(createCompanyCurrency.rejected, (state, { error }) => {
        state.loadingCurrency = LoadingResultsT.FAILED;
        state.error = error.message as string;
      })

      .addCase(deleteCompanyCurrency.pending, (state) => {
        state.loadingCurrency = LoadingResultsT.PENDING;
      })
      .addCase(deleteCompanyCurrency.fulfilled, (state, { payload }) => {
        state.loadingCurrency = LoadingResultsT.SUCCEEDED;
        const filteredCurrencies = state.companyCurrencies.filter((currency) => currency.id !== payload.currencyId);
        const preparedCurrencies = filteredCurrencies.map((currency) => currency.currency);

        state.companyCurrencies = filteredCurrencies;
        state.userCurrencyList = preparedCurrencies;
        state.error = null;
      })
      .addCase(deleteCompanyCurrency.rejected, (state, { error }) => {
        state.loadingCurrency = LoadingResultsT.FAILED;
        state.error = error.message as string;
      }),
});

export const { setActiveCurrency, resetCurrenciesLoading } = currenciesSlice.actions;
export const currenciesReducer = currenciesSlice.reducer;
