import { CurrenciesType, ParsedFunds } from "entities/priceList";
import { handleNumberValue } from "shared/lib";

export function parseExcelTextObject(excelText: string[], currency: CurrenciesType | null) {
  const dataToString = excelText.join('\n');
  const rows = dataToString.trim().split('\n');
  let preparedData: ParsedFunds[] = [];
  if (!currency) return preparedData;

  rows.forEach((row) => {
    const dataFromCurrentRow = parseTableDataToCreatePositionsRequest(row, currency);
    if (dataFromCurrentRow) {
      preparedData = [...preparedData, dataFromCurrentRow];
    }
  });

  return preparedData;
}

function parseTableDataToCreatePositionsRequest(row: string, currency: CurrenciesType) {
  if (row.length < 2) return;
  const cells = row.split('\t').filter((cell) => cell !== '');

  if (cells.length < 3 || cells.length > 4) return;
  const total = isNaN(parseFloat(cells[2])) ? '0' : handleNumberValue(cells[2]);
  const cost = isNaN(parseFloat(cells[3])) ? '' : handleNumberValue(cells[3]);

  const data: ParsedFunds = {
    name: cells[0],
    units: cells[1],
    isTemporary: false,
    funds: {
      [currency as CurrenciesType]: {
        total: total,
        cost: cost,
      },
    },
  };

  return data;
}
