import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';
import { RootState } from 'app/store';
import { CurrenciesType, IPriceList, setPriceList } from 'entities/priceList';
import { ApiService, USER_STATUS, HEADERS } from 'shared/api';
import { text } from 'shared/constants';
import { CompanyCurrency, CurrencyCreate } from './types';

export const createCurrency = createAsyncThunk(
  'currencies/createCurrency',
  async (reqBody: CurrencyCreate, { dispatch, rejectWithValue, getState }) => {
    const { companyID, baseCompanyCurrency, newCurrency, currencyRate } = reqBody;
    const funds = {
      currencyBase: baseCompanyCurrency,
      currencyToChange: newCurrency,
      currencyRate: Number(currencyRate),
    };
    const activeLanguage = (getState() as RootState).language.language;
    try {
      const { data } = await ApiService.apiCall<IPriceList[]>({
        endpoint: `/v1/company/${companyID}/pricelist/currency`,
        method: 'PATCH',
        headers: {
          ...HEADERS,
          userStatus: USER_STATUS,
        },
        query: JSON.stringify({
          funds,
        }),
      });

      toast.success(text[activeLanguage].notifications.currencyCreatedSuccess);
      dispatch(setPriceList(data));
      return data;
    } catch (error) {
      toast.error(text[activeLanguage].Oops);
      const errorT = error as string;
      return rejectWithValue(errorT);
    }
  },
);

export const fetchCompanyCurrencies = createAsyncThunk(
  'currencies/fetchCompanyCurrencies',
  async (companyID: number, { rejectWithValue }) => {
    try {
      const { data } = await ApiService.apiCall<CompanyCurrency[]>({
        endpoint: `/v1/company/${companyID}/pricelist/currency`,
        headers: {
          ...HEADERS,
          userStatus: USER_STATUS,
        },
      });

      return data;
    } catch (error) {
      const errorT = error as string;
      return rejectWithValue(errorT);
    }
  },
);

export const createCompanyCurrency = createAsyncThunk(
  'currencies/createCompanyCurrency',
  async ({ companyID, currency }: { companyID: number; currency: CurrenciesType }, { rejectWithValue }) => {
    try {
      const { data } = await ApiService.apiCall<CompanyCurrency>({
        endpoint: `/v1/company/${companyID}/pricelist/currency`,
        method: 'POST',
        headers: {
          ...HEADERS,
          userStatus: USER_STATUS,
        },
        query: JSON.stringify({
          currency,
        }),
      });

      return data;
    } catch (error) {
      const errorT = error as string;
      return rejectWithValue(errorT);
    }
  },
);

export const deleteCompanyCurrency = createAsyncThunk(
  'currencies/deleteCompanyCurrency',
  async ({ companyID, currencyId }: { companyID: number; currencyId: number }, { rejectWithValue }) => {
    try {
      const { data } = await ApiService.apiCall<any>({
        endpoint: `/v1/company/${companyID}/pricelist/currency/${currencyId}`,
        method: 'DELETE',
        headers: {
          ...HEADERS,
          userStatus: USER_STATUS,
        },
      });
      //TODO: propose to return currency
      return {data, currencyId};
    } catch (error) {
      const errorT = error as string;
      return rejectWithValue(errorT);
    }
  },
);

export const getCurrencyRate = createAsyncThunk(
  'currencies/getCurrencyRate',
  async (currencyCode: string, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `https://bank.gov.ua/NBUStatService/v1/statdirectory/exchange?json&valcode=${currencyCode}`,
        {},
      );

      return data;
    } catch (error) {
      const errorT = error as string;
      return rejectWithValue(errorT);
    }
  },
);
