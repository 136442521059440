import React from 'react';

type Props = {
  size?: number;
};

export const RadioButtonChecked: React.FC<Props> = ({ size = 24 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
      <circle cx="12" cy="12" r="9.5" stroke="url(#paint0_linear_444_5329)" />
      <circle cx="12" cy="12" r="7" fill="url(#paint1_linear_444_5329)" />
      <defs>
        <linearGradient id="paint0_linear_444_5329" x1="2" y1="22" x2="22" y2="2" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF8C21" />
          <stop offset="0.880208" stopColor="#FFD200" />
        </linearGradient>
        <linearGradient id="paint1_linear_444_5329" x1="5" y1="19" x2="19" y2="5" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF8C21" />
          <stop offset="0.880208" stopColor="#FFD200" />
        </linearGradient>
      </defs>
    </svg>
  );
};
