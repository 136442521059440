import React, { useEffect, useState } from 'react';

import { createCategory, groupPositionsToCategory, resetCategoriesLoading } from 'entities/category';
import { removePositionsById } from 'entities/priceList';
import { removeCheckedPositions } from 'entities/positionsFilter';
import { LoadingResultsT } from 'entities/loadingType';

import { text } from 'shared/constants';
import { useAppSelector, useAppDispatch } from 'shared/lib';
import { ActionButton, Input, ModalWrapper } from 'shared/ui';
import { AddCategoryModalButtonsWrapper } from './styles';
import { CommonModalContainer } from '../../styles';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  isGrouping?: boolean;
  order?: number;
}

export const AddCategoryModal: React.FC<Props> = ({ isOpen, handleClose, isGrouping = false, order }) => {
  const language = useAppSelector((state) => state.language.language);
  const company = useAppSelector((state) => state.company.company);
  const loadingGroupPositionsToCategory = useAppSelector((state) => state.categories.loadingGroupPositionsToCategory);
  const loadingCategories = useAppSelector((state) => state.categories.loadingCategories);
  const categoriesError = useAppSelector((state) => state.categories.error);
  const checkedPositionsIds = useAppSelector((state) => state.positionsFilter.checkedPositionsIds);
  const dispatch = useAppDispatch();
  const [categoryName, setCategoryName] = useState('');

  useEffect(() => {
    if (
      !categoriesError &&
      (loadingCategories === LoadingResultsT.SUCCEEDED || loadingGroupPositionsToCategory === LoadingResultsT.SUCCEEDED)
    ) {
      dispatch(resetCategoriesLoading());
      HandleCategoryModalClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingCategories, categoriesError, loadingGroupPositionsToCategory]);

  function HandleCategoryModalClose() {
    handleClose();
    setCategoryName('');
  }

  function OnCreateCategoryClick() {
    if (isGrouping) {
      OnGroupPositionsIntoCategory();
    } else {
      CreateCategoryFunc();
    }
  }

  function OnGroupPositionsIntoCategory() {
    if (!company) return;
    dispatch(
      groupPositionsToCategory({
        companyID: company[0].id,
        positionIds: checkedPositionsIds,
        categoryName,
        article: 1,
      }),
    );
    // removing from local store
    dispatch(removePositionsById(checkedPositionsIds));
    dispatch(removeCheckedPositions(checkedPositionsIds));
  }

  function CreateCategoryFunc() {
    if (!company) return;

    dispatch(createCategory({ companyID: company[0].id, order: order, categoryName }));
  }
  return (
    <ModalWrapper isOpen={isOpen} title={text[language].NewCategory}>
      <CommonModalContainer>
        <Input
          autoFocus={true}
          placeholder={text[language].EnterCategoryName}
          value={categoryName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setCategoryName(event.target.value);
          }}
        />
      </CommonModalContainer>
      <AddCategoryModalButtonsWrapper>
        <ActionButton text={text[language].Cancel} onClick={HandleCategoryModalClose} />
        <ActionButton
          isPending={loadingCategories === LoadingResultsT.PENDING}
          text={text[language].Create}
          orangeBtn
          disabled={!categoryName}
          onClick={OnCreateCategoryClick}
          isPriceListMainButton={true}
        />
      </AddCategoryModalButtonsWrapper>
    </ModalWrapper>
  );
};
