import React, { useEffect, useState } from 'react';
import { editCategory, resetCategoriesLoading } from 'entities/category';
import { LoadingResultsT } from 'entities/loadingType';
import { text } from 'shared/constants';
import { useAppSelector, useAppDispatch } from 'shared/lib';
import { ActionButton, Input, ModalWrapper } from 'shared/ui';
import { EditCategoryModalButtonsWrapper } from './styles';
import { CommonModalContainer } from '../../styles';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  defaultCategoryName: string;
  categoryID: number;
}

export const EditCategoryModal: React.FC<Props> = ({ isOpen, handleClose, defaultCategoryName, categoryID }) => {
  const language = useAppSelector((state) => state.language.language);
  const company = useAppSelector((state) => state.company.company);
  const categoriesError = useAppSelector((state) => state.categories.error);
  const loadingCategories = useAppSelector((state) => state.categories.loadingCategories);
  const dispatch = useAppDispatch();
  const [categoryName, setCategoryName] = useState<string>(defaultCategoryName);

  function onEditCategoryClick() {
    if (!company) return;
    dispatch(
      editCategory({
        companyID: company[0].id,
        categoryID,
        category: categoryName,
      }),
    );
  }

  useEffect(() => {
    if (!categoriesError && loadingCategories === LoadingResultsT.SUCCEEDED) {
      dispatch(resetCategoriesLoading());
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingCategories, categoriesError]);

  return (
    <ModalWrapper isOpen={isOpen} title={text[language].EditCategory}>
      <CommonModalContainer>
        <Input
          placeholder={text[language].EnterCategoryName}
          defaultValue={defaultCategoryName}
          value={categoryName}
          maxLength={50}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setCategoryName(event.target.value);
          }}
        />
      </CommonModalContainer>
      <EditCategoryModalButtonsWrapper>
        <ActionButton
          onClick={() => {
            handleClose();
          }}
          text={text[language].Cancel}
        />
        <ActionButton
          isPending={loadingCategories === LoadingResultsT.PENDING}
          text={text[language].Save}
          orangeBtn
          disabled={!categoryName}
          onClick={onEditCategoryClick}
          isPriceListMainButton={true}
        />
      </EditCategoryModalButtonsWrapper>
    </ModalWrapper>
  );
};
