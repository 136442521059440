import { ICategory, ICategoryEstimates } from 'entities/category';
import { PriceItemType } from 'entities/estimates';

export const addPriceItemsToCategories = (
  categories: (ICategory | ICategoryEstimates)[],
  priceItems: PriceItemType[] | null,
) => {
  return categories.map((cat) => {
    const items = priceItems?.filter((pos) => pos.category?.id === cat?.id);
    return {
      ...cat,
      positions: items || [],
    };
  });
};
