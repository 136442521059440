import React, { useEffect, useRef, useState } from 'react';
import { resetPositionsFilterList } from 'entities/positionsFilter';
import { checkPositionsTypes, headCheckboxHandler } from 'widgets/PriceListTable/model';
import { Checkbox } from 'shared/ui';
import { useHover, useMediaQuery, useAppSelector, useAppDispatch } from 'shared/lib';
import { text, devices } from 'shared/constants';

import {
  ButtonTableCellContainer,
  DropdownBody,
  DropdownElement,
  MainTableCellContainer,
  PriceTableCellContainer,
  SecondaryTableCellContainer,
} from '../styles';
import { TableHeadDropDownContainer, TableHeadRowContainer } from './styles';

type Props = {
  isModalPriceList: boolean;
};

const PriceListTableHead: React.FC<Props> = ({ isModalPriceList }) => {
  const isMobile = useMediaQuery(devices.mobile);
  const language = useAppSelector((state) => state.language.language);
  const priceList = useAppSelector((state) => state.priceList.priceList);
  const filteredByCurrencyPriceListAndSearch = useAppSelector(
    (state) => state.priceList.filteredByCurrencyPriceListAndSearch,
  );
  const theme = useAppSelector((state) => state.theme.theme);
  const checkedPositionsIds = useAppSelector((state) => state.positionsFilter.checkedPositionsIds);
  const checkedCategoryIds = useAppSelector((state) => state.positionsFilter.checkedCategoryIds);
  const categories = useAppSelector((state) => state.categories.categories);
  const filteredCategories = useAppSelector((state) => state.categories.filteredCategories);
  const dispatch = useAppDispatch();
  const checkBoxWrapperRef = useRef<HTMLDivElement | null>(null);
  const checkBoxHovered = useHover(checkBoxWrapperRef.current);
  const [checked, setChecked] = useState(false);
  const [checkedPosition, setCheckedPosition] = useState<Record<checkPositionsTypes, boolean>>({
    all: false,
    materials: false,
    work: false,
    allCategories: false,
    withoutCategories: false,
  });

  const resetChecked = () => {
    setCheckedPosition({
      all: false,
      materials: false,
      work: false,
      allCategories: false,
      withoutCategories: false,
    });
    dispatch(resetPositionsFilterList());
  };

  useEffect(() => {
    if (checkedCategoryIds.length > 0 || checkedPositionsIds.length > 0) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [checkedCategoryIds.length, checkedPositionsIds.length]);

  // Set all fields in an object to false
  useEffect(() => {
    if (!checked) {
      const updatedObj = { ...checkedPosition };
      // Iterate through the keys in the object
      for (const key in updatedObj) {
        if (updatedObj.hasOwnProperty(key)) {
          // Set each field to false
          updatedObj[key as checkPositionsTypes] = false;
        }
      }
      // Use the setCheckedPosition function to update the state
      setCheckedPosition(updatedObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  useEffect(() => {
    const isAnyTrue = Object.values(checkedPosition).some((value) => value === true);
    setChecked(isAnyTrue);
  }, [checkedPosition]);

  function OnDropDownClick(type: checkPositionsTypes) {
    // Reset the filter in the store to start with a clean slate
    dispatch(resetPositionsFilterList());

    // If the value was 'true', set to 'false'
    if (checkedPosition[type] === true) {
      return setCheckedPosition({ ...checkedPosition, [type]: false });
    }

    // Call the HeadCheckboxHandler to update store data based on the selected type
    if (isModalPriceList && (filteredByCurrencyPriceListAndSearch?.length || filteredCategories.length)) {
      headCheckboxHandler(type, filteredByCurrencyPriceListAndSearch, filteredCategories, dispatch);
    } else if (isModalPriceList && !(filteredByCurrencyPriceListAndSearch?.length || filteredCategories.length)) {
      headCheckboxHandler(type, priceList, categories, dispatch);
    } else {
      headCheckboxHandler(type, priceList, categories, dispatch);
    }

    // Update the checkedPosition state to toggle the selected type to 'true' and reset others to 'false'
    const updatedPosition = { ...checkedPosition };

    // Toggle the value for the specified type
    updatedPosition[type] = !updatedPosition[type];

    // If the value is toggled to 'true', set all others to 'false'
    if (updatedPosition[type]) {
      Object.keys(updatedPosition).forEach((key) => {
        if (key !== type) {
          updatedPosition[key as checkPositionsTypes] = false;
        }
      });
    }
    setCheckedPosition(updatedPosition);
  }

  function IsActiveDropDownElement(type: checkPositionsTypes): string {
    return checkedPosition[type] ? 'active' : '';
  }

  const toggleCheckbox = () => {
    if (!checked) {
      OnDropDownClick('all');
    } else {
      resetChecked();
    }
  };
  //TODO: refactoring classnames
  const rowClassName = () => {
    let cn = '';
    if (isModalPriceList) cn += 'modalPriceList';
    if (isDarkTheme) cn += 'dark';
    return cn;
  };

  const isDarkTheme = theme === 'dark';
  return (
    <TableHeadRowContainer className={rowClassName()}>
      {!isModalPriceList && <ButtonTableCellContainer></ButtonTableCellContainer>}
      <ButtonTableCellContainer className={isModalPriceList ? 'modalPriceList' : ''}>
        <div style={{ position: 'relative' }} ref={checkBoxWrapperRef}>
          <Checkbox toggleCheckbox={toggleCheckbox} checked={checked} />
          {checkBoxHovered && (
            <TableHeadDropDownContainer>
              <DropdownBody className={isDarkTheme ? 'dark' : ''}>
                <DropdownElement
                  className={isDarkTheme ? `dark ${IsActiveDropDownElement('all')}` : IsActiveDropDownElement('all')}
                  onClick={() => OnDropDownClick('all')}
                >
                  {text[language].ChooseAll}
                </DropdownElement>
                <DropdownElement
                  className={
                    isDarkTheme ? `dark ${IsActiveDropDownElement('materials')}` : IsActiveDropDownElement('materials')
                  }
                  onClick={() => OnDropDownClick('materials')}
                >
                  {text[language].ChooseMaterials}
                </DropdownElement>
                <DropdownElement
                  className={isDarkTheme ? `dark ${IsActiveDropDownElement('work')}` : IsActiveDropDownElement('work')}
                  onClick={() => OnDropDownClick('work')}
                >
                  {text[language].ChooseWork}
                </DropdownElement>
                <DropdownElement
                  className={
                    isDarkTheme
                      ? `dark ${IsActiveDropDownElement('allCategories')}`
                      : IsActiveDropDownElement('allCategories')
                  }
                  onClick={() => OnDropDownClick('allCategories')}
                >
                  {text[language].ChooseAllCategories}
                </DropdownElement>
                <DropdownElement
                  className={
                    isDarkTheme
                      ? `dark ${IsActiveDropDownElement('withoutCategories')}`
                      : IsActiveDropDownElement('withoutCategories')
                  }
                  onClick={() => OnDropDownClick('withoutCategories')}
                >
                  {text[language].ChoosePositionsWithoutCategory}
                </DropdownElement>
              </DropdownBody>
            </TableHeadDropDownContainer>
          )}
        </div>
      </ButtonTableCellContainer>
      <ButtonTableCellContainer className={isModalPriceList ? 'modalPriceList' : ''}></ButtonTableCellContainer>
      <MainTableCellContainer className={isModalPriceList ? 'modalPriceList' : ''}>
        {text[language].PositionName}
      </MainTableCellContainer>
      <SecondaryTableCellContainer className={isModalPriceList ? 'modalPriceList' : ''}>
        {text[language].Units}
      </SecondaryTableCellContainer>
      <PriceTableCellContainer className={isModalPriceList ? 'modalPriceList' : ''}>
        {text[language].Total}
      </PriceTableCellContainer>
      <PriceTableCellContainer className={isModalPriceList ? 'modalPriceList' : ''}>
        {text[language].Cost}
      </PriceTableCellContainer>
      {!isMobile && !isModalPriceList && <ButtonTableCellContainer></ButtonTableCellContainer>}
      {!isModalPriceList && <ButtonTableCellContainer></ButtonTableCellContainer>}
    </TableHeadRowContainer>
  );
};

export default PriceListTableHead;
