export const API_CONFIG = {
  BASE_URL: process.env.NODE_ENV === 'development' ? 'https://dev.gubadoo.app' : 'https://api.gubadoo.app',
};

export const HEADERS = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Credentials': 'true',
};

export const HEADERS_FOR_EMAIL_REDIRECT = {
  'CONFIRMREDIRECT': 'web',
};

export const USER_STATUS = 'professional';
