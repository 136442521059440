import React from 'react';
import { AddCustomerValues } from '../../model/defaultValues';
import { AddCustomerModalContainer, InputGroups } from '../styles';
import { Input } from 'shared/ui';
import { text } from 'shared/constants';
import { formatPhoneNumber, useAppSelector } from 'shared/lib';

type Props = {
  customerInfo: AddCustomerValues;
  setCustomerInfo: React.Dispatch<React.SetStateAction<AddCustomerValues>>;
};

export const CreateCustomerForm: React.FC<Props> = ({ customerInfo, setCustomerInfo }) => {
  const language = useAppSelector((state) => state.language.language);
  const theme = useAppSelector((state) => state.theme.theme);

  const isDarkTheme = theme === 'dark';
  return (
    <AddCustomerModalContainer>
      <InputGroups className={isDarkTheme ? 'dark' : ''}>
        <span>{text[language].customersInfo}</span>
        <Input
          placeholder={text[language].firstName}
          value={customerInfo.firstName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setCustomerInfo({ ...customerInfo, firstName: event.target.value });
          }}
        />
        <Input
          placeholder={text[language].lastName}
          value={customerInfo.lastName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setCustomerInfo({ ...customerInfo, lastName: event.target.value });
          }}
        />
        <Input
          type="tel"
          placeholder={text[language].phoneNumberNotRequired}
          value={customerInfo.phoneNumber || ''}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const phoneNumber = formatPhoneNumber(event.target.value);
            setCustomerInfo({ ...customerInfo, phoneNumber });
          }}
        />
      </InputGroups>
      <InputGroups className={isDarkTheme ? 'dark' : ''}>
        <span>{text[language].accessToCompany}</span>
        <Input
          type="email"
          placeholder={text[language].customerEmail}
          value={customerInfo.email}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setCustomerInfo({ ...customerInfo, email: event.target.value });
          }}
        />
      </InputGroups>
    </AddCustomerModalContainer>
  );
};
