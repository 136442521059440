import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { AddCurrencyModal, CurrencyRateModal } from 'widgets';
import { CurrenciesType } from 'entities/priceList';
import { text } from 'shared/constants';
import { useAppSelector } from 'shared/lib';
import { ActionButton, ModalWrapper } from 'shared/ui';
import { ButtonsWrapper, TextWrapper } from './styles';
import { CommonModalContainer } from '../../styles';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
}

export const AddCurrencyAlertModal: React.FC<Props> = ({ isOpen, handleClose }) => {
  const language = useAppSelector((state) => state.language.language);
  const projects = useAppSelector((state) => state.projects.projects);
  const theme = useAppSelector((state) => state.theme.theme);
  const [isOpenAddCurrencyModal, setIsOpenAddCurrencyModal] = useState(false);
  const [isOpenCurrencyRateModal, setIsOpenCurrencyRateModal] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState<string | null>(null);

  const { projectId } = useParams();
  const currentCurrency = projects?.find((proj) => proj.id.toString() === projectId)?.currency as CurrenciesType;

  function addCurrencyButton() {
    setIsOpenAddCurrencyModal(true);
  }
  const isDarkTheme = theme === 'dark';
  return (
    <ModalWrapper isOpen={isOpen} title={text[language].currencyPriceList} onCloseButtonClick={handleClose}>
      <CommonModalContainer>
        <TextWrapper className={isDarkTheme ? 'dark' : ''}>
          <p>{text[language].addCurrencyToPriceList}</p>
        </TextWrapper>
      </CommonModalContainer>
      <ButtonsWrapper>
        <ActionButton
          orangeBtn
          text={text[language].AddCurrency}
          onClick={addCurrencyButton}
          isPriceListMainButton={true}
        />
      </ButtonsWrapper>
      <AddCurrencyModal
        projectCurrency={currentCurrency}
        isOpen={isOpenAddCurrencyModal}
        handleClose={() => setIsOpenAddCurrencyModal(false)}
        selectedCurrency={selectedCurrency}
        setIsOpenCurrencyRateModal={setIsOpenCurrencyRateModal}
        setSelectedCurrency={setSelectedCurrency}
      />
      <CurrencyRateModal
        isOpen={isOpenCurrencyRateModal}
        handleClose={() => setIsOpenCurrencyRateModal(false)}
        selectedCurrencies={[selectedCurrency]}
        type="creatingNewCurrency"
        handleCloseImportModal={() => {
          setIsOpenAddCurrencyModal(false);
        }}
      />
    </ModalWrapper>
  );
};
